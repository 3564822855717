import React, { Component } from 'react';
import Layout from '../../components/Layout';
import OtherServicesApplications from './OtherServicesApplications';

class route_orders extends Component {
	render() {
		return (
			<Layout>
				<OtherServicesApplications history={this.props.history} />
			</Layout>
		);
	}
}

export default route_orders;
