import React, {useContext, useState} from "react";
import API from "../../../../../lib/API"
import Button from "../../../../../components/Button";
import cx from "classnames";
import s from "../../../../../components/Dictionary/Dictionary.module.css";
import {AuthContext} from "../../../../../lib/AuthContext";

function EditItem (props) {
  const { item, closePopup, superadmin, departments, TNSDepartments } = props
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})

  const [ name, setName ] = useState(item.name)
  const [ email, setEmail] = useState(item.email)
  const [ departmentId, setDepartmentId ] = useState(item.department_id)
  const [ TNSDepartmentId, setTNSDepartmentId ] = useState(item.department_id)
  const [ permissionId, setPermissionId ] = useState(item.permission_id)
  const [ password, setPassword] = useState("")
  const [ passwordConfirmation, setPasswordConfirmation] = useState("")

  const {authContext} = useContext(AuthContext)

  let tnsAdminId, tnsSuperAdminId

  for (let entry of props.permissions) {
    if (entry[1].name === "tns_admin") {
      tnsAdminId = entry[0]
    }

    if (entry[1].name === 'tns_superadmin') {
      tnsSuperAdminId = entry[0]
    }
  }

  function getPermissionOptions() {
    const options = [];
    if (authContext.permissions.name === 'superadmin')
      for (let entry of props.permissions) {
        options.push(<option key={entry[0]} value={entry[0]}>{entry[1].caption}</option>);
      }

    if (authContext.permissions.name === 'technical_superadmin')
      for (let entry of props.permissions) {
        if (entry[1].name === 'technical_superadmin' || entry[1].name === 'technical_admin')
         options.push(<option key={entry[0]} value={entry[0]}>{entry[1].caption}</option>);
      }

    if (authContext.permissions.name === 'tns_superadmin')
      for (let entry of props.permissions) {
        if (entry[1].name === 'tns_superadmin' || entry[1].name === 'tns_admin')
          options.push(<option key={entry[0]} value={entry[0]}>{entry[1].caption}</option>);
      }

    if (authContext.permissions.name === 'other_services_superadmin')
      for (let entry of props.permissions) {
        if (entry[1].name === 'other_services_superadmin' || entry[1].name === 'other_services_admin')
          options.push(<option key={entry[0]} value={entry[0]}>{entry[1].caption}</option>);
      }

    return options;
  }

  async function editAdmin() {
    const { update } = props

    setLoading(true)

    try {
      const data = {
        id: item.id,
          name,
          email,
          password,
          password_confirmation: passwordConfirmation,
        permission_id: permissionId,
      }

      if (parseInt(permissionId) === tnsAdminId || parseInt(permissionId) === tnsSuperAdminId)
        data.department_id = TNSDepartmentId
      else
        data.department_id = departmentId

      await API.usersAPI.updateAdminUser(data);

      update("Администратор изменен");
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Редактирование администратора</h5>
          <Button
            className="close"
            aria-label="Close"
            onClick={closePopup}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>

        <div className="modal-body">
          {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
            {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
              key={`${index}${indexMsg}`}>{errorMsg}</div>))}
          </div>}

          <div className="form-group">
            <label htmlFor="name">Имя</label>

            <input
              type="text"
              className={cx("form-control", {[s['error-field']]: errors['name']})}
              id="name"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-mail</label>

            <input
              type="email"
              className={cx("form-control", {[s['error-field']]: errors['email']})}
              id="email"
              aria-describedby="emailHelp"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>

          <div className="form-group">
            <label>Права доступа</label>
            <select
              className="custom-select"
              value={permissionId}
              onChange={e => setPermissionId(e.currentTarget.value)}
            >
              { getPermissionOptions() }
            </select>
          </div>

          {superadmin && (parseInt(permissionId) !== tnsAdminId && parseInt(permissionId) !== tnsSuperAdminId) && departments && departments.length &&
          <div className="form-group">
            <label>Подразделение</label>
            <select
              className="custom-select"
              value={departmentId}
              onChange={e => setDepartmentId(e.currentTarget.value)}
            >
              <option value="0">Все</option>
              {departments.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
          </div>
          }

          {superadmin && (parseInt(permissionId) === tnsAdminId || parseInt(permissionId) === tnsSuperAdminId) && TNSDepartments && TNSDepartments.length &&
          <div className="form-group">
            <label>Подразделение ТНС</label>
            <select
              className="custom-select"
              value={TNSDepartmentId}
              onChange={e => setTNSDepartmentId(e.currentTarget.value)}
            >
              <option value="0">Все</option>
              {TNSDepartments.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
          </div>
          }

          <div className="form-group">
            <label htmlFor="password">Пароль</label>

            <input
              type="password"
              className={cx("form-control", {[s['error-field']]: errors['password']})}
              id="password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="passwordConfirmation">Подтвердить пароль</label>

            <input
              type="password"
              className={cx("form-control", {[s['error-field']]: errors['password']})}
              id="passwordConfirmation"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="modal-footer">
          <Button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            disabled={loading}
            onClick={closePopup}
          >
            Отмена
          </Button>

          <Button
            className="btn btn-success"
            onClick={editAdmin}
            loading={loading}
            disabled={loading}
          >
            Изменить
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditItem;