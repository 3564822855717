import React, {Component, useContext, useState} from 'react';
import s from './Layout.module.css';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { getCookie, deleteCookie } from '../../lib/utils';
import 'react-hint/css/index.css'
import {AuthContext} from "../../lib/AuthContext";
import {PopupContext} from "../../lib/PopupContext";
import Popup from "../Popup";

function Layout (props) {
	const { authContext, setAuthContext } = useContext(AuthContext)
	const [ popupSettings, setPopupSettings ] = useState(null)

	const
		access = getCookie('access_token') || localStorage.getItem('access_token'),
		admin = !!authContext?.admin,
		authIsOff = authContext?.authIsOff,
		loginPage = window.location.pathname === '/login'

	return (
		<div className={s['layout']}>
			<div className="container">
				<nav className={cx('navbar', 'navbar-expand-md', s['navbar'])}>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<a className={cx('navbar-brand', s['logo'])} href="http://www.donenergo.ru">
						<img src="/images/logo.svg" alt="" />
					</a>

					{/*<div className="collapse navbar-collapse justify-content-start" id="navbarNav">
						<ul className="navbar-nav">
							{access && admin && <li className="nav-item">
								<Link className="nav-link" to="/admin">Админ. консоль</Link>
							</li>}

							{!admin && <li className="nav-item">
								<Link className="nav-link" to="/technical">Технологическое присоединение</Link>
							</li>}
						</ul>
					</div>*/}

					<div className={cx(s['nav'], 'justify-content-end')}>
						<ul className={cx('navbar-nav', s['navbar-nav'])}>
							{/* {!authIsOff && !access && <li className={cx('nav-item', s['nav-item'])}>
								<Link className="nav-link" to="/registration"><span>Регистрация</span></Link>
							</li>} */}

							{!authIsOff && access && !admin && <li className="nav-item">
								<Link className="nav-link" to="/cabinet">Личный кабинет</Link>
							</li>}

							{access && admin && <li className="nav-item">
								<Link className="nav-link" to="/admin">Админ. консоль</Link>
							</li>}

							{!loginPage && <li className={cx('nav-item', s['nav-item'])}>
								{access ?
									<Link className="nav-link" to="/login" onClick={() => {
										deleteCookie('access_token');
										deleteCookie('admin');
										localStorage.removeItem('access_token');
										localStorage.removeItem('admin');
										setAuthContext(null)
									}}><span>Выйти</span></Link> :
									<Link className={cx('nav-link', s['login'])} to="/login">
										<img src="/images/user.svg" alt=""/>
										<span>Войти в личный кабинет</span>
									</Link>}
							</li>}

							{authIsOff && <li><span className="bg-danger text-white p-2">Сервис временно не доступен</span></li>}
						</ul>
					</div>
				</nav>
			</div>
			<PopupContext.Provider value={{popupSettings, setPopupSettings}}>
				<Popup/>
				{props.children}
			</PopupContext.Provider>
		</div>
	);
}

export default Layout;
