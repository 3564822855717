import BasicAPI from "./BasicAPI";

class PaychecksAPI extends BasicAPI {
  constructor(prefix) {
    super();
    this.prefix = prefix;
  }

  get (data) {
    return this.sendRequest(this.prefix + '/get', data);
  }

  delete (data) {
    return this.sendRequest(this.prefix + '/delete', data);
  }

  upload(data) {
    return this.sendRequest(this.prefix + '/upload', data);
  }

  pay (data) {
    return this.sendRequest(this.prefix + '/pay', data);
  }
}

export default PaychecksAPI;