import React, { useEffect, useState } from 'react';
import s from './Profile.module.css';
import cx from 'classnames';
import Button from '../../components/Button';
import MaskedInput from 'react-text-mask';
import API from "../../lib/API";

function Profile () {
	const [ loading, setLoading] = useState(true);
	const [ data, setData ] = useState(null);
	const [ errors, setErrors ] = useState(null);

	const [ phoneIsEditing, setPhoneIsEditing ] = useState('');
	const [ phoneSetInProgress, setPhoneSetInProgress ] = useState(false);
	const [ newPhone, setNewPhone ] = useState('');
	const [ newPhoneIsSet, setNewPhoneIsSet ] = useState(false);
	const [ phoneSettingError, setPhoneSettingError ] = useState(false);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await API.usersAPI.getProfile()
				setData(data)
			} catch (e) {
				console.log(e);
				setErrors(e?.response?.errors)
			}

			setLoading(false)
		}

		fetchData()

	}, [])

	async function updatePhone () {
		if (!newPhone.trim())
			return

		await setPhoneSetInProgress(true);

		const parsedPhone = newPhone.match(/\d+/g).join('');

		try {
			await API.usersAPI.setPhone({ phone: parsedPhone })
			setData({ ...data, phone: parsedPhone })
			setNewPhoneIsSet(true)
			setPhoneIsEditing(false)
			setPhoneSettingError(false)
			setTimeout(() => {
				setNewPhoneIsSet(false);
			}, 5000);
		} catch (e) {
			console.log(e)
			setPhoneSettingError(e?.response?.errors)
		}

		setPhoneSetInProgress(false)
	}

	return (
		<div className={s['main']}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<span>Профиль</span>

							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Загрузка...</span>
							</div>}
						</div>

						{errors && Object.keys(errors).length > 0 && <div className="mb-3">
							<p>Ошибка загрузки данных</p>
							<Button className="btn btn-success" onClick={() => {window.location.reload()}}>Перезагрузить</Button>
						</div>}
					</div>

					{data && <div className="col-12">
						<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
							<tbody>
							{data.type ? <tr>
								<td>Тип</td>
								<td>{data.type === 'fiz' ? 'Физическое лицо' : data.type === 'ip' ? 'Индивидуальный предприниматель' : data.type === 'ur' ? 'Юридическое лицо' : 'Неопределено'}</td>
							</tr> : null}

							{data.name ? <tr>
								<td>{(data.type === 'fiz' || data.type === 'ip') ? 'ФИО' : 'Наименование организации'}</td>
								<td>{data.name}</td>
							</tr> : null}

							{data.egrnum ? <tr>
								{data.type === 'fiz' && <td>СНИЛС</td>}
								{data.type === 'ip' && <td>ЕГРИП</td>}
								{data.type === 'ur' && <td>ЕГРЮЛ</td>}
								<td>{data.egrnum}</td>
							</tr> : null}

							{data.ogrn ? <tr>
								<td>ОГРН</td>
								<td>{data.ogrn}</td>
							</tr> : null}

							{data.email ? <tr>
								<td>Email</td>
								<td>{data.email}</td>
							</tr> : null}

							{data.phone ? <tr>
								<td>Номер телефона</td>
								<td>
									<div className={s['phone-alerts']}>
										{newPhoneIsSet && <div className="alert alert-success">Номер телефона успешно изменен</div>}
										{phoneSettingError && <div className="alert alert-danger">
											{Object.keys(phoneSettingError).map(key => <div key={key}>{phoneSettingError[key]}</div>)}
										</div>}
									</div>

									<div className={s['phone']}>
										{phoneIsEditing ?
											<MaskedInput
												mask={['+', '7', ' ', '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
												guide={false}
												className={cx('form-control', s['phone__input'])}
												value={newPhone}
												disabled={phoneSetInProgress}
												onChange={ e => setNewPhone(e.target.value) }
											/>
											: data.phone ? <div className={s['phone__title']}>
												+{data.phone}
											</div> : null}

										{phoneIsEditing ? <div className={s['phone__controls']}>
											<Button
												className="btn btn-primary btn-sm"
												onClick={updatePhone}
												disabled={phoneSetInProgress}
												loading={phoneSetInProgress}
											>
												Сохранить
											</Button>

											<Button
												className="btn btn-danger btn-sm"
												onClick={ () => {
													setPhoneIsEditing(false)
													setPhoneSettingError(null)
													setNewPhoneIsSet(false)
												}}
												disabled={phoneSetInProgress}
												loading={phoneSetInProgress}
											>
												Отменить
											</Button>
										</div> : <Button
											className="btn btn-primary btn-sm"
											onClick={ () => setPhoneIsEditing(true) }
										>
											Изменить
										</Button>}
									</div>
								</td>
							</tr> : null}
							</tbody>
						</table>
					</div>}
				</div>
			</div>
		</div>
	)
}

export default Profile;