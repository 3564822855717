import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useRef} from "react";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabPersonal () {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors

  const datepicker = useRef()

  //console.log(appData)
  return <div className="col-12">
    {(appData.type === 'fiz' || appData.type === 'ip') && <>
      <div className="form-group">
        <div className="h5">Данные заявителя</div>
      </div>

      <div className="form-group">
        <label htmlFor="name">ФИО <span className={s['red-star']}>*</span></label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors.name})}
          id="name"
          value={appData.name}
          onChange={e => dispatch({type: "setField", payload: {field: "name", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="inn">ИНН <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.inn})}
          id="inn"
          value={appData.inn}
          onChange={e => dispatch({type: "setField", payload: {field: "inn", value: e.currentTarget.value}})}
        />

        {errors.inn && <div className={s['error-msg']}>{errors.inn}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="snils">СНИЛС <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.snils})}
          id="snils"
          value={appData.snils}
          onChange={e => dispatch({type: "setField", payload: {field: "snils", value: e.currentTarget.value}})}
        />

        {errors.snils && <div className={s['error-msg']}>{errors.snils}</div>}
      </div>
    </>}
    {appData.type === 'ip' && <>
      <div className="form-group">
        <label htmlFor="egrip">ЕГРИП <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={(raw) => {
            let arr = [];
            for (let i = 0; i < raw.length; i++) {
              arr.push(/[0-9]/);
            }
            return arr;
          }}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.egrip})}
          id="egrip"
          value={appData.egrip}
          onChange={e => dispatch({type: "setField", payload: {field: "egrip", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="egrul">Дата внесения в реестр <span className={s['red-star']}>*</span></label>

        <div className="input-group">
          <DatePicker
            className={cx("form-control", {[s['error-field']]: errors.egrip_register_date})}
            selected={appData.egrip_register_date}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={date => dispatch({type: "setField", payload: {field: "egrip_register_date", value: date}})}
            ref={datepicker}
          />

          <div
            className="input-group-append"
            style={{cursor: 'pointer'}}
            // onClick={() => {this.datepicker.setOpen(true)}}
          >
            <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
          </div>
        </div>
      </div>
    </>}

    {appData.type === 'ur' && <>
      <div className="form-group">
        <label htmlFor="name">Наименование организации <span className={s['red-star']}>*</span></label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors.name})}
          id="name"
          value={appData.name}
          onChange={e => dispatch({type: "setField", payload: {field: "name", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="position">Должность руководителя, ФИО <span className={s['red-star']}>*</span></label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors.position})}
          id="position"
          value={appData.position}
          onChange={e => dispatch({type: "setField", payload: {field: "position", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="inn">ИНН</label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.inn})}
          id="inn"
          value={appData.inn}
          onChange={e => dispatch({type: "setField", payload: {field: "inn", value: e.currentTarget.value}})}
        />

        {errors.inn && <div className={s['error-msg']}>{errors.inn}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="kpp">КПП</label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.kpp})}
          id="kpp"
          value={appData.kpp}
          onChange={e => dispatch({type: "setField", payload: {field: "kpp", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="ogrn">ОГРН</label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.ogrn})}
          id="ogrn"
          value={appData.ogrn}
          onChange={e => dispatch({type: "setField", payload: {field: "ogrn", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="egrul">ЕГРЮЛ <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={(raw) => {
            let arr = [];
            for (let i = 0; i < raw.length; i++) {
              arr.push(/[0-9]/);
            }
            return arr;
          }}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.egrul})}
          id="egrul"
          value={appData.egrul}
          onChange={e => dispatch({type: "setField", payload: {field: "egrul", value: e.currentTarget.value}})}
        />
      </div>

      <div className="form-group">
        <label htmlFor="egrul">Дата внесения в реестр <span className={s['red-star']}>*</span></label>

        <div className="input-group">
          <DatePicker
            className={cx("form-control", {[s['error-field']]: errors.egrul_register_date})}
            selected={appData.egrul_register_date}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={date => dispatch({type: "setField", payload: {field: "egrul_register_date", value: date}})}
            ref={datepicker}
          />

          <div
            className="input-group-append"
            style={{cursor: 'pointer'}}
            onClick={() => {datepicker.current.setOpen(true)}}
          >
            <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
          </div>
        </div>
      </div>
    </>}
  </div>
}

export default TabPersonal;