import React, {useContext, useEffect, useState} from "react";
import Button from "../../../../../components/Button";
import cx from "classnames";
import s from "../../../../../components/Dictionary/Dictionary.module.css";
import {AuthContext} from "../../../../../lib/AuthContext";

function EditItem (props) {
  const { item, update, success, dictionaryAPI, headers, fields, closePopup } = props
  const [ values, setValues ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})
  const { authContext } = useContext(AuthContext)

  useEffect(() => {
    const initialValues = {}

    for (let field of props.fields) {
      initialValues[field] = item && item[field] || item[field] === 0 ? item[field] : ""
    }

    setValues(initialValues)
  }, [])

  function handleChangeInput(field, value) {
    values[field] = value;
    setValues({...values})
  }

  async function newItem () {
    setLoading(true)

    try {
      await dictionaryAPI.create(values)
      update(success ? success : "Запись добавлена")
    } catch (e) {
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  async function editItem () {
    setLoading(true)

    try {
      await dictionaryAPI.update({
        id: item.id,
        ...values
      })

      update(success ? success : "Запись изменена")
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  const popupEdit = props.item !== false

  return <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">{props.title ? props.title : popupEdit ? 'Изменить запись' : 'Новая запись'}</h5>
      <Button
        className="close"
        aria-label="Close"
        onClick={closePopup}
      >
        <span aria-hidden="true">&times;</span>
      </Button>
    </div>

    <div className="modal-body">
      {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
        {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
          key={`${index}${indexMsg}`}>{errorMsg}</div>))}
      </div>}

      {Object.keys(values).length > 0 && fields.map(field => (field !== 'id' && field !== 'created_at' && field !== 'updated_at') &&
        (field !== 'system' || authContext.superadmin) &&
        <div key={field} className="form-group">
          <label htmlFor={field}>{headers[field] ? headers[field] : field}</label>
          {field === 'system' && <div>
            <sup className="bg-danger text-white">
              Для нормальной работы системы необходимо иметь записи с системными кодами
              <b> new, increase, tempful, temp150, micro, microcat3</b></sup>
          </div>}
          <input
            type="text"
            className={cx("form-control", {[s['error-field']]: errors[field]})}
            id={field}
            value={values[field]}
            onChange={(e) => {
              handleChangeInput(field, e.currentTarget.value)
            }}
          />
        </div>
      )}
    </div>

    <div className="modal-footer">
      <Button
        type="button"
        className="btn btn-secondary"
        data-dismiss="modal"
        disabled={loading}
        onClick={closePopup}
      >Отмена</Button>

      {popupEdit ? <Button
        className="btn btn-success"
        onClick={editItem}
        loading={loading}
        disabled={loading}
      >Изменить</Button> : <Button
        className="btn btn-success"
        onClick={newItem}
        loading={loading}
        disabled={loading}
      >Добавить</Button>}
    </div>
  </div>
}

export default EditItem
