import React, {Component} from "react";
import s from "../components/ApplicationRequest.module.css"
import locale from "../../../lib/locale";

class ErrorsTab extends Component {
  render() {
    const { percent, validationErrors, serverErrors } = this.props;
    let printErrors = [...new Set(Object.values(validationErrors))]; //delete duplicate messages
    let printServerErrors = [...new Set(Object.values(serverErrors))]; //delete duplicate messages

    return <div className={s['statusForm']}>
      <div className="mb-3 text-center">Статус заполнения формы</div>

      <div className="progress mb-3">
        <div className="progress-bar" role="progressbar" style={{width: `${percent}%`}} aria-valuenow={percent}
             aria-valuemin="0" aria-valuemax="100">{percent}%
        </div>
      </div>

      {percent === 100 && <div className="alert alert-success mt-3">Заявка готова к отправке</div>}

      {(printErrors.length > 0 || Object.keys(printServerErrors).length > 0) && <div className={s['messages']}>

        {printServerErrors.length > 0 && <div className="alert alert-danger">
          {printServerErrors.map((error, index) => {
            return <div key={index}> {locale.errors[error] ? locale.errors[error] : error}</div>
          })}
        </div>}

        {printErrors.length > 0 && <div className="alert alert-warning">
          {printErrors.map((error, index) => {
            return <div key={index}> {error}</div>
          })}
        </div>}

      </div>}
    </div>
  }
}

export default ErrorsTab;