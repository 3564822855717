import React, {Component, useEffect, useRef, useState} from 'react';
import s from './AdminLogs.module.css';
import cx from 'classnames';
import API from '../../../lib/API';
import locale from '../../../lib/locale';

function AdminLogs (props) {
	const [ loading, setLoading ] = useState(false)
	const [ errors, setErrors] = useState({})
	const [ logs, setLogs ] = useState([])
	const [ pagination, setPagination ] = useState(null)
	const [ page, setPage ] = useState(1)
	const [ recordsCount, setRecordsCount ] = useState(20)

	useEffect(() => {
		const fetchData = async () => {
			const data = {
				page,
				count: recordsCount
			};

			setLoading(true)

			try {
				const response = await API.adminAPI.getLog(data)
				setPagination(response)
				setLogs(response.data)
				main.current.scrollTo(0,0)
			} catch (e) {
				console.error(e)
				setErrors(e.response.errors)
			}

			setLoading(false)
		}

		fetchData()
	}, [page, recordsCount])

	const main = useRef()

	return (
		<div className={s['main']} ref={main}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<span>Журнал событий</span>

							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Loading...</span>
							</div>}
						</div>

						{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
							{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
						</div>}
					</div>

					{logs?.length > 0 && <div className="col-12">
						<div className={s['wrap-table']}>
							<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
								<tbody>
									<tr>
										<td className="text-center">Пользователь</td>
										<td className="text-center">Действие</td>
										<td className="text-center">ID заявки</td>
										<td className="text-center">Справочник</td>
									</tr>

									{logs.map(item => <tr key={item.id}>
										<td className="text-center">
											{item.user.id} - {item.user.name}({item.user.email})
										</td>
										<td className="text-center">
											{item.action}
										</td>
										<td className="text-center">
											{item.application_id}
										</td>
										<td className="text-center">
											{locale.dictionaries[item.dictionary] ? locale.dictionaries[item.dictionary] : item.dictionary}
										</td>
									</tr>)}
								</tbody>
							</table>
						</div>
					</div>}

					{pagination && <div className="col-12 mt-4">
						<nav className={cx({[s['pagination-load']]: loading})}>
							<ul className={cx('pagination', s['pagination'])}>
								<li
									className={cx('page-item', {'disabled': pagination.current_page === 1})}
									onClick={() => setPage(pagination.current_page - 1)}
								><span className="page-link" aria-label="Previous"><span aria-hidden="true">&laquo;</span></span></li>

								{Array.from({ length: pagination.last_page }, (item, index) => {
									if (
										(index + 1 > pagination.current_page - 5 && index + 1 < pagination.current_page + 5) ||
										(index === 0) ||
										(index + 1 === pagination.last_page)
									) {
										//console.log(index+1);
										return <li
											key={index}
											className={cx('page-item', {'active': pagination.current_page === index + 1})}
											onClick={() => setPage(index + 1)}
										><span className="page-link">{index + 1}</span></li>
									}
								})}

								<li
									className={cx('page-item', {'disabled': pagination.current_page === pagination.last_page})}
									onClick={() => setPage(pagination.current_page + 1)}
								><span className="page-link" aria-label="Next"><span aria-hidden="true">&raquo;</span></span></li>
							</ul>
						</nav>
					</div>}
				</div>
			</div>
		</div>
	)
}

export default AdminLogs