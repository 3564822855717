import React, { useContext } from 'react';
import Layout from '../../../components/Layout';
import Login from './Login';
import {AuthContext} from "../../../lib/AuthContext";
import {Redirect} from "react-router-dom";
import routes from "../../../routes"


function AuthLoginRoute (props) {
	const { authContext } = useContext(AuthContext)

	if (authContext)
		if (authContext?.admin)
			return <Redirect to={ routes.AdminDashboardRoute.path } />
		else
		 return <Redirect to={ routes.CabinetRoute.path } />
	else
		return <Layout authIsOff={props.authIsOff}>
			<Login authIsOff={props.authIsOff}/>
		</Layout>
}

export default AuthLoginRoute;
