import React, {Component, useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import PageSpinner from '../../components/PageSpinner';
import Calc from './Calc';
import { checkUser } from '../../lib/utils';
import { Redirect } from 'react-router-dom';
import API from "../../lib/API";

function TechnicalCalcRoute (props) {
	const [ loading, setLoading ] = useState(true)
	const [ prices, setPrices ] = useState(null)

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			try {
				const response = await API.adminAPI.getCalcs()
				const newPrices = {}
				response.forEach(value => {
					newPrices[value.field] = parseFloat(value.price)
				});

				setPrices(newPrices)
			} catch (e) {
				console.error(e)
			}

			setLoading(false)
		}

		fetchData()
	}, [])

	if (loading)
		return <PageSpinner/>
	else
		return (
		<Layout>
			<Calc prices={prices}/>
		</Layout>
	)
}

export default TechnicalCalcRoute;
