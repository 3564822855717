import React from 'react'
import Layout from '../../../../components/Layout'
import Dictionary, {DefaultDeleteItem, DefaultEditItem} from "../../../../components/Dictionary"
import API from "../../../../lib/API"
import routes from "../../../index";

const NewItem = (props) => <DefaultEditItem
	title="Добавить тип услуги"
	success="Тип услуги добавлен"
	{...props}
/>

const EditItem = (props) => <DefaultEditItem
	title="Изменить тип услуги"
	success="Тип услуги изменен"
	{...props}
/>

const DeleteItem = (props) => <DefaultDeleteItem
	vocabulary={{
		title: "Удаление типа услуги",
		prompt: "Вы уверены что хотите удалить тип услуги?",
		success: "Тип услуги удален"
	}}
	{...props}
/>

function OtherServicesTypesDictionaryRoute (props) {
	return (
		<Layout>
			<Dictionary
				backRoute={routes.AdminDashboardOtherServicesDictionariesRoute.path}
				dictionaryAPI={API.otherServicesTypesDictionaryAPI}
				vocabulary={{
					name: "Типы прочих услуг",
					headers: {id: "ID", priority: "Положение в списке", name: "Наименования"}
				}}
				popups={ { NewItem, EditItem, DeleteItem } }
			/>
		</Layout>
	)
}

export default OtherServicesTypesDictionaryRoute
