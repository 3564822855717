import React, {Component, useContext, useEffect, useRef, useState} from 'react'
import s from './Dictionary.module.css'
import cx from 'classnames'
import Button from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import routes from "../../routes"
import {PopupContext} from "../../lib/PopupContext";

export function DefaultEditItem (props) {
  const { item, update, success, dictionaryAPI, headers, fields, closePopup } = props
  const [ values, setValues ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    const initialValues = {}

    for (let field of props.fields) {
      initialValues[field] = item && item[field] || item[field] === 0 ? item[field] : ""
    }

    setValues(initialValues)
  }, [])

  function handleChangeInput(field, value) {
    values[field] = value;
    setValues({...values})
  }

  async function newItem () {
    setLoading(true)

    try {
      await dictionaryAPI.create(values)
      update(success ? success : "Запись добавлена")
    } catch (e) {
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  async function editItem () {
    setLoading(true)

    try {
      await dictionaryAPI.update({
        id: item.id,
        ...values
      })

      update(success ? success : "Запись изменена")
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  const popupEdit = props.item !== false

  return <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">{props.title ? props.title : popupEdit ? 'Изменить запись' : 'Новая запись'}</h5>
      <Button
        className="close"
        aria-label="Close"
        onClick={closePopup}
      >
        <span aria-hidden="true">&times;</span>
      </Button>
    </div>

    <div className="modal-body">
      {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
        {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
          key={`${index}${indexMsg}`}>{errorMsg}</div>))}
      </div>}

      {Object.keys(values).length > 0 && fields.map(field => (field !== 'id' && field !== 'created_at' && field !== 'updated_at') &&
        <div key={field} className="form-group">
          <label htmlFor={field}>{headers[field] ? headers[field] : field}</label>

          <input
            type="text"
            className={cx("form-control", {[s['error-field']]: errors[field]})}
            id={field}
            value={values[field]}
            onChange={(e) => {
              handleChangeInput(field, e.currentTarget.value)
            }}
          />
        </div>
      )}
    </div>

    <div className="modal-footer">
      <Button
        type="button"
        className="btn btn-secondary"
        data-dismiss="modal"
        disabled={loading}
        onClick={closePopup}
      >Отмена</Button>

      {popupEdit ? <Button
        className="btn btn-success"
        onClick={editItem}
        loading={loading}
        disabled={loading}
      >Изменить</Button> : <Button
        className="btn btn-success"
        onClick={newItem}
        loading={loading}
        disabled={loading}
      >Добавить</Button>}
    </div>
  </div>

}

export function DefaultDeleteItem (props) {
  const { item, dictionaryAPI, update, closePopup } = props

  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})
  const { setPopupSettings } = useContext(PopupContext)

  async function deleteItem() {
    setLoading(true)

    try {
      await dictionaryAPI.delete({id: item.id})
      update(vocabulary.success)
    } catch (e) {
      update()
      console.error(e)
      setPopupSettings({
        type: 'info',
        text: e.response.errors,
        error: true
      })
    }

    setLoading(false)
  }

  const vocabulary = {
    title: "Удаление записи",
    prompt: "Вы уверены что хотите удалить запись?",
    success: "Запись удалена"
  }

  if (props.vocabulary?.title)
    vocabulary.title = props.vocabulary.title

  if (props.vocabulary?.prompt)
    vocabulary.prompt = props.vocabulary.prompt

  if (props.vocabulary?.success)
    vocabulary.success = props.vocabulary.success

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{vocabulary.title}</h5>
        <Button
          className="close"
          aria-label="Close"
          onClick={closePopup}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>

      <div className="modal-body">
        {loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
          <span className="sr-only">Загрузка...</span>
        </div>}
        {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
          {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
            key={`${index}${indexMsg}`}>{errorMsg}</div>))}
        </div>}
        <div>{vocabulary.prompt}</div>
      </div>

      <div className="modal-footer">
        <Button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          disabled={loading}
          onClick={closePopup}
        >
          Отмена
        </Button>

        <Button
          className="btn btn-danger"
          onClick={deleteItem}
          loading={loading}
          disabled={loading}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  )
}

export function DefaultDictionaryItem (props) {
  const {item, fields, openPopup} = props

  return (
    <tr>
      {fields.map(value => <td className="text-center" key={value}>{typeof item[value] !== 'object' ? item[value] : ""}</td>)}
      <td className="text-center">
        <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
        <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
      </td>
    </tr>
  )
}

function Dictionary (props) {
  const { dictionaryAPI } = props
  const vocabulary = typeof props.vocabulary !== 'undefined' ? props.vocabulary : {name: "Справочник", headers: {}}
  let fields = Object.keys(vocabulary.headers).length > 0 ? Object.keys(vocabulary.headers) : []

  const [ data, setData ] = useState(null)
  const [ search, setSearch ] = useState('')
  const [ popupName, setPopupName] = useState(false)
  const [ popupProps, setPopupProps] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ errors, setErrors ] = useState({})
  const [ success, setSuccess ] = useState(false)
  const [ update, setUpdate ] = useState(true)
  const [ pagination, setPagination ] = useState({ current_page: 1, per_page: 20 })
  const [ page, setPage ] = useState(1)

  const DictionaryItem = (dictionaryProps) => {
    if (props.dictionaryItem)
      return <props.dictionaryItem {...dictionaryProps}/>
    else
      return <DefaultDictionaryItem {...dictionaryProps}/>
  }

  const Search = () => {
    if (props.search)
      return <props.search
        update={(searchString) => {
          setSearch(searchString.trim())
          setUpdate(true)
        }}
      />
    return <></>
  }

  const dataList = useRef()

  const popups = {
    EditItem: DefaultEditItem,
    DeleteItem: DefaultDeleteItem
  }

  for (let popup in props.popups) {
    popups[popup] = props.popups[popup]
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const dataRequestMethod = props.dataRequestMethod ? props.dataRequestMethod : 'get'

        const requestData = {
          'page': page,
          'count': pagination && pagination.per_page
        }

        if (search)
          requestData.search = search

        const result = (await dictionaryAPI[dataRequestMethod](requestData)).data

        if (result && typeof result.current_page !== 'undefined') {
          const newPagination = {...result}
          delete newPagination.data
          setPagination(newPagination)
          setData(result.data)
        } else {
          setPagination(null)
          setData(result)
        }

        if (fields.length === 0)
          fields = result.meta.fields

        if (Object.keys(vocabulary.headers).length === 0) {
          vocabulary.headers = {}
          fields.forEach(value => {
            vocabulary.headers[value] = value
          })
        }

      } catch (e) {
        console.error(e)
        setErrors(e.response.errors)
      }

      if (dataList.current)
        dataList.current.scrollTop = 0

      setPopupName(false)
      setUpdate(false)
      setLoading(false)
    }

    if (update) {
      fetchData()
    }
  }, [update])

  function openPopup(newPopupName, newPopupProps) {
    if (newPopupName === 'NewItem')
      if (!popups.NewItem)
        newPopupName = 'EditItem'

    setPopupName(newPopupName)
    setPopupProps(newPopupProps)
  }

  function closePopup() {
    setPopupName(false)
    setPopupProps(false)
  }

  async function changePage(page) {
    setPage(page)
    setUpdate(true)
  }

  const PopupContent = (props) => {
    if (props.popupName && popups[props.popupName]) {
      const Content = popups[props.popupName]
      return <Content {...props}/>
    } else {
      return <></>
    }
  }

  return (
    <div className={s['main']} ref={dataList}>
      <div className={cx('modal', {[s['modal--active']]: popupName})} tabIndex="-1" role="dialog">
        <PopupContent
          popupName = {popupName}
          dictionaryAPI={dictionaryAPI}
          headers={vocabulary.headers}
          fields={fields}
          closePopup={closePopup}
          update={(message) => {
            setSuccess(message)
            setUpdate(true)
          }}
          {...popupProps}
        />
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={cx('h2', s['title'])}>
              <span>{vocabulary.name}</span>

              {loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
                <span className="sr-only">Загрузка...</span>
              </div>}
            </div>

            <div className="mb-4">
              <Button className="btn btn-primary" to={props.backRoute ? props.backRoute : routes.AdminDashboardRoute.path}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                Назад
              </Button>

              <Button
                className="btn btn-success"
                onClick={() => openPopup('NewItem', { item: false})}
              >
                Добавить
              </Button>
            </div>

            {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
              {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
            </div>}
            {success &&
            <div className="alert alert-success" role="alert">
              <span>{success}</span>
            </div>
            }
          </div>

          <Search/>

          {data && <div className="col-12">
            <div className={s['wrap-table']}>
              <table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
                <tbody>
                  <tr>
                    {Object.values(vocabulary.headers).map((item, index) => <td key={index} className="text-center">{item}</td>)}
                    <td className="text-center">Действия</td>
                  </tr>

                  {data && data.map(item =>
                    <DictionaryItem
                      key={item.id}
                      fields={fields}
                      item={item}
                      openPopup={openPopup}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>}
        </div>

        {pagination && <div className="col-12 mt-4">
          <nav className={cx({[s['pagination-load']]: loading})}>
            <ul className={cx('pagination', s['pagination'])}>
              <li
                className={cx('page-item', {'disabled': pagination.current_page === 1})}
                onClick={() => {
                  if (pagination.current_page > 1)
                    changePage(pagination.current_page - 1)
                }}
              ><span className="page-link" aria-label="Previous"><span aria-hidden="true">&laquo;</span></span></li>

              {Array.from({ length: pagination.last_page }, (item, index) => {
                if (
                  (index + 1 > pagination.current_page - 5 && index + 1 < pagination.current_page + 5) ||
                  (index === 0) ||
                  (index + 1 === pagination.last_page)
                ) {
                  return <li
                    key={index}
                    className={cx('page-item', {'active': pagination.current_page === index + 1})}
                    onClick={() => {
                      changePage(index + 1)
                    }}
                  ><span className="page-link">{index + 1}</span></li>
                }
              })}

              <li
                className={cx('page-item', {'disabled': pagination.current_page === pagination.last_page})}
                onClick={() => {
                  if (pagination.current_page !== pagination.last_page)
                    changePage(pagination.current_page + 1)
                }}
              ><span className="page-link" aria-label="Next"><span aria-hidden="true">&raquo;</span></span></li>
            </ul>
          </nav>
        </div>}
      </div>
    </div>
  )
}

export default Dictionary