import React, {Component, useContext, useEffect, useState, useRef} from 'react'
import s from './AdminOtherServicesApplications.module.css'
import cx from 'classnames'
import API from '../../../lib/API'
import Button from '../../../components/Button'
import TableHeader from "./components/TableHeader"
import TableRow from "./components/TableRow"
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import fileDownload from 'js-file-download'
import routes from "../../index"
import {AuthContext} from "../../../lib/AuthContext"
import ColumnSettings from "./components/ColumnSettings";
import Filter from "./components/Filter";
import {SettingsContext} from "./SettingsContext";

registerLocale('ru', ru)
setDefaultLocale('ru')

function AdminOtherServicesApplications (props) {
	const { authContext } = useContext(AuthContext)

	const [ loading, setLoading ] = useState(true)
	const [ errors, setErrors ] = useState(null)
	const [ page, setPage ] = useState(props.page)
	const [ itemsPerPage, setItemsPerPage ] = useState(20)

	const [ statuses, setStatuses ] = useState({})
	const [ departments, setDepartments ] = useState({})
	const [ reasons, setReasons ] = useState({})
	const [ applications, setApplications ] = useState(null)
	const [ pagination, setPagination ] = useState(null)

	const [ selectedApplications, setSelectedApplications ] = useState({})
	const [ settingViewedUpdateInProgress, setSettingViewedUpdateInProgress ] = useState(false)

	const [ columnSettings, setColumnSettings ] = useState(props.columnSettings)
	const [ columnOrder, setColumnOrder ] = useState(props.columnOrder)
	const [ applicationFilter, setApplicationFilter ] = useState(props.applicationFilter)

	const [ showColumnSettings, setShowColumnSettings ] = useState(false	)
	const [ showFilter, setShowFilter ] = useState(false)

	const selectApplication = (application_id, flag) => {
		if (flag)
			selectedApplications[application_id] = true
		else if (selectedApplications[application_id])
			delete selectedApplications[application_id]

		setSelectedApplications({...selectedApplications})
	}

	const setColumnOption = (field, value) => {
		columnSettings[field] = value
		setColumnSettings({...columnSettings})
	}

	const setAndSaveColumnOrder = (order) => {
		let APS = {}
		if (authContext.application_view_settings)
			APS = authContext.application_view_settings

		APS.order = order
		API.viewSettingsAPI.saveApplicationViewSettings(APS)
		authContext.application_view_settings = APS
		//setAuthContext({...APS})
		setColumnOrder(order)
	}

	async function updateViewed (status) {
		const data = {}
		Object.keys(selectedApplications).map(key => {
			data[`viewed[${key}]`] = status ? 1 : 0
			//applications[key].viewed = !!data[`viewed[${key}]`]
			for (let i = 0; i < applications.length; i++) {
				if (applications[i].id == key) {
					applications[i].viewed = !!data[`viewed[${key}]`]
					break
				}
			}

			return null
		})

		try {
			await API.viewSettingsAPI.setOtherServicesApplicationViewed(data)
			setApplications([...applications])
			setSelectedApplications({})
		} catch (e) {
			console.log(e)
		}
	}

	async function exportApplications () {
		try {
			const response = await API.otherServicesApplicationsAPI.exportApplications()
			fileDownload(new Blob([response]), `Admin.xls`)
		} catch (e) {
			setErrors(e.response.errors)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				setPage(props.page)
				const response = await API.otherServicesApplicationsAPI.get({
					page: props.page,
					count: itemsPerPage,
					order: JSON.stringify(columnOrder),
					filter: JSON.stringify(applicationFilter)
				})

				response.statuses.forEach(status => {
					statuses[status.id] = status
				})
				setStatuses(statuses)

				response.departments.forEach(department => {
					departments[department.id] = department
				})
				setDepartments(departments)

				setApplications(response.applications.data.map(item => {
					item.number = item.application_number

					if (item.type === 'ur') {
						item.fullname = `${item.organization.orgtype} ${item.organization.orgname}`
					} else {
						item.fullname = `${item.name}`
					}

					return item
				}))

				const newPagination = {...response.applications}
				delete newPagination.data
				setPagination(newPagination)
				mainDiv.current.scrollTo(0, 0)
			} catch (e) {
				console.log(e)
				setErrors(e.response?.errors)
			}

			setLoading(false)
		}

		setLoading(true)
		fetchData()

	}, [applicationFilter, columnOrder, props.page, itemsPerPage])

	const userIsTNSAdmin = authContext.permissions.name === 'tns_admin' || authContext.permissions.name === 'tns_superadmin'

	const mainDiv = useRef()

	return <SettingsContext.Provider value={{
		history: props.history,
		loading,
		columnSettings,
		setColumnOption,
		applicationFilter,
		setApplicationFilter,
		statuses,
		departments,
		reasons
	}}>
	<div
		ref={mainDiv}
		className={s['main']}
		onClick={(e) => {
			setShowColumnSettings(false)
			setShowFilter(false)
		}}
	>
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className={cx('h2', s['title'])}>
						<span>Заявки на прочие услуги</span>

						{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
							<span className="sr-only">Загрузка...</span>
						</div>}
					</div>

					{errors && Object.keys(errors).length > 0 && <div className="mb-3">
						<p>Ошибка загрузки данных</p>
						<Button className="btn btn-success" onClick={() => {window.location.reload()}}>Перезагрузить</Button>
					</div>}

					<div className="col-12">
						{/*controls*/}
						<div className={s['controls']}>

							<ColumnSettings showColumnSettings={showColumnSettings} setShowColumnSettings={setShowColumnSettings} setShowFilter={setShowFilter} />

							<Filter showFilter={showFilter} setShowFilter={setShowFilter} setShowColumnSettings={setShowColumnSettings}/>

							{Object.keys(selectedApplications).length > 0 && <Button
							  className="btn btn-primary"
							  onClick={(e) => {
							    e.stopPropagation()
							    updateViewed(true)
							  }}
							  loading={settingViewedUpdateInProgress}
							  disabled={settingViewedUpdateInProgress}
							>
							  <span>Отметить как просмотренное</span>
							</Button>}

							{Object.keys(selectedApplications).length > 0 && <Button
							  className="btn btn-primary"
							  onClick={(e) => {
							    e.stopPropagation()
									updateViewed(false)
							  }}
							  loading={settingViewedUpdateInProgress}
							  disabled={settingViewedUpdateInProgress}
							>Отметить как не просмотренное</Button>}
							{!userIsTNSAdmin && <Button
							  className="btn btn-primary"
							  onClick={(e) => {
							    e.stopPropagation()
							    exportApplications()
							  }}
							>Экспорт в Excel</Button>}
						</div>
					</div>

					{applications && <div className="col-12">
						{/*applications*/}
						<div className={s['table-wrap']}>
							<div className={cx('table', 'table-striped', s['table'], s['table-data'])}>
								{loading && <div className={s['loading-sort']}>
									<div className={cx('spinner-grow', s['spinner'])} role="status">
										<span className="sr-only">Загрузка...</span>
									</div>
								</div>}

								<TableHeader columnSettings={columnSettings} columnOrder={columnOrder} setColumnOrder={setAndSaveColumnOrder}/>

								{applications.map((item, index) => <TableRow
										key={item.id}
										item={item}
										selected={!!selectedApplications[item.id]}
										selectApplication={selectApplication}
									/>
								)}
							</div>
						</div>
						{pagination && <div className="col-12 mt-4">
							<nav className={cx({[s['pagination-load']]: loading})}>
								<ul className="pagination">
									<li
										className={cx('page-item', {'disabled': pagination.current_page === 1})}
										onClick={() => {
											props.history.push(routes.AdminOtherServicesApplicationsPageRoute.path.replace(':page', (pagination.current_page - 1)))
										}}
									><span className="page-link" aria-label="Previous"><span aria-hidden="true">&laquo;</span></span></li>

									{Array.from({ length: pagination.last_page }, (item, index) => {
										if (
											(index + 1 > pagination.current_page - 5 && index + 1 < pagination.current_page + 5) ||
											(index === 0) ||
											(index + 1 === pagination.last_page)
										) {
											//console.log(index+1)
											return <li
												key={index}
												className={cx('page-item', {'active': pagination.current_page === index + 1})}
												onClick={() => {
													props.history.push(routes.AdminOtherServicesApplicationsPageRoute.path.replace(':page', (index + 1)))
												}}
											><span className="page-link">{index + 1}</span></li>
										}
									})}

									<li
										className={cx('page-item', {'disabled': pagination.current_page === pagination.last_page})}
										onClick={() => {
											props.history.push(routes.AdminOtherServicesApplicationsPageRoute.path.replace(':page', (pagination.current_page + 1)))
										}}
									><span className="page-link" aria-label="Next"><span aria-hidden="true">&raquo;</span></span></li>
								</ul>
							</nav>
						</div>}
					</div>}
				</div>
			</div>
		</div>

	</div>
	</SettingsContext.Provider>
}

export default AdminOtherServicesApplications