import React, {useState} from 'react';
import s from './Forgot.module.css';
import API from '../../../lib/API';
import Button from '../../../components/Button';
import routes from "../../index";

function Forgot (props) {
	const [ loading, setLoading ] = useState(false)
	const [ email, setEmail ] = useState('')
	const [ errors, setErrors ] = useState({})
	const [ success, setSuccess ] = useState(false)

	async function forgot(e) {
		e.preventDefault();

		setLoading(true)
		setErrors({})

		try {
			await API.authAPI.forgotPassword({email});
			setSuccess(true)
		} catch (e) {
			console.error(e)
			setErrors(e.response.errors)
		}

		setLoading(false)
	}

	return (
		<div className={s['main']}>
			<form className="form" onSubmit={forgot}>
				<div className="form-title">Забыли пароль?</div>

				<div className="form-body">
					{!success ? <div>
						<div className="form-group">
							<label htmlFor="email">Email</label>

							<input
								type="email"
								className="form-control"
								id="email"
								value={email}
								onChange={e => setEmail(e.currentTarget.value)}
								placeholder="Email"
							/>
						</div>

						{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
							{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
						</div>}

						<Button
							className="btn-app"
							onClick={forgot}
							disabled={!email || loading}
							loading={loading}
							type="submit"
						>Восстановить</Button>

						<Button
							className="btn-app"
							to={routes.AuthLoginRoute.path}
						>Назад</Button>
					</div> :

					<div className="alert alert-success" role="alert">
						На ваш E-mail было отправлено письмо. Перейдите по ссылке в письме для сброса пароля
					</div>}
				</div>
			</form>
		</div>
	);

}

export default Forgot;