import React from 'react'
import Layout from '../../../../components/Layout'
import Dictionary, {DefaultDeleteItem} from "../../../../components/Dictionary"
import DictionaryItem from "./components/DictionaryItem"
import EditItem from "./components/EditItem"
//import DeleteItem from "./components/DeleteItem"
import API from "../../../../lib/API"

const DeleteItem = (props) => <DefaultDeleteItem
	vocabulary={{
		title: "Удаление филиала",
		prompt: "Вы уверены что хотите удалить филиал?",
		success: "Филиал удален"
	}}
	{...props}
/>

function DepartmentsDictionaryRoute (props) {
	return (
		<Layout>
			<Dictionary
				dictionaryAPI={API.departmentsAPI}
				dictionaryItem={ DictionaryItem }
				popups={ { NewItem: EditItem, EditItem, DeleteItem } }
				vocabulary={{
					name: "Отделения",
					headers: {
						name: "Название",
						phone: "Контактный телефон",
						towns: "Населенные пункты"
					},
				}}
			/>
		</Layout>
	)
}

export default DepartmentsDictionaryRoute
