import {
  copyDefinedPropertiesValues,
  isFilesEmpty,
  joinCategories,
  prepareFiles,
  searchValue,
  showCategories
} from "../lib/helpers"
import {validateINN, validateSNILS, validateEmail} from "../lib/validators"
import Address from "../lib/Address"
import moment from "moment";
import {Decimal} from 'decimal.js';


class ApplicationService {
  constructor(reasons) {
    this.initialData = {
      //TabPersonal
      type: '',
      name: '',
      inn: '',
      egrnum: '',
      egrnum_register_date: '',
      snils: '',
      kpp: '',
      ogrn: '',

      //TabPersonalID
      documenttype: 'pasport',
      documenttypeText: '',
      docseries: '',
      docnumber: '',
      docdepartment: '',
      docissuedate: '',

      //TabContactData
      email: '',
      phone: '',
      //TabConnectionType
      connectiontype: false,
      connectiontypeother: '',
      connection_purpose: '',
      device: false,
      deviceother: '',
      connectionobject: '',
      connectionagreementnumber: '',
      connectionsupplier: 'ПАО «ТНС энерго Ростов-на-Дону»',
      connectionagreementtype: 'contractsupply',
      connectionrequisites: '',
      connectiontempduration: '',

      //TabDevices
      connectiontechspecs: {
        category1: "",
        category2: "",
        category3: "",
        microCategory3: "",
        connectiontechspecsarmor: "",
        connectiontechspecsarmorpower: "",
        connectiontechspecsgenerator: "",
        connectiontechspecstransformer: "",
        connectiontechspecscapacity: "",

        powerMicroNew: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        powerMicroOld: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        powerMicroTotal: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        powerNew: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        powerOld: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        powerTotal: {
          power: "", point1: "", point2: "", voltage: "0.23"
        },
        priceCategory: "",
        techmin: "",
        character: "empty",
        microgenerators_count: 0,
        microgenerators_power: 0,
        microgenerators_load_speed: '',
        okved: ''
      },

      //TabDeadlines
      deadlines: null,

      //TabAdditionalData
      additionalconditions: {
        additionalconditionsemergency: false,
        additionalconditionsindividual: false,
        additionalconditionsrepresent: false,
        additionalconditionsuninhabited: false,
      },

      passportfiz: [],
      egrnum_docs: [],
      warrant: [],
      conditionsindividualdocs: [],
      plan: [],
      accident: [],
      constitutivedocuments: [],
      otherdocuments: [],

      passportfizrepeat: [],
      egrnum_docsrepeat: [],
      warrantrepeat: [],
      conditionsindividualdocsrepeat: [],
      planrepeat: [],
      accidentrepeat: [],
      constitutivedocumentsrepeat: [],
      otherdocumentsrepeat: [],

      //TabOtherInfo
      aggrementtype: 0,
      otherinfopayment: false,
      //person: !!appData
      person: false,
    }

    this.initialData.regaddress = null
    this.initialData.liveaddress = null

    this.initialData.uraddress = null
    this.initialData.postaddress = null

    this.initialData.connectionaddress = null

    this.reasons = {}
  }

  init (appData, reasons) {
    this.reasons = {}
    if (reasons)
      reasons.forEach(reason => this.reasons[reason.value] = reason)

    const application = copyDefinedPropertiesValues(this.initialData, appData)

    //TabPersonal
    switch (application.type) {
      case 'fiz':
        application.snils = appData.egrnum
        break
      case 'ip':
        application.egrip = appData.egrnum
        application.egrip_register_date = appData.egrnum_register_date ? new Date(appData.egrnum_register_date) : ''
        break
      case 'ur':
        application.egrul = appData.egrnum
        application.egrul_register_date = appData.egrnum_register_date ? new Date(appData.egrnum_register_date) : ''
        application.position = appData.organization ? appData.organization.position : ""
        application.kpp = appData.organization ? appData.organization.kpp : ""
        application.ogrn = appData.organization ? appData.organization.ogrn : ""
        break
      default:
        break
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {
      //TabPersonalID
      if (appData.document) {
        application.docdepartment = appData.document.docdepartment
        application.docissuedate = appData.document.docissuedate
        application.docnumber = appData.document.docnumber
        application.docseries = appData.document.docseries
        application.documenttype = appData.document.documenttype
        application.documenttypeText = appData.document.documenttypeText
      }

      if (application.docissuedate)
        application.docissuedate = application.docissuedate !== 'Invalid date' ? new Date(application.docissuedate) : ''
      else
        application.docissuedate = ''
      //TabRegAddress
      application.regaddress = new Address('reg', appData.regaddress)

      //TabLiveAddress
      application.liveaddress = new Address('live', appData.liveaddress)
      delete application.uraddress
      delete application.postaddress
    } else if (appData.type === 'ur') {
      //TabUrAddress
      application.uraddress = new Address('ur', appData.uraddress)

      //TabPostAddress
      application.postaddress = new Address('post', appData.postaddress)

      delete application.regaddress
      delete application.liveaddress
    }

    //TabConnectionType
    application.connectiontype = appData.connectiontypeother ? appData.connectiontypeother : appData.connectiontype
    application.device = appData.deviceother ? appData.deviceother : appData.device

    //TabConnectionAddress
    application.connectionaddress = new Address('connection', appData.connectionaddress)
    application.connectionaddress.kadastrView = !!application.connectionaddress.kadastr

    //TabDevices
    application.connectiontechspecs = copyDefinedPropertiesValues(this.initialData.connectiontechspecs, appData.connectiontechspecs);
    //TabDeadlines
    let deadlines
    const categories = joinCategories(appData.connectiontechspecs?.category1, appData.connectiontechspecs?.category2, appData.connectiontechspecs?.category3)

    if (Array.isArray(appData.deadlines))
      deadlines = appData.deadlines.map(item => {
        item.start = item.start && new Date(item.start);
        item.end = item.end && new Date(item.end);
        return item;
      });
    else
      deadlines = [{start: "", end: "", power: "", category: categories}]

    application.deadlines = deadlines

    //TabAdditionalData
    application.additionalconditions = Object.assign({
      additionalconditionsemergency: false,
      additionalconditionsindividual: false,
      additionalconditionsrepresent: false,
      additionalconditionsuninhabited: false,
    },{
      ...appData.additionalconditions
    });

    application.passportfizrepeat = appData.passportfiz ? appData.passportfiz : []
    application.egrnum_docsrepeat = appData.egrnum_docs ? appData.egrnum_docs : []
    application.warrantrepeat = appData.warrant ? appData.warrant : []
    application.conditionsindividualdocsrepeat = appData.conditionsindividualdocs ? appData.conditionsindividualdocs : []
    application.planrepeat = appData.plan ? appData.plan : []
    application.accidentrepeat = appData.accident ? appData.accident : []
    application.constitutivedocumentsrepeat = appData.constitutivedocuments ? appData.constitutivedocuments : []
    application.otherdocumentsrepeat = appData.otherdocuments ? appData.otherdocuments : []

    application.passportfiz = []
    application.egrnum_docs = []
    application.warrant = []
    application.conditionsindividualdocs = []
    application.plan = []
    application.accident = []
    application.constitutivedocuments = []
    application.otherdocuments = []

    application.errors = this.validate(application)

    return application
  }

  reducer (state, action) {
    switch (action.type) {
      case 'setField':
        state[action.payload.field] = action.payload.value

        if (!showCategories(state, this.reasons)) {
          state.connectiontechspecs.category1 = ''
          state.connectiontechspecs.category2 = ''
        }
        if (action.payload.field === 'connectiontype') {
          if (this.reasons[state.connectiontype]?.system === 'micro' || this.reasons[state.connectiontype]?.system === 'microcat3') {
            state.connectiontechspecs.powerOld.voltage = 0.23;
            state.connectiontechspecs.powerNew.voltage = 0.23;
            state.connectiontechspecs.powerTotal.voltage = 0.23;
          }
          // else {
          //   state.connectiontechspecs.powerOld.voltage = 6;
          //   state.connectiontechspecs.powerNew.voltage = 6;
          //   state.connectiontechspecs.powerTotal.voltage = 6;
          // }
        }
        state.errors = this.validate(state)
        return {...state}
      case 'setFields':
        action.payload.forEach(value => {
          state[value.field] = value.value
        })
        state.errors = this.validate(state)
        return {...state}
      case 'setConnectionTechSpecsField':
        state.connectiontechspecs[action.payload.field] = action.payload.value
        state.errors = this.validate(state)
        return {...state}
      case 'setConnectionTechSpecsFields':
        action.payload.forEach(value => {
          state.connectiontechspecs[value.field] = value.value
        })
        state.errors = this.validate(state)
        return {...state}
      case 'setAdditionalConditionsField':
        state.additionalconditions[action.payload.field] = action.payload.value
        state.errors = this.validate(state)
        return {...state}
      case 'validate':
        state.errors = this.validate(state)
        return {...state}
      case 'init':
        return this.init(action.payload.appData, action.payload.reasons);
      default:
        throw new Error();
    }
  }

  isEmpty (files) {
    if (!Array.isArray(files))
      return true

    if (files.length === 0)
      return true

    let isEmpty = true

    for (let i = 0; i < files.length; i++)
      if (files[i] instanceof File) {
        isEmpty = false
        break
      }

    return isEmpty
  }

  validate(appData) {
    let allErrors = {}
    let errors = {}
    const errorTabs = []

    //TabPersonal
    if (appData.type === 'fiz' || appData.type === 'ip') {
      if (!appData.name)
        errors['name'] = 'Заполните ФИО'

      const invalidINN = validateINN(appData.type, appData.inn)
      if (invalidINN)
        errors['inn'] = invalidINN

      const invalidSNILS = validateSNILS(appData.snils)
      if (invalidSNILS)
        errors['snils'] = invalidSNILS

      if (appData.type === 'ip') {
        if (!appData.egrip)
          errors['egrip'] = 'Заполните ЕГРИП'

        if (!appData.egrip_register_date)
          errors['egrip_register_date'] = 'Заполните дату внесения в ЕГРИП'
      }
    } else if (appData.type === 'ur') {
      if (!appData.name)
        errors['name'] = 'Заполните наименование организации'

      if (!appData.position)
        errors['position'] = 'Заполните должность руководителя, ФИО'

      if (!appData.egrul)
        errors['egrul'] = 'Заполните ЕРГЮЛ'

      if (!appData.egrul_register_date)
        errors['egrul_register_date'] = 'Заполните дату внесения в ЕРГЮЛ'
    }

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabPersonal')
      allErrors = errors
    }

    if (appData.type !== 'ur') {
      //TabPersonalId
      errors = {}

      if (!appData.documenttype)
        errors['documenttype'] = 'Выберите тип документа';

      if (appData.documenttype === 'other')
        if (!appData.documenttypeText)
          errors['documenttypeText'] = 'Выберите тип документа';

      if (!appData.docseries)
        errors['docseries'] = 'Заполните серию документа';

      if (!appData.docnumber)
        errors['docnumber'] = 'Заполните номер документа';

      if (!appData.docdepartment)
        errors['docdepartment'] = 'Заполните поле, кем выдан документ';

      if (!appData.docissuedate)
        errors['docissuedate'] = 'Заполните дату выдачи документа';

      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabPersonalId')
        allErrors = {...allErrors, ...errors}
      }
    }

    //TabContactData
    errors = {}

    const emailInvalid = validateEmail(appData.email);
    if (emailInvalid)
      errors.email = emailInvalid;

    if (appData.phone.length < 11)
      errors.phone = 'Заполните номер телефона';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabContactData')
      allErrors = {...allErrors, ...errors}
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {

      //TabRegAddress
      errors = appData.regaddress.validate('адреса регистрации')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabRegAddress')
        allErrors = {...allErrors, ...errors}
      }

      //TabLiveAddress
      errors = appData.liveaddress.validate('адреса места жительства')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabLiveAddress')
        allErrors = {...allErrors, ...errors}
      }
    } else if (appData.type === 'ur') {
      //TabLiveAddress
      errors = appData.uraddress.validate('юридического адреса')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabUrAddress')
        allErrors = {...allErrors, ...errors}
      }

      //TabLiveAddress
      errors = appData.postaddress.validate('почтового адреса')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabPostAddress')
        allErrors = {...allErrors, ...errors}
      }
    }

    //TabConnectionType
    errors = {}

    if (appData.connectiontype === false || !appData.connectiontype?.toString().trim())
      errors.connectiontype = 'Укажите причину обращения';

    if (!appData.connection_purpose)
      errors.connection_purpose = 'Не выбрана цель присоединения'

    if (appData.device === false || !appData.device?.toString().trim())
      errors.device = 'Укажите энергопринимающее устройство';

    if (!appData.connectionobject)
      errors.connectionobject = 'Заполните наименование объекта присоединения';

    if (!appData.connectionsupplier)
      errors.connectionsupplier = 'Укажите гарантирующего поставщика';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabConnectionType')
      allErrors = {...allErrors, ...errors}
    }

    //TabConnectionAddress
    errors = {}

    if (appData.connectionaddress.kadastrView) {
      if (!appData.connectionaddress.kadastr)
        errors[`connectionkadastr`] = `Заполните кадастровый номер адреса объекта`;
    } else if (!appData.connectionaddress.street) {
      errors[`connectionstreet`] = `Заполните улицу адреса объекта`;
    }

    if (!appData.connectionaddress.city)
      errors[`connectioncity`] = `Выберите город адреса объекта из выпадающего списка`;

    if (!appData.connectionaddress.kadastrView && !appData.connectionaddress.house)
      errors[`connectionhouse`] = `Заполните номер дома адреса объекта`;

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabConnectionAddress')
      allErrors = {...allErrors, ...errors}
    }

    //TabDevices
    errors = {}

    if (Number(appData.connectiontechspecs.category1) + Number(appData.connectiontechspecs.category2) + Number(appData.connectiontechspecs.category3) <= 0 )
      errors.category = 'Укажите категории надежности';

    if (!appData.connectiontechspecs.powerNew?.power)
      errors.powerNewPower = 'Заполните все обязательные поля энергопринимающих устройств';

    if (this.reasons[appData.connectiontype]?.system === 'micro' || this.reasons[appData.connectiontype]?.system === 'microcat3')
      if (!appData.connectiontechspecs.powerMicroNew?.power)
        errors.powerMicroNewPower = 'Заполните все обязательные поля энергопринимающих устройств';

    if (appData.connectiontechspecs.category1 || appData.connectiontechspecs.category2) {
      if (appData.connectiontechspecs.powerNew?.point1 <= 0)
        errors.powerNewPoint1 = 'Заполните все обязательные поля энергопринимающих устройств';

      if (appData.connectiontechspecs.powerNew?.point2 <= 0)
        errors.powerNewPoint2 = 'Заполните все обязательные поля энергопринимающих устройств';

      /*if (!appData.connectiontechspecs.powerOld.point1)
        errors.powerOldPoint1 = 'Заполните все обязательные поля энергопринимающих устройств';

      if (!appData.connectiontechspecs.powerOld.point2)
        errors.powerOldPoint2 = 'Заполните все обязательные поля энергопринимающих устройств';
       */
    }

    if (this.reasons[appData.connectiontype]?.system === 'micro' || this.reasons[appData.connectiontype]?.system === 'microcat3')
      if (appData.connectiontechspecs.powerMicroTotal.power instanceof Decimal && appData.connectiontechspecs.powerMicroTotal.power.greaterThan(15))
        errors.powerMicroTotalPower = 'Мощность микрогенерации не должна превышать 15 кВт'

    if (appData.type !== 'fiz' && appData.connectiontechspecs.character === 'empty')
      errors.character = 'Выберите характер нагрузки';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabDevices')
      allErrors = {...allErrors, ...errors}
    }

    //TabDeadlines
    errors = {}

    appData.deadlines.forEach((value, index) => {
      if (!value.start)
        errors[`start${index}`] = 'Заполните все этапы ввода в эксплуатацию';

      if (!value.end)
        errors[`end${index}`] = 'Заполните все этапы ввода в эксплуатацию';

      if (!value.power)
        errors[`power${index}`] = 'Заполните все этапы ввода в эксплуатацию';
    });

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabDeadlines')
      allErrors = {...allErrors, ...errors}
    }

    //TabAdditionalData
    errors = {}

    if (appData.type === 'fiz' && this.isEmpty(appData.passportfiz) && appData.passportfizrepeat.length === 0)
      errors.passportfiz = 'Загрузите файл(ы) сканированной копии паспорта';
    else if ((appData.type === 'ip' || appData.type === 'ur') && appData.egrnum_docs.length === 0 && appData.egrnum_docsrepeat.length === 0)
      errors.egrnum_docs = (appData.type === 'ip' ? 'Загрузите файл(ы) документа ЕГРИП' : 'Загрузите файл(ы) документа ЕГРЮЛ');

    if (
      appData.additionalconditions.additionalconditionsrepresent
      && this.isEmpty(appData.warrant)
      && !appData.warrantrepeat.length
    )
      errors.warrant = 'Загрузите файл(ы) сканированной копии доверенности и паспорта представителя';

    if (
      appData.additionalconditions.additionalconditionsindividual
      && this.isEmpty(appData.conditionsindividualdocs)
      && !appData.conditionsindividualdocsrepeat.length
    )
      errors.conditionsindividualdocs = 'Загрузите файл(ы) документов для индивидуального вводно-распределительное устройства';

    if (this.isEmpty(appData.plan) && appData.planrepeat.length === 0)
      errors.plan = 'Загрузите файл(ы) плана расположения энергопринимающих устройств';

    if (this.isEmpty(appData.constitutivedocuments) && !appData.constitutivedocumentsrepeat.length)
      errors.constitutivedocuments = 'Загрузите файл(ы) правоустанавливающих документов';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabAdditionalData')
      allErrors = {...allErrors, ...errors}
    }

    //TabOtherInfo
    errors = {}

    if (typeof appData.aggrementtype === 'undefined')
      errors.aggrementtype = 'Не выбран способ обмена документами';

    const powerCondition = Number(appData.connectiontechspecs.powerTotal) > 15 && Number(appData.connectiontechspecs.powerTotal) <= 150;

    if (powerCondition && !appData.otherinfopayment)
      errors.otherinfopayment = 'Не выбран вариант рассрочки';

    if (!appData.person)
      errors.person = 'Подтвердите разрешение на использование персональных данных';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabOtherInfo')
      allErrors = {...allErrors, ...errors}
    }

    return {errors: allErrors, errorTabs}
  }

  prepareData(appData, dictionaries) {
    let application = {...this.initialData}
    //let application = copyDefinedPropertiesValues(this.initialData, appData)

    //TabPersonal
    application.name = appData.name
    application.inn = appData.inn

    switch (appData.type) {
      case 'fiz':
        application.egrnum = appData.snils;
        //application.personal.egrnum = application.egrnum
        break;
      case 'ip':
        application.snils = appData.snils;
        application.egrnum = appData.egrip;
        application.egrnum_register_date = appData.egrip_register_date ? moment(appData.egrip_register_date).format('YYYY-MM-DD') : "";
        break;
      case 'ur':
        application.kpp = appData.kpp;
        application.ogrn = appData.ogrn;
        application.egrnum = appData.egrul;
        application.egrnum_register_date = appData.egrul_register_date ? moment(appData.egrul_register_date).format('YYYY-MM-DD') : "";
        application.position = appData.position;
        break;
      default:
        break;
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {
      //TabPersonalId
      application.documenttype = appData.documenttypeText ? appData.documenttypeText : appData.documenttype
      application.documenttypeText = appData.documenttypeText
      application.docseries = appData.docseries
      application.docnumber = appData.docnumber
      application.docdepartment = appData.docdepartment
      application.docissuedate = moment(appData.docissuedate).format('YYYY-MM-DD')

      //TabRegAddress
      application.regaddress = appData.regaddress.getJson();

      //TabLiveAddress
      application.liveaddress = appData.liveaddress.sameAddress ? appData.regaddress.getJson() : appData.liveaddress.getJson();
    }

    //TabContactData
    application.email = appData.email
    application.phone = appData.phone

    if (appData.type === 'ur') {
      //TabUrAddress
      application.uraddress = appData.uraddress.getJson();

      //TabPostAddress
      application.postaddress = appData.postaddress.sameAddress ? appData.uraddress.getJson() : appData.postaddress.getJson();
    }

    //TabConnectionAddress
    appData.connectionaddress.subject = "Ростовская обл"
    application.connectionaddress = appData.connectionaddress.getJson()

    //TabConnectionType
    application.connection_purpose = appData.connection_purpose
    application.connectionobject = appData.connectionobject
    application.connectionagreementnumber = appData.connectionagreementnumber
    application.connectionsupplier = appData.connectionsupplier
    application.connectionagreementtype = appData.connectionagreementtype
    application.connectionrequisites = appData.connectionrequisites
    application.connectiontempduration = appData.connectiontempduration

    const connectionTypeId = dictionaries.reasons.find(v => {
      if (v.value == appData.connectiontype)
        return true
    })
    //console.log(connectionTypeId)

    if (connectionTypeId)
      application.connectiontype = connectionTypeId.value
    else {
      delete application.connectiontype
      application.connectiontypeother = appData.connectiontype
    }

    const deviceId = dictionaries.devices.find(v => {
      if (v.value == appData.device)
        return true
    })
    //console.log(deviceId)
    if (deviceId)
      application.device = deviceId.value
    else {
      delete application.device
      application.deviceother = appData.device
    }

    //TabDevices
    application.connectiontechspecs = JSON.stringify(appData.connectiontechspecs)

    //TabDeadlines
    const categories = joinCategories(appData.connectiontechspecs.category1, appData.connectiontechspecs.category2, appData.connectiontechspecs.category3)
    const deadlines = [];
    appData.deadlines.forEach(row => {
      row.category = categories;
      deadlines.push(row);
    })

    application.deadlines = JSON.stringify(deadlines)

    //TabAdditionalData
    application.additionalconditions = JSON.stringify(appData.additionalconditions)

    if (!this.isEmpty(appData.passportfiz))
      application.passportfiz = appData.passportfiz.filter(file => file instanceof File)
    if (appData.passportfizrepeat)
      application.passportfizrepeat = appData.passportfizrepeat
    if (!this.isEmpty(appData.egrnum_docs))
      application.egrnum_docs = appData.egrnum_docs.filter(file => file instanceof File)
    if (appData.egrnum_docsrepeat)
      application.egrnum_docsrepeat = appData.egrnum_docsrepeat
    if (!this.isEmpty(appData.warrant))
      application.warrant = appData.warrant.filter(file => file instanceof File)
    if (appData.warrantrepeat)
      application.warrantrepeat = appData.warrantrepeat
    if (!this.isEmpty(appData.conditionsindividualdocs))
      application.conditionsindividualdocs = appData.conditionsindividualdocs.filter(file => file instanceof File)
    if (appData.conditionsindividualdocsrepeat)
      application.conditionsindividualdocsrepeat = appData.conditionsindividualdocsrepeat
    if (!this.isEmpty(appData.plan))
      application.plan = appData.plan.filter(file => file instanceof File)
    if (appData.planrepeat)
      application.planrepeat = appData.planrepeat
    if (!this.isEmpty(appData.accident))
      application.accident = appData.accident.filter(file => file instanceof File)
    if (appData.accidentrepeat)
      application.accidentrepeat = appData.accidentrepeat
    if (!this.isEmpty(appData.constitutivedocuments))
      application.constitutivedocuments = appData.constitutivedocuments.filter(file => file instanceof File)
    if (appData.constitutivedocumentsrepeat)
      application.constitutivedocumentsrepeat = appData.constitutivedocumentsrepeat
    if (!this.isEmpty(appData.otherdocuments))
      application.otherdocuments = appData.otherdocuments.filter(file => file instanceof File)
    if (appData.otherdocumentsrepeat)
      application.otherdocumentsrepeat = appData.otherdocumentsrepeat


    //TabOtherInfo
    application.aggrementtype = appData.aggrementtype
    application.otherinfo = JSON.stringify({
      otherinfopayment: appData.otherinfopayment,
      person: appData.person
    })

    return application
  }
  

}

export default ApplicationService
