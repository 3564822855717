import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Profile from './Profile';

function ProfileRoute () {
	return <Layout>
		<Profile/>
	</Layout>
}

export default ProfileRoute;
