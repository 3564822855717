import React from 'react';
import Layout from '../../../../components/Layout';
import Dictionary, {DefaultDeleteItem, DefaultEditItem} from "../../../../components/Dictionary";
import API from "../../../../lib/API";
import DictionaryItem from "./components/DictionaryItem";
import EditItem from "./components/EditItem";
import routes from "../../../index";

const NewItem = (props) => <DefaultEditItem
  title="Добавление причины обращения"
  success="Причина обращения добавлена"
  {...props}
/>

const NewEditItem = (props) => <EditItem
  title="Изменить причину обращения"
  success="Причина обращения изменена"
  {...props}
/>

const DeleteItem = (props) => <DefaultDeleteItem
  vocabulary={{
    title: "Удаление причины обращения",
    prompt: "Вы уверены что хотите удалить причину обращения?",
    success: "Причина обращения удален"
  }}
  {...props}
/>

function ReasonsDictionaryRoute(props) {
  return <Layout>
    <Dictionary
      backRoute={routes.AdminDashboardTechnicalDictionariesRoute.path}
      dictionaryAPI={API.requestReasonsAPI}
      vocabulary={{
        name: "Причины обращения",
        headers: {
          id: "ID",
          order: "Положение в списке",
          description: "Описание",
          system: "Системный код"
        }
      }}
      popups={ { NewItem, EditItem: NewEditItem, DeleteItem } }
      dictionaryItem={ DictionaryItem }

    />
  </Layout>
}

export default ReasonsDictionaryRoute;
