import {deleteCookie, getAccessToken} from "./utils"
import axios from "axios"
import locale from "./locale"
import UnauthorizedError from './UnauthorizedError'
import ResponseError from "./ResponseError";

class BasicAPI {
  apiUrl = process.env.REACT_APP_API_URL;

  async sendRequest (urlRequest, data, optionsData, accessToken) {
    if (!accessToken)
      accessToken = getAccessToken();

    const formData = new FormData();
    if (typeof data === 'object')
      for (let field in data) {
        if (Array.isArray(data[field])) {
          //console.log(field, data[field])
          data[field].forEach(value => {
            if (!value || (typeof value === 'object' && Object.keys(value) === 0))
              return
            if (value.path)
              formData.append(field+'[]', value.path)
            else
              formData.append(field+'[]', value)
          })
        } else {
          formData.append(field, data[field]);
        }
      }

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      },
      url: `${this.apiUrl}${urlRequest}`,
      data: formData,
      ...optionsData
    };

    let response = {};

    return (await axios(options).catch(error => {
      if (error.response) {
        console.log(urlRequest, error.response)

        if (error.response.status === 401) {
          throw new UnauthorizedError()
        }

        if (error.response.status === 403 && error.response.data?.code === 'service_temporary_closed') {
          window.location.replace('/');
          deleteCookie('access_token');
          deleteCookie('admin');
          localStorage.removeItem('access_token');
          localStorage.removeItem('admin');
        }
        //setAuthContext(null)
        const errors = {};

        if (error.response.data.error && error.response.data.error.errors) {
          Object.keys(error.response.data.error.errors).map(type => {
            errors[type] = [];

            error.response.data.error.errors[type].map(typeError => {
              errors[type].push(locale.errors[typeError.msg] ? locale.errors[typeError.msg] : typeError.msg);
              return null;
            });

            return null;
          });
        }

        if (error.response.data.error && error.response.data.error.msg) {
          errors[error.response.data.error.type] = [locale.errors[error.response.data.error.msg] ? locale.errors[error.response.data.error.msg] : error.response.data.error.msg];
        } else if (error.response.data.error && !error.response.data.error.errors && !error.response.data.error.msg) {
          errors[error.response.data.error] = [locale.errors[error.response.data.error] ? locale.errors[error.response.data.error] : error.response.data.error];
        } else if (!error.response.data.error) {
          errors['unknown'] = [locale.errors['Unknown error'] ? locale.errors['Unknown error'] : 'Unknown error'];
        }

        response = {
          status: error.response.status,
          errors: errors
        };
      } else if (error.request) {
        console.log(urlRequest, error.request);

        response = {
          status: error.request.status,
          errors: { 'unknown' : [locale.errors['Unknown error'] ? locale.errors['Unknown error'] : 'Unknown error'] }
        };

        // deleteCookie('access_token');
        // localStorage.removeItem('access_token');
        // window.browserHistory && window.browserHistory.push('/login');

        console.log(response);
      } else {
        console.log('Error', urlRequest, error.message);

        // deleteCookie('access_token');
        // localStorage.removeItem('access_token');
        // window.browserHistory && window.browserHistory.push('/login');

        response = {
          errors: { 'unknown' : [locale.errors['Unknown error'] ? locale.errors['Unknown error'] : 'Unknown error'] }
        };
      }

      throw new ResponseError(response);
    })).data;
  }
}

export default BasicAPI;