import React, {useContext, useState} from 'react';
import s from './Admin.module.css';
import Button from '../../../components/Button';
import routes from "../../index";
import {AuthContext} from "../../../lib/AuthContext";
import {PopupContext} from "../../../lib/PopupContext";
import API from "../../../lib/API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";

function AdminDashboard (props) {
	const {authContext, setAuthContext} = useContext(AuthContext)
	const {setPopupSettings} = useContext(PopupContext)
	const permissions = authContext.permissions

	function MainPage() {
		async function setAuth(value) {
			try {
				await API.adminAPI.setSetting({name: 'authIsOff', value: value ? "1" : "0"})
				authContext.authIsOff = !!value
				setAuthContext({...authContext})
			} catch (e) {
				console.error(e)
				setPopupSettings({
					type: 'info',
					text: e.response.errors,
					error: true
				})
			}

			setPopupSettings({})
		}

		async function setTpApps(value) {
			try {
				await API.adminAPI.setSetting({name: 'tpAppsIsOff', value: value ? "1" : "0"})
				authContext.tpAppsIsOff = !!value
				setAuthContext({...authContext})
			} catch (e) {
				console.error(e)
				setPopupSettings({
					type: 'info',
					text: e.response.errors,
					error: true
				})
			}

			setPopupSettings({})
		}

		function authPrompt(value) {
			setPopupSettings({
				type: 'confirm',
				text: !value ? 'Вы уверены что хотите открыть авторизацию на сайте?' : 'Вы уверены что хотите закрыть авторизацию на сайте?',
				doAction: () => setAuth(value)
			})
		}

		function tpAppsPrompt(value) {
			setPopupSettings({
				type: 'confirm',
				text: !value ? 'Вы уверены что хотите открыть подачу заявок на ТП на сайте?' : 'Вы уверены что хотите закрыть подачу заявок на ТП на сайте?',
				doAction: () => setTpApps(value)
			})
		}

		return <>
			{!!(permissions.view_technical_applications) &&
			<div>
				<Button className="btn btn-primary" to={routes.AdminTechnicalApplicationsRoute.path}>Технологическое
					присоединение</Button>
			</div>
			}
			{!!permissions.view_other_services_applications &&
			<div>
				<Button className="btn btn-primary" to={routes.AdminOtherServicesApplicationsRoute.path}>Прочие услуги</Button>
			</div>
			}
			{!!(
				permissions.view_admins_tp || permissions.view_admins_tns || permissions.view_admins_os
				|| permissions.view_users
				|| permissions.view_departments
				|| permissions.view_tns_departments
				|| permissions.view_technical_dictionaries
				|| permissions.view_other_services_dictionaries
			) &&
			<div>
				<Button className="btn btn-primary" to={routes.AdminDashboardDictionariesRoute.path}>Управление
					справочниками</Button>
			</div>
			}

			{(
				permissions.name === 'superadmin'
				|| permissions.name === 'technical_superadmin'
				|| permissions.name === 'other_services_superadmin'
				|| permissions.name === 'support'
			) && <>
				<div>
					<Button className="btn btn-primary" to={routes.AdminEvaluationRoute.path}>Оценка потребителем качества
						услуг</Button>
				</div>


				<div>
					<Button className="btn btn-primary" to={routes.AdminLogsRoute.path}>Журнал событий</Button>
				</div>
				</>
			}
			{(permissions.name === 'superadmin' || permissions.name === 'support') && <>
				<div>
					<Button className="btn btn-primary" to={routes.AdminConnectionCalcsRoute.path}>Ставки за технологическое
						присоединение</Button>
				</div>

				<div>
					<Button className="btn btn-primary" to={routes.AdminMainPageEditorRoute.path}>Редактировать текст главной
						страницы</Button>
				</div>

				<div>
					{!authContext?.tpAppsIsOff && <Button
						className="btn btn-danger"
						onClick={() => tpAppsPrompt(true)}
					>
						Закрыть заявки ТП
					</Button>}
					{authContext?.tpAppsIsOff && <Button
						className="btn btn-success"
						onClick={() => tpAppsPrompt(false)}
					>
						Открыть заявки ТП
					</Button>}
				</div>

				<div>
					{!authContext?.authIsOff && <Button
						className="btn btn-danger"
						onClick={() => authPrompt(true)}
					>
						Закрыть доступ к сайту
					</Button>}
					{authContext?.authIsOff && <Button
						className="btn btn-success"
						onClick={() => authPrompt(false)}
					>
						Открыть доступ к сайту
					</Button>}
				</div>
			</>
			}
		</>
	}

	function DictionariesPage() {
		return <>
			<div>
				<Button className="btn btn-primary" to={routes.AdminDashboardRoute.path}>
					<FontAwesomeIcon icon={faArrowAltCircleLeft} />
					Назад
				</Button>
			</div>
			{!!(permissions.view_admins_tp || permissions.view_admins_tns || permissions.view_admins_os) && <div>
				<Button className="btn btn-primary" to={routes.AdminsDictionaryRoute.path}>Управление
					администраторами</Button>
			</div>}

			{!!permissions.view_users && <div>
				<Button className="btn btn-primary" to={routes.UsersDictionaryRoute.path}>Управление пользователями</Button>
			</div>}

			{!!permissions.view_departments && <div>
				<Button className="btn btn-primary" to={routes.DepartmentsDictionaryRoute.path}>Управление
					филиалами</Button>
			</div>}

			{!!permissions.view_tns_departments && <div>
				<Button className="btn btn-primary" to={routes.TNSDepartmentsDictionaryRoute.path}>Управление
					филиалами ТНС</Button>
			</div>}

			{!!permissions.view_technical_dictionaries && <div>
				<Button className="btn btn-primary" to={routes.AdminDashboardTechnicalDictionariesRoute.path}>Справочники
					технологического присоединения</Button>
			</div>}

			{!!permissions.view_other_services_dictionaries && <div>
				<Button className="btn btn-primary" to={routes.AdminDashboardOtherServicesDictionariesRoute.path}>Справочники прочих
					услуг</Button>
			</div>}
		</>
	}

	function TechnicalDictionariesPage() {
		return <>
			<div>
				<Button className="btn btn-primary" to={routes.AdminDashboardDictionariesRoute.path}>
					<FontAwesomeIcon icon={faArrowAltCircleLeft} />
					Назад
				</Button>
			</div>
			<div>
				<Button className="btn btn-primary" to={routes.StatusesDictionaryRoute.path}>Управление статусами заявок на
					технологическое присоединение</Button>
			</div>

			<div>
				<Button className="btn btn-primary" to={routes.ReasonsDictionaryRoute.path}>Управление причинами
					обращения</Button>
			</div>

			<div>
				<Button className="btn btn-primary" to={routes.DevicesDictionaryRoute.path}>Управление энергопринимающими
					устройствами</Button>
			</div>
		</>
	}

	function OtherServicesDictionariesPage() {
		return <>
			<div>
				<Button className="btn btn-primary" to={routes.AdminDashboardDictionariesRoute.path}>
					<FontAwesomeIcon icon={faArrowAltCircleLeft} />
					Назад
				</Button>
			</div>
			<div>
				<Button className="btn btn-primary" to={routes.OtherServicesStatusesDictionariesRoute.path}>Управление
					статусами заявок на прочие услуги</Button>
			</div>
			<div>
				<Button className="btn btn-primary" to={routes.OtherServicesTypesDictionaryRoute.path}>Управление
					справочником типов услуг</Button>
			</div>
			<div>
				<Button className="btn btn-primary" to={routes.OtherServicesDictionaryRoute.path}>Управление
					справочником услуг</Button>
			</div>
		</>
	}

	function Links(props) {
		switch (props.page) {
			case 'dictionaries':
				return <DictionariesPage/>
			case 'technical_dictionaries':
				return <TechnicalDictionariesPage/>
			case 'other_services_dictionaries':
				return <OtherServicesDictionariesPage/>
			default:
				return <MainPage/>
		}
	}

	return (
		<div className={s['main']}>
			<div className={s['admin']}>
				<div className="h2 text-center">Административная консоль</div>

				<div className={s['links']}>
					<Links page={props.page}/>
				</div>

			</div>
		</div>
	);
}

export default AdminDashboard