import React, {Component} from 'react';
import s from './InfoModal.module.css';
import cx from 'classnames';
import Button from '../Button';

function InfoModal(props) {
  const {visible, title, text, error, closePopup} = props;

  return (
    <div className={cx('modal', {[s['modal--active']]: visible})} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>

            <Button
              className="close"
              aria-label="Close"
              onClick={closePopup}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>

          <div className="modal-body">
            {error && <div className="alert alert-danger" role="alert">
              {typeof text === 'object' && Object.keys(text).length > 0 && <div className="alert alert-danger" role="alert">
                {Object.keys(text).map((errorType, index) => text[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
              </div>}

              {typeof text === 'string' && text}
            </div>}
            {!error && <div>
              {text}
            </div>}
          </div>

          <div className="modal-footer">
            <Button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closePopup}
            >Ок</Button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default InfoModal;
