import React, {Component, useContext, useEffect, useState} from 'react';
import s from './TechnicalApplication.module.css';
import cx from 'classnames';
import { getCookie, docTypes } from '../../lib/utils';
import API from '../../lib/API';
import moment from 'moment';
import Button from '../../components/Button';
import Chat from '../../components/Chat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import routes from "../index";
import FullAddressTable from "../../components/FullAddressTable/FullAddressTable";
import locale from "../../lib/locale"
import UserDocuments from "./components/UserDocuments";
import AdminDocuments from "./components/AdminDocuments";
import UserPaychecks from "./components/UserPaychecks";
import AdminPaychecks from "./components/AdminPaychecks";
import TNSDocuments from "./components/TNSDocuments";
import {AuthContext} from "../../lib/AuthContext";
import {PopupContext} from "../../lib/PopupContext";

function TechnicalApplication (props) {
	const [application, setApplication] = useState(null)
	const [statuses, setStatuses] = useState(null)
	const [appStatus, setAppStatus] = useState(null)
	const [paychecks, setPaychecks] = useState(null)
	const [devices, setDevices] = useState(null)
	const [reasons, setReasons] = useState(null)
	const [departments, setDepartments] = useState(null)
	const [tnsDepartments, setTnsDepartments] = useState(null)
	const [applicationUser, setApplicationUser] = useState(null)
	const [agreementFiles, setAgreementFiles] = useState(null)

	const [statusPayment, setStatusPayment] = useState(props.urlParams.get('statusPayment'))
	const [loading, setLoading] = useState(true)
	const [page, setPage] = useState(statusPayment ? 3 : 1)
	const [errors, setErrors] = useState(null)

	const [refresh, setRefresh] = useState(true)

	const applicationId = props.id
	const { authContext } = useContext(AuthContext)
	const isAdmin = authContext.admin
	const isTNS = authContext?.permissions?.name === "tns_superadmin" || authContext?.permissions?.name === "tns_admin"

	const { setPopupSettings } = useContext(PopupContext)

	async function updateDepartment (department_id) {
		try {
			await API.applicationsAPI.setDepartment({application_id: application.id, department_id})
			setPopupSettings({
				type: 'info',
				text: 'Филиал изменен'
			})
		} catch (e) {
			console.error(e.response.errors)
			setPopupSettings({
				type: 'info',
				text: 'Ошибка изменения филиала',
				error: true
			})
		}
	}

	async function updateTNSDepartment (tns_department_id) {
		try {
			await API.applicationsAPI.setTNSDepartment({application_id: application.id, tns_department_id})
			setPopupSettings({
				type: 'info',
				text: 'Филиал изменен'
			})
		} catch (e) {
			console.error(e.response.errors)
			setPopupSettings({
				type: 'info',
				text: 'Ошибка изменения филиала',
				error: true
			})
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await API.applicationsAPI.getById({id: applicationId})
				setApplication(data.application)
				setStatuses(data.statuses)
				setPaychecks(data.paychecks)
				setDevices(data.devices)
				setReasons(data.requestReasons)
				setDepartments(data.departments)
				setTnsDepartments(data.tnsDepartments)
				setApplicationUser(data.user)

				const af = {...data.application.agreement_files}

				if (data.application.agreement_files?.tnsproject?.url)
					af.tnsproject = data.application.agreement_files.tnsproject
				else
					af.tnsproject = data.application.tnsproject

				if (data.application.agreement_files?.tnsprojectep?.url)
					af.tnsprojectep = data.application.agreement_files.tnsprojectep
				else
					af.tnsprojectep = data.application.tnsprojectep

				if (data.application.agreement_files?.tnsmicro?.url)
					af.tnsmicro = data.application.agreement_files.tnsmicro
				else
					af.tnsmicro = data.application.tnsmicro

				if (data.application.agreement_files?.tnsmicroep?.url)
					af.tnsmicroep = data.application.agreement_files.tnsmicroep
				else
					af.tnsmicro = data.application.tnsmicro

				setAgreementFiles(af)

				const status = data.statuses.find(status => {
					return status.id === data.application.status_id
				})
				setAppStatus(status)
			} catch (e) {
				console.log(e)
				setErrors(e?.response?.errors)
			}

			setLoading(false)
		}

		if (refresh) {
			fetchData()
			setRefresh(false)
		}

		const interval = setInterval(() => { setRefresh(true); console.log('refresh') }, 10000);
		return () => clearInterval(interval);
	}, [refresh])


	const specialCondition = (application &&
		(
			(application.type === 'fiz' && application.connectiontechspecs.powerTotal.power <= 15 && application.connectiontechspecs.category3 && application.connection_purpose === 'private') ||
			((application.type === 'ip' || application.type === 'ur') && application.connectiontechspecs.powerTotal.power <= 150 && (application.connectiontechspecs.category2 || application.connectiontechspecs.category3))
		)
	)
	const specInstructions = (application && specialCondition && (application.connectiontechspecs.powerTotal.voltage == '0.23' || application.connectiontechspecs.powerTotal.voltage == '0.4'))

	const reasonSystemCode = reasons?.find(i => i.id === Number(application.connectiontype))?.system
	const isMicro = (reasonSystemCode === 'micro' || reasonSystemCode === 'microcat3')

	return (
		<div className={s['main']}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<Button
								className="btn btn-primary"
								//onClick={() => props.history.goBack()}
								to={isAdmin ? routes.AdminTechnicalApplicationsRoute.path : routes.TechnicalApplicationsRoute.path}
							>
								<FontAwesomeIcon icon={faArrowAltCircleLeft}/> Назад
							</Button>

							{application?.application_number && <span>Номер заявки {application.application_number}</span>}

							{ isAdmin && !!authContext?.permissions.edit_technical_applications && appStatus?.system === 'new' && <Button
									className="btn btn-primary"
									onClick={() => props.setEditApplication(application)}
								>
								Редактировать
							</Button>}
							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Загрузка...</span>
							</div>}
						</div>

						{errors && Object.keys(errors).length > 0 && <div className="mb-3">
							<p>Ошибка загрузки данных</p>
							<Button className="btn btn-success" onClick={() => {
								window.location.reload()
							}}>Перезагрузить</Button>
						</div>}
					</div>

					{application && <div className="col-12">
						<ul className="nav nav-tabs">
							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 1})}
										aria-selected="true"
										onClick={() => setPage(1)}
									>Данные по заявке</span>
							</li>
							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 2})}
										aria-selected="true"
										onClick={() => setPage(2)}
									>
										<span>Исполнение заявки</span>

										{application && application.unviewed_files ? <div className={s['unviewed']}>
											{application.unviewed_files}
										</div> : null}
										{application && application.unviewed_tns_files ? <div className={s['unviewed_tns']}>
											{application.unviewed_tns_files}
										</div> : null}
									</span>
							</li>

							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 3})}
										aria-selected="true"
										onClick={() => {
											setPage(3)
											//setPaymentError(null)
										}}
									>Оплата по договору</span>
							</li>

							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 4})}
										aria-selected="true"
										onClick={() => {
											setPage(4)
											//setPaymentError(null)
										}}
									>
										<span>Обратная связь</span>

										{application && (application.unviewed_messages || application.unviewed_request_nullify) ? <div className={s['unviewed']}>
											{parseInt(application.unviewed_messages) + parseInt(application.unviewed_request_nullify)}
										</div> : null}
									</span>
							</li>
						</ul>
					</div>}

					{application && <>
						{page === 1 && <div className="col-12">
							{authContext.admin && ((authContext?.permissions.name === 'superadmin' || authContext?.permissions.name === 'technical_superadmin') ||
							(authContext?.permissions.name !== 'tns_superadmin' && authContext?.permissions.name !== 'tns_admin')) &&
								departments && tnsDepartments && <>
							<div className="h5">Филиал</div>
							<div>
								<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
									<tbody>
									{(authContext.permissions.name === 'superadmin' || authContext.permissions.name === 'technical_superadmin') &&
										<tr>
											<td>Филиал Донэнерго</td>
											<td>
												<select
													className="custom-select"
													defaultValue={application.department_id}
													onChange={(e) => updateDepartment(e.target.value)}
												>
													{departments.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
												</select>
												</td>
										</tr>}
									{(authContext.permissions.name !== 'tns_superadmin' && authContext.permissions.name !== 'tns_admin') &&
										<tr>
											<td>Филиал ТНС</td>
											<td>
												<select
													className="custom-select"
													defaultValue={application.tns_department_id}
													onChange={(e) => updateTNSDepartment(e.target.value)}
												>
													{tnsDepartments.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
												</select>
											</td>
										</tr>}
									</tbody>
								</table>
							</div>
							</>}

							<div className="h5">Данные заявителя</div>

							<div>
								<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
									<tbody>
									{application.type ? <tr>
										<td>Тип</td>
										<td>{application.type === 'fiz' ?
											'Физическое лицо' :
											application.type === 'ip' ? 'Индивидуальный предприниматель' :
												application.type === 'ur' ? 'Юридическое лицо' : 'Неопределено'}
										</td>
									</tr> : null}
									<tr>
										<td>Регистрационные данные пользователя</td>
										{(applicationUser?.type == 'fiz' || applicationUser?.type == 'ur') ? <td>{applicationUser?.name}</td> : <td>ИП {applicationUser?.name}</td>}
									</tr>
									{application.name ? <tr>
										<td>Наименование заявителя</td>
										<td>{application.name}</td>
									</tr> : null}

									{application.inn ? <tr>
										<td>ИНН</td>
										<td>{application.inn}</td>
									</tr> : null}

									{application.snils ? <tr>
										<td>СНИЛС</td>
										<td>{application.snils}</td>
									</tr> : null}

									{application.egrip ? <tr>
										<td>ЕГРИП</td>
										<td>{application.egrip}</td>
									</tr> : null}

									<tr>
										<td>Адрес объекта подключения</td>
										<td>{application?.connectionaddress.stringified}</td>
									</tr>

									{application.email ? <tr>
										<td>Email</td>
										<td>{application.email}</td>
									</tr> : null}

									{application.phone ? <tr>
										<td>Номер телефона</td>
										<td>{application.phone}</td>
									</tr> : null}
									</tbody>
								</table>
							</div>

							{application.document && <div>
								<div className="h5">Документ, удостоверяющий личность</div>

								<div className={cx(s['doc'])}>
									<div>{`${docTypes[application.document.documenttype]} Серия ${application.document.docseries} Номер ${application.document.docnumber} Выдан ${application.document.docdepartment} ${moment(application.document.docissuedate).format('DD.MM.YYYY')}`}</div>
								</div>
							</div>}

							{application.regaddress && <div>
								<div className="h5">Адрес места регистрации</div>

								<FullAddressTable address={application.regaddress}/>
							</div>}

							{application.liveaddress && <div>
								<div className="h5">Адрес места жительства</div>

								<FullAddressTable address={application.liveaddress}/>

							</div>}

							{application.uraddress && <div>
								<div className="h5">Юридический адрес</div>

								<FullAddressTable address={application.uraddress}/>
							</div>}

							{application.postaddress && <div>
								<div className="h5">Почтовый адрес</div>

								<FullAddressTable address={application.postaddress}/>

							</div>}

							<div>
								<div className="h5">Вид присоединения</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									{reasons && <tr>
										<td>Причина обращения</td>

										{application.connectiontype && !Number(application.connectiontype) ? <td>
											{application.connectiontype}
										</td> : null}

										{application.connectiontypeother && !Number(application.connectiontypeother) ? <td>
											{application.connectiontypeother}
										</td> : null}

										{application.connectiontype && !!Number(application.connectiontype) ? <td>
											{reasons.find(i => i.id === Number(application.connectiontype)).description}
										</td> : null}
									</tr>}

									{application.connection_purpose && (application.connection_purpose === 'private' || application.connection_purpose === 'commercial') &&
									<tr>
										<td></td>
										{application.connection_purpose === 'private' && <td>Для бытовых нужд</td>}
										{application.connection_purpose === 'commercial' && <td>Для коммерческого использования</td>}
									</tr>}

									{devices && <tr>
										<td>Наименования энергопринимающего устройства</td>
										{application.deviceother ? <td>
											{application.deviceother}
										</td> : <td>
											{devices.find(i => i.id === Number(application.device)) && devices.find(i => i.id === Number(application.device)).description}
										</td>}
									</tr>}

									<tr>
										<td>Наименования объекта присоединения</td>
										<td>{application.connectionobject}</td>
									</tr>

									<tr>
										<td>Номер основного договора электроснабжения</td>
										<td>{application.connectionagreementnumber}</td>
									</tr>

									<tr>
										<td>Гарантирующий поставщик (энергосбытовая организация), с которым планируется заключение
											договора
										</td>
										<td>{application.connectionsupplier}</td>
									</tr>

									<tr>
										<td>Вид договора</td>
										<td>{locale.titles.connectionagreementtype[application.connectionagreementtype]}</td>
									</tr>
									</tbody>
								</table>
							</div>

							{application.connectiontechspecs && <div>
								<div className="h5">Энергопринимающие устройства</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									<tr>
										<td>Категория надежности</td>
										<td>
											{application.connectiontechspecs.category1 &&
											<div>{`I категория ${application.connectiontechspecs.category1} кВт`}</div>}
											{application.connectiontechspecs.category2 &&
											<div>{`II категория ${application.connectiontechspecs.category2} кВт`}</div>}
											{application.connectiontechspecs.category3 &&
											<div>{`III категория ${application.connectiontechspecs.category3} кВт`}</div>}
										</td>
									</tr>

									<tr>
										<td>Характер нагрузки</td>
										<td>{locale.titles.character[application.connectiontechspecs.character]}</td>
									</tr>
									</tbody>
								</table>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									{application.connectiontechspecs.powerTotal && <tr>
										<td>Максимальная мощность устройств (присоединяемых и ранее присоединенных) энергопринимающих
											устройств , кВт
										</td>
										<td>При напряжении, кВ</td>
										{application.connectiontechspecs.powerTotal.point1 ? <td>Точка присоединения (1), кВ</td> :
											<td></td>}
										{application.connectiontechspecs.powerTotal.point2 ? <td>Точка присоединения (2), кВ</td> :
											<td></td>}
									</tr>}

									{application.connectiontechspecs.powerTotal && <tr>
										<td>{application.connectiontechspecs.powerTotal.power}</td>
										<td>{application.connectiontechspecs.powerTotal.voltage}</td>
										{application.connectiontechspecs.powerTotal.point1 ?
											<td>{application.connectiontechspecs.powerTotal.point1}</td> : <td></td>}
										{application.connectiontechspecs.powerTotal.point2 ?
											<td>{application.connectiontechspecs.powerTotal.point2}</td> : <td></td>}
									</tr>}

									{application.connectiontechspecs.powerNew && <tr>
										<td>Максимальная мощность присоединяемых энергопринимающих устройств, кВт</td>
										<td>При напряжении, кВ</td>
										{application.connectiontechspecs.powerNew.point1 ? <td>Точка присоединения (1), кВ</td> : <td></td>}
										{application.connectiontechspecs.powerNew.point2 ? <td>Точка присоединения (2), кВ</td> : <td></td>}
									</tr>}

									{application.connectiontechspecs.powerNew && <tr>
										<td>{application.connectiontechspecs.powerNew.power}</td>
										<td>{application.connectiontechspecs.powerNew.voltage}</td>
										{application.connectiontechspecs.powerNew.point1 ?
											<td>{application.connectiontechspecs.powerNew.point1}</td> : <td></td>}
										{application.connectiontechspecs.powerNew.point2 ?
											<td>{application.connectiontechspecs.powerNew.point2}</td> : <td></td>}
									</tr>}

									{application.connectiontechspecs.powerOld && <tr>
										<td>Максимальная мощность ранее присоединенных в данной точке присоединения энергопринимающих
											устройств, кВт
										</td>
										<td>При напряжении, кВ</td>
										{application.connectiontechspecs.powerOld.point1 ? <td>Точка присоединения (1), кВ</td> : <td></td>}
										{application.connectiontechspecs.powerOld.point2 ? <td>Точка присоединения (2), кВ</td> : <td></td>}
									</tr>}

									{application.connectiontechspecs.powerOld && <tr>
										<td>{application.connectiontechspecs.powerOld.power}</td>
										<td>{application.connectiontechspecs.powerOld.voltage}</td>
										{application.connectiontechspecs.powerOld.point1 ?
											<td>{application.connectiontechspecs.powerOld.point1}</td> : <td></td>}
										{application.connectiontechspecs.powerOld.point2 ?
											<td>{application.connectiontechspecs.powerOld.point2}</td> : <td></td>}
									</tr>}
									</tbody>
								</table>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									{application.connectiontechspecs.connectiontechspecsarmor ? <tr>
										<td>Необходимость наличия технологической и (или) аварийной брони:</td>
										<td>{application.connectiontechspecs.connectiontechspecsarmor}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.connectiontechspecsarmorpower ? <tr>
										<td>Величина и обоснование технологической и аварийной брони:</td>
										<td>{application.connectiontechspecs.connectiontechspecsarmorpower}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.connectiontechspecstransformer ? <tr>
										<td>Количество и мощность присоединяемых к сети трансформаторов, кВт:</td>
										<td>{application.connectiontechspecs.connectiontechspecstransformer}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.connectiontechspecsgenerator ? <tr>
										<td>Количество и мощность генераторов:</td>
										<td>{application.connectiontechspecs.connectiontechspecsgenerator}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.capacity ? <tr>
										<td>Заявляемый характер нагрузки (для генераторов - возможная скорость набора или снижения нагрузки)
											и наличие нагрузок, искажающих форму кривой электрического тока и вызывающих несимметрию
											напряжения в точках
											присоединения
										</td>
										<td>{application.connectiontechspecs.capacity}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.techmin ? <tr>
										<td>Величина и обоснование величины технологического минимума (для генераторов)</td>
										<td>{application.connectiontechspecs.techmin}</td>
									</tr> : <tr></tr>}

									{application.connectiontechspecs.priceCategory ? <tr>
										<td>Выбор ценовой категории, условий почасового планирования потребления электрической энергии и
											иных существенных условий договора, обеспечивающего продажу электрической энергии (мощности) на
											розничном рынке,
											предусмотреных основными положениями функционирования розничных рынков электрической энергии
										</td>
										<td>{application.connectiontechspecs.priceCategory}</td>
									</tr> : <tr></tr>}
									</tbody>
								</table>
							</div>}

							{application.deadlines && application.deadlines.length > 0 && <div>
								<div className="h5">Сроки проектирования и поэтапного ввода в эксплуатацию объекта</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									<tr>
										<td>Планируемый срок проектирования энергопринимающих устройств (месяц, год)</td>
										<td>Планируемый срок введения энергопринимающих устройств в эксплуатацию (месяц, год)</td>
										<td>Максимальная мощность энергопринимающих устройств (кВт)</td>
										<td>Категория надежности энергопринимающих устройств (II, III)</td>
									</tr>

									{application.deadlines.map((item, index) => <tr key={index}>
										<td>{moment(item.start).format('MM.YYYY')}</td>
										<td>{moment(item.end).format('MM.YYYY')}</td>
										<td>{item.voltage}</td>
										<td>{item.category === '2' ? 'II' : item.category === '3' ? 'III' : null}</td>
									</tr>)}
									</tbody>
								</table>
							</div>}

							{application.otherinfo?.otherinfopayment && <div>
								<div className="h5">
									Порядок   расчета   и   условия   рассрочки   внесения   платы  за
									технологическое присоединение по договору осуществляются по:
								</div>

								{application.otherinfo?.otherinfopayment === 1 && <div>
									<p>Вариант 1, при котором:</p>

									<ul>
										<li>15 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня заключения договора;</li>
										<li>30 процентов платы за технологическое присоединение вносятся в течение 60 дней со дня заключения договора, но не позже дня фактического присоединения;</li>
										<li>45 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня фактического присоединения:</li>
										<li>10 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня подписания акта об осуществлении технологического присоединения.</li>
									</ul>
								</div>}

								{application.otherinfo?.otherinfopayment === 2 && <div>
									<p>Вариант 2, при котором:</p>

									<ul>
										<li>авансовый платеж вносится в размере 10 процентов размера платы за технологическое присоединение;</li>
										<li>осуществляется беспроцентная рассрочка платежа в размере 90 процентов платы за технологическое присоединение с условием ежеквартального внесения платы равными долями от общей суммы рассрочки на период до 3 лет со дня подписания сторонами акта об осуществлении технологического присоединения.</li>
									</ul>
								</div>}
							</div>}

							{application.additionalconditions && <div>
								<div className="h5">Дополнительные условия</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									<tr>
										<td>От моего имени будет выступать представитель по доверенности</td>
										<td>{application.additionalconditions.additionalconditionsrepresent ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Осуществляется присоединение устройств, находящихся в нежилых помещениях многоквартирного дома
										</td>
										<td>{application.additionalconditions.additionalconditionsuninhabited ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Проектом на многоквартирный дом для нежилого помещения предусмотрено индивидуальное
											вводно-распределительное устройство
										</td>
										<td>{application.additionalconditions.additionalconditionsindividual ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Энергопринимающие устройства будут присоединены к устройствам противоаварийной и режимной
											автоматики
										</td>
										<td>{application.additionalconditions.additionalconditionsemergency ? 'Да' : 'Нет'}</td>
									</tr>
									</tbody>
								</table>
							</div>}

							<div>
								<div className="h5">Документы</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									{application.type === 'fiz' && <tr>
										<td>Сканированная копия паспорта физического лица:</td>
										<td>{application.passportfiz && application.passportfiz.length > 0 && application.passportfiz.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									{application.type === 'ur' && <tr>
										<td>Сканированная копии документов ЕГРЮЛ:</td>
										<td>{application.egrnum_docs && application.egrnum_docs.length > 0 && application.egrnum_docs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									{application.type === 'ip' && <tr>
										<td>Сканированная копии документов ЕГРИП:</td>
										<td>{application.egrnum_docs && application.egrnum_docs.length > 0 && application.egrnum_docs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									<tr>
										<td>Сканированная копия доверенности представителя:</td>
										<td>{application.warrant && application.warrant.length > 0 && application.warrant.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Документы для индивидуального вводно-распределительное устройства:</td>
										<td>{application.conditionsindividualdocs && application.conditionsindividualdocs.length > 0 && application.conditionsindividualdocs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>План расположения энергопринимающих устройств:</td>
										<td>{application.plan && application.plan.length > 0 && application.plan.map((item, index) => <div
											key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Перечень и мощность энергопринимающих устройств, которые могут быть присоединены к устройствам
											противоаварийной автоматики:
										</td>
										<td>{application.accident && application.accident.length > 0 && application.accident.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Правоустанавливающие документы:</td>
										<td>{application.constitutivedocuments && application.constitutivedocuments.length > 0 && application.constitutivedocuments.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Прочие документы:</td>
										<td>{application.otherdocuments && application.otherdocuments.length > 0 && application.otherdocuments.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>
									</tbody>
								</table>
							</div>

							<div>
								<div className="h5">Прочие сведения</div>
							</div>
							<div>
								{application && application.aggrementtype === 0 &&
								<div className="alert alert-warning" role="alert">При подаче заявки был выбран способ получения договора
									по email</div>}
								{application && application.aggrementtype === 1 &&
								<div className="alert alert-warning" role="alert">При подаче заявки был выбран способ получения договора
									на бумажном носителе</div>}
							</div>

						</div>}

						{page === 2 && <div className="col-12">
							{application && application.application_file && <a
								className="btn btn-primary"
								href={application.application_file.url}
							>Скачать сгенерированную заявку</a>}
							{/*{console.log(application)}*/}
							{!isAdmin &&
								<UserDocuments
									applicationId={applicationId}
									agreement_files={agreementFiles}
									oferta={application.oferta}
									specialCondition={specialCondition}
									specInstructions={specInstructions}
									isMicro={isMicro}
								/>
							}

							{isAdmin && isTNS &&
							<TNSDocuments
								applicationId={applicationId}
								agreement_files={agreementFiles}
								oferta={application.oferta}
								specialCondition={specialCondition}
								specInstructions={specInstructions}
								isMicro={isMicro}
							/>
							}

							{isAdmin && !isTNS &&
								<AdminDocuments
									applicationId={applicationId}
									agreement_files={agreementFiles}
									oferta={application.oferta}
									specialCondition={specialCondition}
									specInstructions={specInstructions}
									isMicro={isMicro}
								/>
							}

						</div>}

						{page === 3 && !isAdmin && <UserPaychecks
							paychecks={paychecks}
							statusPayment={statusPayment}
							//setRefresh={setRefresh}
						/>}

						{page === 3 && isAdmin && <AdminPaychecks
							applicationId={applicationId}
							paychecks={paychecks}
							statusPayment={statusPayment}
							setPaychecks={setPaychecks}
							//setRefresh={setRefresh}
						/>}

						{page === 4 && <div className="col-12">
							<Chat application={application} serviceField="application_id" isAdmin={isAdmin}/>
						</div>}
					</>}
				</div>
			</div>
		</div>
	);
}

export default TechnicalApplication;