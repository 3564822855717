import React, {useContext, useEffect, useState} from 'react'
import Layout from '../../../../components/Layout'
import PageSpinner from '../../../../components/PageSpinner'
import {Redirect} from 'react-router-dom'
import Dictionary from "../../../../components/Dictionary"
import DictionaryItem from "./components/DictionaryItem"
import DeleteItem from "./components/DeleteItem"
import NewItem from "./components/NewItem"
import EditItem from "./components/EditItem"
import API from "../../../../lib/API"
import locale from "../../../../lib/locale"
import {AuthContext} from "../../../../lib/AuthContext"
import UnauthorizedError from "../../../../lib/UnauthorizedError"

function AdminsDictionaryRoute(props) {
  const {authContext} = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [departments, setDepartments] = useState(false)
  const [TNSDepartments, setTNSDepartments] = useState(false)
  const [permissions, setPermissions] = useState(false)

  useEffect(() => {
		const fetchData = async () => {
			try {
				let result = (await API.departmentsAPI.get()).data
        let newDepartments = []
				result.forEach(item => {
          newDepartments.push({
						id: item.id,
						name: item.name
					})
				})
				setDepartments(newDepartments)

        result = (await API.TNSDepartmentsAPI.get()).data
        newDepartments = []
        result.forEach(item => {
          newDepartments.push({
            id: item.id,
            name: item.name
          })
        })
        setTNSDepartments(newDepartments)

        result = (await API.adminAPI.getPermissions())

        const newPermissions = new Map()
        result.forEach(item => newPermissions.set(item.id, { name: item.name, caption: locale.permissionGroups[item.name] }))
				setPermissions(newPermissions)
			} catch (e) {
				console.error(e)
			}

			setLoading(false)
		}

		setLoading(true)
		fetchData()
	}, [])

  if (loading || !departments || !permissions) {
    return <PageSpinner/>
  } else {
    const ExtendedDictionaryItem = (props) => <DictionaryItem
      permissions={permissions}
      departments={departments}
      TNSDepartments={TNSDepartments}
      {...props}
    />
    const ExtendedNewItem = (props) => <NewItem
      permissions={permissions}
      departments={departments}
      TNSDepartments={TNSDepartments}
      superadmin={authContext.permissions.name === 'superadmin'}
      {...props}
    />
    const ExtendedEditItem = (props) => <EditItem
      permissions={permissions}
      departments={departments}
      TNSDepartments={TNSDepartments}
      superadmin={authContext.permissions.name === 'superadmin'}
      {...props}
    />

    return (
      <Layout>
        <Dictionary
          dictionaryAPI={API.usersAPI}
          dataRequestMethod="getAdminUsers"
          dictionaryItem={ExtendedDictionaryItem}
          popups={{
            NewItem: ExtendedNewItem,
            EditItem: ExtendedEditItem,
            DeleteItem
          }}
          vocabulary={{
            name: "Администраторы",
            headers: {
              id: "ID",
              name: "ФИО",
              email: "E-mail",
              permission_id: "Права доступа",
              department: "Подразделение"
            }
          }}
        />
      </Layout>
    )
  }
}

export default AdminsDictionaryRoute
