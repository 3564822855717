import React, {useContext} from "react";
import s from '../AdminUsers.module.css';
import cx from "classnames";
import Button from "../../../../../components/Button";
import {userType} from "../../../../../lib/utils";
import moment from "moment";
import {AuthContext} from "../../../../../lib/AuthContext";

function DictionaryItem (props) {

  const { item, openPopup } = props
  const { authContext } = useContext(AuthContext)

  return (
    <tr className={cx({[s['inactive']]: !item.email_verified_at})}>
      <td className={cx('text-center', s['id'])}>{item.id}</td>
      <td className="text-center">{userType[item.type]}</td>
      <td className="text-center">{item.email}</td>
      <td className={cx('text-center', s['name'])}>{item.name}</td>

      {item.type === 'fiz' && <td className="text-center">{item.egrnum ? `СНИЛС: ${item.egrnum}` : ''}</td>}
      {item.type === 'ip' && <td className="text-center">{item.egrnum ? `ЕГРИП: ${item.egrnum}` : ''}</td>}
      {item.type === 'ur' && <td className="text-center">{item.egrnum ? `ЕГРЮЛ: ${item.egrnum}` : ''}</td>}

      <td className="text-center">{item.phone}</td>
      <td className="text-center">{moment(item.created_at).format("DD.MM.YYYY")}</td>
      <td className={cx('text-center', s['controls'])}>
        {authContext?.permissions?.name !== "support" && <Button
          className="btn btn-primary w-100"
          onClick={() => openPopup('CreateTechnicalRequest', { item })}
        >Создать заявку</Button>}

        <Button
          className="btn btn-primary mt-2 w-100"
          onClick={() => openPopup('EditItem', { item })}
        >Редактировать</Button>

        <Button
          className="btn btn-danger w-100 mt-2"
          onClick={() => openPopup('DeleteItem', { item })}
        >Удалить</Button>
      </td>
    </tr>
  )
}

export default DictionaryItem