import React, {Component} from "react";
import cx from "classnames";
import s from "../AdminDepartments.module.css";

function DictionaryItem (props) {

  const {item, openPopup} = props;

  return (
    <tr>
      <td className="text-center">{item.name}</td>
      <td className="text-center">
        {item.phone && item.phone.length > 0 && item.phone[0]}
        {item.phone && item.phone.length > 1 && <><br/>{item.phone[1]}</>}
      </td>
      <td className={cx('text-center', s['towns'])}>{item.towns.map((town, index) => <span key={index}>{town.name}{index !== item.towns.length - 1 ? ", " : ""}</span>)}</td>
      <td className="text-center">
        <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
        <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
      </td>
    </tr>
  );
}

export default DictionaryItem;