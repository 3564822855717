import React, {useContext, useState} from "react";
import s from "../../routes/TechnicalApplication/TechnicalApplication.module.css";
import cx from "classnames";
import {PopupContext} from "../../lib/PopupContext";
import API from "../../lib/API";
import {AuthContext} from "../../lib/AuthContext";
import moment from "moment";

function DownloadFile (props) {
  const { setPopupSettings } = useContext(PopupContext)
  const { authContext } = useContext(AuthContext)
  const [ url, setURL ] = useState(props.url)
  const serviceAPI = props.API ? props.API : API.applicationsAPI

  async function deleteFile () {
    try {
      await serviceAPI.deleteAgreementFile({application_id: props.applicationId, name: props.name})
      setPopupSettings({})
      setURL(null)
    } catch (e) {
      console.error(e)
      setPopupSettings({
        type: 'info',
        text: "Ошибка загрузки. Проверьте сетевое соединение и повторите попытку позже."
      })
    }
  }

  function deleteFileConfirm () {
    setPopupSettings({
      type: 'confirm',
      text: 'Удалить файл "' + props.text + '" ?',
      doAction: deleteFile
    })
  }

  return <div className={s['docs__item']}>
    <div className={s['docs__label']}>{ props.text }</div>
    <div className={s['docs__field']}>
      {url ? <><a
          className={cx('btn', 'btn-primary', 'mr-2', {[s['unviewed-btn']]: !props.viewed})}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
      >Скачать</a>
      {authContext.superadmin && <button
        className="btn btn-danger mr-2"
        onClick={deleteFileConfirm}
      >
        Удалить
      </button>}
      </>
      : <div className={s['docs__warning']}>Файл не приложен</div>
      }
    </div>
    {props.uploaded_at && <sup>Загружен {moment(props.uploaded_at).format("DD.MM.YYYY HH:mm:ss")}</sup>}
  </div>
}

export default DownloadFile;