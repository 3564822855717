import React from 'react';
import cx from "classnames";
import s from "../../routes/TechnicalApplication/TechnicalApplication.module.css";

function FullAddressTable (props) {
  const { address } = props;

  return <table className={cx('table', s['address'], 'table-striped', s['table'])}>
    <tbody>
      <tr>
        <td>Индекс</td>
        <td>Субъект РФ</td>
        <td>Район</td>
        <td>Город</td>
        <td>Населенный пункт</td>
        <td>Улица</td>
        <td>Номер дома</td>
        <td>Корпус</td>
        <td>Строение</td>
        <td>Квартира/Офис/Комната</td>
      </tr>

      <tr>
        <td>{address.index}</td>
        <td>{address.subject}</td>
        <td>{address.district}</td>
        <td>{address.city}</td>
        <td>{address.town}</td>
        <td>{address.street}</td>
        <td>{address.house}</td>
        <td>{address.section}</td>
        <td>{address.building}</td>
        <td>{address.flat}</td>
      </tr>
    </tbody>
  </table>
}

export default FullAddressTable