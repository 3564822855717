import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import MaskedInput from "react-text-mask";
import AddressPartSelect from "./AddressPartSelect";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabAddress (props) {
  const { appData, dispatch } = useContext(ApplicationContext)
  const { header, field, sameAddressLabel } = props;

  function setAddressPart(part, value) {
    const address = appData[props.field]
    address[part] = value
    dispatch({type: "setField", payload: { field, value: address}})
  }

  function setAddressPartByObject(part) {
    const address = appData[props.field]

    return value => {
      switch (part) {
        case 'subject':
          address.subject = value.value.region_with_type;
          address.region_fias_id = value.value.region_fias_id;
          break;
        case 'district':
          address.district = value.value.area_with_type;
          address.area_fias_id = value.value.area_fias_id;
          if (!address.subject)
            address.subject = value.value.region_with_type;
          address.region_fias_id = value.value.region_fias_id;
          break;
        case 'city':
          address.city = value.value.settlement_with_type || value.value.city_with_type;
          address.kladr_full = value.value;
          address.subject = value.value.region_with_type;
          address.district = value.value.area_with_type;

          if (value.value.settlement)
            address.settlement_fias_id = value.value.settlement_fias_id;
          if (value.value.city)
            address.city_fias_id = value.value.city_fias_id;
          break;
        case 'street':
          address.street = value.value.street_with_type;
          address.kladr_full = value.value;

          if (!address.subject)
            address.subject = value.value.region_with_type;

          if (!address.district)
            address.district = value.value.area_with_type;

          if (!address.city) {
            address.city = value.settlement_with_type || value.city_with_type;

            if (value.value.settlement)
              address.settlement_fias_id = value.value.settlement_fias_id;
            if (value.value.city)
              address.city_fias_id = value.value.city_fias_id;
          }
          break;
      }

      dispatch({ type: "setField", payload: { field, value: address } })
    }
  }

  const address = appData[field]
  console.log(address)
  const errors = appData.errors.errors

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">{header}</div>
    </div>

    {sameAddressLabel && <div className="form-group">
      <div>
        <label>{sameAddressLabel}</label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="sameAddressYes"
          name="sameAddress"
          className="custom-control-input"
          checked={!!address.sameAddress}
          onChange={(e) => {setAddressPart('sameAddress', true)}}

        />

        <label className="custom-control-label" htmlFor="sameAddressYes">Да</label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="sameAddressNo"
          name="sameAddress"
          className="custom-control-input"
          checked={!address.sameAddress}
          //onChange={() => dispatch({ type: "setField", payload: { field: 'sameAddress', value: false } })}
          onChange={(e) => {setAddressPart('sameAddress', false)}}
        />

        <label className="custom-control-label" htmlFor="sameAddressNo">Нет</label>
      </div>
    </div>}
    {!address.sameAddress && <>
      <div className="form-group">
        <label htmlFor="index">Почтовый индекс</label>
        <MaskedInput
          id="index"
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.index})}
          autoComplete="no"
          value={address.index}
          onChange={(e) => {setAddressPart('index', e.currentTarget.value)}}
        />
      </div>

      <div className="form-group">
        <label htmlFor="subject">Субъект РФ <span className={s['red-star']}>*</span></label>
        <AddressPartSelect
          id="subject"
          error={!!(errors[`${address.type}subject`])}
          value={address.subject}
          searchOptions={{
            "from_bound": { "value": "region" },
            "to_bound": { "value": "region" }
          }}
          setAddressPart={setAddressPartByObject('subject')}
        />
      </div>

      <div className="form-group">
        <label htmlFor="district">Район <div className={s['info']}>В случае проживания в городе район не указывать</div></label>
        <AddressPartSelect
          id="district"
          value={address.district}
          searchOptions={{
            from_bound: { "value": "area" },
            to_bound: { "value": "area" },
            restrict_value: true,
            locations: [{ 'region_fias_id': address.region_fias_id }]
          }}
          setAddressPart={setAddressPartByObject('district')}
        />
      </div>

      <div className="form-group">
        <label htmlFor="city">Город <span className={s['red-star']}>*</span> <div className={s['info']}>Начните вводить город и выберите из выпадающего списка</div></label>

        <AddressPartSelect
          id="city"
          error={!!(errors[`${address.type}city`])}
          value={address.city}
          searchOptions={{
            "from_bound": { "value": "city" },
            "to_bound": { "value": "settlement" },
            "restrict_value": true,
            locations: [{
              'region_fias_id': address.region_fias_id,
              'area_fias_id': address.area_fias_id
            }]
          }}
          setAddressPart={setAddressPartByObject('city')}
        />
      </div>

      <div className="form-group">
          <label htmlFor="street">Улица <span className={s['red-star']}>*</span></label>
          <AddressPartSelect
            id="street"
            error={!!(errors[`${address.type}street`])}
            value={address.street}
            searchOptions={{
              "from_bound": { "value": "street" },
              "to_bound": { "value": "street" },
              "restrict_value": true,
              locations: [{
                'region_fias_id': address.region_fias_id,
                'settlement_fias_id': address.settlement_fias_id,
                'city_fias_id': address.city_fias_id
              }]
            }}
            setAddressPart={setAddressPartByObject('street')}
          />
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="house">Номер дома <span className={s['red-star']}>*</span></label>
            <input
              className={cx("form-control", {[s['error-field']]: errors[`${address.type}house`]})}
              id="house"
              autoComplete="no"
              value={address.house}
              onChange={(e) => {setAddressPart('house', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="section">Корпус</label>
            <input
              type="text"
              className={cx("form-control", {[s['error-field']]: errors.section})}
              id="section"
              autoComplete="no"
              value={address.section}
              onChange={(e) => {setAddressPart('section', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="building">Строение</label>
            <input
              type="text"
              className={cx("form-control", {[s['error-field']]: errors.building})}
              id="building"
              autoComplete="no"
              value={address.building}
              onChange={(e) => {setAddressPart('building', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="flat">Квартира/Офис/Комната</label>
            <MaskedInput
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
              guide={false}
              className={cx("form-control", {[s['error-field']]: errors.flat})}
              id="flat"
              autoComplete="no"
              value={address.flat}
              onChange={(e) => {setAddressPart('flat', e.currentTarget.value)}}
            />
          </div>
        </div>
      </>}

  </div>

}

export default TabAddress;