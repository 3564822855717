import React, {useEffect, useState} from "react"
import AdminConnectionCalcs from "./AdminConnectionCalcs";
import Layout from "../../../components/Layout";
import API from "../../../lib/API";
import PageSpinner from "../../../components/PageSpinner";

function AdminConnectionCalcsRoute (props) {
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        const response = await API.adminAPI.getCalcs()
        setData(response)
      } catch (e) {
        console.error(e)
      }

      setLoading(false)
    }

    fetchData()
  }, [])

  if (loading)
    return <PageSpinner/>
  else
    return <Layout>
      <AdminConnectionCalcs data={data}/>
    </Layout>
}

export default AdminConnectionCalcsRoute