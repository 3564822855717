import React from "react"
import Layout from "../../../components/Layout";
import AdminMainPageEditor from "./AdminMainPageEditor";

function AdminMainPageEditorRoute (props) {
  return <Layout>
    <AdminMainPageEditor/>
  </Layout>
}

export default AdminMainPageEditorRoute