import React, { Component } from 'react';
import Layout from '../../../components/Layout';
import Registration from './Registration';
import { getAccessToken, deleteCookie } from '../../../lib/utils';
import Button from '../../../components/Button';

class route_auth_registration extends Component {
	render() {
		const
			access = getAccessToken(),
			confirm = this.props.history.location.pathname.indexOf('registration/confirm') !== -1;
		
		if (!access) {
			return (
				<Layout>
					<div style={{
						    display: "flex",
							width: "100%",
							height: "100%",
							alignItems: "center",
							justifyContent: "center"
					}}>
						<p>Регистрация закрыта</p>
					</div>
					{/* <Registration /> */}
				</Layout>
			);
		} else if (access && confirm) {
			return (
				<Layout>
					<div style={{
						    display: "flex",
							width: "100%",
							height: "100%",
							alignItems: "center",
							justifyContent: "center"
					}}>
						<p>Регистрация закрыта</p>
					</div>
					{/* <Registration confirm={confirm} /> */}
				</Layout>
			);
		} else {
			return (<div className="">
				<div className="h2 text-center mt-5">Пользователь авторизован</div>
				<div className="text-center mt-5">
					<Button className="btn btn-primary" to="/cabinet">Личный кабинет</Button>
					
					<Button className="btn btn-primary" to="/login" onClick={() => {
						deleteCookie('access_token');
						deleteCookie('admin');
						localStorage.removeItem('access_token');
						localStorage.removeItem('admin');
					}}>Выход</Button>
				</div>
			</div>);
		}
	}
}

export default route_auth_registration;
