import API from './API';

export let docTypes = {
	'pasport': 'Паспорт',
	'representpasport': 'Паспорт',
};

export function setCookie(name, value, days) {
	var d = new Date();
	d.setTime(d.getTime() + 24*60*60*1000*days);
	document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
};

export function getCookie(name) {
	var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return v ? v[2] : null;
};

export function deleteCookie(name) {
	setCookie(name, '', -1);
};

export function setStorageItem(name, value) {
    window.localStorage.setItem(name, value)
};

export function removeStorageItem(name) {
    window.localStorage.removeItem(name)
};

export function getStorageItem(name) {
    return window.localStorage.getItem(name)
};

export function getOptionsByType(typeField, state) {
	let
		options = {},
		type = typeField.split('address')[0],
		field = typeField.split('address')[1];


	if (field === 'rfsubject') {
		options = {
			"from_bound": { "value": "region" },
			"to_bound": { "value": "region" }  
		};
	} else if (field === 'district') {
		options = {
			"from_bound": { "value": "area" },
			"to_bound": { "value": "area" },
			"restrict_value": true,
		};

		if (type === 'connection')
			options.locations = [{ 'region_fias_id': state['region_fias_id'] }];
		else if (state[type] && state[type]['region_fias_id']) {
			options.locations = [{ 'region_fias_id': state[type]['region_fias_id'] }];
		}

	} else if (field === 'city') {
		options = {
			"from_bound": { "value": "city" },
			"to_bound": { "value": "settlement" },
			"restrict_value": true,
		};

		if (type === 'connection')
			options.locations = [{ 'region_fias_id': state['region_fias_id'] }];
		else if (state[type] && state[type]['region_fias_id']) {
			options.locations = [{ 'region_fias_id': state[type]['region_fias_id'] }];
		}

	} else if (field === 'street') {
		options = {
			"from_bound": { "value": "street" },
			"to_bound": { "value": "street" },
			"restrict_value": true,
		};
		
		if (state[type] && state[type]['settlement_fias_id']) {
			options.locations = [{ 'settlement_fias_id': state[type]['settlement_fias_id'] }];
		} else if (state[type] && state[type]['city_fias_id']) {
			options.locations = [{ 'city_fias_id': state[type]['city_fias_id'] }];
		}
	} else if (field === 'house') {
		options = {
			"from_bound": { "value": "house" },
			"restrict_value": true,
		};
		
		if (state[type] && state[type]['street_fias_id']) {
			options.locations = [{ 'street_fias_id': state[type]['street_fias_id'] }];
		}
	} else {
		options = {
			"from_bound": '',
			"to_bound": ''
		};
	}
	
	return options;
};

export function getStatus(statusNum) {
	switch (statusNum) {
		case 0:
			return 'Аннулирована';
		case 1:
			return 'Ожидает обработки';
		case 2:
			return 'Принята';
		case 3:
			return 'Выполнена';
		default:
			return '';
	}
};

export function validateSnils(snils) {
	let result = false, error = {};
	
	if (typeof snils === 'number') {
		snils = snils.toString();
	} else if (typeof snils !== 'string') {
		snils = '';
	}

	
	if (!snils.length) {
		result = true
	} else if (/[^0-9]/.test(snils)) {
		error.code = 2;
		error.message = 'СНИЛС может состоять только из цифр';
	} else if (snils.length !== 11) {
		error.code = 3;
		error.message = 'СНИЛС может состоять только из 11 цифр';
	} else {
		let sum = 0;
		
		for (let i = 0; i < 9; i++) {
			sum += parseInt(snils[i]) * (9 - i);
		}
		
		let checkDigit = 0;
		
		if (sum < 100) {
			checkDigit = sum;
		} else if (sum > 101) {
			checkDigit = parseInt(sum % 101);
			
			if (checkDigit === 100) {
				checkDigit = 0;
			}
		}
		
		if (checkDigit === parseInt(snils.slice(-2))) {
			result = true;
		} else {
			error.code = 4;
			error.message = 'Неправильное контрольное число';
		}
	}
	
	return { result, error };
};

export async function checkAuth() {
	const
		access = getCookie('access_token') || localStorage.getItem('access_token'),
		userData = (await API.check(access)).data;

	let accessAdmin = true

	if (!access || !userData || !userData.admin) {
		accessAdmin = false;
		localStorage.removeItem('admin');
		deleteCookie('admin');
	}

	return { userData, accessAdmin }
}

export const getAccessToken = () => {
	const
		cookieAccess = getCookie('access_token'),
		localAccess = localStorage.getItem('access_token');
	return localAccess ? localAccess : cookieAccess;
};

export const userType = {
	'fiz': 'Физическое лицо',
	'ip': 'Индивидуальный предприниматель',
	'ur': 'Юридическое лицо'
};

export function makeRandomString(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for ( let i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result + Date.now();
}

export async function checkUser() { //TODO remove, moved to AuthAPI
	const
		access = getAccessToken(),
		{ data } = await API.authAPI.check(),
		checkData = {};

	if (!access || !data || !data.admin) {
		checkData.noAdminAccess = true;
		localStorage.removeItem('admin');
		deleteCookie('admin');
	}

	if (data) {
		checkData.userId = data.user_id;
		checkData.userData = data.user;
		checkData.permissions = data.permissions;
		checkData.application_view_settings = data.application_view_settings;
		checkData.other_services_view_settings = data.other_services_view_settings;
		checkData.access = !!access;
	}

	return checkData;
}