import DictionaryAPI from "./DictionaryAPI";

class UsersAPI extends DictionaryAPI {
  async getProfile (data) {
    return this.sendRequest(this.url + '/getProfile', data)
  }

  async setPhone (data) {
    return this.sendRequest(this.url + '/setPhone', data)
  }

  async getAdminUsers(data) {
    return this.sendRequest(this.url + '/getAdminUsers', data)
  }

  async createAdminUser(data) {
    return this.sendRequest(this.url + '/createAdminUser', data)
  }

  async updateAdminUser(data) {
    return this.sendRequest(this.url + '/updateAdminUser', data)
  }

  async setAdminDepartment(data) {
    return this.sendRequest(this.url + '/setAdminDepartment', data)
  }
}

export default UsersAPI;