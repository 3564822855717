import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import ReactHintFactory from 'react-hint';
import cx from "classnames";
import {ApplicationContext} from "../lib/ApplicationContext";
const ReactHint = ReactHintFactory(React);

function TabOtherInfo () {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors
  //const { data, powerCondition, errors } = this.props;

  const powerCondition = Number(appData.connectiontechspecs.powerTotal.power) > 15 && Number(appData.connectiontechspecs.powerTotal.power) <= 150;

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Прочие сведения</div>
    </div>

    <div className="form-group">
      <label>Способ обмена документами <span className={s['red-star']}>*</span> <span data-rh='aggrementtypeSelect' className="hint">?</span></label>

      <select
        className="custom-select"
        value={appData.aggrementtype}
        onChange={e => dispatch({type: "setField", payload: {field: 'aggrementtype', value: e.currentTarget.value}})}
      >
        <option value="0">В электронной форме</option>
        <option value="1">На бумажном носителе</option>
      </select>
    </div>

    <ReactHint
      autoPosition
      events
      onRenderContent={(target, content) => {
        if (content === 'aggrementtypeSelect')
            return <div className="hint-text">
              Взаимодействие до завершения процедуры технологического присоединения с физическими лицами максимальная
              мощность энергопринимающих устройств которых составляет до 15 кВт для бытовых и иных нужд, не связанных
              с осуществлением предпринимательской деятельности и юридическими лицами или индивидуальными предпринимателями
              по второй или третьей категории надежности энергопринимающих устройств, максимальная мощность которых
              составляет до 150 кВт на уровне напряжения 0,4 кВ осуществляется только в электронной форме
            </div>
        }
      }
    />

    {powerCondition && <>
      <div className="form-group">
        <div className="h6">Порядок расчета и условия рассрочки внесения платы за технологическое присоединение по договору осуществляются по:</div>
      </div>

      <div className="form-group">
        <div>
          <label>Способ получения счета: <span className={s['red-star']}>*</span></label>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="otherinfopayment1"
            name="otherinfopayment"
            className="custom-control-input"
            checked={appData.otherinfopayment === 1}
            onChange={e => dispatch({type: "setField", payload: {field: 'otherinfopayment', value: 1}})}
          />

          <label className={cx('custom-control-label', s['custom-control-label-radio'])} htmlFor="otherinfopayment1">Вариант 1, при котором:</label>

          <ul>
            <li>15 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня заключения договора;</li>
            <li>30 процентов платы за технологическое присоединение вносятся в течение 60 дней со дня заключения договора, но не позже дня фактического присоединения;</li>
            <li>45 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня фактического присоединения:</li>
            <li>10 процентов платы за технологическое присоединение вносятся в течение 15 дней со дня подписания акта об осуществлении технологического присоединения.</li>
          </ul>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="otherinfopayment2"
            name="otherinfopayment"
            className="custom-control-input"
            checked={appData.otherinfopayment === 2}
            onChange={e => dispatch({type: "setField", payload: {field: 'otherinfopayment', value: 2}})}
          />

          <label className={cx('custom-control-label', s['custom-control-label-radio'])} htmlFor="otherinfopayment2">Вариант 2, при котором:</label>

          <ul>
            <li>авансовый платеж вносится в размере 10 процентов размера платы за технологическое присоединение;</li>
            <li>осуществляется беспроцентная рассрочка платежа в размере 90 процентов платы за технологическое присоединение с условием ежеквартального внесения платы равными долями от общей суммы рассрочки на период до 3 лет со дня подписания сторонами акта об осуществлении технологического присоединения.</li>
          </ul>
        </div>
      </div>
    </>}

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="person"
          checked={appData.person}
          onChange={e => dispatch({type: "setField", payload: {field: 'person', value: !appData.person}})}
        />

        <label className="custom-control-label" htmlFor="person">В соответствии с Федеральным законом от 27 июля 2006 года № 152 «О персональных данных», я согласен на использование моих персональных данных, и даю согласие на обработку своих персональных данных по настоящей заявке с целью осуществления технологического присоединения энергопринимающих устройств объекта <span className={s['red-star']}>*</span></label>
      </div>
    </div>

  </div>

}

export default TabOtherInfo;