import React from 'react';
import AdminControls from './AdminControls';
import Layout from "../../../components/Layout";

function AdminDashboardControlsRoute (props) {
	return <Layout>
		<AdminControls/>
	</Layout>
}

export default AdminDashboardControlsRoute;
