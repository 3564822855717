import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Cabinet from './Cabinet';

class CabinetRoute extends Component {
	render() {
		return <Layout>
			<Cabinet/>
		</Layout>
	}
}

export default CabinetRoute;
