import React, {useContext, useState} from "react"
import cx from "classnames";
import s from "../AdminTechnicalApplications.module.css";
import Button from "../../../../components/Button";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../../../../lib/AuthContext";
import {SettingsContext} from "../SettingsContext";
import API from "../../../../lib/API";

function FilterOption (props) {
  const { index, field, value, setFieldValue } = props

  const {
    statuses,
    departments
  } = useContext(SettingsContext)

  switch (field) {
    case 'viewed':
      return <>
          <Button
            onClick={() => setFieldValue(index, 1)}
            className={cx('btn', {'btn-outline-primary': value !== 1}, {'btn-primary': value === 1})}
          >Да</Button>
          <Button
            onClick={() => setFieldValue(index, 0)}
            className={cx('btn', {'btn-outline-primary': value !== 0}, {'btn-primary': value === 0})}
          >Нет</Button>
      </>
    case 'application_number':
      return <>
        <input
          type="text"
          className="form-control"
          value={value ? value : ''}
          onChange={(e) => setFieldValue(index, e.currentTarget.value)}
        />
      </>
    case 'created_at':
      return <>
        <div>От</div>
        <div className="input-group">
          <DatePicker
            className="form-control"
            selected={value.from}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              value.from = date
              setFieldValue(index, value)
            }}
          />
        </div>

        <div>До</div>
        <div className="input-group">
          <DatePicker
            className="form-control"
            selected={value.to}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              value.to = date
              setFieldValue(index, value)
            }}
          />
        </div>
      </>
    case 'status_id':
      return <>
        <select
          className="custom-select"
          value={value}
          onChange={(e) => {
            setFieldValue(index, e.currentTarget.value)
          }}
        >
          {statuses && Object.keys(statuses).map(key => <option key={statuses[key].id} value={statuses[key].id}>{statuses[key].description}</option>)}
        </select>
      </>
    case 'registered_at':
      return <>
        <div>От</div>
        <div className="input-group">
          <DatePicker
            className="form-control"
            selected={value.from}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              value.from = date
              setFieldValue(index, value)
            }}
          />
        </div>

        <div>До</div>
        <div className="input-group">
          <DatePicker
            className="form-control"
            selected={value.to}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              value.to = date
              setFieldValue(index, value)
            }}
          />
        </div>
      </>
    case 'name':
      return <>
        <input
          type="text"
          className="form-control"
          value={value ? value : ''}
          onChange={(e) => { setFieldValue(index, e.currentTarget.value) }}
        />
      </>
    case 'department_id':
      return <>
        <select
          className="custom-select"
          value={value}
          onChange={(e) => { setFieldValue(index, e.currentTarget.value) }}
        >
          {departments && Object.keys(departments).map(key => <option key={key} value={key}>{departments[key].name}</option>)}
        </select>
      </>
    case 'address':
      return <>
        <input
          type="text"
          className="form-control"
          value={value ? value : ''}
          onChange={(e) => { setFieldValue(index, e.currentTarget.value) }}
        />
      </>
    case 'aggrementtype':
      return <>
        <select
          className="custom-select"
          value={value}
          onChange={(e) => { setFieldValue(index, value) }}
        >
          <option value="1">На бумажном носителе</option>
          <option value="0">Цифровой</option>
        </select>
      </>
    case 'comments':
      return <>
        <input
          type="text"
          className="form-control"
          value={value ? value : ''}
          onChange={(e) => { setFieldValue(index, e.currentTarget.value) }}
        />
      </>
  }
}

function Filter (props) {
  const { showFilter, setShowFilter, setShowColumnSettings } = props
  const { authContext } = useContext(AuthContext)
  const {
    loading,
    applicationFilter,
    setApplicationFilter,
    statuses,
    departments
  } = useContext(SettingsContext)

  const filterCaptions = {
    viewed: 'Флаг просмотра',
    application_number: 'Номер заявки',
    created_at: 'Дата заявки',
    status_id: 'Статус',
    registered_at: 'Дата регистрации',
    name: 'Наименование заявителя',
    department_id: 'Филиал',
    address: 'Адрес объекта',
    aggrementtype: 'Способ получения договора',
    comments: 'Комментарий'
  }

  const defaultFilterValues = {
    viewed: 1,
    application_number: '',
    created_at: { from: '', to: '' },
    status_id: statuses[Object.keys(statuses)[0]],
    registered_at: { from: '', to: '' },
    name: '',
    department_id: departments[Object.keys(departments)[0]],
    address: '',
    aggrementtype: 0,
    comments: ''
  }

  const initialFilter = []
  if (typeof applicationFilter === 'object') {
    Object.entries(applicationFilter).forEach(([field, value]) => {
      if (field === 'created_at') {
        if (value.from) value.from = new Date(value.from)
        if (value.to) value.to = new Date(value.to)
      }
      if (field === 'registered_at') {
        if (value.from) value.from = new Date(value.from)
        if (value.to) value.to = new Date(value.to)
      }

      initialFilter.push({
        field,
        value
      })
    })
  }

  const [ filter, setFilter ] = useState([...initialFilter])
  //console.log('filter', filter)
  //const defaultFieldArray = Object.keys(defaultFilterValues)
  let availableFilterOptions = {...defaultFilterValues}

  filter.forEach(row => {
    delete availableFilterOptions[row.field]
  })

  availableFilterOptions = Object.keys(availableFilterOptions)
  //console.log(availableFilterOptions)

  function setFilterField(index, field) {
    filter[index].field = field
    filter[index].value = defaultFilterValues[field]
    setFilter([...filter])
  }

  function setFieldValue (index, value) {
    filter[index].value = value
    setFilter([...filter])
  }

  function applyFilter () {
    const newFilter = {}
    filter.forEach(row => {
      newFilter[row.field] = row.value
    })

    try {
      API.viewSettingsAPI.saveApplicationViewSettings({ filter: newFilter })
    } catch (e) {
      console.error(e)
    }

    setApplicationFilter(newFilter)
  }

  const filterCount = filter.length

  //console.log(filter)
  return <div className={cx('dropdown', s['dropdown'])}>
    <button
      className={cx('btn', 'btn-primary', 'dropdown-toggle', {'btn-success': filterCount > 0})}
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      onClick={(e) => {
        e.stopPropagation()
        setShowFilter(!showFilter)
        setShowColumnSettings(false)
      }}
    >
      Фильтр {filterCount > 0 ? `(${filterCount})` : ''}
    </button>

    <div
      className={cx('dropdown-menu', {[s['dropdown-menu-opened']]: showFilter})}
      aria-labelledby="dropdownMenuButton"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {filter.map((filterOption, index) => {
        return (
          <div key={index} className={cx('dropdown-item', s['dropdown-item'])}>
            <div className={s['filter-item']}>
              <div className={s['filter-field']}>
                <FilterOption key={index} index={index} field={filterOption.field} value={filterOption.value} setFieldValue={setFieldValue}/>
              </div>
              <select
                className="custom-select"
                value={filterOption.field}
                onChange={(e) => {
                  setFilterField(index, e.currentTarget.value)
                }}
              >
                <option value={filterOption.field}>{filterCaptions[filterOption.field]}</option>
                {availableFilterOptions.map((field, index) => <option key={index} value={field}>{filterCaptions[field]}</option>)}
              </select>

              <Button
                className="btn btn-danger"
                //disabled={Object.keys(filters).length === 1}
                onClick={() => {
                  filter.splice(index, 1)
                  setFilter([...filter])
                }}
              ><FontAwesomeIcon icon={faTimes} /></Button>
            </div>
          </div>
        )
      })}

      <div className={cx('dropdown-item', s['dropdown-item'], s['dropdown-item-controls'])}>
        <div>
          <Button
            className={cx('btn', 'btn-success')}
            onClick={applyFilter}
            loading={loading}
            disabled={loading}
          >Применить</Button>

          <Button
            className={cx('btn', 'btn-primary', 'mr-2')}
            disabled={loading}
            onClick={() => {
              setFilter([])
            }}
          >Очистить</Button>
        </div>

        {availableFilterOptions.length > 0 && <Button
          className={cx('btn', 'btn-primary')}
          onClick={() => {

            filter.push({
              field: availableFilterOptions[0],
              value: defaultFilterValues[availableFilterOptions[0]]
            })

            setFilter([...filter])
          }}
        >
          Добавить
        </Button>}
      </div>
    </div>
  </div>
}

export default Filter