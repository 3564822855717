import React, {useContext} from 'react';
import {PopupContext} from "../../lib/PopupContext";
import InfoModal from "../InfoModal";
import ConfirmModal from "../ConfirmModal";
import QRModal from "../QRModal";

function Popup() {
  const { popupSettings, setPopupSettings } = useContext(PopupContext)

  switch (popupSettings?.type) {
    case 'info':
      return <InfoModal
        visible
        error={popupSettings.error}
        title={popupSettings.title}
        text={popupSettings.text}
        closePopup={() => setPopupSettings(null)}
      />
    case 'confirm':
      return <ConfirmModal
        visible
        title={popupSettings.title}
        text={popupSettings.text}
        doAction={popupSettings.doAction}
        closePopup={() => setPopupSettings(null)}
      />
    case 'qr':
      return <QRModal
        visible
        title={popupSettings.title}
        text={popupSettings.text}
        image={popupSettings.image}
        closePopup={() => setPopupSettings(null)}
      />
    default:
      return <></>
  }
}

export default Popup;
