import axios from "axios";
import UsersAPI from "./UsersAPI";
import AdminAPI from "./AdminAPI";
import DepartmentsAPI from "./DepartmentsAPI";
import DictionaryAPI from "./DictionaryAPI";
import ViewSettingsAPI from "./ViewSettingsAPI";
import ApplicationsAPI from "./ApplicationsAPI";
import OtherServicesApplicationsAPI from "./OtherServicesApplicationsAPI";
import BasicAPI from "./BasicAPI";
import AuthAPI from "./AuthAPI";
import PaychecksAPI from "./PaychecksAPI";
import FeedbackAPI from "./FeedbackAPI";
//import Admin from "../routes/admin_dashboard/AdminDashboard/AdminDashboard";

class API {
  constructor() {
    this.authAPI = new AuthAPI('/auth');
    this.usersAPI = new UsersAPI('/users');
    this.adminAPI = new AdminAPI('/admin');
    this.applicationsAPI = new ApplicationsAPI('/applications');
    this.otherServicesApplicationsAPI = new OtherServicesApplicationsAPI('/otherServicesApplications');
    this.paychecksAPI = new PaychecksAPI('/paychecks');
    this.departmentsAPI = new DepartmentsAPI('/departments');
    this.TNSDepartmentsAPI = new DepartmentsAPI('/tns_departments');
    this.statusesAPI = new DictionaryAPI('/statuses');
    this.otherServicesStatusesAPI = new DictionaryAPI('/other_services_statuses');
    this.otherServicesTypesDictionaryAPI = new DictionaryAPI('/otherservicestypes');
    this.otherServicesDictionaryAPI = new DictionaryAPI('/otherservices');
    this.requestReasonsAPI = new DictionaryAPI('/requestreasons');
    this.devicesAPI = new DictionaryAPI('/devices');
    this.viewSettingsAPI = new ViewSettingsAPI();
    this.feedbackAPI = new FeedbackAPI('/feedback');
    this.pollsAPI = new BasicAPI();//TODO refactor this
    this.pollsAPI.vote = function (data) {
      return this.sendRequest('/polls/vote', data);
    }
  }

  // setAuthFunction (authFunction) { //hack
  //   this.authAPI.setAuthorized = authFunction;
  //   this.usersAPI.setAuthorized = authFunction;
  //   this.applicationsAPI.setAuthorized = authFunction;
  //   this.otherServicesApplicationsAPI.setAuthorized = authFunction;
  //   this.paychecksAPI.setAuthorized = authFunction;
  //   this.departmentsAPI.setAuthorized = authFunction;
  //   this.statusesAPI.setAuthorized = authFunction;
  //   this.otherServicesStatusesAPI.setAuthorized = authFunction;
  //   this.otherServicesTypesDictionaryAPI.setAuthorized = authFunction;
  //   this.otherServicesDictionaryAPI.setAuthorized = authFunction;
  //   this.requestReasonsAPI.setAuthorized = authFunction;
  //   this.devicesAPI.setAuthorized = authFunction;
  //   this.viewSettingsAPI.setAuthorized = authFunction;
  //   this.feedbackAPI.setAuthorized = authFunction;
  //   this.adminAPI.setAuthorized = authFunction;
  //   this.pollsAPI.setAuthorized = authFunction;
  // }

  async getAddress (query, locationOptions) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token d615ac968b96124ae7908c61a09f5a1919703218'
      },
      url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      data: {
        query: query,
        count: 20,
        ...locationOptions
      }
    }

    const requestResponse = await axios(options).catch(error => {
      console.log(error);
    });

    return requestResponse.data.suggestions;
  }


}

export default new API();