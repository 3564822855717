import React, {useContext, useState} from 'react';
import s from './Login.module.css';
import cx from 'classnames';
import API from "../../../lib/API";
import { setCookie } from '../../../lib/utils';
import Button from '../../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import routes from "../../index";
import {AuthContext} from "../../../lib/AuthContext";

function Login (props) {
	const { authContext, setAuthContext } = useContext(AuthContext)
	// const [ redirectTo, setRedirectTo ] = useState('');
	const [emailAuth, setEmailAuth] = useState('')
	const [password, setPassword] = useState('')
	const [remember, setRemember] = useState(false)
	const [errors, setErrors] = useState(null)
	const [loadRequest, setLoadRequest] = useState(false)
	const [viewPass, setViewPass] = useState(false)
	const [emailVerify, setEmailVerify] = useState()
	const [email, setEmail] = useState()
	const [emailVerifySuccess, setEmailVerifySuccess] = useState()
	const [accessToken, setAccessToken] = useState()

	const login = async (e) => {
		e.preventDefault()

		await setLoadRequest(true)
		setEmailVerifySuccess(false)
		setErrors(null)

		let authIsOff = authContext?.authIsOff

		try {
			const response = await API.authAPI.login({email: emailAuth, password: password})
			//console.log(response)
			const accessToken = response?.access_token
			const userData = await API.authAPI.check(accessToken)

			if (!userData.user?.email_verified) {
				setErrors({check: ['Данный Email не подтвержден']})
				setEmailVerify(true)
				setAccessToken(accessToken)
			} else {
				//const user = await API.authAPI.checkUser()
				if (userData.authIsOff)
					authIsOff = userData.authIsOff
				if (authIsOff && !userData.admin) {
					setErrors({error: ["Сервис временно не доступен. Приносим извинения за доставленные неудобства."]})
				}	else {
					localStorage.setItem('access_token', accessToken)
					remember && setCookie('access_token', accessToken ? accessToken : null)
					const checkUser = await API.authAPI.checkUser()
					checkUser.authorized = true
					setAuthContext(checkUser)
				}
			}

		} catch (e) {
			console.log(e)
			setErrors(e?.response?.errors)
		}

		setLoadRequest(false);
	}

	const verifyEmail = async () => {
		await setLoadRequest(true)
		setEmailVerifySuccess(false)
		setErrors(null)

		try {
			await API.authAPI.requestVerification({ email }, accessToken);
			setEmailVerify(false)
			setEmailVerifySuccess(true)
			setEmail(null)
			setAccessToken(null)
		} catch (e) {
			console.log(e)
			setErrors(e?.response?.errors)
		}

		setLoadRequest(false)
	}

	return (
		<div className={s['main']}>
			<form className="form" onSubmit={login}>
				<div className="form-title">Авторизация</div>

				<div className="form-body">
					<div className="form-group">
						<label htmlFor="emailAuth">E-mail</label>

						<input
							className="form-control"
							id="emailAuth"
							value={emailAuth}
							onChange={ e => setEmailAuth(e.currentTarget.value) }
							placeholder="Ваш e-mail"
						/>
					</div>

					<div className="form-group">
						<label htmlFor="password">Пароль</label>

						<div className={s['wrap-pass']}>
							{viewPass ? <input
								type="text"
								className={cx('form-control', 'password-view')}
								id="password"
								value={password}
								onChange={ e => setPassword(e.currentTarget.value) }
								placeholder="Ваш пароль"
							/> : <input
								type="password"
								className={cx('form-control', 'password-view')}
								id="password"
								value={password}
								onChange={ e => setPassword(e.currentTarget.value) }
								placeholder="Ваш пароль"
							/>}

							<div
								onClick={ () => setViewPass(!viewPass) }
								className={s['pass-icon']}
							>
								<FontAwesomeIcon icon={viewPass ? faEyeSlash : faEye} />
							</div>
						</div>
					</div>

					{errors && <div className="alert alert-danger" role="alert">
						{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}

						{emailVerify && <Button
							className="btn-app btn-app-sm mt-3"
							onClick={verifyEmail}
							type="submit"
							loading={loadRequest}
						>Отправить письмо с активацией повторно</Button>}
					</div>}

					{emailVerifySuccess && <div className="alert alert-success" role="alert">
						Письмо с активацией отправлено
					</div>}

					<div className="form-group">
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="remember"
								checked={remember}
								onChange={ () => setRemember(!remember) }
							/>

							<label className="custom-control-label" htmlFor="remember">Запомнить меня</label>
						</div>
					</div>

					<div className={s['login-controls']}>
						<Button
							className="btn-app btn-app-forward"
							onClick={ login }
							type="submit"
							disabled={!emailAuth || !password || loadRequest}
							loading={loadRequest}
						>Войти</Button>

						<div>
							<div>
								<Link to={routes.AuthForgotRoute.path}>Забыли пароль</Link>
							</div>
							{/* <div>
								<Link to={routes.route_auth_registration.path}>Регистрация</Link>
							</div> */}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Login;