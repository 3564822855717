import React, {Component, useState} from "react";
import Button from "../../../../../components/Button";
import cx from "classnames";
import s from "../AdminDepartments.module.css";
import API from "../../../../../lib/API";
import MaskedInput from "react-text-mask";

function EditItem (props) {
  let item = {
    name: "",
    phone: "",
    merchantLogin: "",
    other_services_merchantLogin: "",
    towns: []
  }

  if (props.item)
    item = props.item;

  const [ name, setName ] = useState(item.name)
  const [ phone1, setPhone1 ] = useState(item.phone && item.phone.length > 0 ? item.phone[0] : '')
  const [ phone2, setPhone2 ] = useState(item.phone && item.phone.length > 1 ? item.phone[1] : '')
  const [ merchantLogin, setMerchantLogin ] = useState(item.merchantLogin)
  const [ otherServicesMerchantLogin, setOtherServicesMerchantLogin ] = useState(item.other_services_merchantLogin)
  const [ town, setTown ] = useState()
  const [ towns, setTowns ] = useState(item.towns)
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})

  let timeoutSuggestions

  function openSuggestions(town, value) { //TODO do not make request every time suggetion opens, do it only if value changed
    closeSuggetions(town);
    API.getAddress(value).then(response => {
      town.suggestions = response;
      town.loading = false;
      setTowns([...towns])
    });
  }

  function handleChangeInputTown(town, value) {
    town.loading = true;

    clearTimeout(timeoutSuggestions);
    closeSuggetions(town);
    timeoutSuggestions = setTimeout(() => {
      API.getAddress(value).then(response => {
        town.suggestions = response;
        town.loading = false;
        setTowns([...towns])
      });

    }, 500);

    town.name = value;
    setTowns([...towns])
  }

  function closeSuggetions (town) {
    towns.forEach(item => {
      if (item.code !== town.code )
        item.suggestions = false;
    });
    setTowns([...towns])
  }

  function checkValidTowns() {
    let disabled = false;

    if (towns.length === 0) {
      disabled = true;
    } else {
      towns.map(town => {
        if ((town.suggestions && town.suggestions.length > 0) || !town.name || town.loading) {
          disabled = true;
        }

        return null;
      });
    }

    return disabled;
  }

  async function newDepartment() {
    setLoading(true)

    const { dictionaryAPI, update } = props;

    try {
      await dictionaryAPI.create({
        name,
        phone: JSON.stringify([phone1, phone2]),
        merchantLogin,
        other_services_merchantLogin: otherServicesMerchantLogin,
        towns: JSON.stringify(towns)
      });

      update("Филиал добавлен");
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }
    setLoading(false)
  }

  async function editDepartment() {
    setLoading(true)

    const { dictionaryAPI, update } = props;

    const townsFormatted = [];

    towns.map(city => {
      townsFormatted.push({
        name: city.name,
        code: city.code,
        full: city.full,
        kladr_full: city.kladr_full
      });
      return null;
    });

    try {
      await dictionaryAPI.update({
        id: item.id,
        name,
        phone: JSON.stringify([phone1, phone2]),
        merchantLogin,
        other_services_merchantLogin: otherServicesMerchantLogin,
        towns: JSON.stringify(townsFormatted)
      });

      update("Филиал обновлен");
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }
  }

  const { closePopup } = props;

  return <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">Новое отделение</h5>
      <Button
        className="close"
        aria-label="Close"
        onClick={closePopup}
      >
        <span aria-hidden="true">&times;</span>
      </Button>
    </div>

    <div className="modal-body">
      <div className="form-group">
        <label htmlFor="description">Название</label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors['name']})}
          id="name"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Контактные телефоны</label>

        {/*<MaskedInput*/}
        {/*  mask={['+', '7', ' ', '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}*/}
        {/*  guide={false}*/}
        {/*  className={cx('form-control', s['phone__input'])}*/}
        {/*  value={phone1}*/}
        {/*  onChange={ e => setPhone1(e.target.value) }*/}
        {/*/>*/}

        {/*<MaskedInput*/}
        {/*  mask={['+', '7', ' ', '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}*/}
        {/*  guide={false}*/}
        {/*  className={cx('form-control', s['phone__input'])}*/}
        {/*  value={phone2}*/}
        {/*  onChange={ e => setPhone2(e.target.value) }*/}
        {/*/>*/}

        <input
          className={cx('form-control', s['phone__input'])}
          value={phone1}
          onChange={ e => setPhone1(e.target.value) }
        />

        <input
          className={cx('form-control', s['phone__input'])}
          value={phone2}
          onChange={ e => setPhone2(e.target.value) }
        />

      </div>

      <div className="form-group">
        <label htmlFor="description">merchantLogin</label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors['merchantLogin']})}
          id="name"
          value={merchantLogin}
          onChange={e => setMerchantLogin(e.currentTarget.value)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">merchantLogin для прочих услуг</label>

        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors['other_services_merchantLogin']})}
          id="name"
          value={otherServicesMerchantLogin}
          onChange={e => setOtherServicesMerchantLogin(e.currentTarget.value)}
        />
      </div>

      {towns.map((town, index) => {
        return <div className="form-group" key={index}>
          <label htmlFor="description">Населенный пункт (введите и выберите из списка)</label>

          <div className={s['new-town']}>
            <input
              type="text"
              className="form-control"
              id={town[index]}
              value={town.name}
              onChange={e => handleChangeInputTown(town, e.currentTarget.value)}
              onFocus={e => openSuggestions(town, e.currentTarget.value)}
            />
            <Button
              className="btn btn-danger"
              onClick={() => {
                towns.splice(index, 1);
                setTowns([...towns])
              }}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>
          <sup>{town.full}</sup>

          {town.loading && <div className={s['suggestions-loading']}></div>}

          {!town.loading && town.suggestions && town.suggestions.length > 0 && <div className={s['suggestions']}>
            {town.suggestions.map((item, index) => <div
              key={index}
              className={s['suggestions-item']}
              onClick={() => {
                console.log(item);
                town.name = item.data.settlement ? item.data.settlement : item.data.city;
                town.city_type = item.data.settlement ? item.data.settlement_type : item.data.city_type;
                town.code = item.data.settlement_kladr_id ? item.data.settlement_kladr_id : item.data.city_kladr_id;
                town.full = item.unrestricted_value;
                town.kladr_full = item.data;
                town.suggestions = [];
                setTowns([...towns])
              }}
            >{item.unrestricted_value}</div>)}
          </div>}
        </div>
      })}

      <div className={s['controls']}>
        <Button
          type="button"
          className="btn btn-success"
          disabled={loading}
          onClick={() => {
            towns.push({
              name: '',
              code: '',
              suggestions: [],
              loading: false
            });
            setTowns([...towns])
          }}
        >Добавить населенный пункт</Button>

        <Button
          type="button"
          className="btn btn-primary"
          disabled={!name || checkValidTowns() || loading}
          loading={loading}
          onClick={props.item ? editDepartment : newDepartment}
        >Сохранить</Button>
      </div>
    </div>
  </div>
}

export default EditItem;