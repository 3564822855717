import React, {useContext, useState} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import MaskedInput from "react-text-mask";
import AddressPartSelect from "./AddressPartSelect";
import ConnectionCitySelect from "./ConnectionCitySelect";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabConnectionAddress (props) {
  const { appData, dispatch } = useContext(ApplicationContext)

  function setAddressPart(part, value) {
    const address = appData["connectionaddress"]
    address[part] = value
    dispatch({type: "setField", payload: { field: "connectionaddress", value: address}})
  }

  function setAddressPartByObject(part) {
    const address = appData["connectionaddress"]

    return value => {
      switch (part) {
        case 'city':
          //address.city_with_type = (value.value.city_type && value.value.city_type + ' ') + value.value.name;
          address.city = value.value.name;
          address.kladr_full = value.value.kladr_full ? value.value.kladr_full : {};

          address.district = value.value.district;
          address.settlement_fias_id = "";
          address.city_fias_id = "";

          if (address.kladr_full.settlement)
            address.settlement_fias_id = value.value.kladr_full.settlement_fias_id;

          if (address.kladr_full.city)
            address.city_fias_id = value.value.kladr_full.city_fias_id;

          address.street = "";
          break;
        case 'street':
          address.street = value.value.street_with_type;
          //address.kladr_full = value.value.kladr_full ? value.value.kladr_full : {};

          if (!address.district)
            address.district = value.value.district;

          if (!address.city) {
            address.city = (value.value.city_type && value.value.city_type + ' ') + value.value.name;

          if (address.kladr_full.settlement)
            address.settlement_fias_id = value.value.kladr_full.settlement_fias_id;
          if (address.kladr_full.city)
            address.city_fias_id = value.value.kladr_full.city_fias_id;
          }
          break;
      }

      console.log(address);
      dispatch({ type: "setField", payload: { field: "connectionaddress", value: address } })
    }
  }

  const { options } = props;

  const address = appData["connectionaddress"]
  const errors = appData.errors.errors

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Адрес объекта</div>
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="connectionaddresskadastrView"
          checked={address.kadastrView}
          //onChange={() => setKadastrView(!kadastrView)}
          onChange={(e) => {setAddressPart('kadastrView', !address.kadastrView)}}
        />

        <label className="custom-control-label" htmlFor="connectionaddresskadastrView">Кадастровый номер</label>
      </div>
    </div>

    {address.kadastrView && <div className="form-group">
      <label htmlFor="kadastr">Кадастровый номер <span className={s['red-star']}>*</span></label>

      <input
        type="text"
        className={cx("form-control", {[s['error-field']]: errors[`connectionkadastr`]})}
        id="kadastr"
        autoComplete="no"
        value={address.kadastr}
        onChange={(e) => {setAddressPart('kadastr', e.currentTarget.value)}}
      />
    </div>}

    <div className="form-group">
      <label htmlFor="subject">Субъект РФ <span className={s['red-star']}>*</span></label>
      <div>Ростовская область</div>
    </div>

    {address.district &&<div className="form-group">
      <label htmlFor="district">Район</label>
      <div>{ address.district }</div>
    </div>}

    <div className="form-group">
      <label htmlFor="index">Почтовый индекс</label>
      <MaskedInput
        id="index"
        mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        guide={false}
        className={cx("form-control", {[s['error-field']]: errors[`connectionindex`]})}
        autoComplete="no"
        value={address.index}
        onChange={(e) => {setAddressPart('index', e.currentTarget.value)}}
      />
    </div>

    <div className="form-group">
      <label htmlFor="city">Город <span className={s['red-star']}>*</span> <div className={s['info']}>Начните вводить город и выберите из выпадающего списка</div></label>

      <ConnectionCitySelect
        id="city"
        error={!!(errors[`connectioncity`])}
        options={options}
        value={address.city}
        setAddressPart={setAddressPartByObject('city')}
      />
    </div>

    <div className="form-group">
      <label htmlFor="street">Улица {!address.kadastrView && <span className={s['red-star']}>*</span>}</label>

      {(!!address.settlement_fias_id || !!address.city_fias_id) && <AddressPartSelect
        id="street"
        error={!!(errors[`connectionstreet`])}
        value={address.street}
        searchOptions={{
          "from_bound": { "value": "street" },
          "to_bound": { "value": "street" },
          "restrict_value": true,
          locations: [{
            'region_fias_id': address.region_fias_id,
            'settlement_fias_id': address.settlement_fias_id,
            'city_fias_id': address.city_fias_id
          }]
        }}
        setAddressPart={setAddressPartByObject('street')}
      />}
      {(!address.settlement_fias_id && !address.city_fias_id) && <input
        className={cx("form-control", {[s['error-field']]: errors[`connectionstreet`]})}
        id="street"
        autoComplete="no"
        value={address.street}
        onChange={(e) => {setAddressPart('street', e.currentTarget.value)}}
      />}
    </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="house">Номер дома <span className={s['red-star']}>*</span></label>
            <input
              className={cx("form-control", {[s['error-field']]: errors[`connectionhouse`]})}
              id="house"
              autoComplete="no"
              value={address.house}
              onChange={(e) => {setAddressPart('house', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="section">Корпус</label>
            <input
              type="text"
              className={cx("form-control")}
              id="section"
              autoComplete="no"
              value={address.section}
              onChange={(e) => {setAddressPart('section', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
          <div className="col-4">
            <label htmlFor="building">Строение</label>
            <input
              type="text"
              className={cx("form-control")}
              id="building"
              autoComplete="no"
              value={address.building}
              onChange={(e) => {setAddressPart('building', e.currentTarget.value)}}
            />
          </div>
        </div>

      <div className="form-group form-row">
        <div className="col-4">
          <label htmlFor="flat">Квартира/Офис/Комната</label>
          <MaskedInput
            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            guide={false}
            className={cx("form-control")}
            id="flat"
            autoComplete="no"
            value={address.flat}
            onChange={(e) => {setAddressPart('flat', e.currentTarget.value)}}
          />
        </div>
      </div>
  </div>
}

export default TabConnectionAddress;