import API from "./API";
import BasicAPI from "./BasicAPI";

class ViewSettingsAPI extends BasicAPI {
  saveApplicationViewSettings (data) {
    if (typeof data != 'object')
      return
    const stringifiedData = {}
    Object.keys(data).forEach(value => {
      stringifiedData[value] = JSON.stringify(data[value])
    })

    return this.sendRequest('/admin/saveApplicationViewSettings', {service: 'technical', ...stringifiedData});
  }
  saveOtherServicesViewSettings (data) {
    if (typeof data != 'object')
      return
    const stringifiedData = {}
    Object.keys(data).forEach(value => {
      stringifiedData[value] = JSON.stringify(data[value])
    })

    return this.sendRequest('/admin/saveApplicationViewSettings', {service: 'other_services', ...stringifiedData});
  }
  setViewed (data) {
    return this.sendRequest('/applications/setViewed', data);
  }
  setOtherServicesApplicationViewed (data) {
    return this.sendRequest('/otherServicesApplications/setViewed', data);
  }
}

export default ViewSettingsAPI;