import React from 'react';
import Layout from '../../../components/Layout';
import AdminLogs from './AdminLogs';

function AdminLogsRoute (props) {
	return (
		<Layout>
			<AdminLogs />
		</Layout>
	);
}

export default AdminLogsRoute;
