import React, { Component } from 'react';
import s from './Calc.module.css';
import Button from '../../components/Button';
import input from 'react-text-mask';

class Calc extends Component {
	constructor(props) {
		super(props);

		// this.prices = {
		// 	stnd: 13183.53,
		// 	stndPower: 688.07,
		// 	airNN: 1265512.63,
		// 	airSN: 1636250.33,
		// 	cabNN: 2232083.87,
		// 	cabSN: 23876490.26,
		// 	gbnNN: 22132297.65,
		// 	gbnSN: 3972971.91,
		// 	tpNN: 2386.14,
		// 	tpSN: 5651.31,
		// 	lowPower3: 550,
		// 	airNNPower: 17012.41,
		// 	airSNPower: 4145.25,
		// 	cabNNPower: 9418.44,
		// 	cabSNPower: 25297.83,
		// 	gbnNNPower: 143647.31,
		// 	gbnSNPower: 179213.33,
		// };

		this.prices = props.prices;

		this.defaultValues = {
			power12: 0,
			power3:  0,
			airNN12: 0,
			airNN3:  0,
			airSN12: 0,
			airSN3:  0,
			cabNN12: 0,
			cabNN3:  0,
			cabSN12: 0,
			cabSN3:  0,
			gbnNN12: 0,
			gbnNN3:  0,
			gbnSN12: 0,
			gbnSN3:  0,
			tp12:    0,
			tp3:     0,
		};

		this.state = {
			place: 'city',
			distance: 'normal',
			result1: '0',
			...this.defaultValues,
			prices: {
				...this.prices
			}
		};
		
		this.handleChangeInput = this.handleChangeInput.bind(this);
	}

	handleChangeInput(type, value) {
		this.setState({
			[type]: value,
			errors: {},
			loadRequest: false,
		});
	}

	getResult() {
		const {
			power12, power3,
			airNN12, airNN3, airSN12, airSN3,
			cabNN12, cabNN3, cabSN12, cabSN3,
			gbnNN12, gbnNN3, gbnSN12, gbnSN3,
			tp12, tp3, prices, distance
		} = this.state;

		let
			result1 = 0,
			result2 = 0;

		if ((power12 + power3) > 150) {
			result1 += (airNN12 + airNN3) * prices.airNN;
			result1 += (airSN12 + airSN3) * prices.airSN;
			result1 += (cabNN12 + cabNN3) * prices.cabNN;
			result1 += (cabSN12 + cabSN3) * prices.cabSN;
			result1 += (gbnNN12 + gbnNN3) * prices.gbnNN;
			result1 += (gbnSN12 + gbnSN3) * prices.gbnSN;
			result1 += tp12 * prices.tpNN;
			result1 += tp3 * prices.tpSN;
			result1 += prices.stnd;

			result2 += (airNN12 + airNN3) * prices.airNNPower;
			result2 += (airSN12 + airSN3) * prices.airSNPower;
			result2 += (cabNN12 + cabNN3) * prices.cabNNPower;
			result2 += (cabSN12 + cabSN3) * prices.cabSNPower;
			result2 += (gbnNN12 + gbnNN3) * prices.gbnNNPower;
			result2 += (gbnSN12 + gbnSN3) * prices.gbnSNPower;
			result2 += tp12 * prices.tpNN;
			result2 += tp3 * prices.tpSN;
		} else if (
			power12 || power3 || airNN12 || airNN3 || airSN12 || airSN3 || cabNN12 || cabNN3 ||
			cabSN12 || cabSN3 || gbnNN12 || gbnNN3 || gbnSN12 || gbnSN3 || tp12 || tp3
		) {
			result1 = prices.stnd;
		}
		
		if (!power12 && power3 && power3 <= 15 && distance === 'normal') {
			result2 = prices.lowPower3;
		} else {
			result2 += (power12 + power3) * prices.stndPower;
			result2 += result2 * 0.2;
		}

		result1 += result1 * 0.2;
		
		result1 = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(result1);
		result2 = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(result2);

		return { result1, result2 };
	}
	
	render() {
		const {
			place, distance,
			power12, power3,
			airNN12, airNN3, airSN12, airSN3,
			cabNN12, cabNN3, cabSN12, cabSN3,
			gbnNN12, gbnNN3, gbnSN12, gbnSN3,
			tp12, tp3,
		} = this.state,
		{ result1, result2 } = this.getResult();
		
		return (
			<div className={s['main']}>
				<div className={s['calc']}>
					<div className="h2 text-center">Рассчитать стоимость технологического присоединения</div>
					
					<div className="container">
						<div className="row">
							<div className="col-12 mt-3">
								<p>
									КСТП предназначен для онлайн-расчета ориентировочной стоимости технологического присоединения в соответствии с утвержденными стандартизированными тарифными ставками и ставками платы за единицу мощности*.
									<br />
									<br />
									Ознакомиться с утвержденными ставками платы и распорядительным документом уполномоченного органа в области государственного регулирования тарифов можно в разделе Технологическое присоединение к электрическим сетям на странице Размеры платы за ТП.
								</p>
							</div>
						</div>
						
						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label>Территориальное расположение:</label>
								
									<select
										className="custom-select"
										value={place}
										onChange={(e) => {this.setState({ place: e.currentTarget.value })}}
									>
										<option value="city">Город</option>
										<option value="region">Область (города,района и т.д.)</option>
									</select>
								</div>
							</div>
							
							<div className="col-6">
								<div className="form-group">
									<label>Расстояние до объекта:</label>
								
									{place === 'city' ? <select
										className="custom-select"
										value={distance}
										onChange={(e) => {this.setState({ distance: e.currentTarget.value })}}
									>
										<option value="normal">До 300 метров</option>
										<option value="high">Свыше 300 метров</option>
									</select> : <select
										className="custom-select"
										value={distance}
										onChange={(e) => {this.setState({ distance: e.currentTarget.value })}}
									>
										<option value="normal">До 500 метров</option>
										<option value="high">Свыше 500 метров</option>
									</select>}
								</div>
							</div>
							
							<div className="col-12">
								<div className={s['table']}>
									<div className={s['table-row']}>
										<div className={s['table-cell']}></div>
										<div className={s['table-cell']}>наименование строительства</div>
										<div className={s['table-cell']}>категория надёжности</div>
										<div className={s['table-cell']}>значения</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>1</div>
										<div className={s['table-cell']}>Запрашиваемая мощность заявителя, (кВт)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={power12}
												onChange={(e) => {this.handleChangeInput('power12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>2</div>
										<div className={s['table-cell']}>Запрашиваемая мощность заявителя, (кВт)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={power3}
												onChange={(e) => {this.handleChangeInput('power3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>Объем строительства воздушных линий  0,4кВ (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={airNN12}
												onChange={(e) => {this.handleChangeInput('airNN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>4</div>
										<div className={s['table-cell']}>Объем строительства воздушных линий  0,4кВ (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={airNN3}
												onChange={(e) => {this.handleChangeInput('airNN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>5</div>
										<div className={s['table-cell']}>Объем строительства воздушных линий  6-10кВ (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={airSN12}
												onChange={(e) => {this.handleChangeInput('airSN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>6</div>
										<div className={s['table-cell']}>Объем строительства воздушных линий  6-10кВ (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={airSN3}
												onChange={(e) => {this.handleChangeInput('airSN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>7</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий  0,4кВ (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={cabNN12}
												onChange={(e) => {this.handleChangeInput('cabNN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>8</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий  0,4кВ (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={cabNN3}
												onChange={(e) => {this.handleChangeInput('cabNN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>9</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий  6-10кВ (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={cabSN12}
												onChange={(e) => {this.handleChangeInput('cabSN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>10</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий  6-10кВ (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={cabSN3}
												onChange={(e) => {this.handleChangeInput('cabSN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>11</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий 0,4кВ методом горизонтально-направленного бурения, (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={gbnNN12}
												onChange={(e) => {this.handleChangeInput('gbnNN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>12</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий 0,4кВ методом горизонтально-направленного бурения, (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={gbnNN3}
												onChange={(e) => {this.handleChangeInput('gbnNN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>13</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий 6-10 кВ методом горизонтально-направленного бурения, (км)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={gbnSN12}
												onChange={(e) => {this.handleChangeInput('gbnSN12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>14</div>
										<div className={s['table-cell']}>Объем строительства кабельных линий 6-10 кВ методом горизонтально-направленного бурения, (км)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={gbnSN3}
												onChange={(e) => {this.handleChangeInput('gbnSN3', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>15</div>
										<div className={s['table-cell']}>Расходы на строительство подстанций/ ТП/ КТП, (кВт)</div>
										<div className={s['table-cell']}>1-2</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={tp12}
												onChange={(e) => {this.handleChangeInput('tp12', e.currentTarget.value)}}
											/>
										</div>
									</div>
									
									<div className={s['table-row']}>
										<div className={s['table-cell']}>16</div>
										<div className={s['table-cell']}>Расходы на строительство подстанций/ ТП/ КТП, (кВт)</div>
										<div className={s['table-cell']}>3</div>
										<div className={s['table-cell']}>
											<input
                                                type="number"
												className="form-control"
												value={tp3}
												onChange={(e) => {this.handleChangeInput('tp3', e.currentTarget.value)}}
											/>
										</div>
									</div>
								</div>
							</div>
							
							<div className="col-12 text-right mt-3">
								{/* <Button
									className="btn-app"
								>Рассчитать</Button> */}

								<Button
									className="btn-app"
									onClick={() => {
										this.setState(this.defaultValues);
									}}
								>Очистить</Button>
							</div>
							
							<div className="col-12">
								<div className="h5">Размер платы по стандартизированным тарифным ставкам (в т.ч. НДС): <span className="h4">{result1}</span></div>
								<div className="h5">Размер платы по ставкам платы за единицу мощности (в т.ч. НДС): <span className="h4">{result2}</span></div>
							</div>
							
							<div className="col-12 mt-3">
								<p>
									Выполненный расчет является ориентировочным, итоговая стоимость технологического присоединения будет определена сетевой организацией в соответствии с
									утвержденными ставками после подачи Вами заявки на ТП при подготовке Договора об осуществлении технологического присоединения. Плата за технологическое
									присоединение составляет 550 рублей при условии, если расстояние от границ участка заявителя до объектов электросетевого хозяйства АО «Донэнерго» на уровне
									напряжения до 20 кВ менее 300 метров в городах и поселках городского типа и 500 метров в сельской местности; размер максимальной мощности не превышает 15 кВт
									включительно (с учетом ранее присоединенных в данной точке присоединения энергопринимающих устройств); технологическое присоединение объектов, отнесенных к
									третьей категории надежности (по одному источнику электроснабжения).
									<br/>
									<br/>
   									В границах муниципальных районов, городских округов и на внутригородских территориях городов федерального значения одно и то же лицо может осуществить
   									технологическое присоединение энергопринимающих устройств, принадлежащих ему на праве собственности или на ином законном основании, с платой за технологическое
   									присоединение в размере, не превышающем 550 рублей, не более одного раза в течение 3 лет.
   									<br/>
									<br/>
									Данный Калькулятор рассчитан на заявителей с максимальной мощностью энергопринимающих устройств менее 8900 кВт и на уровне напряжения менее 10 кВ. В случае
									присоединения энергопринимающих устройств с заявляемой максимальной мощностью не менее 8900 кВт включительно и (или) на уровне напряжения не ниже 10 кВ, применяется
									индивидуальный тариф, утверждаемый отдельным постановлением регулирующего органа.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Calc;