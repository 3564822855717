import React, {Component} from "react";
import cx from "classnames";
import s from "../components/ApplicationRequest.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

class TabHeader extends Component {

  render() {
    const { label, setPage, active, valid, error} = this.props;
      return <span
        className={cx(
          'nav-link',
          s['nav-link'],
          {[s['error']]: error},
          {'active': active},
          {[s['success']]: valid}
        )}
        aria-selected="true"
        onClick={setPage}
      >
      <span>{label}</span>
      {valid && <span className={s['nav-item-svg']}><FontAwesomeIcon icon={faCheck} /></span>}
    </span>
  }
}

export default TabHeader;