import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Evaluation from './Evaluation';

function EvaluationRoute (props)  {
	return (
		<Layout>
			<Evaluation />
		</Layout>
	)
}

export default EvaluationRoute
