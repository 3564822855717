import React from 'react';
import Layout from '../../../components/Layout';
import Verify from './Verify';

function AuthVerifyRoute (props) {
	const token = new URLSearchParams(props.location.search).get('token');

	return (
		<Layout>
			<Verify token={token} />
		</Layout>
	);
}

export default AuthVerifyRoute;
