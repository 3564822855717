import React from 'react';
import Layout from '../../../components/Layout';
import TechnicalApplicationRequest from './TechnicalApplicationRequest';

function TechnicalApplicationRequestRoute (props) {
		return (
			<Layout>
				<TechnicalApplicationRequest />
			</Layout>
		);
}

export default TechnicalApplicationRequestRoute;
