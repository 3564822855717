import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import setProfileData from './setProfileData';
import setAccess from './setAccess';

export default combineReducers({
	router: routerReducer,
	setProfileData,
	setAccess
});
