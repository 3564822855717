import React, {useContext, useState} from "react";
import Button from "../../../../../components/Button";
import cx from "classnames";
import s from "../../../../../components/Dictionary/Dictionary.module.css";
import {PopupContext} from "../../../../../lib/PopupContext";

function DeleteItem (props) {
  const [ loading, setLoading ] = useState(false)
  const { setPopupSettings } = useContext(PopupContext)

  async function deleteItem() {
    const {dictionaryAPI, item, update} = props;

    setLoading(true)

    try {
      await dictionaryAPI.delete({id: item.id});
      update("Администратор удален");
    } catch (e ){
      console.error(e)
      setPopupSettings({
        type: 'info',
        text: e.response.errors,
        error: true
      })
    }

    setLoading(false)
  }
  const {closePopup} = props;

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Удаление администратора</h5>
          <Button
            className="close"
            aria-label="Close"
            onClick={closePopup}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>

        <div className="modal-body">
          {loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
            <span className="sr-only">Загрузка...</span>
          </div>}
          <div>Вы уверены что хотите удалить администратора?</div>
        </div>

        <div className="modal-footer">
          <Button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            disabled={loading}
            onClick={closePopup}
          >
            Отмена
          </Button>

          <Button
            className="btn btn-danger"
            onClick={deleteItem}
            loading={loading}
            disabled={loading}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteItem;