import React, {Component, useState} from 'react';
import Layout from '../../components/Layout';
import OtherServicesApplication from './OtherServicesApplication';
import OtherServicesApplicationRequest
	from "../ApplicationRequests/OtherServicesApplicationRequest/OtherServicesApplicationRequest";

function OtherServicesApplicationRoute (props) {
	const [ editApplication, setEditApplication ] = useState(null)

	return (
		<Layout>
			{editApplication && <OtherServicesApplicationRequest
				typeRequest="editRequest"
				userData={editApplication}
				setEditApplication={setEditApplication}
			/>}
			{!editApplication && <OtherServicesApplication
				isAdmin={props.isAdmin}
				history={props.history}
				id={props.match.params.id}
				urlParams={new URLSearchParams(props.location.search)}
				setEditApplication={setEditApplication}
			/>}
		</Layout>
	);
}

export default OtherServicesApplicationRoute;
