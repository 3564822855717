export const SET_ACCESS = 'setAccess/SET_ACCESS';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS:
            return {
                ...state,
                access: action.access
            }

        default:
            return state
    }
};

export const setAccess = (props) => {
    return dispatch => {
        dispatch({
            type: SET_ACCESS,
            access: props
        })
    }
};
