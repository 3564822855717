import React, { Component } from 'react';
import s from './Notfound.module.css';

function Notfound (props) {
	return (
		<div className={s['main']}>
			<div className={s['title']}>
				<div>404</div>
				<div>Страница не найдена</div>
			</div>
		</div>
	);
}

export default Notfound;
