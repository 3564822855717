import React, {useReducer, useState} from 'react';
import s from './Evaluation.module.css';
import Button from '../../components/Button';
import API from '../../lib/API';

function Evaluation (props) {
	const defaultFields = {
		channel: '',
		center: '',
		place: '',
		service: '',
		qualification: '',
		deadlineRequest: '',
		serviceTechnical: '',
		problemPower: '',
		reportProblem: '',
		satisfiedQualification: '',
		satisfiedDeadline: '',
		typeServices: '',
		satisfiedAdditional: '',
		deadlineContract: '',
		channelOther: '',
		typeServicesOther: '',
		quality: '',
		wishes: ''
	}

	function reducer(state, action) {
		switch (action.type) {
			case 'reset':
				return defaultFields
			case 'setField':
				state[action.payload.field] = action.payload.value
				return {...state}
			case 'setFields':
				state = Object.assign(state, action.payload)
				return state
			default:
				// A reducer must always return a valid state.
				// Alternatively you can throw an error if an invalid action is dispatched.
				return state
		}
	}

	const [fields, dispatch] = useReducer(reducer, defaultFields)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [errors, setErrors] = useState({})

	function handleChangeInput(field, value) {
		dispatch({type: 'setField', payload: {field, value}})
		setErrors({})
		//setLoading(false)
	}

	function reset() {
		dispatch({type: 'reset'})
		setErrors({})
		setLoading(false)
	}

	function isDisableSubmit() {
		let disabled = false

		Object.keys(defaultFields).map(key => {
			if (
				(!fields[key] && key !== 'channelOther' && key !== 'typeServicesOther' && key !== 'quality' && key !== 'wishes') ||
				(key === 'channel' && fields['channel'] === '4' && !fields['channelOther']) ||
				(key === 'typeServices' && fields['typeServices'] === '4' && !fields['typeServicesOther'])
			) {
				disabled = true
			}

			return null
		})

		return disabled
	}

	async function submit() {
		setLoading(true)
		setErrors({})

		try {
			await API.pollsAPI.vote(fields);
			setSuccess(true)
		} catch (e) {
			setErrors(e.response.errors)
		}

		setLoading(false)
	}

	return (
		<div className={s['main']}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="h2 text-center">Оценка потребителем качества услуг</div>
					</div>
				</div>

				<div className="row">
					{!success ? <div className="col-12 mt-4">
						<div className="h5 text-center">1. Клиентское обслуживание</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Какой из каналов взаимодействия с электросетевой компанией для Вас
									предпочтительнее?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="channel1"
										name="channel"
										className="custom-control-input"
										checked={fields.channel === '1'}
										onChange={() => handleChangeInput('channel', '1')}
									/>

									<label className="custom-control-label" htmlFor="channel1">Центр обслуживания клиентов
										(потребителей)</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="channel2"
										name="channel"
										className="custom-control-input"
										checked={fields.channel === '2'}
										onChange={() => handleChangeInput('channel', '2')}
									/>

									<label className="custom-control-label" htmlFor="channel2">Internet-приемная</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="channel3"
										name="channel"
										className="custom-control-input"
										checked={fields.channel === '3'}
										onChange={() => handleChangeInput('channel', '3')}
									/>

									<label className="custom-control-label" htmlFor="channel3">Электронная почта</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="channel4"
										name="channel"
										className="custom-control-input"
										checked={fields.channel === '4'}
										onChange={() => handleChangeInput('channel', '4')}
									/>

									<label className="custom-control-label" htmlFor="channel4">Прочее</label>
								</div>
							</div>
						</div>

						{fields.channel === '4' && <div className="form-group">
							<input
								type="text"
								className="form-control"
								id="channelOther"
								value={fields.channelOther}
								onChange={e => handleChangeInput('channelOther', e.currentTarget.value)}
								placeholder="Введите текст..."
							/>
						</div>}

						<div className="form-group">
							<div>
								<label className={s['title']}>Известно ли Вам, что по вопросам, относящимся к технологическому
									присоединению и электроснабжению, Вы можете обращаться в Центр обслуживания клиентов(потребителей) в
									Вашем регионе?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="center1"
										name="center"
										className="custom-control-input"
										checked={fields.center === '1'}
										onChange={e => handleChangeInput('center', '1')}
									/>

									<label className="custom-control-label" htmlFor="center1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="center2"
										name="center"
										className="custom-control-input"
										checked={fields.center === '2'}
										onChange={e => handleChangeInput('center', '2')}
									/>

									<label className="custom-control-label" htmlFor="center2">Нет</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Если Вы посещали Центр обслуживания клиентов (потребителей), ответьте,
									пожалуйста, наследующие вопросы:</label>
								<label className={s['title']}>Довольны ли Вы месторасположением Центра обслуживания
									клиентов(потребителей)?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="place1"
										name="place"
										className="custom-control-input"
										checked={fields.place === '1'}
										onChange={e => handleChangeInput('place', '1')}
									/>

									<label className="custom-control-label" htmlFor="place1">Очень доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="place2"
										name="place"
										className="custom-control-input"
										checked={fields.place === '2'}
										onChange={e => handleChangeInput('place', '2')}
									/>

									<label className="custom-control-label" htmlFor="place2">Доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="place3"
										name="place"
										className="custom-control-input"
										checked={fields.place === '3'}
										onChange={e => handleChangeInput('place', '3')}
									/>

									<label className="custom-control-label" htmlFor="place3">Недоволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="place4"
										name="place"
										className="custom-control-input"
										checked={fields.place === '4'}
										onChange={e => handleChangeInput('place', '4')}
									/>

									<label className="custom-control-label" htmlFor="place4">Крайне недоволен</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Довольны ли вы обслуживанием в Центре обслуживания клиентов (потребителей)
									?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="service1"
										name="service"
										className="custom-control-input"
										checked={fields.service === '1'}
										onChange={e => handleChangeInput('service', '1')}
									/>

									<label className="custom-control-label" htmlFor="service1">Очень доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="service2"
										name="service"
										className="custom-control-input"
										checked={fields.service === '2'}
										onChange={e => handleChangeInput('service', '2')}
									/>

									<label className="custom-control-label" htmlFor="service2">Доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="service3"
										name="service"
										className="custom-control-input"
										checked={fields.service === '3'}
										onChange={e => handleChangeInput('service', '3')}
									/>

									<label className="custom-control-label" htmlFor="service3">Недоволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="service4"
										name="service"
										className="custom-control-input"
										checked={fields.service === '4'}
										onChange={e => handleChangeInput('service', '4')}
									/>

									<label className="custom-control-label" htmlFor="service4">Крайне недоволен</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Квалифицированной была помощь персонала Центра для решения Вашего
									вопроса?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="qualification1"
										name="qualification"
										className="custom-control-input"
										checked={fields.qualification === '1'}
										onChange={e => handleChangeInput('qualification', '1')}
									/>

									<label className="custom-control-label" htmlFor="qualification1">Квалифицированна</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="qualification2"
										name="qualification"
										className="custom-control-input"
										checked={fields.qualification === '2'}
										onChange={e => handleChangeInput('qualification', '2')}
									/>

									<label className="custom-control-label" htmlFor="qualification2">Доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="qualification3"
										name="qualification"
										className="custom-control-input"
										checked={fields.qualification === '3'}
										onChange={e => handleChangeInput('qualification', '3')}
									/>

									<label className="custom-control-label" htmlFor="qualification3">Недоволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="qualification4"
										name="qualification"
										className="custom-control-input"
										checked={fields.qualification === '4'}
										onChange={e => handleChangeInput('qualification', '4')}
									/>

									<label className="custom-control-label" htmlFor="qualification4">Крайне недоволен</label>
								</div>
							</div>
						</div>

						<div className="h5 text-center">2. Технологическое присоединение</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Соблюдались ли АО «Донэнерго» сроки рассмотрения Вашей заявки на
									технологическое присоединение?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="deadlineRequest1"
										name="deadlineRequest"
										className="custom-control-input"
										checked={fields.deadlineRequest === '1'}
										onChange={e => handleChangeInput('deadlineRequest', '1')}
									/>

									<label className="custom-control-label" htmlFor="deadlineRequest1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="deadlineRequest2"
										name="deadlineRequest"
										className="custom-control-input"
										checked={fields.deadlineRequest === '2'}
										onChange={e => handleChangeInput('deadlineRequest', '2')}
									/>

									<label className="custom-control-label" htmlFor="deadlineRequest2">Нет</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Были ли соблюдены сроки выполнения работ по договору со стороны АО
									«Донэнерго» (от 2 месяцев до 2-х лет)?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="deadlineContract1"
										name="deadlineContract"
										className="custom-control-input"
										checked={fields.deadlineContract === '1'}
										onChange={e => handleChangeInput('deadlineContract', '1')}
									/>

									<label className="custom-control-label" htmlFor="deadlineContract1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="deadlineContract2"
										name="deadlineContract"
										className="custom-control-input"
										checked={fields.deadlineContract === '2'}
										onChange={e => handleChangeInput('deadlineContract', '2')}
									/>

									<label className="custom-control-label" htmlFor="deadlineContract2">Нет</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Удовлетворены ли Вы деятельностью сотрудников АО «Донэнерго» при
									осуществлении процедуры технологического присоединения?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="serviceTechnical1"
										name="serviceTechnical"
										className="custom-control-input"
										checked={fields.serviceTechnical === '1'}
										onChange={e => handleChangeInput('serviceTechnical', '1')}
									/>

									<label className="custom-control-label" htmlFor="serviceTechnical1">Доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="serviceTechnical2"
										name="serviceTechnical"
										className="custom-control-input"
										checked={fields.serviceTechnical === '2'}
										onChange={e => handleChangeInput('serviceTechnical', '2')}
									/>

									<label className="custom-control-label" htmlFor="serviceTechnical2">Частично доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="serviceTechnical3"
										name="serviceTechnical"
										className="custom-control-input"
										checked={fields.serviceTechnical === '3'}
										onChange={e => handleChangeInput('serviceTechnical', '3')}
									/>

									<label className="custom-control-label" htmlFor="serviceTechnical3">Недоволен</label>
								</div>
							</div>
						</div>

						<div className="h5 text-center">3. Передача электроэнергии</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Как часто у Вас возникают перепады напряжения в электросети (мигание
									света, низкая освещенность, перебои в работе электробытовых приборов и т. д.)?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="problemPower1"
										name="problemPower"
										className="custom-control-input"
										checked={fields.problemPower === '1'}
										onChange={e => handleChangeInput('problemPower', '1')}
									/>

									<label className="custom-control-label" htmlFor="problemPower1">Часто</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="problemPower2"
										name="problemPower"
										className="custom-control-input"
										checked={fields.problemPower === '2'}
										onChange={e => handleChangeInput('problemPower', '2')}
									/>

									<label className="custom-control-label" htmlFor="problemPower2">Редко</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="problemPower3"
										name="problemPower"
										className="custom-control-input"
										checked={fields.problemPower === '3'}
										onChange={e => handleChangeInput('problemPower', '3')}
									/>

									<label className="custom-control-label" htmlFor="problemPower3">Никогда</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Сообщали ли Вы в случае ненадлежащего качества электроэнергии в АО
									«Донэнерго»?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="reportProblem1"
										name="reportProblem"
										className="custom-control-input"
										checked={fields.reportProblem === '1'}
										onChange={e => handleChangeInput('reportProblem', '1')}
									/>

									<label className="custom-control-label" htmlFor="reportProblem1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="reportProblem2"
										name="reportProblem"
										className="custom-control-input"
										checked={fields.reportProblem === '2'}
										onChange={e => handleChangeInput('reportProblem', '2')}
									/>

									<label className="custom-control-label" htmlFor="reportProblem2">Нет</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Удовлетворены ли Вы оперативностью и квалификацией работников при
									рассмотрении Вашего обращения?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedQualification1"
										name="satisfiedQualification"
										className="custom-control-input"
										checked={fields.satisfiedQualification === '1'}
										onChange={e => handleChangeInput('satisfiedQualification', '1')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedQualification1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedQualification2"
										name="satisfiedQualification"
										className="custom-control-input"
										checked={fields.satisfiedQualification === '2'}
										onChange={e => handleChangeInput('satisfiedQualification', '2')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedQualification2">Нет</label>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>Удовлетворены ли Вы сроками и качеством обслуживания приборов учета
									электрической энергии специалистами АО «Донэнерго» по Вашим заявкам?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedDeadline1"
										name="satisfiedDeadline"
										className="custom-control-input"
										checked={fields.satisfiedDeadline === '1'}
										onChange={e => handleChangeInput('satisfiedDeadline', '1')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedDeadline1">Доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedDeadline2"
										name="satisfiedDeadline"
										className="custom-control-input"
										checked={fields.satisfiedDeadline === '2'}
										onChange={e => handleChangeInput('satisfiedDeadline', '2')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedDeadline2">Частично доволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedDeadline3"
										name="satisfiedDeadline"
										className="custom-control-input"
										checked={fields.satisfiedDeadline === '3'}
										onChange={e => handleChangeInput('satisfiedDeadline', '3')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedDeadline3">Недоволен</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedDeadline4"
										name="satisfiedDeadline"
										className="custom-control-input"
										checked={fields.satisfiedDeadline === '4'}
										onChange={e => handleChangeInput('satisfiedDeadline', '4')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedDeadline4">Не обращался</label>
								</div>
							</div>
						</div>

						<div className="h5 text-center">4. Оказание дополнительных услуг</div>

						<div className="form-group">
							<div>
								<label className={s['title']}>В каких услугах нашей организации Вы больше всего заинтересованы?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="typeServices1"
										name="typeServices"
										className="custom-control-input"
										checked={fields.typeServices === '1'}
										onChange={e => handleChangeInput('typeServices', '1')}
									/>

									<label className="custom-control-label" htmlFor="typeServices1">Установка/замена прибора учета</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="typeServices2"
										name="typeServices"
										className="custom-control-input"
										checked={fields.typeServices === '2'}
										onChange={e => handleChangeInput('typeServices', '2')}
									/>

									<label className="custom-control-label" htmlFor="typeServices2">Техническое обслуживание
										электросетевого оборудования</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="typeServices3"
										name="typeServices"
										className="custom-control-input"
										checked={fields.typeServices === '3'}
										onChange={e => handleChangeInput('typeServices', '3')}
									/>

									<label className="custom-control-label" htmlFor="typeServices3">Испытания и диагностика
										высоковольтного оборудования, электрозащитных средств</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="typeServices4"
										name="typeServices"
										className="custom-control-input"
										checked={fields.typeServices === '4'}
										onChange={e => handleChangeInput('typeServices', '4')}
									/>

									<label className="custom-control-label" htmlFor="typeServices4">Энергоаудит предприятий,
										электроустановок</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="typeServices4"
										name="typeServices"
										className="custom-control-input"
										checked={fields.typeServices === '5'}
										onChange={e => handleChangeInput('typeServices', '5')}
									/>

									<label className="custom-control-label" htmlFor="typeServices4">Иное</label>
								</div>
							</div>
						</div>

						{fields.typeServices === '5' && <div className="form-group">
							<input
								type="text"
								className="form-control"
								id="typeServicesOther"
								value={fields.typeServicesOther}
								onChange={e => handleChangeInput('typeServices', e.currentTarget.value)}
								placeholder="Введите текст..."
							/>
						</div>}

						<div className="form-group">
							<div>
								<label className={s['title']}>Удовлетворены ли Вы качеством, предоставляемым АО «Донэнерго»
									дополнительных услуг?</label>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedAdditional1"
										name="satisfiedAdditional"
										className="custom-control-input"
										checked={fields.satisfiedAdditional === '1'}
										onChange={e => handleChangeInput('satisfiedAdditional', '1')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedAdditional1">Да</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedAdditional2"
										name="satisfiedAdditional"
										className="custom-control-input"
										checked={fields.satisfiedAdditional === '2'}
										onChange={e => handleChangeInput('satisfiedAdditional', '2')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedAdditional2">Нет</label>
								</div>
							</div>

							<div>
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="satisfiedAdditional3"
										name="satisfiedAdditional"
										className="custom-control-input"
										checked={fields.satisfiedAdditional === '3'}
										onChange={e => handleChangeInput('satisfiedAdditional', '3')}
									/>

									<label className="custom-control-label" htmlFor="satisfiedAdditional3">Не обращался</label>
								</div>
							</div>
						</div>

						<div className="h5 text-center">5. Ваши пожелания</div>

						<div className="form-group">
							<div>
								<label className={s['title']} htmlFor="quality">Что, на Ваш взгляд, необходимо изменить, чтобы повысить
									качество предоставляемых услуг?</label>
							</div>

							<div>
								<input
									type="text"
									className="form-control"
									id="quality"
									value={fields.quality}
									onChange={e => handleChangeInput('quality', e.currentTarget.value)}
									placeholder="Введите текст..."
								/>
							</div>
						</div>

						<div className="form-group">
							<div>
								<label className={s['title']} htmlFor="wishes">Ваши пожелания по работе взаимодействия с
									клиентами?</label>
							</div>

							<div>
								<input
									type="text"
									className="form-control"
									id="wishes"
									value={fields.wishes}
									onChange={e => handleChangeInput('wishes', e.currentTarget.value)}
									placeholder="Введите текст..."
								/>
							</div>
						</div>

					</div> : <div className="col-12 mt-4">
						<div className="alert alert-success" role="alert">
							Ваша отзыв отправлен
						</div>
					</div>}

					<div className="col-12">
						{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
							{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
								key={`${index}${indexMsg}`}>{errorMsg}</div>))}
						</div>}

						{!success && <div className="form-group">
							<Button
								className="btn-app btn-app-forward"
								onClick={submit}
								disabled={loading || isDisableSubmit()}
								loading={loading}
							>Отправить</Button>

							<Button
								className="btn-app"
								onClick={reset}
								disabled={loading}
							>Сбросить</Button>
						</div>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Evaluation