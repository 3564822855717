import React, {useState} from "react";
import cx from "classnames";
import s from "../OtherServicesApplication.module.css";
import moment from "moment";
import Button from "../../../components/Button";
import API from "../../../lib/API";

function UserPaychecks (props) {
  const { paychecks, statusPayment } = props
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  const [paymentError, setPaymentError] = useState(null)

  async function requestPayment(paycheckId) {
    await setPaymentInProgress(true)
    setPaymentError(null)
    try {
      const response = await API.paychecksAPI.pay({id: paycheckId})
      response.formUrl && window.location.replace(response.formUrl)
    } catch (e) {
      console.log(e)
      setPaymentError(e.response.errors)
    }
    setPaymentInProgress(false)
  }

  return <div className="col-12">
    <div className="h4 mb-2">Счет на оплату</div>

    {statusPayment === 'success' && <div className="alert alert-success mt-3">
      Транзакция произведена успешно
    </div>}

    {statusPayment === 'failed' && <div className="alert alert-danger mt-3">
      Транзакция произведена неудачно
    </div>}

    <div>
      {paychecks && paychecks.length ? <div>
        {paychecks.map(item => <div key={item.id} className={cx(s['paycheck'], 'mb-2')}>
          <div className={s['paycheck__controls']}>
            <span>Cчет № {item.number} от {item.date ? moment(item.date).format('DD.MM.YYYY') : ''}</span>
            {!!item.paid && <span className="alert-success p-1 m-1">Оплачен {item.authTimestamp ? moment(item.authTimestamp).format('DD.MM.YYYY HH:mm:ss') : ''}</span>}
          </div>

          <div className="mt-3">
            <a href={item.paycheck?.url}>{item.paycheck?.name}</a>
          </div>

          {!item.paid  && <div className="mt-3">
            <Button
              className={cx(s['paycheck__button'], 'btn', 'btn-primary')}
              onClick={() => {
                item.orderURL ?
                  window.location.replace(item.orderURL) : requestPayment(item.id)
              }}
              loading={paymentInProgress}
              disabled={paymentInProgress}
            >Оплатить {item.sum.toFixed(2)}р.</Button>
          </div>}
        </div>)}
      </div> : null}

      {paymentError && <div className="alert alert-danger mt-3">
        {Object.keys(paymentError).map(key => <div key={key}>{paymentError[key]}</div>)}
      </div>}
    </div>
    {process.env.REACT_APP_API_URL === 'http://lkdontest.site/API' && <div className={s['payment-info']}>
      <div className={s['payment-info__left']}>
        <div className={s['payment-info__title']}>Способы оплаты:</div>

        <div className={s['payment-info__term']}>
          <div className={s['payment-info__term-title']}>Наличный расчёт:</div>
          <div>Оплата производится в кассе организации.</div>
        </div>

        <div className={s['payment-info__term']}>
          <div className={s['payment-info__term-title']}>Банковской картой:</div>
          <div>
            <div>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку <span className={s['text-link']}>Оплатить</span>.
              Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:</div>

            <div className={s['payment-info__images']}>
              <img src="/images/payment1.png" alt="" className={s['payment-info__image']}/>
              <img src="/images/payment2.png" alt="" className={s['payment-info__image']}/>
              <img src="/images/payment3.png" alt="" className={s['payment-info__image']}/>
              <img src="/images/payment4.png" alt="" className={s['payment-info__image']}/>
            </div>
          </div>
        </div>
      </div>

      <div className={s['payment-info__right']}>
        <div className={s['payment-info__title']}>Возврат средств:</div>
        <div className={s['payment-info__term']}>Возврат переведённых средств, производится на ваш банковский счёт в течение 5-60 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).</div>
      </div>
    </div>}
  </div>
}

export default UserPaychecks