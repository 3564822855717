import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import Technical from './Technical';
import PageSpinner from "../../components/PageSpinner";
import API from "../../lib/API";

function TechnicalRoute (props) {
	const [ loading, setLoading ] = useState(true)
	const [ mainPageTextBlock, setMainPageTextBlock] = useState('')

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			try {
				const response = await API.adminAPI.getTextBlock({name: "mainPage"})
				setMainPageTextBlock(response.text)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}

		fetchData()
	}, [])

	if (loading)
		return <PageSpinner />
	else
		return (
			<Layout>
				<Technical isAdmin={props.isAdmin} mainPageTextBlock={mainPageTextBlock}/>
			</Layout>
	);
}

export default TechnicalRoute;
