import React, {Component} from "react";

class DictionaryItem extends Component {
  constructor(props) {
    super(props);
    this.formatter = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
    });

  }
  render() {
    const {item, fields, openPopup, serviceTypes} = this.props;

    return (
      <tr>
        {fields.map(value => {
          if (value === 'other_services_type_id')
            return <td className="text-center" key={value}>
              {serviceTypes.get(item.other_services_type_id)}
            </td>
          else if (value === 'price')
            return <td className="text-center" key={value}>
              {this.formatter.format(item.price)}
            </td>
          else
            return <td className="text-center" key={value}>{typeof item[value] !== 'object' ? item[value] : ""}</td>
        })}
        <td className="text-center">
          <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
          <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
        </td>
      </tr>
    );
  }
}

export default DictionaryItem;