export function getTypeLabel (type) {
  let label = '';
  switch (type) {
    case 'fiz':
      label = 'Физ. лицо';
      break;
    case 'ip':
      label = 'ИП';
      break;
    case 'ur':
      label = 'Юр. лицо';
      break;
  }
  return label;
}

export function copyDefinedPropertiesValues (obj1, obj2) {
  if (typeof obj2 !== 'object')
    return Object.assign(obj1);
  else
    return Object.keys(obj1)
      .reduce((a, key) => ({ ...a, [key]: obj2[key] || obj1[key]}), {});
}

export function parseFloat0(value) {
  if (!value)
    return 0;
  else
    return parseFloat(value);
}

export function zeroEqualsEmpty(value) {
  if (value == 0)
    return "";
  return value;
}

export function joinCategories(category1, category2, category3) {
  const categories = [];
  category1 && categories.push('I');
  category2 && categories.push('II');
  category3 && categories.push('III');

  return categories.join(',');
}

export function searchValue(options, value) {
  let found = false;

  let i = 0;
  while (!found && i < options.length) {
    if (options[i].value == value)
      found = options[i];
    i++;
  }

  return found;
}

export function isObjEmpty(obj) {

  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0)    return false;
  if (obj.length === 0)  return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function isFilesEmpty(fileArray) {
  return fileArray.length === 0 || (fileArray.length === 1 && !fileArray[0].name);
}

export function prepareFiles(key, fileArray) {
  const data = {}
  if (!isFilesEmpty(fileArray)) {
    fileArray.forEach(file => {
      if (file.path)
        data[key + '[]'] = file.path;
      else
        data[key + '[]'] = file;
    });
  }
  return data
}


export function searchObjectByKeyValue(array, key, value) {
  let found = false;

  let i = 0;
  while (!found && i < array.length) {
    if (array[i][key] === value)
      found = array[i];
    else
      i++;
  }

  return found;
}

export function searchObjectSecondLevelByKeyValue(array, key, value) {
  let found = false;

  let i = 0;
  while (!found && i < array.length) {
    found = searchObjectByKeyValue(array[i].services, key, value);
    i++;
  }

  return { firstLevel: array[i - 1], secondLevel: found };
}

export function showCategories (state, reasons) {
  if (
    (
      state.type === 'fiz'
      && (reasons[state.connectiontype]?.system === 'new' || reasons[state.connectiontype]?.system === 'increase')
      && state.connection_purpose === 'private'
    )
    || (
      reasons[state.connectiontype]?.system === 'tempfull'
      || reasons[state.connectiontype]?.system === 'temp150'
      || reasons[state.connectiontype]?.system === 'micro'
      || reasons[state.connectiontype]?.system === 'microcat3'
    )

  ) {
    return false
  }

  return true
}

