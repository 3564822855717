import React from 'react';
import Layout from '../../../components/Layout';
import Restore from './Restore';

function AuthRestorePasswordRoute (props) {

	const
		urlParams = new URLSearchParams(props.location.search),
		token = urlParams.get('token'),
		id = urlParams.get('id');

	return (
		<Layout>
			<Restore token={token} id={id} />
		</Layout>
	);

}

export default AuthRestorePasswordRoute;
