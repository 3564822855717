import BasicAPI from "./BasicAPI";

class DictionaryAPI extends BasicAPI {
  constructor(url) {
    super();
    this.url = url;
  }

  async get(data) {
    return this.sendRequest(this.url + '/get', data);
  }

  async create(data) {
    return this.sendRequest(this.url + '/create', data);
  }

  async update(data) {
    return this.sendRequest(this.url + '/update', data);
  }

  async delete(data) {
    return this.sendRequest(this.url + '/delete', data);
  }
}

export default DictionaryAPI;