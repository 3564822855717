import React, {useContext, useState} from "react";
import cx from "classnames";
import s from "../TechnicalApplication.module.css"
import moment from "moment";
import Button from "../../../components/Button";
import CurrencyInput from "../../../components/CurrencyInput";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {PopupContext} from "../../../lib/PopupContext";
import API from "../../../lib/API";
import {AuthContext} from "../../../lib/AuthContext";

function AdminPaychecks (props) {
  const { applicationId, paychecks, setPaychecks } = props
  const { setPopupSettings } = useContext(PopupContext)
  const [ loading, setLoading ] = useState(false)
  const [ paycheckFile, setPaycheckFile ] = useState(null)
  const [ paycheckSum, setPaycheckSum ] = useState('')
  const [ paycheckNumber, setPaycheckNumber ] = useState('')
  const [ paycheckDate, setPaycheckDate ] = useState(new Date())
  const [ errors, setErrors ] = useState(null)
  const { authContext } = useContext(AuthContext)

  async function uploadPaychecks() {
    setLoading(true)

    try {
      const paycheck = await API.paychecksAPI.upload({
        application_id: applicationId,
        sum: paycheckSum,
        number: paycheckNumber,
        date: moment(paycheckDate).format('YYYY-MM-DD'),
        paycheck: paycheckFile
      })

      setPopupSettings({
        type: 'info',
        text: 'Счет №' + paycheckNumber + ' загружен',
      })

      setPaycheckSum('')
      setPaycheckNumber('')
      setPaycheckDate(new Date())
      setPaycheckFile(null)

      paycheck.sum = parseFloat(paycheck.sum)
      paychecks.push(paycheck)
      setPaychecks([...paychecks])
    } catch (e) {
      setPopupSettings({
        type: 'info',
        text: e.response.errors,
        error: true
      })
    }

    setLoading(false)
  }

  async function deletePaycheck(paycheckId) {
    setLoading(true)

    try {
      await API.paychecksAPI.delete({ id: paycheckId })

      for (let i = 0; i < paychecks.length; i++) {
        if (paychecks[i].id === paycheckId) {
          paychecks.splice(i, 1)
          setPaychecks([...paychecks])
          break
        }

      }
      setPopupSettings(null)
    } catch (e) {
      setPopupSettings({
        type: 'info',
        text: e.response.errors,
        error: true
      })
    }

    setLoading(false)
  }

  function deletePaycheckConfirm(paycheck) {
    setPopupSettings({
      type: 'confirm',
      text: 'Удалить счёт №' + paycheck.number + ' ?',
      doAction: () => deletePaycheck(paycheck.id)
    })
  }

  let datepicker

  return <div className="col-9">
    {paychecks && paychecks.length ? <div className="mt-3">

      {paychecks.map(item => {
        //if (item.paycheck)
        return (
          <div key={item.id} className={cx(s['paycheck'], 'mb-2')}>
            <div>
              <span>Счет № {item.number} от {item.date ? moment(item.date).format('DD.MM.YYYY') : ''} на сумму {item.sum.toFixed(2)}р.</span>
              {!!item.paid && <span
                className="alert-success p-1 m-1">Оплачен {item.authTimestamp ? moment(item.authTimestamp).format('DD.MM.YYYY HH:mm:ss') : ''}</span>}
            </div>

            <div>
              <a href={item.paycheck && item.paycheck.url}>{item.paycheck && item.paycheck.name}</a>
              {authContext?.permissions?.name !== "tns_superadmin"
              && authContext?.permissions?.name !== "tns_admin"
              && authContext?.permissions?.name !== "support"
              && !item.paid &&
              <Button
                className="btn btn-danger btn-sm ml-3"
                onClick={ () => deletePaycheckConfirm(item) }
                loading={loading}
                disabled={loading}
              >Удалить</Button>}
            </div>
          </div>
        )
      })}

    </div> : null}
    {authContext?.permissions?.name !== "tns_superadmin"
    && authContext?.permissions?.name !== "tns_admin"
    && authContext?.permissions?.name !== "support"
    && <>
    <div className="h4 mt-3 mb-3">Создать счет</div>

    <div>
      <label htmlFor="paychecksFiles">Счет на оплату</label>

      <div className="mt-1">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="paycheckFile"
            onChange={ e => setPaycheckFile(e.currentTarget.files[0]) }
          />

          <label
            className="custom-file-label"
            htmlFor="paycheckFile"
          >{paycheckFile && `${paycheckFile.name}`}</label>
        </div>
      </div>

      <div className={cx('form-group', 'mt-2')}>
        <label htmlFor="paycheckSum">Сумма:</label>

        <CurrencyInput
          type="text"
          className={cx('form-control')}
          maskOptions={{
            thousandsSeparatorSymbol: '',
            integerLimit: 10
          }}
          value={paycheckSum}
          onChange={ e => setPaycheckSum(e.target.value) }
          id="paycheckSum"
        />
      </div>

      <div className={cx('form-group')}>
        <label htmlFor="paycheckNumber">Номер счета:</label>

        <input
          type="text"
          className={cx('form-control')}
          value={paycheckNumber}
          onChange={ e => setPaycheckNumber(e.target.value) }
          id="paycheckNumber"
        />
      </div>

      <div className={cx('form-group')}>
        <label htmlFor="paycheckDate">Дата счета:</label>

        <div className="input-group">
          <DatePicker
            className="form-control"
            selected={paycheckDate}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={ date => setPaycheckDate(date) }
            ref={(c) => datepicker = c}
          />

          <div
            className="input-group-append"
            style={{cursor: 'pointer'}}
            onClick={ () => datepicker.setOpen(true) }
          >
            <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar}/></span>
          </div>
        </div>
      </div>

      {/*{errors.paychecksFiles &&*/}
      {/*<div className={s['error-msg']}>*/}
      {/*  {Object.keys(errors.paychecksFiles).map((errorKey, index) =>*/}
      {/*    <div key={index}>{errors.paychecksFiles[errorKey]}</div>*/}
      {/*  )}*/}
      {/*</div>}*/}
    </div>

    <div className="row">
      <div className="col-md-4">
        <Button
          className="btn btn-primary mt-2"
          onClick={ uploadPaychecks }
          loading={loading}
          disabled={loading || !paycheckFile || !paycheckNumber || !paycheckSum || !paycheckDate}
        >Отправить</Button>
      </div>
      <div className="col">
        {errors && Object.keys(errors).length > 0 && <div className="alert alert-danger">
          {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
            className={s['error-msg-paycheck']} key={`${index}${indexMsg}`}>{errorMsg}</div>))}
        </div>}
      </div>
    </div>
    </>}
  </div>
}

export default AdminPaychecks