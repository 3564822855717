import BasicAPI from "./BasicAPI";

class ApplicationsAPI extends BasicAPI{
  constructor(prefix) {
    super();
    this.prefix = prefix;
  }
  
  getById (data) {
    return this.sendRequest(this.prefix + '/getById', data);
  }

  getDraft () {
    return this.sendRequest(this.prefix + '/getDraft');
  }

  get (data) {
    return this.sendRequest(this.prefix + '/get', data);
  }

  make (data) {
    return this.sendRequest(this.prefix + '/make', data);
  }

  update (data) {
    return this.sendRequest(this.prefix + '/update', data);
  }

  setApplicationNumber (data) {
    return this.sendRequest(this.prefix + '/setApplicationNumber', data);
  }

  setApplicationStatus (data) {
    return this.sendRequest(this.prefix + '/setStatus', data);
  }

  setApplicationRegisterDate (data) {
    return this.sendRequest(this.prefix + '/setApplicationRegisterDate', data);
  }

  setDepartment (data) {
    return this.sendRequest(this.prefix + '/setDepartment', data);
  }

  setTNSDepartment (data) {
    return this.sendRequest(this.prefix + '/setTNSDepartment', data);
  }

  setComment (data) {
    return this.sendRequest(this.prefix + '/setComment', data);
  }

  setOferta (data) {
    return this.sendRequest(this.prefix + '/setOferta', data);
  }

  setOffer (data) {
    return this.sendRequest(this.prefix + '/setOffer', data);
  }

  setPaycheckEmail (data) {
    return this.sendRequest(this.prefix + '/setPaycheckEmail', data);
  }

  requestNullify (data) {
    return this.sendRequest(this.prefix + '/requestNullify', data);
  }

  nullify (data) {
    return this.sendRequest(this.prefix + '/nullify', data);
  }

  cancelNullify (data) {
    return this.sendRequest(this.prefix + '/cancelNullify', data);
  }

  requestSignDocument (data) {
    return this.sendRequest(this.prefix + '/requestSignDocument', data);
  }

  signDocument (data) {
    return this.sendRequest(this.prefix + '/signDocument', data);
  }

  uploadAgreementFile (data) {
    return this.sendRequest(this.prefix + '/uploadAgreementFile', data);
  }

  deleteAgreementFile (data) {
    return this.sendRequest(this.prefix + '/deleteAgreementFile', data);
  }

  uploadTNSProject (data) {
    return this.sendRequest(this.prefix + '/uploadTNSProject', data);
  }

  exportApplications () {
    return this.sendRequest(this.prefix + '/exportApplications ', null, {
      responseType: 'blob'
    });
  }
}

export default ApplicationsAPI;