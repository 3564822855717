export function validateINN (type, inn) {
  let result = false, error = false

  if (typeof inn === 'number') {
    inn = inn.toString()
  } else if (typeof inn !== 'string') {
    inn = ''
  }

  if (!inn.length && type === 'ur') {
    result = true
  } else if (!inn.length && type !== 'ur') {
    error = 'Заполните ИНН'
  } else if (/[^0-9]/.test(inn)) {
    error = 'ИНН может состоять только из цифр'
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error = 'ИНН может состоять только из 10 или 12 цифр'
  } else {
    let checkDigit = (inn, coefficients) => {
      let n = 0
      for (let i in coefficients) {
        n += coefficients[i] * inn[i]
      }
      return parseInt(n % 11 % 10)
    }

    switch (inn.length) {
      case 10:
        let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (n10 === parseInt(inn[9])) {
          result = true
        }
        break
      case 12:
        let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])

        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true
        }

        break
      default:
        break
    }

    if (!result) {
      error = 'Неправильное контрольное число ИНН'
    }
  }

  if (!result)
    return error
}

export function validateSNILS (snils) {
  if (!snils)
    return 'Заполните СНИЛС'

  snils = snils.replace(/[^\d]/g, "")

  let result = false, error = false

  if (typeof snils === 'number') {
    snils = snils.toString()
  } else if (typeof snils !== 'string') {
    snils = ''
  }
  if (!snils.length) {
    error = 'Заполните СНИЛС'
  } else if (/[^0-9]/.test(snils)) {
    error = 'СНИЛС может состоять только из цифр'
  } else if (snils.length !== 11) {
    error = 'СНИЛС может состоять только из 11 цифр'
  } else {
    let sum = 0

    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i)
    }

    let checkDigit = 0

    if (sum < 100) {
      checkDigit = sum
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101)

      if (checkDigit === 100) {
        checkDigit = 0
      }
    }

    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true
    } else {
      error = 'Неправильное контрольное число СНИЛС'
    }
  }

  if (!result)
    return error
}

export function validateEmail(email) {
  let result = false, error = '';

  if (!email.trim().length)
    error = 'Заполните Email';
  else {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    error = 'Неверный формат E-mail';
  }

  if (!result)
    return error;
}