import React from "react";
import DownloadFile from "../../../components/DownloadFile/DownloadFile";
import ToggleInput from "../../../components/ToggleInput/ToggleInput";
import API from "../../../lib/API";
import UploadFileInput from "../../../components/UploadFileInput/UploadFileInput";
import s from "../OtherServicesApplication.module.css";
import moment from "moment";
import ToggleInputResult from "../../../components/ToggleInputResult/ToggleInputResult";

function AdminDocuments (props) {
  const { applicationId, agreement_files, oferta, specialCondition, specInstructions } = props

  return <>
    <div className="h4 mb-4">Заключение договора об осуществлении ПУ</div>

    <UploadFileInput
      API={API.otherServicesApplicationsAPI}
      applicationId={applicationId}
      name="agreement"
      text='Договор на оказание "Прочие услуги" со стороны сетевой организации'
      url={agreement_files?.agreement?.url}
      viewed={agreement_files?.agreement?.viewed}
    />

    <DownloadFile
      API={API.otherServicesApplicationsAPI}
      applicationId={applicationId}
      name="agreementUser"
      text='Договор на оказание "Прочие услуги" со стороны заявителя'
      url={agreement_files?.agreementUser?.url}
      viewed={agreement_files?.agreementUser?.viewed}
    />

    <UploadFileInput
      API={API.otherServicesApplicationsAPI}
      applicationId={applicationId}
      name="act"
      text='Акты выполненных работ по стороны сетевой организации'
      url={agreement_files?.act?.url}
      viewed={agreement_files?.act?.viewed}
    />

    <DownloadFile
      API={API.otherServicesApplicationsAPI}
      applicationId={applicationId}
      name="actUser"
      text='Акты выполненных работ со стороны заявителя'
      url={agreement_files?.actUser?.url}
      viewed={agreement_files?.actUser?.viewed}
    />
  </>
}

export default AdminDocuments