export default class Address {
  type = '';
  index = '';
  subject = '';
  district = '';
  city = '';
  street = '';
  house = '';
  section = '';
  building = '';
  flat = '';
  kladr = '';
  kadastr = '';
  kladr_full = '';
  region_fias_id = '';
  city_fias_id = '';
  settlement_fias_id = '';
  sameAddress = false;

  constructor(type, address) {
    this.type = type;
    if (typeof address !== 'undefined')
      this.set(address);
  }

  set(address) {
    if (address) {
      this.index = address.index;
      this.subject = address.subject;
      this.district = address.district;
      this.city = address.city;
      this.street = address.street;
      this.house = address.house;
      this.building = address.building;
      this.section = address.section;
      this.flat = address.flat;
      this.kadastr = address.kadastr;
      this.kladr = address.kladr;
      this.kladr_full = address.kladr_full;
      this.region_fias_id = address.kladr_full && typeof address.kladr_full === 'object' && address.kladr_full.region_fias_id && address.kladr_full.region_fias_id;
      this.city_fias_id = address.kladr_full && typeof address.kladr_full === 'object' && address.kladr_full.city_fias_id;
      this.settlement_fias_id = address.kladr_full && typeof address.kladr_full === 'object' && address.kladr_full.settlement_fias_id;
    }
  }

  getJson() {
    return JSON.stringify({
      index: this.index,
      subject: this.subject,
      district: this.district,
      city: this.city,
      street: this.street,
      house: this.house,
      section: this.section,
      building: this.building,
      flat: this.flat,
      kladr: this.kladr,
      kladr_full: this.kladr_full,
      kadastr: this.kadastr,
      region_fias_id: this.kladr_full && typeof this.kladr_full === 'object' && this.kladr_full.region_fias_id && this.kladr_full.region_fias_id,
      city_fias_id: this.kladr_full && typeof this.kladr_full === 'object' && this.kladr_full.city_fias_id,
      settlement_fias_id: this.kladr_full && typeof this.kladr_full === 'object' && this.kladr_full.settlement_fias_id
    });
  }

  validate(errorSuffix) {
    const errors = {};

    if (!this.sameAddress) {
      if (!this.subject)
        errors[`${this.type}subject`] = `Заполните регион ${errorSuffix}`;

      if (!this.street)
        errors[`${this.type}street`] = `Заполните улицу ${errorSuffix}`;

      if (!this.city)
        errors[`${this.type}city`] = `Выберите город ${errorSuffix} из выпадающего списка`;

      if (!this.house)
        errors[`${this.type}house`] = `Заполните номер дома ${errorSuffix}`;
    }

    return errors
  }
}