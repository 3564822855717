import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import MultiFileInput from "./MultiFileInput";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabAdditionalData ()  {
  const { appData, dispatch } = useContext(ApplicationContext)
  const additionalconditions = appData.additionalconditions
  const errors = appData.errors.errors

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Документы:</div>
    </div>

    <div className="form-group">
      <div className="h6">Информация</div>
      <p>Максимальный размер одного файла - 5 Мб. Допустимые форматы документа - doc, docx, xls, xlsx, odt, ods, pdf, tif, tiff, jpg, png, zip. Если требуется приложить более одного файла к одному документу, поместите их в архив.</p>
    </div>

    <div className="form-group">
      <div className="h6">Дополнительные условия</div>
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="additionalconditionsrepresent"
          checked={additionalconditions.additionalconditionsrepresent}
          onChange={() => dispatch({type: "setAdditionalConditionsField", payload: {field: 'additionalconditionsrepresent', value: !additionalconditions.additionalconditionsrepresent}})}
        />

        <label className="custom-control-label" htmlFor="additionalconditionsrepresent">От моего имени будет выступать представитель по доверенности</label>
      </div>
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="additionalconditionsuninhabited"
          checked={additionalconditions.additionalconditionsuninhabited}
          onChange={() => dispatch({type: "setAdditionalConditionsField", payload: {field: 'additionalconditionsuninhabited', value: !additionalconditions.additionalconditionsuninhabited}})}
        />

        <label className="custom-control-label" htmlFor="additionalconditionsuninhabited">Осуществляется присоединение устройств, находящихся в нежилых помещениях многоквартирного дома</label>
      </div>
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="additionalconditionsindividual"
          checked={additionalconditions.additionalconditionsindividual}
          onChange={() => dispatch({type: "setAdditionalConditionsField", payload: {field: 'additionalconditionsindividual', value: !additionalconditions.additionalconditionsindividual}})}
        />

        <label className="custom-control-label" htmlFor="additionalconditionsindividual">Проектом на многоквартирный дом для нежилого помещения предусмотрено индивидуальное вводно-распределительное устройство</label>
      </div>
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="additionalconditionsemergency"
          checked={additionalconditions.additionalconditionsemergency}
          onChange={() => dispatch({type: "setAdditionalConditionsField", payload: {field: 'additionalconditionsemergency', value: !additionalconditions.additionalconditionsemergency}})}
        />

        <label className="custom-control-label" htmlFor="additionalconditionsemergency">Энергопринимающие устройства будут присоединены к устройствам противоаварийной и режимной автоматики</label>
      </div>
    </div>

    <div className="form-group">
      <div className="h5">Приложения к заявке:</div>

      <div className={s['info']}>Максимальный размер одного файла - 5 Мб. Максимальный общий объем файлов - 100 Мб</div>

      {appData.type === 'fiz' && <div className="form-group">
        <label htmlFor="passportfiz">Сканированная копия паспорта физического лица: <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.passportfiz}
              repeat={appData.passportfizrepeat}
              error={errors.passportfiz}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'passportfiz', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'passportfizrepeat', value}})}
            />
          </div>
        </div>
      </div>}

      {(appData.type === 'ip' || appData.type === 'ur') && <div className="form-group">
        <label htmlFor="egripdocs">{appData.type === 'ip' ? 'Сканированные копии документов ЕГРИП:' : 'Сканированные копии документов ЕГРЮЛ:'} <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.egrnum_docs}
              repeat={appData.egrnum_docsrepeat}
              error={errors.egrnum_docs}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'egrnum_docs', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'egrnum_docsrepeat', value}})}
            />
          </div>
        </div>
      </div>}

      {additionalconditions.additionalconditionsrepresent && <div className="form-group">
        <label htmlFor="warrant">Сканированная копия доверенности представителя и паспорта представителя: <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.warrant}
              repeat={appData.warrantrepeat}
              error={errors.warrant}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'warrant', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'warrantrepeat', value}})}
            />
          </div>
        </div>
      </div>}

      {additionalconditions.additionalconditionsindividual && <div className="form-group">
        <label htmlFor="conditionsindividualdocs">Документы для индивидуального вводно-распределительное устройства: <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.conditionsindividualdocs}
              repeat={appData.conditionsindividualdocsrepeat}
              error={errors.conditionsindividualdocs}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'conditionsindividualdocs', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'conditionsindividualdocsrepeat', value}})}
            />
          </div>
        </div>
      </div>}

      <div className="form-group">
        <label htmlFor="plan">План расположения энергопринимающих устройств: <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.plan}
              repeat={appData.planrepeat}
              error={errors.plan}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'plan', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'planrepeat', value}})}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="accident">Перечень и мощность энергопринимающих устройств, которые могут быть присоединены к устройствам противоаварийной и режимной автоматики:</label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.accident}
              repeat={appData.accidentrepeat}
              error={errors.accident}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'accident', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'accidentrepeat', value}})}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="constitutivedocuments">Правоустанавливающие документы: <span className={s['red-star']}>*</span></label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.constitutivedocuments}
              repeat={appData.constitutivedocumentsrepeat}
              error={errors.constitutivedocuments}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'constitutivedocuments', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'constitutivedocumentsrepeat', value}})}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="otherdocuments">Прочие документы:</label>
        <div className="form-row">
          <div className="col-8">
            <MultiFileInput
              required
              files={appData.otherdocuments}
              repeat={appData.otherdocumentsrepeat}
              error={errors.otherdocuments}
              setFiles={ value => dispatch({type: 'setField', payload: { field: 'otherdocuments', value}})}
              setRepeat={ value => dispatch({type: 'setField', payload: { field: 'otherdocumentsrepeat', value}})}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default TabAdditionalData;