import {copyDefinedPropertiesValues } from "../lib/helpers"
import {validateINN, validateSNILS, validateEmail} from "../lib/validators"
import Address from "../lib/Address"
import moment from "moment";

class ApplicationService {
  constructor(appData) {
    this.initialData = {
      //Service
      serviceType: { label: null, value: null },
      service: { label: null, value: null },
      quantity: 1,
      //TabPersonal
      type: '',
      name: '',
      inn: '',
      egrnum: '',
      egrnum_register_date: '',
      snils: '',
      kpp: '',
      ogrn: '',

      //TabPersonalID
      documenttype: 'pasport',
      documenttypeText: '',
      docseries: '',
      docnumber: '',
      docdepartment: '',
      docissuedate: '',

      //TabContactData
      email: '',
      phone: '',

      //TabAdditionalData
      additionalconditions: {
        additionalconditionsrepresent: false
      },

      passportfiz: [],
      egrnum_docs: [],
      warrant: [],
      plan: [],
      accident: [],
      otherdocuments: [],

      passportfizrepeat: [],
      egrnum_docsrepeat: [],
      warrantrepeat: [],
      planrepeat: [],
      accidentrepeat: [],
      otherdocumentsrepeat: [],

      //TabOtherInfo
      aggrementtype: 0,
      otherinfopayment: false,
      person: !!appData
    }

    //console.log(appData.type)

    this.initialData.regaddress = null
    this.initialData.liveaddress = null

    this.initialData.uraddress = null
    this.initialData.postaddress = null

    this.initialData.connectionaddress = null
  }

  init (appData) {
    const application = copyDefinedPropertiesValues(this.initialData, appData)

    //TabPersonal
    switch (application.type) {
      case 'fiz':
        application.snils = appData.egrnum
        break
      case 'ip':
        application.egrip = appData.egrnum
        application.egrip_register_date = appData.egrnum_register_date ? new Date(appData.egrnum_register_date) : ''
        break
      case 'ur':
        application.egrul = appData.egrnum
        application.egrul_register_date = appData.egrnum_register_date ? new Date(appData.egrnum_register_date) : ''
        application.position = appData.organization ? appData.organization.position : ""
        application.kpp = appData.organization ? appData.organization.kpp : ""
        application.ogrn = appData.organization ? appData.organization.ogrn : ""
        break
      default:
        break
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {
      //TabPersonalID
      if (appData.document) {
        application.docdepartment = appData.document.docdepartment
        application.docissuedate = appData.document.docissuedate
        application.docnumber = appData.document.docnumber
        application.docseries = appData.document.docseries
        application.documenttype = appData.document.documenttype
        application.documenttypeText = appData.document.documenttypeText
      }

      if (application.docissuedate)
        application.docissuedate = application.docissuedate !== 'Invalid date' ? new Date(application.docissuedate) : ''
      else
        application.docissuedate = ''

      //TabRegAddress
      application.regaddress = new Address('reg', appData.regaddress)

      //TabLiveAddress
      application.liveaddress = new Address('live', appData.liveaddress)
    } else if (appData.type === 'ur') {
      //TabRegAddress
      application.uraddress = new Address('ur', appData.uraddress)

      //TabLiveAddress
      application.postaddress = new Address('post', appData.postaddress)
    }

    //TabConnectionAddress
    application.connectionaddress = new Address('connection', appData.connectionaddress)
    application.connectionaddress.kadastrView = !!application.connectionaddress.kadastr

    //TabAdditionalData
    application.passportfizrepeat = appData.passportfiz ? appData.passportfiz : []
    application.egrnum_docsrepeat = appData.egrnum_docs ? appData.egrnum_docs : []
    application.warrantrepeat = appData.warrant ? appData.warrant : []
    application.planrepeat = appData.plan ? appData.plan : []
    application.accidentrepeat = appData.accident ? appData.accident : []
    application.otherdocumentsrepeat = appData.otherdocuments ? appData.otherdocuments : []

    application.passportfiz = []
    application.egrnum_docs = []
    application.warrant = []
    application.plan = []
    application.accident = []
    application.otherdocuments = []

    application.errors = this.validate(application)

    return application
  }

  reducer (state, action) {
    switch (action.type) {
      case 'setField':
        state[action.payload.field] = action.payload.value
        state.errors = this.validate(state)
        return {...state}
      case 'setFields':
        action.payload.forEach(value => {
          state[value.field] = value.value
        })
        state.errors = this.validate(state)
        return {...state}
      case 'setAdditionalConditionsField':
        state.additionalconditions[action.payload.field] = action.payload.value
        state.errors = this.validate(state)
        return {...state}
      case 'validate':
        state.errors = this.validate(state)
        return {...state}
      case 'init':
        return this.init(action.payload.appData);
      default:
        throw new Error();
    }
  }

  isEmpty (files) {
    if (!Array.isArray(files))
      return true

    if (files.length === 0)
      return true

    let isEmpty = true

    for (let i = 0; i < files.length; i++)
      if (files[i] instanceof File) {
        isEmpty = false
        break
      }

    return isEmpty
  }

  validate(appData) {
    let allErrors = {}
    let errors = {}
    const errorTabs = []

    //TabPersonal
    if (appData.type === 'fiz' || appData.type === 'ip') {
      if (!appData.name)
        errors['name'] = 'Заполните ФИО'

      const invalidINN = validateINN(appData.type, appData.inn)
      if (invalidINN)
        errors['inn'] = invalidINN

      const invalidSNILS = validateSNILS(appData.snils)
      if (invalidSNILS)
        errors['snils'] = invalidSNILS

      if (appData.type === 'ip') {
        if (!appData.egrip)
          errors['egrip'] = 'Заполните ЕГРИП'

        if (!appData.egrip_register_date)
          errors['egrip_register_date'] = 'Заполните дату внесения в ЕГРИП'
      }
    } else if (appData.type === 'ur') {
      if (!appData.name)
        errors['name'] = 'Заполните наименование организации'

      if (!appData.position)
        errors['position'] = 'Заполните должность руководителя, ФИО'

      if (!appData.egrul)
        errors['egrul'] = 'Заполните ЕРГЮЛ'

      if (!appData.egrul_register_date)
        errors['egrul_register_date'] = 'Заполните дату внесения в ЕРГЮЛ'
    }

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabPersonal')
      allErrors = errors
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {
      //TabPersonalId
      errors = {}

      if (!appData.documenttype)
        errors['documenttype'] = 'Выберите тип документа';

      if (appData.documenttype === 'other')
        if (!appData.documenttypeText)
          errors['documenttypeText'] = 'Выберите тип документа';

      if (!appData.docseries)
        errors['docseries'] = 'Заполните серию документа';

      if (!appData.docnumber)
        errors['docnumber'] = 'Заполните номер документа';

      if (!appData.docdepartment)
        errors['docdepartment'] = 'Заполните поле, кем выдан документ';

      if (!appData.docissuedate)
        errors['docissuedate'] = 'Заполните дату выдачи документа';

      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabPersonalId')
        allErrors = {...allErrors, ...errors}
      }
    }
    //TabContactData
    errors = {}

    const emailInvalid = validateEmail(appData.email);
    if (emailInvalid)
      errors.email = emailInvalid;

    if (appData.phone.length < 11)
      errors.phone = 'Заполните номер телефона';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabContactData')
      allErrors = {...allErrors, ...errors}
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {

      //TabRegAddress
      errors = appData.regaddress.validate('адреса регистрации')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabRegAddress')
        allErrors = {...allErrors, ...errors}
      }

      //TabLiveAddress
      errors = appData.liveaddress.validate('адреса места жительства')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabLiveAddress')
        allErrors = {...allErrors, ...errors}
      }
    } else if (appData.type === 'ur') {
      //TabLiveAddress
      errors = appData.uraddress.validate('юридического адреса')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabUrAddress')
        allErrors = {...allErrors, ...errors}
      }

      //TabLiveAddress
      errors = appData.postaddress.validate('почтового адреса')
      if (Object.keys(errors).length > 0) {
        errorTabs.push('TabPostAddress')
        allErrors = {...allErrors, ...errors}
      }
    }

    //TabConnectionAddress
    errors = {}

    if (appData.connectionaddress.kadastrView) {
      if (!appData.connectionaddress.kadastr)
        errors[`connectionkadastr`] = `Заполните кадастровый номер адреса объекта`;
    } else if (!appData.connectionaddress.street) {
      errors[`connectionstreet`] = `Заполните улицу адреса объекта`;
    }

    if (!appData.connectionaddress.city)
      errors[`connectioncity`] = `Выберите город адреса объекта из выпадающего списка`;

    if (!appData.connectionaddress.kadastrView && !appData.connectionaddress.house)
      errors[`connectionhouse`] = `Заполните номер дома адреса объекта`;

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabConnectionAddress')
      allErrors = {...allErrors, ...errors}
    }

    //TabAdditionalData
    errors = {}

    if (appData.type === 'fiz' && this.isEmpty(appData.passportfiz) && appData.passportfizrepeat.length === 0)
      errors.passportfiz = 'Загрузите файл(ы) сканированной копии паспорта';
    else if ((appData.type === 'ip' || appData.type === 'ur') && this.isEmpty(appData.egrnum_docs) && appData.egrnum_docsrepeat.length === 0)
      errors.egrnum_docs = (appData.type === 'ip' ? 'Загрузите файл(ы) документа ЕГРИП' : 'Загрузите файл(ы) документа ЕГРЮЛ');

    if (
      appData.additionalconditions.additionalconditionsrepresent
      && this.isEmpty(appData.warrant)
      && !appData.warrantrepeat.length
    )
      errors.warrant = 'Загрузите файл(ы) сканированной копии доверенности и паспорта представителя';

    if (this.isEmpty(appData.plan) && appData.planrepeat.length === 0)
      errors.plan = 'Загрузите файл(ы) плана расположения энергопринимающих устройств';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabAdditionalData')
      allErrors = {...allErrors, ...errors}
    }

    //TabOtherInfo
    errors = {}

    if (typeof appData.aggrementtype === 'undefined')
      errors.aggrementtype = 'Не выбран способ обмена документами';

    if (!appData.person)
      errors.person = 'Подтвердите разрешение на использование персональных данных';

    if (Object.keys(errors).length > 0) {
      errorTabs.push('TabOtherInfo')
      allErrors = {...allErrors, ...errors}
    }

    return {errors: allErrors, errorTabs}
  }

  prepareData(appData, dictionaries) {
    //let application = this.initialData

    const application = {}

    application.serviceId = appData.service.value
    //TabPersonal
    application.name = appData.name
    application.inn = appData.inn

    switch (appData.type) {
      case 'fiz':
        application.egrnum = appData.snils;
        //application.personal.egrnum = application.egrnum
        break;
      case 'ip':
        application.snils = appData.snils;
        application.egrnum = appData.egrip;
        application.egrnum_register_date = appData.egrip_register_date ? moment(appData.egrip_register_date).format('YYYY-MM-DD') : "";
        break;
      case 'ur':
        application.kpp = appData.kpp;
        application.ogrn = appData.ogrn;
        application.egrnum = appData.egrul;
        application.egrnum_register_date = appData.egrul_register_date ? moment(appData.egrul_register_date).format('YYYY-MM-DD') : "";
        application.position = appData.position;
        break;
      default:
        break;
    }

    if (appData.type === 'fiz' || appData.type === 'ip') {
      //TabPersonalId
      application.documenttype = appData.documenttypeText ? appData.documenttypeText : appData.documenttype
      application.documenttypeText = appData.documenttypeText
      application.docseries = appData.docseries
      application.docnumber = appData.docnumber
      application.docdepartment = appData.docdepartment
      application.docissuedate = moment(appData.docissuedate).format('YYYY-MM-DD')

      //TabRegAddress
      application.regaddress = appData.regaddress.getJson();

      //TabLiveAddress
      application.liveaddress = appData.liveaddress.sameAddress ? appData.regaddress.getJson() : appData.liveaddress.getJson();
    }

    //TabContactData
    application.email = appData.email
    application.phone = appData.phone

    if (appData.type === 'ur') {
      //TabUrAddress
      application.uraddress = appData.uraddress.getJson();

      //TabPostAddress
      application.postaddress = appData.postaddress.sameAddress ? appData.uraddress.getJson() : appData.postaddress.getJson();
    }

    //TabConnectionAddress
    appData.connectionaddress.subject = "Ростовская обл"
    application.connectionaddress = appData.connectionaddress.getJson()

    //TabAdditionalData
    application.additionalconditions = JSON.stringify(appData.additionalconditions)

    // application = {
    //   ...application,
    //   ...prepareFiles('passportfiz', appData.passportfiz), //TODO multiple file support
    //   ...prepareFiles('passportfizrepeat', appData.passportfizrepeat),
    //   ...prepareFiles('egrnum_docs', appData.egrnum_docs),
    //   ...prepareFiles('egrnum_docsrepeat', appData.egrnum_docsrepeat),
    //   ...prepareFiles('warrant', appData.warrant),
    //   ...prepareFiles('warrantrepeat', appData.warrantrepeat),
    //   ...prepareFiles('plan', appData.plan),
    //   ...prepareFiles('planrepeat', appData.planrepeat),
    //   ...prepareFiles('accident', appData.accident),
    //   ...prepareFiles('accidentrepeat', appData.accidentrepeat),
    //   ...prepareFiles('otherdocuments', appData.otherdocuments),
    //   ...prepareFiles('otherdocumentsrepeat', appData.otherdocumentsrepeat),
    // }

    if (!this.isEmpty(appData.passportfiz))
      application.passportfiz = appData.passportfiz.filter(file => file instanceof File)
    if (appData.passportfizrepeat)
      application.passportfizrepeat = appData.passportfizrepeat
    if (!this.isEmpty(appData.egrnum_docs))
      application.egrnum_docs = appData.egrnum_docs.filter(file => file instanceof File)
    if (appData.egrnum_docsrepeat)
      application.egrnum_docsrepeat = appData.egrnum_docsrepeat
    if (!this.isEmpty(appData.warrant))
      application.warrant = appData.warrant.filter(file => file instanceof File)
    if (appData.warrantrepeat)
      application.warrantrepeat = appData.warrantrepeat
    if (!this.isEmpty(appData.plan))
      application.plan = appData.plan.filter(file => file instanceof File)
    if (appData.planrepeat)
      application.planrepeat = appData.planrepeat
    if (!this.isEmpty(appData.accident))
      application.accident = appData.accident.filter(file => file instanceof File)
    if (appData.accidentrepeat)
      application.accidentrepeat = appData.accidentrepeat
    if (!this.isEmpty(appData.otherdocuments))
      application.otherdocuments = appData.otherdocuments.filter(file => file instanceof File)
    if (appData.otherdocumentsrepeat)
      application.otherdocumentsrepeat = appData.otherdocumentsrepeat

    //TabOtherInfo
    application.aggrementtype = appData.aggrementtype
    application.otherinfo = JSON.stringify({
      otherinfopayment: appData.otherinfopayment,
      person: appData.person
    })

    return application
  }
}

export default ApplicationService
