import React from "react";

import AuthLoginRoute from "./auth/login";
import route_auth_registration from './auth/registration';
import AuthRestorePasswordRoute from './auth/restorepassword';
import AuthForgotRoute from './auth/forgot';
import AuthVerifyRoute from './auth/verify';

import AdminDashboardRoute from './admin_dashboard/AdminDashboard';
import AdminDashboardControlsRoute from './admin_dashboard/Controls';
import AdminConnectionCalcsRoute from "./admin_dashboard/AdminConnectionCalcs";
import AdminEvaluationRoute from './admin_dashboard/Evaluation';
import AdminLogsRoute from './admin_dashboard/Logs';
import AdminMainPageEditorRoute from './admin_dashboard/AdminMainPageEditor';
import AdminTechnicalApplicationsRoute from './admin_dashboard/AdminTechnicalApplications';
import AdminOtherServicesApplicationsRoute from './admin_dashboard/AdminOtherServicesApplications';
 
import AdminsDictionaryRoute from './admin_dashboard/dictionaries/Admins';
import UsersDictionaryRoute from './admin_dashboard/dictionaries/Users';
import DepartmentsDictionaryRoute from './admin_dashboard/dictionaries/Departments';
import TNSDepartmentsDictionaryRoute from './admin_dashboard/dictionaries/TNSDepartments';
import StatusesDictionaryRoute from './admin_dashboard/dictionaries/Statuses';
import ReasonsDictionaryRoute from './admin_dashboard/dictionaries/Reasons';
import DevicesDictionaryRoute from './admin_dashboard/dictionaries/Devices';
import OtherServicesStatusesDictionariesRoute from './admin_dashboard/dictionaries/OtherServicesStatuses';
import OtherServicesTypesDictionaryRoute from './admin_dashboard/dictionaries/OtherServicesTypesDictionary';
import OtherServicesDictionaryRoute from './admin_dashboard/dictionaries/OtherServicesDictionary';

import CabinetRoute from './Cabinet';
import EvaluationRoute from './Evaluation';
import NotFoundRoute from './NotFound';
import TechnicalApplicationsRoute from "./TechnicalApplications";
import TechnicalApplicationRoute from './TechnicalApplication';
import OtherServicesApplicationRoute from './OtherServicesApplication';
import OtherServicesApplicationsRoute from './OtherServicesApplications';
import OtherServicesApplicationRequestRoute from './ApplicationRequests/OtherServicesApplicationRequest';
import ProfileRoute from './Profile';
import TechnicalRoute from './technical';
import TechnicalCalcRoute from './technical_calc';
import TechnicalApplicationRequestRoute from './ApplicationRequests/TechnicalApplicationRequest';
import route_technical_power from './technical_power';

/*const redirects = {
	'/orders': '/technical_applications',
	'/order/:id': '/technical_application/:id'
}*/

const routes = {
	TechnicalRoute: {
		path: '/',
		component: TechnicalRoute,
		public: true
	},

	AuthLoginRoute: {
		path: '/login',
		component: AuthLoginRoute,
		public: true
	},
	route_auth_registration: {
		path: '/registration',
		component: route_auth_registration,
		public: true
	},
	route_auth_registration_confirm: {
		path: '/registration/confirm',
		component: route_auth_registration,
		public: true
	},
	AuthRestorePasswordRoute: {
		path: '/restorepassword',
		component: AuthRestorePasswordRoute,
		public: true
	},
	AuthForgotRoute: {
		path: '/forgot',
		component: AuthForgotRoute,
		public: true
	},
	AuthVerifyRoute: {
		path: '/verify',
		component: AuthVerifyRoute,
		public: true
	},

	AdminDashboardRoute: {
		path: '/admin',
		component: AdminDashboardRoute,
		permission: 'admin'
	},
	AdminDashboardDictionariesRoute: {
		path: '/admin/dictionaries',
		component: () => <AdminDashboardRoute page="dictionaries"/>,
		permission: ['view_users', 'view_technical_dictionaries', 'view_other_services_dictionaries']
	},
	AdminDashboardTechnicalDictionariesRoute: {
		path: '/admin/dictionaries/technical_dictionaries',
		component: () => <AdminDashboardRoute page="technical_dictionaries"/>,
		permission: ['view_technical_dictionaries']
	},
	AdminDashboardOtherServicesDictionariesRoute: {
		path: '/admin/dictionaries/other_services_dictionaries',
		component: () => <AdminDashboardRoute page="other_services_dictionaries"/>,
		permission: ['view_other_services_dictionaries']
	},

	// AdminDashboardControlsRoute: {
	// 	path: '/admin/controls',
	// 	component: AdminDashboardControlsRoute,
	// 	permission: ['admins', 'users', 'departments', 'tns_departments', 'technical_dictionaries', 'other_services_dictionaries']
	// },
	AdminConnectionCalcsRoute: {
		path: '/admin/calcs',
		component: AdminConnectionCalcsRoute,
		permission: 'superadmin'
	},
	AdminEvaluationRoute: {
		path: '/admin/evaluation',
		component: AdminEvaluationRoute,
		permission: 'admin'
	},
	AdminLogsRoute: {
		path: '/admin/logs',
		component: AdminLogsRoute,
		permission: 'superadmin'
	},
	AdminMainPageEditorRoute: {
		path: '/admin/editor',
		component: AdminMainPageEditorRoute,
		permission: 'superadmin'
	},
	AdminTechnicalApplicationsRoute: {
		path: '/admin/technical_applications',
		component: AdminTechnicalApplicationsRoute,
		permission: ['view_technical_applications']
	},
	AdminTechnicalApplicationsPageRoute: {
		path: '/admin/technical_applications/:page',
		component: AdminTechnicalApplicationsRoute,
		permission: ['view_technical_applications']
	},
	AdminTechnicalApplicationRoute: {
		path: '/admin/technical_application/:id',
		component: TechnicalApplicationRoute,
		permission: ['view_technical_applications']
	},
	// route_admin_dashboard_order: {
	// 	path: '/admin/order/:id',
	// 	component: route_admin_dashboard_order,
	// 	permission: ['view_technical_applications', 'edit_technical_applications']
	// },
	AdminOtherServicesApplicationsRoute: {
		path: '/admin/other_services_applications',
		component: AdminOtherServicesApplicationsRoute,
		permission: ['view_other_services_applications']
	},
	AdminOtherServicesApplicationsPageRoute: {
		path: '/admin/other_services_applications/:page',
		component: AdminOtherServicesApplicationsRoute,
		permission: ['view_other_services_applications']
	},
	AdminOtherServicesApplicationRoute: {
		path: '/admin/other_services_application/:id',
		component: OtherServicesApplicationRoute,
		permission: ['view_other_services_applications']
	},

	AdminsDictionaryRoute: {
		path: '/admin/dictionaries/admins',
		component: AdminsDictionaryRoute,
		permission: ['view_admins_tp', 'view_admins_tns', 'view_admins_os']
	},
	UsersDictionaryRoute: {
		path: '/admin/dictionaries/users',
		component: UsersDictionaryRoute,
		permission: ['view_users']
	},
	DepartmentsDictionaryRoute: {
		path: '/admin/dictionaries/departments',
		component: DepartmentsDictionaryRoute,
		permission: ['view_departments']
	},
	TNSDepartmentsDictionaryRoute: {
		path: '/admin/dictionaries/tns_departments',
		component: TNSDepartmentsDictionaryRoute,
		permission: ['view_tns_departments']
	},
	StatusesDictionaryRoute: {
		path: '/admin/dictionaries/status',
		component: StatusesDictionaryRoute,
		permission: ['view_technical_dictionaries']
	},
	ReasonsDictionaryRoute: {
		path: '/admin/dictionaries/reasons',
		component: ReasonsDictionaryRoute,
		permission: ['view_technical_dictionaries']
	},
	DevicesDictionaryRoute: {
		path: '/admin/dictionaries/devices',
		component: DevicesDictionaryRoute,
		permission: ['view_technical_dictionaries']
	},
	OtherServicesStatusesDictionariesRoute: {
		path: '/admin/dictionaries/other_services_status',
		component: OtherServicesStatusesDictionariesRoute,
		permission: ['view_other_services_dictionaries']
	},
	OtherServicesTypesDictionaryRoute: {
		path: '/admin/dictionaries/other_services_types_dictionary',
		component: OtherServicesTypesDictionaryRoute,
		permission: ['view_other_services_dictionaries']
	},
	OtherServicesDictionaryRoute: {
		path: '/admin/dictionaries/other_services_dictionary',
		component: OtherServicesDictionaryRoute,
		permission: ['view_other_services_dictionaries']
	},
	// AdminDashboardPagesRoute: {
	// 	path: '/admin/:page',
	// 	component: AdminDashboardRoute,
	// 	permission: 'admin'
	// },


	CabinetRoute: {
		path: '/cabinet',
		component: CabinetRoute
	},
	EvaluationRoute: {
		path: '/evaluation',
		component: EvaluationRoute
	},
	TechnicalApplicationRoute: {
		path: '/technical_application/:id',
		component: TechnicalApplicationRoute
	},
	TechnicalApplicationsRoute: {
		path: '/technical_applications',
		component: TechnicalApplicationsRoute
	},
	OtherServicesApplicationRoute: {
		path: '/otherservices_application/:id',
		component: OtherServicesApplicationRoute
	},
	OtherServicesApplicationsRoute: {
		path: '/otherservices_applications',
		component: OtherServicesApplicationsRoute
	},
	OtherServicesApplicationRequestRoute: {
		path: '/otherservices/request',
		component: OtherServicesApplicationRequestRoute
	},
	ProfileRoute: {
		path: '/profile',
		component: ProfileRoute
	},
	TechnicalCalcRoute: {
		path: '/technical/calc',
		component: TechnicalCalcRoute,
		public: true
	},
	TechnicalApplicationRequestRoute: {
		path: '/technical/request',
		component: TechnicalApplicationRequestRoute
	},
	route_technical_power: {
		path: '/technical/power',
		component: route_technical_power
	},
	NotFoundRoute: {
		path: '*',
		component: NotFoundRoute
	}
}

export default routes;