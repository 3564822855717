import React, {useState} from "react";
import Button from "../../../../../components/Button";

function Search (props) {
  const { update } = props
  const [ search, setSearch ] = useState('')

  return <>
    <div className="form-group col-4">
      <input
        type="text"
        value={search}
        onChange={e => setSearch(e.target.value)}
        className="form-control"
        placeholder="Поиск пользователя"
      />
    </div>
    <div className="form-group col-1">
      <Button
        className="btn btn-primary h-100"
        onClick={() => update(search)}
      >Найти</Button>
    </div>
    <div className="form-group col-1 ml-2">
      <Button
        className="btn btn-dark h-100"
        onClick={() => {
          setSearch('')
          update('')
        }}
      >Сбросить</Button>
    </div>
    <div className="col-8"/>
  </>
}

export default Search