import React from 'react'
import Layout from '../../../../components/Layout'
import Dictionary, {DefaultDeleteItem, DefaultEditItem} from "../../../../components/Dictionary"
import API from "../../../../lib/API"
import routes from "../../../index";

const NewItem = (props) => <DefaultEditItem
	title="Добавить устройство"
	success="Устройство добавлено"
	{...props}
/>

const EditItem = (props) => <DefaultEditItem
	title="Изменить устройство"
	success="Устройство изменено"
	{...props}
/>

const DeleteItem = (props) => <DefaultDeleteItem
	vocabulary={{
		title: "Удаление устройства",
		prompt: "Вы уверены что хотите удалить устройство?",
		success: "Устройство удалено"
	}}
	{...props}
/>

function DevicesDictionaryRoute (props) {
	return (
		<Layout>
			<Dictionary
				backRoute={routes.AdminDashboardTechnicalDictionariesRoute.path}
				dictionaryAPI={ API.devicesAPI }
				popups={ { NewItem, EditItem, DeleteItem } }
				vocabulary={{
					name: "Энергопринимающие устройства",
					headers: {id: "ID", order: "Положение в списке", description: "Описание"}
				}}
			/>
		</Layout>
	)
}

export default DevicesDictionaryRoute
