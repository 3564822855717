import React, {useContext, useEffect, useState} from 'react';
import Layout from '../../../components/Layout';
import AdminTechnicalApplications from './AdminTechnicalApplications';
import API from "../../../lib/API";
import {PopupContext} from "../../../lib/PopupContext";
import PageSpinner from "../../../components/PageSpinner";

function AdminTechnicalApplicationsRoute(props) {
	const defaultColumnSettings = {
		number: true,
		status_id: true,
		dateReg: true,
		name: true,
		paycheck: true,
		address: true,
		docs: true,
		comments: true,
		aggrementtype: true,
		maxpower: true,
		category: true,
		phone: true,
		voltage: true,
		oldpower: true,
		newpower: true,
		reasons: true,
		connectionobject: true
	}

	const defaultColumnOrder = {field: 'id', order: 'desc'}
	const defaultFilter = []

	const [ columnSettings, setColumnSettings ] = useState(defaultColumnSettings)
	const [ columnOrder, setColumnOrder ] = useState(defaultColumnOrder)
	const [ applicationFilter, setApplicationFilter ] = useState(defaultFilter)

	const [ loading, setLoading ] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const application_view_settings = (await API.authAPI.checkUser()).application_view_settings
				if (application_view_settings?.columnSettings)
					setColumnSettings(application_view_settings.columnSettings)
				if (application_view_settings?.order)
					setColumnOrder(application_view_settings.order)
				if (application_view_settings?.filter)
					setApplicationFilter(application_view_settings.filter)

				// console.log('filter', application_view_settings?.filter)
				// console.log('condition', application_view_settings?.filter ? application_view_settings.filter : defaultFilter)
				// console.log('start up', applicationFilter)

			} catch (e) {
				console.error(e)
			}
			setLoading(false)
		}

		setLoading(true)
		fetchData()
	}, [])

	if (loading)
		return <Layout>
			<PageSpinner />
		</Layout>
	else
		return <Layout>
			<AdminTechnicalApplications
				history={props.history}
				page={props.match.params.page}
				columnSettings={columnSettings}
				columnOrder={columnOrder}
				applicationFilter={applicationFilter}
			/>
		</Layout>
}

export default AdminTechnicalApplicationsRoute;
