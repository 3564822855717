import React, {useContext, useState} from "react"
import cx from "classnames";
import s from "../AdminTechnicalApplications.module.css";
import {SettingsContext} from "../SettingsContext";
import {isEqual} from "lodash";
import Button from "../../../../components/Button";
import API from "../../../../lib/API";

function ColumnOption (props) {
  const { field, checked, onChange } = props

  return <div className={cx('dropdown-item', s['dropdown-item'])}>
    <div className="custom-control custom-checkbox custom-checkbox-simple">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`settings${field}`}
        checked={checked}
        onChange={onChange}
      />

      <label className="custom-control-label" htmlFor={`settings${field}`}>{field}</label>
    </div>
  </div>
}

function ColumnSettings (props) {
  const { showColumnSettings, setShowColumnSettings, setShowFilter } = props
  const { columnSettings, setColumnOption } = useContext(SettingsContext)

  const [ savedColumnSettings, setSavedColumnSettings ] = useState({...columnSettings})
  const [ columnSettingsUpdateInProgress, setColumnSettingsUpdateInProgress ] = useState(false)

  async function uploadSettings () {
    setColumnSettingsUpdateInProgress(true)
    try {
      console.log(columnSettings)

      await API.viewSettingsAPI.saveApplicationViewSettings({ columnSettings })
      setSavedColumnSettings({...columnSettings})
      setShowColumnSettings(false)
    } catch (e) {
      console.log(e)
    }
    setColumnSettingsUpdateInProgress(false)
  }

  return <>
    <div className={cx('dropdown', s['dropdown'])}>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e) => {
          e.stopPropagation()
          setShowColumnSettings(!showColumnSettings)
          setShowFilter(false)
        }}
      >
        Столбцы
      </button>
      <div
        className={cx('dropdown-menu', {[s['dropdown-menu-opened']]: showColumnSettings})}
        aria-labelledby="dropdownMenuButton"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <ColumnOption field='ID' checked={columnSettings.id} onChange={() => { setColumnOption('id', !columnSettings.id) }} />
        <ColumnOption field='Номер заявки' checked={columnSettings.number} onChange={() => { setColumnOption('number', !columnSettings.number) }} />
        <ColumnOption field='Статус заявки' checked={columnSettings.status_id} onChange={() => { setColumnOption('status_id', !columnSettings.status_id) }} />
        <ColumnOption field='Дата регистрации' checked={columnSettings.dateReg} onChange={() => { setColumnOption('dateReg', !columnSettings.dateReg) }} />
        <ColumnOption field='Наименование заявителя' checked={columnSettings.name} onChange={() => { setColumnOption('name', !columnSettings.name) }} />
        <ColumnOption field='Телефон' checked={columnSettings.phone} onChange={() => { setColumnOption('phone', !columnSettings.phone) }} />
        <ColumnOption field='Оплата по договору' checked={columnSettings.paycheck} onChange={() => { setColumnOption('paycheck', !columnSettings.paycheck) }} />
        <ColumnOption field='Адрес объекта' checked={columnSettings.address} onChange={() => { setColumnOption('address', !columnSettings.address) }} />
        <ColumnOption field='Документы' checked={columnSettings.docs} onChange={() => { setColumnOption('docs', !columnSettings.docs) }} />
        <ColumnOption field='Комментарий' checked={columnSettings.comments} onChange={() => { setColumnOption('comments', !columnSettings.comments) }} />
        <ColumnOption field='Способ получения договора' checked={columnSettings.aggrementtype} onChange={() => { setColumnOption('aggrementtype', !columnSettings.aggrementtype) }} />
        <ColumnOption field='Максимальная мощность' checked={columnSettings.maxpower} onChange={() => { setColumnOption('maxpower', !columnSettings.maxpower) }} />
        <ColumnOption field='Категория надёжности' checked={columnSettings.category} onChange={() => { setColumnOption('category', !columnSettings.category) }} />
        <ColumnOption field='Уровень напряжения' checked={columnSettings.voltage} onChange={() => { setColumnOption('voltage', !columnSettings.voltage) }} />
        <ColumnOption field='Существующая мощность' checked={columnSettings.oldpower} onChange={() => { setColumnOption('oldpower', !columnSettings.oldpower) }} />
        <ColumnOption field='Присоединяемая мощность' checked={columnSettings.newpower} onChange={() => { setColumnOption('newpower', !columnSettings.newpower) }} />
        <ColumnOption field='Причина обращения' checked={columnSettings.reasons} onChange={() => { setColumnOption('reasons', !columnSettings.reasons) }} />
        <ColumnOption field='Наименование объекта заявителя/присоединения' checked={columnSettings.connectionobject} onChange={() => { setColumnOption('connectionobject', !columnSettings.connectionobject) }} />
      </div>
    </div>
    {!isEqual(columnSettings, savedColumnSettings) && <Button
      className="btn btn-success"
      onClick={(e) => {
        e.stopPropagation()
        uploadSettings()
      }}
      loading={columnSettingsUpdateInProgress}
      disabled={columnSettingsUpdateInProgress}
    >Сохранить</Button>}
  </>
}

export default ColumnSettings