import BasicAPI from "./BasicAPI";

class AdminAPI extends BasicAPI {
  constructor(prefix) {
    super();
    this.prefix = prefix;
  }

  getVotes () {
    return this.sendRequest('/admin/getPollsResult');
  }
  getLog (data) {
    return this.sendRequest('/admin/getLog', data);
  }
  getPermissions () {
    return this.sendRequest('/admin/getPermissions')
  }
  getTextBlock (data) {
    return this.sendRequest('/admin/getTextBlock', data)
  }
  updateTextBlock (data) {
    return this.sendRequest('/admin/updateTextBlock', data)
  }
  uploadFile (data) {
    return this.sendRequest('/admin/uploadFile', data)
  }
  getCalcs () {
    return this.sendRequest('/admin/getCalcs')
  }
  updateCalcs (data) {
    return this.sendRequest('/admin/updateCalcs', data)
  }
  getSetting (data) {
    return this.sendRequest('/admin/getSetting', data)
  }
  setSetting (data) {
    return this.sendRequest('/admin/setSetting', data)
  }
}

export default AdminAPI