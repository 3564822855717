import React, { Component } from 'react';
import s from './Button.module.css';
import cx from 'classnames';
import { Link } from 'react-router-dom';

class Button extends Component {
	render() {
		const {
			children, disabled, onClick, className, loading, to, type
		} = this.props;

		let onBtnClick = () => true;
		if (!disabled)
			onBtnClick = onClick;

		if (to) {
			return (<Link
				className={cx(s['button'], {[s['disabled']]: disabled}, {[s['loading']]: loading}, className)}
				onClick={onBtnClick}
				to={to}
			>
				{loading && <span className={cx('spinner-grow', 'spinner-grow-sm', s['spinner'])} role="status" aria-hidden="true"></span>}
				<span>{children}</span>
			</Link>);
		} else {
			return (
				<button
					type={type ? type : 'button'}
					className={cx(s['button'], {[s['disabled']]: disabled}, {[s['loading']]: loading}, className)}
					onClick={onBtnClick}
				>
					{loading && <span className={cx('spinner-grow', 'spinner-grow-sm', s['spinner'])} role="status" aria-hidden="true"></span>}
					<span>{children}</span>
				</button>
			);
		}
	}
}

export default Button;
