import React, {useContext} from "react";
import {AuthContext} from "../../../../../lib/AuthContext";

function DictionaryItem (props) {
  const {item, fields, openPopup} = props
  const { authContext } = useContext(AuthContext)

  return (
    <tr>
      {fields.map(value => <td className="text-center" key={value}>{typeof item[value] !== 'object' ? item[value] : ""}</td>)}
      <td className="text-center">
        <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
        {authContext.superadmin && <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>}
      </td>
    </tr>
  )
}

export default DictionaryItem