import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import {parseFloat0, showCategories, zeroEqualsEmpty} from '../lib/helpers';
import {Decimal} from 'decimal.js';
import NumberFormat from 'react-number-format';
import {ApplicationContext} from "../lib/ApplicationContext";

function TabDevices (props) {
  const {appData, dispatch} = useContext(ApplicationContext)
  const data = appData.connectiontechspecs

  const errors = appData.errors.errors
  const reasons = {}
  props.reasons.forEach(reason => reasons[reason.value] = reason)

  function customSetValue(field, value) {
    let priceCategory = data.priceCategory;

    data[field] = value;
    //this.setValue(field, value);

    if (!((data.category2 || data.category3)
      && (
        (appData.type === 'fiz' && Number(data.powerTotal.power) < 15)
        || (
          (appData.type === 'ip' || appData.type === 'ur')
          && Number(data.powerTotal.power) < 150
        )
      ))) {
      priceCategory = "";
    }

    dispatch({
      type: "setConnectionTechSpecsFields",
      payload: [
        {field, value},
        {field: "priceCategory", value: priceCategory},
      ]
    })
  }

  function setCategoryValue(field, value) {
    if (value.trim() !== '')
      value = Math.abs(value);

    const powerTotal = data.powerTotal;
    const powerNew = data.powerNew;
    const powerOld = data.powerOld;

    //if (value == 0)
    //  value = "";
    data[field] = value;
    if (!data.category1 && !data.category2) {
      powerTotal.point1 = "";
      powerTotal.point2 = "";
      powerNew.point1 = "";
      powerNew.point2 = "";
      powerOld.point1 = "";
      powerOld.point2 = "";
    }

    dispatch({
      type: "setConnectionTechSpecsFields",
      payload: [
        {field, value},
        {field: "powerTotal", value: powerTotal},
        {field: "powerNew", value: powerNew},
        {field: "powerOld", value: powerOld},
      ]
    })
  }

  function setPowerValue(powerType, key, value) {
    if (value.trim() !== '')
      value = Math.abs(value);

    const power = data[powerType];
    const powerTotal = data.powerTotal;

    power[key] = value;

    if (key === 'point1' || key === 'point2') {
      power.power = (new Decimal(parseFloat0(power.point1)).plus(new Decimal(parseFloat0(power.point2))));
      powerTotal.point1 = new Decimal(parseFloat0(data.powerNew.point1)).plus(parseFloat0(data.powerOld.point1));
      powerTotal.point2 = new Decimal(parseFloat0(data.powerNew.point2)).plus(parseFloat0(data.powerOld.point2));
    }

    if (isMicro) {
      const powerMicroTotal = data.powerMicroTotal;
      powerMicroTotal.power = new Decimal(parseFloat0(data.powerMicroNew.power)).plus(new Decimal(parseFloat0(data.powerMicroOld.power)));
      customSetValue('powerMicroTotal', powerMicroTotal);
    }

    powerTotal.power = new Decimal(parseFloat0(data.powerNew.power)).plus(new Decimal(parseFloat0(data.powerOld.power)));

    customSetValue('powerTotal', powerTotal);
    customSetValue(powerType, power);
  }

  const isMicro = (reasons[appData.connectiontype]?.system === 'micro' || (reasons[appData.connectiontype]?.system === 'microcat3'))
  const showOkved = (appData.type !== 'fiz' && reasons[appData.connectiontype]?.system === 'microcat3')

  return <div className="col-12">
    {isMicro && <>
    <div className="form-group">
      <div className="h5">Максимальная мощность объектов микрогенерации (присоединяемых и ранее присоединенных)</div>
    </div>

    <div className="form-group">
      <div>
        <label>Категория надежности <span className={s['red-star']}>*</span></label>
      </div>

        <div className={cx('form-group', s['category'], 'mt-1')}>
          <label htmlFor="category3">III категория</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.category})}
            id="category3"
            value={data.microCategory3.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setCategoryValue('microCategory3', e.currentTarget.value.replaceAll(",", "."))
            }}
          />

          <label>кВт</label>
        </div>
      </div>

      <div className="row">
        <div className={cx('form-group', 'col-6', s['power-block'])}>
          <label htmlFor="powerMicroOldPower">Максимальная мощность ранее присоединенных объектов микрогенерации, кВт.</label>

          <div className="form-row">
            <NumberFormat
              min="0"
              decimalSeparator=","
              className={cx("form-control")}
              id="powerMicroOldPower"
              value={data.powerMicroOld.power.toString().replaceAll(".", ",")}
              onChange={(e) => {
                setPowerValue('powerMicroOld', 'power', e.currentTarget.value.replaceAll(",", "."))
              }}
            />
          </div>
        </div>

        <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
          <label>При напряжении, кВ</label>

          <select
            className="custom-select custom-select--voltage"
            value={data.powerMicroOld.voltage}
            onChange={(e) => {
              setPowerValue('powerMicroOld', 'voltage', e.currentTarget.value)
            }}
          >
            <option value="0.23">0,23 кВ</option>
            <option value="0.4">0,4 кВ</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className={cx('form-group', 'col-6', s['power-block'])}>
          <label htmlFor="powerMicroNewPower">Максимальная мощность присоединяемых объектов микрогенерации, кВт.<span className={s['red-star']}>*</span></label>

          <div className="form-row">
            <NumberFormat
              min="0"
              decimalSeparator=","
              className={cx("form-control", {[s['error-field']]: errors.powerMicroNewPower})}
              id="powerMicroNewPower"
              value={data.powerMicroNew.power.toString().replaceAll(".", ",")}
              onChange={(e) => {
                setPowerValue('powerMicroNew', 'power', e.currentTarget.value.replaceAll(",", "."))
              }}
            />
          </div>
        </div>

        <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
          <label>При напряжении, кВ <span className={s['red-star']}>*</span></label>

          <select
            className="custom-select custom-select--voltage"
            value={data.powerMicroNew.voltage}
            onChange={(e) => {
              setPowerValue('powerMicroNew', 'voltage', e.currentTarget.value)
            }}
          >
            <option value="0.23">0,23 кВ</option>
            <option value="0.4">0,4 кВ</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className={cx('form-group', 'col-6', s['power-block'])}>
          <label htmlFor="powerMicroTotalPower">Максимальная мощность (присоединяемых и ранее присоединенных) объектов микрогенерации, кВт.
            Мощность микрогенерации не должна превышать 15 кВ<span className={s['red-star']}>*</span></label>

          <div className="form-row">
            <input
              className={cx("form-control", {[s['error-field']]: errors.powerMicroTotalPower})}
              id="powerMicroTotalPower"
              value={data.powerMicroTotal.power.toString().replaceAll(".", ",")}
              readOnly={true}
            />
          </div>
          {errors.powerMicroTotalPower && <sup className="bg-danger text-white">{errors.powerMicroTotalPower}</sup>}
        </div>
        <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
          <label>При напряжении, кВ <span className={s['red-star']}>*</span></label>

          <select
            className="custom-select custom-select--voltage"
            value={data.powerMicroTotal.voltage}
            onChange={(e) => {
              setPowerValue('powerMicroTotal', 'voltage', e.currentTarget.value)
            }}
          >
            <option value="0.23">0,23 кВ</option>
            <option value="0.4">0,4 кВ</option>
          </select>
        </div>
      </div>
    </>}

    <div className="form-group">
      <div className="h5">Максимальная мощность энергопринимающих устройств (присоединяемых и ранее
        присоединенных)
      </div>
    </div>

    <div className="form-group">
      <div>
        <label>Категория надежности <span className={s['red-star']}>*</span></label>
      </div>

      {showCategories(appData, reasons) && <>
        <div className={cx('form-group', s['category'], 'mt-1')}>
          <label htmlFor="category1">I категория</label>
          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.category})}
            id="category1"
            value={data.category1.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setCategoryValue('category1', e.currentTarget.value.replaceAll(",", "."))
            }}
          />

          <label>кВт</label>
        </div>

        <div className={cx('form-group', s['category'], 'mt-1')}>
          <label htmlFor="category2">II категория</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.category})}
            id="category2"
            value={data.category2.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setCategoryValue('category2', e.currentTarget.value.replaceAll(",", "."))
            }}
          />

          <label>кВт</label>
        </div>
      </>}
      <div className={cx('form-group', s['category'], 'mt-1')}>
        <label htmlFor="category3">III категория</label>

        <NumberFormat
          min="0"
          decimalSeparator=","
          className={cx("form-control", {[s['error-field']]: errors.category})}
          id="category3"
          value={data.category3.toString().replaceAll(".", ",")}
          onChange={(e) => {
            setCategoryValue('category3', e.currentTarget.value.replaceAll(",", "."))
          }}
        />

        <label>кВт</label>
      </div>
    </div>

    <div className="row">
      <div className={cx('form-group', 'col-6', s['power-block'])}>
        <label htmlFor="powerOldPower">Максимальная мощность ранее присоединенных в данной точке присоединения энергопринимающих устройств, кВт</label>

        <div className="form-row">
          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control")}
            id="powerOldPower"
            value={data.powerOld.power?.toString().replaceAll(".", ",")}
            readOnly={(data.category1 || data.category2)}
            onChange={(e) => {
              setPowerValue('powerOld', 'power', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>

      <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
        <label>При напряжении, кВ</label>

        <select
          className="custom-select custom-select--voltage"
          value={data.powerOld.voltage}
          onChange={(e) => {
            setPowerValue('powerOld', 'voltage', e.currentTarget.value)
          }}
        >

          <option value="0.23">0,23 кВ</option>
          <option value="0.4">0,4 кВ</option>

          {!isMicro && <>
          <option value="6">6 кВ</option>
          <option value="10">10 кВ</option>+
          </>}
        </select>
      </div>
    </div>

    {(data.category1 || data.category2) && <div className="form-group form-row align-items-end">
      <div className="col-6">
        <div><label>С распределением по точкам присоединения:</label></div>

        <div>
          <label htmlFor="powerOldPoint1">Точка присоединения (1), кВт</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.powerOldPoint1})}
            id="powerOldPoint1"
            value={data.powerOld.point1.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setPowerValue('powerOld', 'point1', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>

      <div className="col-6">
        <div>
          <label htmlFor="powerOldPoint2">Точка присоединения (2), кВт</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.powerOldPoint2})}
            id="powerOldPoint2"
            value={data.powerOld.point2.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setPowerValue('powerOld', 'point2', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>
    </div>}

    <div className="row">
      <div className={cx('form-group', 'col-6', s['power-block'])}>
        <label htmlFor="powerNewPower">Максимальная мощность присоединяемых энергопринимающих устройств, кВт<span className={s['red-star']}>*</span></label>

        <div className="form-row">
          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.powerNewPower})}
            id="powerNewPower"
            value={data.powerNew.power.toString().replaceAll(".", ",")}
            readOnly={(data.category1 || data.category2)}
            onChange={(e) => {
              setPowerValue('powerNew', 'power', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>

      <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
        <label>При напряжении, кВ <span className={s['red-star']}>*</span></label>

        <select
          className="custom-select custom-select--voltage"
          value={data.powerNew.voltage}
          onChange={(e) => {
            setPowerValue('powerNew', 'voltage', e.currentTarget.value)
          }}
        >

            <option value="0.23">0,23 кВ</option>
            <option value="0.4">0,4 кВ</option>

          {!isMicro && <>
            <option value="6">6 кВ</option>
            <option value="10">10 кВ</option>+
          </>}
        </select>
      </div>
    </div>

    {(data.category1 || data.category2) && <div className="form-group form-row align-items-end">
      <div className="col-6">
        <div><label>С распределением по точкам присоединения:</label></div>

        <div>
          <label htmlFor="powerNewPoint1">Точка присоединения (1), кВт <span className={s['red-star']}>*</span></label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.powerNewPoint1})}
            id="powerNewPoint1"
            value={data.powerNew.point1?.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setPowerValue('powerNew', 'point1', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>

      <div className="col-6">
        <div>
          <label htmlFor="powerNewPoint2">Точка присоединения (2), кВт <span className={s['red-star']}>*</span></label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control", {[s['error-field']]: errors.powerNewPoint2})}
            id="powerNewPoint2"
            value={data.powerNew.point2?.toString().replaceAll(".", ",")}
            onChange={(e) => {
              setPowerValue('powerNew', 'point2', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>
    </div>}


    <div className="row">
      <div className={cx('form-group', 'col-6', s['power-block'])}>
        <label htmlFor="powerTotalPower">Максимальная мощность устройств (присоединяемых и ранее присоединенных)
          энергопринимающих устройств , кВт <span className={s['red-star']}>*</span></label>

        <div className="form-row">
          <input
            className={cx("form-control", {[s['error-field']]: errors.powerTotalPower})}
            id="powerTotalPower"
            value={data.powerTotal.power?.toString().replaceAll(".", ",")}
            readOnly={true}
          />
        </div>
        {errors.powerTotalPower && <sup className="bg-danger text-white">{errors.powerTotalPower}</sup>}
      </div>
      <div className={cx('form-group', 'form-row ', 'col-6', s['power-block'])}>
        <label>При напряжении, кВ <span className={s['red-star']}>*</span></label>

        <select
          className="custom-select custom-select--voltage"
          value={data.powerTotal.voltage}
          onChange={(e) => {
            setPowerValue('powerTotal', 'voltage', e.currentTarget.value)
          }}
        >

          <option value="0.23">0,23 кВ</option>
          <option value="0.4">0,4 кВ</option>

          {!isMicro && <>
            <option value="6">6 кВ</option>
            <option value="10">10 кВ</option>+
          </>}
        </select>
      </div>
    </div>

    {(data.category1 || data.category2) && <div className="form-group form-row align-items-end">
      <div className="col-6">
        <div><label>С распределением по точкам присоединения:</label></div>

        <div>
          <label htmlFor="powerTotalPoint1">Точка присоединения (1), кВт</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control")}
            id="powerTotalPoint1"
            value={data.powerTotal.point1?.toString().replaceAll(".", ",")}
            readOnly={(data.category1 || data.category2)}
            onChange={(e) => {
              setPowerValue('powerTotal', 'point1', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>

      <div className="col-6">
        <div>
          <label htmlFor="powerTotalPoint2">Точка присоединения (2), кВт</label>

          <NumberFormat
            min="0"
            decimalSeparator=","
            className={cx("form-control")}
            id="powerTotalPoint2"
            value={data.powerTotal.point2?.toString().replaceAll(".", ",")}
            readOnly={(data.category1 || data.category2)}
            onChange={(e) => {
              setPowerValue('powerTotal', 'point2', e.currentTarget.value.replaceAll(",", "."))
            }}
          />
        </div>
      </div>
    </div>}
    <div className="form-group form-row">
      <div className="col-6">
        <label>Характер нагрузки {appData.type !== 'fiz' && <span className={s['red-star']}>*</span>}</label>

        <select
          className={cx("custom-select", {[s['error-field']]: errors.character})}
          value={data.character}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "character", value: e.currentTarget.value}
          })}
        >
          <option value="empty">не выбрано</option>
          <option value="generators">для генераторов</option>
          <option value="lighting">осветительная</option>
          <option value="power">силовая</option>
          <option value="production">для производственной деятельности</option>
        </select>
      </div>
    </div>

    {data.powerTotal.power >= 150 && <div>
      <div className="form-group">
        <label htmlFor="connectiontechspecsArmor">Необходимость наличия технологической и (или) аварийной брони:</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsArmor"
          value={data.connectiontechspecsarmor}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "connectiontechspecsarmor", value: e.currentTarget.value}
          })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="connectiontechspecsArmorpower">Величина и обоснование технологической и аварийной брони:</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsArmorpower"
          value={data.connectiontechspecsarmorpower}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "connectiontechspecsarmorpower", value: e.currentTarget.value}
          })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="connectiontechspecsTransformer">Количество и мощность присоединяемых к сети трансформаторов,
          кВ:</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsTransformer"
          value={data.connectiontechspecstransformer}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "connectiontechspecstransformer", value: e.currentTarget.value}
          })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="connectiontechspecsGenerator">Количество и мощность генераторов:</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsGenerator"
          value={data.connectiontechspecsgenerator}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "connectiontechspecsgenerator", value: e.currentTarget.value}
          })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="connectiontechspecsCapacity">Заявляемый характер нагрузки (для генераторов - возможная скорость
          набора или снижения нагрузки) и наличие нагрузок, искажающих форму кривой электрического тока и вызывающих
          несимметрию напряжения в точках
          присоединения</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsCapacity"
          value={data.connectiontechspecscapacity}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "connectiontechspecscapacity", value: e.currentTarget.value}
          })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="connectiontechspecsTechMin">Величина и обоснование величины технологического минимума (для
          генераторов)</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsTechMin"
          value={data.techmin}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "techmin", value: e.currentTarget.value}
          })}
        />
      </div>
    </div>}

    {(data.category2 || data.category3)
    && (
      (appData.type === 'fiz' && Number(data.powerTotal.power) < 15)
      || (
        (appData.type === 'ip' || appData.type === 'ur')
        && Number(data.powerTotal.power) < 150
      )
    ) && <div className="form-group form-row">
      <div className="col-12">
        <label htmlFor="connectiontechspecsPriceCategory">Выбор ценовой категории, условий почасового планирования
          потребления электрической энергии и иных существенных условий договора, обеспечивающего продажу электрической
          энергии (мощности) на розничном рынке,
          предусмотреных основными положениями функционирования розничных рынков электрической энергии</label>

        <input
          type="text"
          className={cx("form-control")}
          id="connectiontechspecsPriceCategory"
          value={data.priceCategory}
          onChange={e => dispatch({
            type: "setConnectionTechSpecsField",
            payload: {field: "priceCategory", value: e.currentTarget.value}
          })}
        />
      </div>
    </div>}
    {isMicro && <>
      <div className="form-group form-row">
        <div className="col-6">
          <label htmlFor="microgenerators_count">Количество микрогенераторов, шт.</label>
          <NumberFormat
            min="0"
            className={cx("form-control")}
            id="microgenerators_count"
            value={data.microgenerators_count}
            onChange={e => dispatch({
              type: "setConnectionTechSpecsField",
              payload: {field: "microgenerators_count", value: e.currentTarget.value}
            })}
          />
        </div>
      </div>
      <div className="form-group form-row">
        <div className="col-6">
          <label htmlFor="microgenerators_power">Мощность микрогенераторов, кВт</label>
          <NumberFormat
            min="0"
            className={cx("form-control")}
            id="microgenerators_power"
            value={data.microgenerators_power}
            onChange={e => dispatch({
              type: "setConnectionTechSpecsField",
              payload: {field: "microgenerators_power", value: e.currentTarget.value}
            })}
          />
        </div>
      </div>
      <div className="form-group form-row">
        <div className="col-12">
          <label htmlFor="microgenerators_load_speed">Возможная скорость набора или снижения нагрузки для объектов
            микрогенерации в соответствии с паспортными характеристиками</label>
          <textarea
            className={cx("form-control")}
            id="microgenerators_load_speed"
            value={data.microgenerators_load_speed}
            onChange={e => dispatch({
              type: "setConnectionTechSpecsField",
              payload: {field: "microgenerators_load_speed", value: e.currentTarget.value}
            })}
          />
        </div>
      </div>
    </>}
    {showOkved && <>
      <div className="form-group form-row">
        <div className="col-12">
          <label htmlFor="okved">ОКВЭД</label>

          <input
            type="text"
            className={cx("form-control")}
            id="okved"
            value={data.okved}
            onChange={e => dispatch({
              type: "setConnectionTechSpecsField",
              payload: {field: "okved", value: e.currentTarget.value}
            })}
          />
        </div>
      </div>
    </>}
  </div>

}

export default TabDevices;