import React, {useContext, useEffect, useReducer, useRef, useState} from 'react';
import s from "../components/ApplicationRequest.module.css";
import cx from "classnames";
import Button from "../../../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft, faCheck} from "@fortawesome/free-solid-svg-icons";

import {copyDefinedPropertiesValues, getTypeLabel} from "../lib/helpers";
import {Link} from "react-router-dom";
import API from "../../../lib/API";

import TabHeader from "../components/TabHeader";
import ErrorsTab from '../components/ErrorsTab';
import TabPersonal from "../components/TabPersonal";
import TabPersonalId from "../components/TabPersonalId";
import TabContactData from "../components/TabContactData";
import TabAddress from "../components/TabAddress";
import TabConnectionType from "../components/TabConnectionType";
import TabConnectionAddress from "../components/TabConnectionAddress";
import TabDevices from "../components/TabDevices";
import TabDeadlines from "../components/TabDeadlines";
import TabAdditionalData from "../components/TabAdditionalData";
import TabOtherInfo from "../components/TabOtherInfo";
import {AuthContext} from "../../../lib/AuthContext";
import {ApplicationContext} from "../lib/ApplicationContext";
import ApplicationService from "./ApplicationService";
import {PopupContext} from "../../../lib/PopupContext";

const appDataService = new ApplicationService()

function TechnicalApplicationRequest (props) {
	const [loading, setLoading] = useState(true)
	const [page, setPage] = useState(1)
	const [statusSuccessEdit, setStatusSuccessEdit] = useState(false)
	const [statusSuccessDraft, setStatusSuccessDraft] = useState(false)
	const [statusSuccess, setStatusSuccess] = useState(false)
	const [loadRequest, setLoadRequest] = useState(false)
	const [serverErrors, setServerErrors] = useState({})
	const [reasons, setReasons] = useState([])
	const [devices, setDevices] = useState([])
	const [towns, setTowns] = useState([])
	const { close, typeRequest } = props;

	const { authContext } = useContext(AuthContext)
	const userData = props.userData ? props.userData : authContext.userData

	const { setPopupSettings } = useContext(PopupContext)

	const requestByAdmin = typeRequest === 'createRequest',
		requestEditByAdmin = typeRequest === 'editRequest';
	const [ appData, dispatch ] = useReducer(appDataService.reducer.bind(appDataService), appDataService.initialData);
	//console.log('reducer', appData)

	useEffect(() => {
		const fetchData = async () => {
			let draft, reasons, devices, towns
			try {
				await Promise.all([
					API.applicationsAPI.getDraft().then(response => {
						draft = response
						Object.keys(draft).forEach(key => {
							if (String(draft[key]).toLowerCase() === 'false')
								draft[key] = false
						})
					}),
					API.requestReasonsAPI.get().then(response => {
						reasons = response.data.sort((a, b) => (a.order < b.order ? -1 : 1))
						reasons = reasons.map(option => ({value: option.id, label: option.description, system: option.system}))
					}),
					API.devicesAPI.get().then(response => {
						devices = response.data.sort((a, b) => (a.order < b.order ? -1 : 1))
						devices = devices.map(option => ({value: option.id, label: option.description}))
					}),
					API.departmentsAPI.getTowns().then(response => {
						towns = response.map(option => ({
							value: option,
							label: (option.city_type && option.city_type + ' ') + option.name
						}));
					})
				])

				setReasons(reasons)
				setDevices(devices)
				setTowns(towns)

				let combinedData
				if (requestByAdmin || requestEditByAdmin) {
					combinedData = userData
				} else {

					const newUserData = await API.authAPI.checkUser();
					userData.personal = newUserData.userData.personal;

					const personal = userData.personal ? userData.personal : userData
					combinedData = {...(personal ? personal : {}), ...draft}
				}
				//console.log('useEffect', combinedData)
				//if userData is set get user type from it, no matter the draft or personal data
				if (userData.type)
					combinedData.type = userData.type;

				if (requestByAdmin || requestEditByAdmin)
					combinedData.person = true
				//appDataService.applicationData = combinedData
				dispatch({type: "init", payload: {appData: combinedData, reasons}})

				/*
        if (cleared)
          return {type: appData.type};
        else
          return appData;
        */

			} catch (e) {
				console.error(e);
				setPopupSettings({
					type: 'info',
					error: true,
					text: "Ошибка загрузки. Проверьте сетевое соединение и повторите попытку позже."
				})
			}

			setLoading(false)
		}

		fetchData()

	}, [])

	function clear() {
		const data = appDataService.initialData
		data.type = userData?.type
		dispatch({type: "init", payload: {appData: appDataService.initialData}})
	}

	async function reset() {
		try {
			const userData = (await API.authAPI.checkUser()).userData
			const combinedData = userData.personal ? userData.personal : {}

			//if userData is set get user type from it, no matter the draft or personal data
			if (userData.type)
				combinedData.type = userData.type;

			dispatch({type: "init", payload: {appData: combinedData}})

			setPage(1)
			setStatusSuccessEdit(false)
			setStatusSuccessDraft(false)
			setStatusSuccess(false)
			setLoadRequest(false)
			setServerErrors({})
		} catch (e) {
			console.error(e)
			setServerErrors(e.response.errors ?? {})
		}
	}

	async function sendApplication(action) {
		setLoadRequest(true)

		const data = appDataService.prepareData(appData, {reasons, devices})

		try {
				switch (action) {
					case 'new':
						if (typeRequest && typeRequest === 'createRequest') {
							data.user_id = userData.id;
						}

						await API.applicationsAPI.make(data);

						setStatusSuccessDraft(false)
						setStatusSuccess(true)

						break;
					case 'draft':
						data.draft = 1;
						await API.applicationsAPI.make(data);

						setStatusSuccessDraft(false)
						setPopupSettings({
							type: 'info',
							text: 'Черновик сохранен'
						})
						break;
					case 'update':
						data.id = props.userData.id;
						await API.applicationsAPI.update(data);
						props.setEditApplication(null)
						//setStatusSuccess(true)

						break;
					default:
						break;
				}

				//const { onSuccess } = props;
				//typeRequest === 'editRequest' && onSuccess && await onSuccess()

			} catch (e) {
				console.error(e)
				setServerErrors(e.response.errors ?? {})
			}

		setLoadRequest(false)
	}

	function calcCompletionPercent () {
		const allFields = Object.keys(appData).length - 1
		const errFields = appData.errors ? Object.keys(appData.errors.errors).length : allFields
		return Math.round((allFields - errFields) / (allFields) * 100)
	}

	const	isDisableSubmit = appData.errors && Object.keys(appData.errors.errors).length > 0

	const main = useRef()

	if (!authContext.admin && authContext.tpAppsIsOff)
		return <div className={s['main']}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						Подача новых заявок на технологическое подключение не доступна
					</div>
				</div>
			</div>
		</div>

	return <ApplicationContext.Provider value={{appData, dispatch}}>
		<div className={s['main']} ref={main}>
			{appData.errors && <ErrorsTab percent={calcCompletionPercent()} validationErrors={appData.errors.errors} serverErrors={serverErrors}/>}
			<div className={s['technical']}>
				<div className={cx('h2', 'text-center', s['title'])}>
					<div>Заявка на технологическое подключение</div>
					{requestByAdmin && userData && userData.email && <div>для пользователя {userData.email}</div>}
				</div>
				<div className={cx('container-fluid', s['container'])}>
					<div className="row">
						<div className="col-12">
							{(requestByAdmin || requestEditByAdmin) && close && <Button className="btn btn-primary mb-3" onClick={close}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Назад</Button>}
						</div>
					</div>
					{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
						<span className="sr-only">Загрузка...</span>
					</div>}
					{!loading && !statusSuccess && <div className="row">
						<div className="col-12">
							<div className="form-group">
								<div className="custom-control custom-radio custom-control-inline">
									<label className="custom-control-label">{getTypeLabel(appData.type)}</label>
								</div>
							</div>
							<div className="alert alert-warning" role="alert"><span className={s['red-star']}>*</span> - Поля обязательные для заполнения</div>
						</div>

						<div className="col-12">
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<TabHeader
										label={(appData.type === 'fiz' || appData.type === 'ip') ? "Данные заявителя" : "Сведения об организации"}
										active={page === 1}
										valid={appData.errors.errorTabs.indexOf('TabPersonal') < 0}
										error={false}
										setPage={() => setPage(1)}
									/>
								</li>
								{(appData.type === 'fiz' || appData.type === 'ip') && <li className="nav-item">
									<TabHeader
										label="Документ, удостоверяющий личность"
										active={page === 2}
										valid={appData.errors.errorTabs.indexOf('TabPersonalId') < 0}
										error={false}
										setPage={() => setPage(2)}
									/>
								</li>}
								<li className="nav-item">
									<TabHeader
										label="Контактные данные"
										active={page === 3}
										valid={appData.errors.errorTabs.indexOf('TabContactData') < 0}
										error={false}
										setPage={() => setPage(3)}
									/>
								</li>
								{(appData.type === 'fiz' || appData.type === 'ip') && <>
									<li className="nav-item">
										<TabHeader
											label="Адрес регистрации"
											active={page === 4}
											valid={appData.errors.errorTabs.indexOf('TabRegAddress') < 0}
											error={false}
											setPage={() => setPage(4)}
										/>
									</li>
									<li className="nav-item">
										<TabHeader
											label="Адрес места жительства"
											active={page === 5}
											valid={appData.errors.errorTabs.indexOf('TabLiveAddress') < 0}
											error={false}
											setPage={() => setPage(5)}
										/>
									</li>
								</>}
								{(appData.type === 'ur') && <>
									<li className="nav-item">
										<TabHeader
											label="Юридический адрес"
											active={page === 4}
											valid={appData.errors.errorTabs.indexOf('TabUrAddress') < 0}
											error={false}
											setPage={() => setPage(4)}
										/>
									</li>
									<li className="nav-item">
										<TabHeader
											label="Почтовый адрес"
											active={page === 5}
											valid={appData.errors.errorTabs.indexOf('TabPostAddress') < 0}
											error={false}
											setPage={() => setPage(5)}
										/>
									</li>
								</>}
								<li className="nav-item">
									<TabHeader
										label="Вид присоединения"
										active={page === 6}
										valid={appData.errors.errorTabs.indexOf('TabConnectionType') < 0}
										error={false}
										setPage={() => setPage(6)}
									/>
								</li>
								<li className="nav-item">
									<TabHeader
										label="Адрес объекта"
										active={page === 7}
										valid={appData.errors.errorTabs.indexOf('TabConnectionAddress') < 0}
										error={false}
										setPage={() => setPage(7)}
									/>
								</li>

								<li className="nav-item">
									<TabHeader
										label="Энергопринимающие устройства"
										active={page === 8}
										valid={appData.errors.errorTabs.indexOf('TabDevices') < 0}
										error={false}
										setPage={() => setPage(8)}
									/>
								</li>

								<li className="nav-item">
									<TabHeader
										label="Сроки и этапы"
										active={page === 9}
										valid={appData.errors.errorTabs.indexOf('TabDeadlines') < 0}
										error={false}
										setPage={() => setPage(9)}
									/>
								</li>

								<li className="nav-item">
									<TabHeader
										label="Документы"
										active={page === 10}
										valid={appData.errors.errorTabs.indexOf('TabAdditionalData') < 0}
										error={false}
										setPage={() => setPage(10)}
									/>
								</li>

								<li className="nav-item">
									<TabHeader
										label="Прочие сведения"
										active={page === 11}
										valid={appData.errors.errorTabs.indexOf('TabOtherInfo') < 0}
										error={false}
										setPage={() => setPage(11)}
									/>
								</li>
							</ul>
						</div>

						{page === 1 && <TabPersonal/>}

						{page === 2 && (appData.type === 'fiz' || appData.type === 'ip') && <TabPersonalId/>}

						{page === 3 && <TabContactData/>}

						{page === 4  && (appData.type === 'fiz' || appData.type === 'ip') && <TabAddress
							header="Адрес регистрации"
							field="regaddress"
						/>}

						{page === 5 && (appData.type === 'fiz' || appData.type === 'ip') && <TabAddress
							header="Адрес места жительства"
							field="liveaddress"
							sameAddressLabel="Совпадает с адресом регистрации"
						/>}

						{page === 4  && (appData.type === 'ur') && <TabAddress
							header="Юридический адрес"
							field="uraddress"
						/>}

						{page === 5  && (appData.type === 'ur') && <TabAddress
							header="Почтовый адрес"
							field="postaddress"
							sameAddressLabel="Совпадает с юридическим адресом"
						/>}

						{page === 6 && <TabConnectionType
							reasons={reasons}
							devices={devices}
						/>}

						{page === 7  && <TabConnectionAddress
							options={towns}
						 />}

						{page === 8 && <TabDevices reasons={reasons}/>}

						{page === 9 && <TabDeadlines reasons={reasons}/>}

						{page === 10 && <TabAdditionalData/>}

						{page === 11 && <TabOtherInfo/>}
					</div>}
				</div>
				{!loading && !statusSuccess && <div className="row">
					<div className="col-12">
						<nav>
							<ul className="pagination">
								<li className={cx('page-item', {'disabled': page === 1})}>
												<span className="page-link" onClick={() => {
													if (appData.type === 'ur' && page - 1 === 2) {
														page > 1 && setPage(1);
													} else {
														page > 1 && setPage(page - 1);
													}
													main.current.scrollTop = 0;
												}}>Назад</span>
								</li>
								<li className={cx('page-item', {'disabled': page === 11})}>
												<span className="page-link" onClick={() => {
													if (appData.type === 'ur' && page + 1 === 2) {
														setPage(3)
													} else {
														setPage(page + 1)
													}
													main.current.scrollTop = 0;
												}}>Далее</span>
								</li>
							</ul>
						</nav>
					</div>
				</div>}
				<div className="row">
					<div className="col-12">
						{statusSuccess && <div className="alert alert-success" role="alert">
										<span className="mr-2">Заявка на технологическое подключение отправлена. Вы можете отслеживать статус заявки в <Link to="/cabinet">
											личном кабинете
										</Link></span>

							<Button
								className={cx('btn', 'btn-success', s['btn-reset'])}
								disabled={loadRequest}
								loading={loadRequest}
								onClick={() => {reset()}}
							>Новая заявка</Button>
						</div>}

						{statusSuccessEdit && <div className="alert alert-success" role="alert">
							<span className="mr-2">Заявка на технологическое подключение изменена</span>
						</div>}

						{statusSuccessDraft && <div className="alert alert-success" role="alert">
							<span>Черновик сохранен</span>
						</div>}

						{!loading && !statusSuccess && !requestEditByAdmin && <Button
							className="btn btn-primary"
							loading={loadRequest}
							disabled={loadRequest}
							onClick={() => sendApplication('draft')}
						>Сохранить черновик</Button>}
						{!loading && !statusSuccess && !requestEditByAdmin && <Button
							className="btn btn-primary"
							disabled={isDisableSubmit || loadRequest}
							loading={loadRequest}
							onClick={() => sendApplication('new')}
						>Отправить заявку</Button>}

						{!loading && !statusSuccess && requestEditByAdmin && <Button
							className="btn btn-primary"
							disabled={isDisableSubmit || loadRequest}
							loading={loadRequest}
							onClick={() => sendApplication('update')}
						>Сохранить</Button>}

						{!loading && !statusSuccess && <Button
							className="btn btn-dark"
							disabled={loadRequest}
							onClick={() => {clear()}}
						>Очистить</Button>}
					</div>
				</div>
			</div>
		</div>
	</ApplicationContext.Provider>
}

export default TechnicalApplicationRequest