import React from "react";

function DictionaryItem (props) {
  const {item, fields, openPopup} = props;

  return (
    <tr>
      {fields.map(value => <td  className="text-center" key={value}>{item[value]}</td>)}
      <td className="text-center">
        <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
        {(!item.system) && <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
        }
      </td>
    </tr>
  );

}

export default DictionaryItem;