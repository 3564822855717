import React, {useContext, useEffect, useReducer, useState} from 'react'
import s from './Registration.module.css'
import API from '../../../lib/API'
import Button from '../../../components/Button'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import MaskedInput from 'react-text-mask'
import { validateSnils } from '../../../lib/utils'
import { getAccessToken, makeRandomString } from '../../../lib/utils'
import {AuthContext} from "../../../lib/AuthContext";

function Registration (props) {
	const	{ typeReg, close } = props

	const initialState = {
		type: 'fiz',
		email: '',
		snils: '',
		phone: '',
		lastname: '',
		name: '',
		secondname: '',
		egrnum: '',
		password: '',
		password_confirmation: '',
	}

	function validate (user) {
		const errorsValidation = {}

		if (user.type === 'fiz') {
			const numberSnils = user.snils.replace(/-/g, '').replace(/\s+/g, '')
			const {result, error} = validateSnils(numberSnils)

			if (!result)
				errorsValidation.snils = error.message
		}

		if (typeReg === 'adminEditUser')
			return errorsValidation

		if (!user.type.trim())
			errorsValidation.type = "Не выбран тип пользователя"

		if (!user.email.trim())
			errorsValidation.email = "Не указан email"
/*
		if (!user.phone.trim().length <= 1)
			errorsValidation.phone = "Не указан телефон"
*/
		if ((user.type === 'fiz' || user.type === 'ip') && !user.lastname.trim())
			errorsValidation.lastname = "Не указана фамилия"

		if (!user.name.trim()) {
			if (user.type === 'fiz' || user.type === 'ip')
				errorsValidation.name = "Не указано имя"
			else
				errorsValidation.name = "Не указано наименование"
		}

		if ((user.type === 'fiz' || user.type === 'ip') && !user.secondname.trim())
			errorsValidation.secondname = "Не указано отчество"

		if (user.type === 'ip' && !user.egrnum.trim())
			errorsValidation.egrnum = "Не указан ЕГРИП"

		if (user.type === 'ur' && !user.egrnum.trim())
			errorsValidation.egrnum = "Не указан ЕГРЮЛ"

		if (user.password !== user.password_confirmation) {
			errorsValidation.password = 'Пароли не совпадают'
			errorsValidation.password_confirmation = 'Пароли не совпадают'
		}

		return errorsValidation
	}

	function reducer(state, action) {
		switch (action.type) {
			case 'reset':
				return initialState
			case 'setField':
				state[action.payload.field] = action.payload.value
				setErrorsValidation(validate(state))
				return {...state}
			case 'setUser':
				state = Object.assign(state, action.payload)
				setErrorsValidation(validate(state))
				return state
			default:
				// A reducer must always return a valid state.
				// Alternatively you can throw an error if an invalid action is dispatched.
				return state
		}
	}

	const [ loading, setLoading ] = useState(false)
	const [ personalDataPermission, setPersonalDataPermission ] = useState('')
	const [ viewPassword, setViewPassword ] = useState(false)
	const [ viewPasswordConfirmation, setViewPasswordConfirmation ] = useState(false)
	const [ registered, setRegistered ] = useState(false)
	const [ errors, setErrors ] = useState({})
	const [ errorsValidation, setErrorsValidation ] = useState({})

	const [ user, dispatch ] = useReducer(reducer, initialState)

	const { authContext } = useContext(AuthContext)

	useEffect(() => {
		if (typeReg === 'adminEditUser') {
			setPersonalDataPermission(true)
			dispatch({type: 'setUser', payload: {...props.user, snils: props.user.egrnum}})
		}
		setErrorsValidation(validate(user))
	}, [])

	function handleChangeInput(field, value) {
		// if (typeof value === 'string') {
		// 	value = value.replace(/</g,'').replace(/>/g,'')
		// }

		// if (field === 'phone' && value)
		// 	value = value.match(/\d+/g).join('')

		dispatch({type: 'setField', payload: {field, value}})
	}
	
	function generateUser() {
		const user = {...initialState}
		user.email = makeRandomString(5)
		const password = makeRandomString(5)
		user.password = password
		user.password_confirmation = password
		
		dispatch({
			type: 'setUser',
			payload: user
		})
		
		setViewPassword(true)
		setViewPasswordConfirmation(true)
	}

	async function register(e) {
		//console.log(user)
		e.preventDefault()

		setLoading(true)

		const data = {...user}
		data.name = (user.type === 'fiz' || user.type === 'ip') ? `${user.lastname.trim()} ${user.name.trim()} ${user.secondname.trim()}` : user.name
		data.egrnum = user.type === 'fiz' ? (user.snils ? user.snils.match(/\d+/g).join('') : "") : user.egrnum

		data.email = data.email.trim()

		if (user.phone) {
			data.phone = user.phone.match(/\d+/g).join('')
		}

		if (typeRegEdit) {
			data.user_id = props.user.id
		}

		try {
			if (typeRegAdd) {
				await API.usersAPI.create(data, getAccessToken())
			} else if (typeRegEdit) {
				await API.usersAPI.update(data, getAccessToken())
			} else {
				await API.authAPI.register(data)
			}

			setErrorsValidation({})
			setErrors({})
			setRegistered(true)
			if (typeRegAdd) await props.onSuccess("Пользователь добавлен")
			else if (typeRegEdit) await props.onSuccess("Пользователь изменен")
		} catch (e) {
			console.error(e)
			setErrors(e.response.errors)
		}

		setLoading(false)
	}

	const
		typeRegAdd = typeReg === 'adminAddUser',
		typeRegEdit = typeReg === 'adminEditUser'

	return (
		<div className={s['main']}>
			<form className={s['registration']} onSubmit={register}>
				<div className={cx('h2', 'text-center', s['title'])}>
					{typeRegAdd ?
						'Добавить пользователя' :
					typeRegEdit ?
						'Редактировать пользователя' :
						'Регистрация'}
				</div>

				<div className="container">
					{typeReg && <div className="row">
						<div className="col-12">
							{close && <Button
								className="btn btn-primary mb-3"
								onClick={close}
							>
								<FontAwesomeIcon icon={faArrowAltCircleLeft} />
								Назад
							</Button>}

							{!registered && !typeRegEdit && <Button
								className="btn btn-primary mb-3"
								onClick={generateUser}
							>
								Сгенерировать логин и пароль
							</Button>}
						</div>
					</div>}

					{!registered && <div className="row">
						<div className="col-12">
							<div className="form-group">
								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="fiz"
										name="type"
										className="custom-control-input"
										checked={user.type === 'fiz'}
										onChange={() => handleChangeInput('type', 'fiz')}
									/>

									<label className="custom-control-label" htmlFor="fiz">Физ. лицо</label>
								</div>

								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="ip"
										name="type"
										className="custom-control-input"
										checked={user.type === 'ip'}
										onChange={() => handleChangeInput('type','ip')}
									/>

									<label className="custom-control-label" htmlFor="ip">ИП</label>
								</div>

								<div className="custom-control custom-radio custom-control-inline">
									<input
										type="radio"
										id="ur"
										name="type"
										className="custom-control-input"
										checked={user.type === 'ur'}
										onChange={() => handleChangeInput('type', 'ur')}
									/>

									<label className="custom-control-label" htmlFor="ur">Юр. лицо</label>
								</div>
							</div>

							<div className="alert alert-warning" role="alert">
								<span className={s['red-star']}>*</span> - Поля обязательные для заполнения
							</div>
						</div>

						<div className="col-12">
							<div>
								<div className="form-group">
									<label htmlFor="email">
										<span>E-mail</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["email"] || errors["email"]})}
											id="email"
											value={user.email}
											onChange={e => handleChangeInput('email', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.email && <div className={s['error-msg']}>{errorsValidation.email}</div>}
								</div>

								{user.type === 'fiz' && <div className="form-group">
									<label htmlFor="snils">
										<span>СНИЛС</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<MaskedInput
											//ref="maskInput"
											mask={[/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/]}
											showMask
											placeholderChar="_"
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["snils"] || errors["snils"]})}
											id="snils"
											value={user.snils}
											onChange={e => handleChangeInput('snils', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.snils && <div className={s['error-msg']}>{errorsValidation.snils}</div>}
								</div>}

								{user.type !== 'fiz' && <div className="form-group">
									<label htmlFor="egrnum">
										<span>{ user.type === 'ip' ? "ЕГРИП" : "ЕГРЮЛ"}</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["egrnum"] || errors["egrnum"]})}
											id="egrnum"
											value={user.egrnum}
											onChange={e => handleChangeInput('egrnum', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.egrnum && <div className={s['error-msg']}>{errorsValidation.egrnum}</div>}
								</div>}

								<div className="form-group">
									<label htmlFor="phone">
										<span>Номер телефона</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<MaskedInput
											//ref="maskInput"
											mask={['+', '7', ' ', '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
											showMask
											placeholderChar="_"
											guide
											placeholder={"+7"}
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["phone"] || errors["phone"]})}
											id="phone"
											value={user.phone}
											onChange={e => handleChangeInput("phone", e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.phone && <div className={s['error-msg']}>{errorsValidation.phone}</div>}
								</div>

								{(user.type === 'fiz' || user.type === 'ip') && !typeRegEdit && <div className="form-group">
									<label htmlFor="lastname">
										<span>Фамилия</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["lastname"] || errors["lastname"]})}
											id="lastname"
											value={user.lastname}
											onChange={e => handleChangeInput('lastname', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.lastname && <div className={s['error-msg']}>{errorsValidation.lastname}</div>}
								</div>}

								<div className="form-group">
									<label htmlFor="name">
										<span>{user.type === 'ur' ? "Наименование организации" : "Имя"}</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["name"] || errors["name"]})}
											id="lastname"
											value={user.name}
											onChange={e => handleChangeInput('name', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.name && <div className={s['error-msg']}>{errorsValidation.name}</div>}
								</div>


								{(user.type === 'fiz' || user.type === 'ip') && !typeRegEdit && <div className="form-group">
									<label htmlFor="secondname">
										<span>Отчество</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type="text"
											className={cx("form-control", {[s['error-field']]: errorsValidation["secondname"] || errors["secondname"]})}
											id="secondname"
											value={user.secondname}
											onChange={e => handleChangeInput('secondname', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.secondname && <div className={s['error-msg']}>{errorsValidation.secondname}</div>}
								</div>}

								<div className="form-group">
									<label htmlFor="password">
										<span>Пароль</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type={viewPassword ? "text" : "password"}
											className={cx("form-control", {[s['error-field']]: errorsValidation["password"] || errors["password"]})}
											id="password"
											value={user.password}
											onChange={e => handleChangeInput('password', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.password && <div className={s['error-msg']}>{errorsValidation.password}</div>}
								</div>

								<div className="form-group">
									<label htmlFor="password_confirmation">
										<span>Подтвердить пароль</span>
										<span className={cx(s['red-star'], s['red-star--label'])}>*</span>
									</label>
									<div>
										<input
											type={viewPasswordConfirmation ? "text" : "password"}
											className={cx("form-control", {[s['error-field']]: errorsValidation["password_confirmation"] || errors["password_confirmation"]})}
											id="password"
											value={user.password_confirmation}
											onChange={e => handleChangeInput('password_confirmation', e.currentTarget.value)}
										/>
									</div>
									{errorsValidation.password_confirmation && <div className={s['error-msg']}>{errorsValidation.password_confirmation}</div>}
								</div>
							</div>
						</div>

						{!typeReg && <div className="col-12">
							<div className="form-group">
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="personalDataPermission"
										checked={personalDataPermission}
										onChange={() => setPersonalDataPermission(!personalDataPermission)}
									/>

									<label className="custom-control-label" htmlFor="personalDataPermission">Я ознакомлен(а) с пользовательским соглашением и даю согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»</label>
								</div>
							</div>
						</div>}
					</div>}

					<div className="row">
						<div className="col-12">
							{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
								{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
							</div>}

							{registered && <div className="alert alert-success" role="alert">
								{typeRegAdd ?
									<div>
										<div>Пользователь успешно добавлен</div>
										<div>Логин: {user.email}</div>
										<div>Пароль: {user.password}</div>
									</div> :
								typeRegEdit ?
									<span>Пользователь успешно изменен</span> :
									<span>На ваш E-mail было отправлено письмо. Перейдите по ссылке в письме для подтверждения регистрации</span>}
							</div>}

							{console.log(errorsValidation)}
							{console.log(Object.keys(errorsValidation).length > 0, !personalDataPermission)}
							{!registered && <Button
								type="submit"
								className="btn btn-primary"
								disabled={Object.keys(errorsValidation).length > 0 || loading || !personalDataPermission}
								loading={loading}
								onClick={register}
							>
								{typeRegAdd ?
									<span>Добавить пользователя</span> :
								typeRegEdit ?
									<span>Сохранить изменения</span> :
									<span>Зарегистрироваться</span>}
							</Button>}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Registration