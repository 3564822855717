import React, {useState} from "react";
import Button from "../Button";
import cx from "classnames";
import s from "../../routes/TechnicalApplication/TechnicalApplication.module.css";
import API from "../../lib/API";

function ToggleInputResult (props) {
  return <div className={s['docs__item']}>
    <div className={s['docs__label']}>{ props.text }</div>
    <div className={s['docs__field']}>
      <div>
        {props.value && <Button
          className={cx('btn', 'btn-primary')}
          disabled
        >Да</Button>}

        {!props.value && <Button
          className={cx('btn', 'btn-primary')}
          disabled
        >Нет</Button>}
      </div>
    </div>
  </div>

}

export default ToggleInputResult