import React, {useContext, useRef} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import {ApplicationContext} from "../lib/ApplicationContext";
import {joinCategories} from "../lib/helpers";

function TabDeadlines (props) {
  const { appData, dispatch } = useContext(ApplicationContext)
  const data = appData.deadlines
  const errors = appData.errors.errors
  const reasons = {}
  props.reasons.forEach(reason => reasons[reason.value] = reason)

  function setLineValue (index, key, value) {
    data[index][key] = value;
    dispatch({type: 'setField', payload: {field: 'deadlines', value: data}})
  }

  function deleteRow (index) {
    data.splice(index, 1);
    dispatch({type: 'setField', payload: {field: 'deadlines', value: data}})
  }

  function addRow () {
    data.push({
      start: "",
      end: "",
      power: ""
    });

    dispatch({type: 'setField', payload: {field: 'deadlines', value: data}})
  }

  const categories = joinCategories(appData.connectiontechspecs.category1, appData.connectiontechspecs.category2, appData.connectiontechspecs.category3)

  const datepickers = []

  Array.isArray(data) && data.forEach((value, index) => {
    datepickers.push({
      datepicker1: React.createRef(),
      datepicker2: React.createRef()
    })
  })

  const isMicro = (reasons[appData.connectiontype]?.system === 'micro' || (reasons[appData.connectiontype]?.system === 'microcat3'))

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Сроки проектирования и поэтапного ввода в эксплуатацию объекта</div>
    </div>

    <div className="form-group">
      {errors.deadlines && <div className={cx(s['error-msg'], 'mb-3')}>{errors.deadlines.map((error, index) => <div key={index}>{error}</div>)}</div>}

      <table className={cx('table', s['table'])}>
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">
            {!isMicro && <>Планируемый срок проектирования энергопринимающих устройств (месяц, год) <span className={s['red-star']}>*</span></>}
            {isMicro && <>Планируемый срок проектирования энергопринимающих устройств и (или) объектов микрогенерации (месяц, год) <span className={s['red-star']}>*</span></>}
          </th>
          <th scope="col">
            {!isMicro && <>Планируемый срок введения энергопринимающих устройств в эксплуатацию (месяц, год) <span className={s['red-star']}>*</span></>}
            {isMicro && <>Планируемый срок введения энергопринимающих устройств и (или) объектов микрогенерации в эксплуатацию (месяц, год)  <span className={s['red-star']}>*</span></>}
          </th>
          <th scope="col">
            {!isMicro && <>Максимальная мощность энергопринимающих устройств (кВт) <span className={s['red-star']}>*</span></>}
            {isMicro && <>Максимальная мощность объектов микрогенерации (кВт) <span className={s['red-star']}>*</span></>}
          </th>
          <th scope="col">
            Категория надежности энергопринимающих устройств (I, II, III) <span className={s['red-star']}>*</span>
          </th>
          <th scope="col">Действия</th>
        </tr>
        </thead>
        <tbody>

        {data.map((item, index) => <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>
            <div className="input-group">
              <DatePicker
                className={cx("form-control", {[s['error-field']]: errors[`start${index}`]})}
                selected={item.start}
                dateFormat="dd.MM.yyyy"
                locale="ru"
                onChange={(date) => {setLineValue(index, 'start', date)}}
                //ref={(c) => this[`datepicker${index}1`] = c}
                ref={(c) => datepickers[index].datepicker1 = c}
              />

              <div
                className="input-group-append"
                style={{cursor: 'pointer'}}
                onClick={() => {datepickers[index].datepicker1.setOpen(true)}}
              >
                <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
              </div>
            </div>
          </td>
          <td>
            <div className="input-group">
              <DatePicker
                className={cx("form-control", {[s['error-field']]: errors[`end${index}`]})}
                selected={item.end}
                dateFormat="dd.MM.yyyy"
                locale="ru"
                onChange={(date) => {setLineValue(index, 'end', date)}}
                //ref={(c) => this[`datepicker${index}2`] = c}
                ref={(c) => datepickers[index].datepicker2 = c}
              />

              <div
                className="input-group-append"
                style={{cursor: 'pointer'}}
                onClick={() => {datepickers[index].datepicker2.setOpen(true)}}
              >
                <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
              </div>
            </div>
          </td>
          <td>
            <input
              type="text"
              className={cx("form-control", {[s['error-field']]: errors[`power${index}`]})}
              value={item.power}
              onChange={(e) => {setLineValue(index, 'power', e.currentTarget.value)}}
            />
          </td>
          <td>
            {categories}
          </td>
          <td><Button
            className="btn btn-danger"
            disabled={data.length === 1}
            onClick={() => deleteRow(index)}
          ><FontAwesomeIcon icon={faTimes} /></Button></td>
        </tr>)}
        </tbody>
      </table>

      <div><Button
        className={cx('btn', 'btn-success', s['add-deadline'])}
        onClick={() => addRow()}
      ><FontAwesomeIcon icon={faPlus} /> Добавить строку</Button></div>
    </div>

  </div>
}

export default TabDeadlines;