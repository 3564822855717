import React from 'react';
import Layout from '../../../components/Layout';
import AdminDashboard from "./AdminDashboard";

function AdminDashboardRoute (props) {
	return <Layout>
		<AdminDashboard
			page={props.page}
		/>
	</Layout>
}

export default AdminDashboardRoute;
