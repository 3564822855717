import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import MaskedInput, {conformToMask} from "react-text-mask";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabContactData () {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Контактные данные</div>
    </div>
    <div className="form-group">
      <label htmlFor="email">E-mail <span className={s['red-star']}>*</span></label>

      <input
        type="email"
        className={cx("form-control", {[s['error-field']]: errors.email})}
        id="email"
        aria-describedby="emailHelp"
        value={appData.email}
        onChange={e => dispatch({type: "setField", payload: {field: "email", value: e.currentTarget.value}})}
      />
    </div>
    <div className="form-group">
      <label htmlFor="phone">Номер телефона <span className={s['red-star']}>*</span></label>

      <MaskedInput
        showMask
        mask={['+', '7', ' ', '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
        guide={true}
        className={cx("form-control", {[s['error-field']]: errors.phone})}
        id="phone"
        placeholder="+7"
        value={appData.phone.slice(1)}
        onChange={e => dispatch({type: "setField", payload: {field: "phone", value: e.currentTarget.value.match(/\d+/g).join('')}})}
      />
    </div>
  </div>
}

export default TabContactData;