import React from "react";
import s from '../AdminAdmins.module.css';
import cx from "classnames";

function DictionaryItem (props) {
  const { permissions, openPopup, item } = props

  let tnsAdminId, tnsSuperAdminId

  for (let entry of props.permissions) {
    if (entry[1].name === "tns_admin") {
      tnsAdminId = entry[0]
    }

    if (entry[1].name === 'tns_superadmin') {
      tnsSuperAdminId = entry[0]
    }
  }

  return (
    <tr>
      <td className="text-center">{item.id}</td>
      <td className={cx('text-center', s['name'])}>{item.name}</td>
      <td className="text-center">{item.email}</td>
      <td className="text-center">{permissions.get(item.permission_id).caption}</td>
      {(parseInt(item.permission_id) !== tnsAdminId && parseInt(item.permission_id) !== tnsSuperAdminId) && <td className="text-center">
        {item.department && item.department.name ? item.department.name : "Все"}
      </td>}
      {(parseInt(item.permission_id) === tnsAdminId || parseInt(item.permission_id) === tnsSuperAdminId) && <td className="text-center">
        {item.tns_department && item.tns_department.name ? item.tns_department.name : "Все"}
      </td>}
      <td>
        <button className="btn btn-primary w-100 mt-2" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
        <button className="btn btn-danger w-100 mt-2" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
      </td>
    </tr>
  );
}

export default DictionaryItem;