import React, {useContext} from 'react'
import s from '../AdminOtherServicesApplications.module.css'
import cx from 'classnames'
import {AuthContext} from "../../../../lib/AuthContext";

function TableHeader (props) {
  const { columnSettings, columnOrder, setColumnOrder } = props

  return <div className={s['table-row']}>
    <div className={s['table-cell']}>
      <div
        onClick={() => setColumnOrder({field: 'unviewed_total', order: columnOrder.order === 'asc' ? 'desc' : 'asc'})}
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'unviewed_total'})}
      ><div className={s['warning']}>
        !
      </div></div>
    </div>

    <div className={s['table-cell']}>Флаг просмотра</div>

    {columnSettings.id && <div className={s['table-cell']}>
      <div
        onClick={() => setColumnOrder({field: 'id', order: columnOrder.order === 'asc' ? 'desc' : 'asc'})}
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'id'})}
      >ID</div>
    </div>}

    {columnSettings.number && <div className={s['table-cell']}>
      <div
        onClick={() => setColumnOrder({field: 'application_number', order: columnOrder.order === 'asc' ? 'desc' : 'asc'})}
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'application_number'})}
      >№ заявки в ЛК</div>
    </div>}

    <div className={s['table-cell']}>
      <div
        onClick={() => setColumnOrder({field: 'created_at', order: columnOrder.order === 'asc' ? 'desc' : 'asc'})}
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'created_at'})}
      >Дата заявки</div>
    </div>

    {columnSettings.status_id && <div className={s['table-cell']}>Статус заявки</div>}

    {columnSettings.dateReg && <div className={s['table-cell']}>
      <div
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'registered_at'})}
      >Дата регистрации заявки</div>
    </div>}

    {columnSettings.name && <div className={s['table-cell']}>
      <div
        className={cx(s['sort-cell'], s[`sort-${columnOrder.order}`], {[s['sorted']]: columnOrder.field === 'name'})}
      >Наименование заявителя</div>
    </div>}

    {columnSettings.paycheck && <div className={s['table-cell']}>
      <div className={s['table-cell']}>Оплата по договору</div>
    </div>}

    <div className={s['table-cell']}>Филиал</div>
    {columnSettings.address && <div className={s['table-cell']}>Адрес объекта</div>}
    {columnSettings.docs && <div className={s['table-cell']}>Документы</div>}
    {columnSettings.comments && <div className={s['table-cell']}>Комментарий</div>}
    {columnSettings.aggrementtype && <div className={s['table-cell']}>Способ обмена документами</div>}
    {/*<div className={s['table-cell']}>Сгенерированная заявка</div>*/}
    {columnSettings.phone && <div className={s['table-cell']}>Телефон</div>}
  </div>
}

export default TableHeader