import React, {Component, useState} from 'react';
import s from './Restore.module.css';
import API from '../../../lib/API';
import Button from '../../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Restore (props) {
	const	{ token, id } = props

	const [ loading, setLoading ] = useState(false)
	const [ success, setSuccess ] = useState(false)
	const [ password, setPassword ] = useState('')
	const [ passwordConfirmation, setPasswordConfirmation ] = useState('')
	const [ viewPassword, setViewPassword ] = useState(false)
	const [ viewPasswordConfirmation, setViewPasswordConfirmation ] = useState(false)
	const [ errors, setErrors ] = useState({})

	async function reset() {
		setLoading(true)
		setErrors({})

		const data = {
			password,
			password_confirmation: passwordConfirmation,
			id,
			token
		}

		try {
			await API.authAPI.resetPassword(data);
			setSuccess(true)
		} catch (e) {
			console.error(e)
			setErrors(e.response?.errors)
		}

		setLoading(false)
	}

	return (
		<div className={s['main']}>
			<form className="form">
				<div className="form-title">Сброс пароля</div>

				<div className="form-body">
					{!success ? <div>
						<div className={s['info']}>Пароль должен содержать не менее 8 символов</div>
						{password !== passwordConfirmation && <div className="text-danger">Пароли не совпадают</div>}

						<div className="form-group">
							<label htmlFor="password">Новый пароль</label>

							<div className={s['wrap-pass']}>
								{viewPassword ? <input
									type="text"
									className="form-control"
									id="password"
									value={password}
									onChange={e => setPassword(e.currentTarget.value)}
									placeholder="Пароль"
								/> : <input
									type="password"
									className="form-control"
									id="password"
									value={password}
									onChange={e => setPassword(e.currentTarget.value)}
									placeholder="Пароль"
								/>}

								<div
									onClick={() => setViewPassword(!viewPassword)}
									className={s['pass-icon']}
								>{viewPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</div>
							</div>
						</div>

						<div className="form-group">
							<label htmlFor="passwordConfirmation">Повторите новый пароль</label>

							<div className={s['wrap-pass']}>
								{viewPasswordConfirmation ? <input
									type="text"
									className="form-control"
									id="passwordConfirmation"
									value={passwordConfirmation}
									onChange={e => setPasswordConfirmation(e.currentTarget.value)}
									placeholder="Пароль"
								/> : <input
									type="password"
									className="form-control"
									id="passwordConfirmation"
									value={passwordConfirmation}
									onChange={e => setPasswordConfirmation(e.currentTarget.value)}
									placeholder="Пароль"
								/>}

								<div
									onClick={() => setViewPasswordConfirmation(!viewPasswordConfirmation)}
									className={s['pass-icon']}
								>{viewPasswordConfirmation ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</div>
							</div>
						</div>

						{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
							{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
						</div>}

						<Button
							className="btn-app"
							onClick={reset}
							disabled={!token || !id || !password || password.length < 8 || !passwordConfirmation || passwordConfirmation.length < 8 || (password !== passwordConfirmation) || loading}
							loading={loading}
						>Отправить</Button>
					</div> : <div className="alert alert-success" role="alert">
						Пароль успешно изменен
					</div>}
				</div>
			</form>
		</div>
	)
}

export default Restore