import React, { Component } from 'react';
import s from './Cabinet.module.css';
import Button from '../../components/Button';
import routes from "../index";

class Cabinet extends Component {
	render() {
		return (
			<div className={s['main']}>
				<div className={s['technical']}>
					<div className="h2 text-center">Мой кабинет</div>
					
					<div className={s['links']}>
						<div>
							<Button to={routes.ProfileRoute.path} className="btn btn-primary">Мой профиль</Button>
						</div>
						<div>
							<Button to={routes.TechnicalApplicationsRoute.path} className="btn btn-primary">Заявки на технологическое присоединение</Button>
						</div>
						<div>
							<Button to={routes.OtherServicesApplicationsRoute.path} className="btn btn-primary">Прочие услуги</Button>
						</div>
						<div>
							<Button to={routes.EvaluationRoute.path} className="btn btn-primary">Оценка потребителем качества услуг</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Cabinet;