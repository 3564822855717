import React from 'react';
import Layout from '../../../components/Layout';
import OtherServicesApplicationRequest from './OtherServicesApplicationRequest';

function OtherServicesApplicationRequestRoute(props) {
	return (
		<Layout>
			<div style={{
				display: "flex",
				width: "100%",
				height: "100%",
				alignItems: "center",
				justifyContent: "center"
			}}>
				<p>Подача заявок закрыта</p>
			</div>

			{/* <OtherServicesApplicationRequest /> */}
		</Layout>
	);
}

export default OtherServicesApplicationRequestRoute;
