import React, { Component } from 'react';
import Layout from '../../components/Layout';
import PageSpinner from '../../components/PageSpinner';
import { checkUser } from '../../lib/utils';
import { Redirect } from 'react-router-dom';

class route_technical_power extends Component {
	render() {
		return (
			<Layout>
				<h2 className="text-center mt-5">Недоступно на данный момент</h2>
			</Layout>
		);
	}
}

export default route_technical_power;
