import React from 'react';
import Layout from '../../../components/Layout';
import AdminEvaluation from './AdminEvaluation';

function AdminEvaluationRoute (props) {
	return (
		<Layout>
			<AdminEvaluation />
		</Layout>
	);
}

export default AdminEvaluationRoute;
