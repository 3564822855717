import React from "react";
import DownloadFile from "../../../components/DownloadFile/DownloadFile";
import ToggleInput from "../../../components/ToggleInput/ToggleInput";
import API from "../../../lib/API";
import UploadFileInput from "../../../components/UploadFileInput/UploadFileInput";
import s from "../TechnicalApplication.module.css";
import moment from "moment";

function UserDocuments (props) {
  const { applicationId, agreement_files, oferta, specialCondition, specInstructions } = props

  return <>
    <div className="h4 mb-4">Заключение договора об осуществлении ТП</div>
    <DownloadFile
      applicationId={applicationId}
      name="contractTPOrg"
      text="Договор на ТП (с ТУ) со стороны сетевой организации"
      url={agreement_files?.contractTPOrg?.url}
      viewed={agreement_files?.contractTPOrg?.viewed}
    />

    {specialCondition &&
    <DownloadFile
      applicationId={applicationId}
      name="agreementConditions"
      text="Условие типового договора для заявителей 12(1), 14"
      url={agreement_files?.agreementConditions?.url}
      viewed={agreement_files?.agreementConditions?.viewed}
    />
    }

    <DownloadFile
      applicationId={applicationId}
      name="tnsproject"
      text="Проект договора энергоснабжения или обеспечивающего продажу электрической энергии (информация о лицевом счете и платежных реквизитах гарантирующего поставщика до 15 квт физ лиц)"
      url={agreement_files?.tnsproject?.url}
      viewed={agreement_files?.tnsproject?.viewed}
      uploaded_at={agreement_files?.tnsproject?.uploaded_at}
    />

    <DownloadFile
      applicationId={applicationId}
      name="tnsprojectep"
      text="Файл ЭП для проекта договора энергоснабжения или обеспечивающего продажу электрической энергии (информация о лицевом счете и платежных реквизитах гарантирующего поставщика до 15 квт физ лиц)"
      url={agreement_files?.tnsprojectep?.url}
      viewed={agreement_files?.tnsprojectep?.viewed}
      uploaded_at={agreement_files?.tnsprojectep?.uploaded_at}
    />

    {props.isMicro && <>
      <DownloadFile
        applicationId={applicationId}
        name="tnsmicro"
        text="Договор продажи электрической энергии (мощности), выработанной на объектах микрогенерации, на розничном рынке"
        url={agreement_files?.tnsmicro?.url}
        viewed={agreement_files?.tnsmicro?.viewed}
        uploaded_at={agreement_files?.tnsmicro?.uploaded_at}
      />
      <DownloadFile
        applicationId={applicationId}
        name="tnsmicroep"
        text="Файл ЭП для проекта Договор продажи электрической энергии (мощности), выработанной на объектах микрогенерации, на розничном рынке"
        url={agreement_files?.tnsmicroep?.url}
        viewed={agreement_files?.tnsmicroep?.viewed}
        uploaded_at={agreement_files?.tnsmicroep?.uploaded_at}
      />
    </>}

    <ToggleInput
      applicationId={applicationId}
      setterFunc={API.applicationsAPI.setOffer.bind(API.applicationsAPI)}
      name="ofertaConfirm"
      text="Оферта договора энергоснабжения, размещенная в личном кабинете на сайте сетевой организации, мной акцептована, с открытием лицевого счета гарантирующим поставщиком и направлением логина и пароля для создания личного кабинета на сайте гарантирующего поставщика на адрес электронной почты, указанный при подаче заявления на технологическое присоединение, согласен"
      defaultValue={oferta?.ofertaConfirm}
    />

    <ToggleInput
      applicationId={applicationId}
      setterFunc={API.applicationsAPI.setPaycheckEmail.bind(API.applicationsAPI)}
      name="paycheckEmail"
      text="Я согласен с направлением счетов за потребленную электроэнергию, на адрес электронной почты, указанный при подаче заявления на технологическое присоединение:"
      defaultValue={oferta?.paycheckEmail}
    />

    <DownloadFile
      applicationId={applicationId}
      name="fileEPOrg"
      text="Файл ЭП для договора на ТП"
      url={agreement_files?.fileEPOrg?.url}
      viewed={agreement_files?.fileEPOrg?.viewed}
    />

    {specialCondition &&
    <DownloadFile
      applicationId={applicationId}
      name="techConditions"
      text="Технические условия"
      url={agreement_files?.techConditions?.url}
      viewed={agreement_files?.techConditions?.viewed}
    />
    }

    <DownloadFile
      applicationId={applicationId}
      name="addContractTPOrg"
      text="Дополнительное соглашение к договору ТП со стороны сетевой организации"
      url={agreement_files?.addContractTPOrg?.url}
      viewed={agreement_files?.addContractTPOrg?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="addFileEPOrg"
      text="Файл ЭП «Дополнительное соглашение к договору ТП со стороны сетевой организации»"
      url={agreement_files?.addFileEPOrg?.url}
      viewed={agreement_files?.addFileEPOrg?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="contractTPUser"
      text="Договор на ТП (с ТУ) со стороны заявителя"
      url={agreement_files?.contractTPUser?.url}
      viewed={agreement_files?.contractTPUser?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="fileEPUser"
      text="Файл ЭП для «Договор на ТП (с ТУ) со стороны заявителя»"
      url={agreement_files?.fileEPUser?.url}
      viewed={agreement_files?.fileEPUser?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="addContractTPUser"
      text="Дополнительное соглашение к договору ТП со стороны заявителя"
      url={agreement_files?.addContractTPUser?.url}
      viewed={agreement_files?.addContractTPUser?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="addFileEPUser"
      text="Файл ЭП «Дополнительное соглашение к договору ТП со стороны заявителя»"
      url={agreement_files?.addFileEPUser?.url}
      viewed={agreement_files?.addFileEPUser?.viewed}
    />

    <div className="h4 mb-4">Исполнение договора</div>

    <div className={s['docs__item']}>
      <div className={s['docs__label']}>Дата уведомления заявителя о выполнении ТУ со стороны сетевой организации</div>
      <div>{agreement_files?.dateTUOrg ? moment(agreement_files.dateTUOrg).format('DD.MM.YYYY') : <div className={s['docs__warning']}>Дата не указана</div>}</div>
    </div>

    {specInstructions && <DownloadFile
      name="instruction"
      text="Инструкцию, содержащую последовательный перечень мероприятий, обеспечивающих безопасное осуществление действиями заявителя фактического присоединения и фактического приема напряжения и мощности. (п.105 Правил)"
      url={agreement_files?.instruction?.url}
      viewed={agreement_files?.instruction?.viewed}
    />}

    <DownloadFile
      applicationId={applicationId}
      name="letter"
      text="Письмо с уведомлением в выполнении ТУ со стороны сетевой организации"
      url={agreement_files?.letter?.url}
      viewed={agreement_files?.letter?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="actTUOrg"
      text="Акт выполнения ТУ со стороны сетевой организации"
      url={agreement_files?.actTUOrg?.url}
      viewed={agreement_files?.actTUOrg?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="fileTUOrg"
      text="Файл ЭП «Акт выполнения ТУ со стороны сетевой организации»"
      url={agreement_files?.fileTUOrg?.url}
      viewed={agreement_files?.fileTUOrg?.viewed}
    />

    <div className={s['docs__item']}>
      <div className={s['docs__label']}>Дата уведомления о выполнении ТУ со стороны заявителя</div>
      <div>{agreement_files?.dateTUUser ? moment(agreement_files.dateTUUser).format('DD.MM.YYYY') : <div className={s['docs__warning']}>Дата не указана</div>}</div>
    </div>

    <UploadFileInput
      applicationId={applicationId}
      name="actTUUser"
      text="Акт выполнения ТУ со стороны заявителя"
      url={agreement_files?.actTUUser?.url}
      viewed={agreement_files?.actTUUser?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="permissionAct"
      text="Акт допуска прибора учета в эксплуатацию (п.109 Правил)"
      url={agreement_files?.permissionAct?.url}
      viewed={agreement_files?.permissionAct?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="fileTUUser"
      text="Файл ЭП «Акт выполнения ТУ со стороны заявителя»"
      url={agreement_files?.fileTUUser?.url}
      viewed={agreement_files?.fileTUUser?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="permissionActEs"
      text="Файл ЭП «Акт допуска прибора учета в эксплуатацию (п.109 Правил)»"
      url={agreement_files?.permissionActEs?.url}
      viewed={agreement_files?.permissionActEs?.viewed}
    />

    <div className="h4 mb-4">Фактическое присоединение</div>

    <DownloadFile
      applicationId={applicationId}
      name="actTPOrg"
      text="Акт ТП со стороны сетевой организации"
      url={agreement_files?.actTPOrg?.url}
      viewed={agreement_files?.actTPOrg?.viewed}
    />

    <DownloadFile
      applicationId={applicationId}
      name="fileActEPOrg"
      text="Файл ЭП «Акт ТП со стороны сетевой организации»"
      url={agreement_files?.fileActEPOrg?.url}
      viewed={agreement_files?.fileActEPOrg?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="actTPUser"
      text="Акт ТП со стороны заявителя"
      url={agreement_files?.actTPUser?.url}
      viewed={agreement_files?.actTPUser?.viewed}
    />

    <UploadFileInput
      applicationId={applicationId}
      name="fileActTPUser"
      text="Файл ЭП «Акт ТП со стороны заявителя»"
      url={agreement_files?.fileActTPUser?.url}
      viewed={agreement_files?.fileActTPUser?.viewed}
    />
  </>
}

export default UserDocuments