import React, {Component} from "react";
import cx from "classnames";
import s from "./MultiFileInput.module.css";
import Button from "../../../components/Button";

class MultiFileInput extends Component {
  permittedMimes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/tiff',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet'
  ];

  addInput() {
    const { files, setFiles } = this.props;
    files.push({});
    setFiles(files);
  }

  removeInput(index) {
    const { files, setFiles } = this.props;
    files.splice(index, 1);
    setFiles(files);
  }

  setFile(index, file) {
    const { files, setFiles } = this.props;

    if (typeof file === 'undefined')
      files[index] = {}
    else if (file instanceof File && this.permittedMimes.indexOf(file.type) === -1)
      files[index] = {validationError: 'Формат файла должен быть: doc, docx, xls, xlsx, odt, ods, pdf, tif, tiff, jpg, png, zip'}
    else if (file.size > 5 * 1024 * 1024)
      files[index] = {validationError: 'Файл не должен превышать размер в 5 МБ'}
    else
      files[index] = file

    setFiles(files);
  }

  removeRepeat(index) {
    const { repeat, setRepeat } = this.props;
    repeat.splice(index, 1);
    setRepeat(repeat);
  }

  render() {
    const { required, label, files, repeat, error } = this.props;

    let { filesCount } = this.props;
    if (!filesCount)
      filesCount = 5;

    if (files.length === 0)
      files.push({})

   // console.log(files)
    return <>
      <div>
        {label && <label>{label} {required && <span className={s['red-star']}>*</span>}</label>}

        <div className={cx(s['main'], s['main--order'])}>
          {files.length < filesCount && <Button
            onClick={this.addInput.bind(this)}
            className={cx('btn', 'btn-primary', s['btn'], s['btn--add'])}
          >+</Button>}

          <div className={s['inputs']}>
            {files && files.map((file, index) => <div key={index}><div className={s['input']}>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  onChange={(e) => {this.setFile(index, e.currentTarget.files[0])}}
                />

                <label className={cx('custom-file-label', {[s['error-field']]: (file.validationError || error)})}>
                  {files[index] && files[index].name}
                </label>
              </div>

              {files.length > 1 && <Button
                onClick={this.removeInput.bind(this, index)}
                className={cx('btn', 'btn-danger', s['btn'], s['btn--remove'])}
              >-</Button>}

            </div>
              {file.validationError && <div className={s['error-msg']}>
                <div>{file.validationError}</div>
              </div>}
            </div>)}
          </div>
        </div>
      </div>

      {repeat.length > 0 && <div className="mt-3">
        {repeat.map((item, index) => <div key={index} className={cx(s['doclink'], 'mt-1')}>
          <a href={item.url}>{item.name}</a>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.removeRepeat.bind(this, index)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>)}
      </div>}

      {error && <div className={s['error-msg']}>
        <div>{error}</div>
      </div>}
    </>
  }
}

export default MultiFileInput;