import React, { Component } from 'react';
import Layout from '../../components/Layout';
import TechnicalApplications from "./TechnicalApplications";

class TechnicalApplicationsRoute extends Component {
	render() {

		return (
			<Layout>
				<TechnicalApplications history={this.props.history} />
			</Layout>
		);
	}
}

export default TechnicalApplicationsRoute;
