import React, {Component} from 'react';
import s from './ConfirmModal.module.css';
import cx from 'classnames';
import Button from '../Button';

function ConfirmModal (props) {
    const { visible, title, text, closePopup, doAction } = props;

    return (
      <div className={cx('modal', {[s['modal--active']]: visible})} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>

              <Button
                className="close"
                aria-label="Close"
                onClick={closePopup}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </div>

            <div className="modal-body">
                <div>{text}</div>
            </div>

            <div className="modal-footer">
              <Button
                className="btn btn-danger"
                onClick={() => doAction()}
              >Да</Button>

              <Button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closePopup}
              >Нет</Button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ConfirmModal;
