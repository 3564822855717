import BasicAPI from "./BasicAPI";
import {deleteCookie, getAccessToken} from "./utils";

class AuthAPI extends BasicAPI {
  constructor(prefix) {
    super();
    this.prefix = prefix;
  }

  check (accessToken) {
    return this.sendRequest(this.prefix + '/check', null, {}, accessToken);
  }

  login (data) {
    return this.sendRequest(this.prefix + '/login', data);
  }

  register (data) {
    return this.sendRequest(this.prefix + '/register', data);
  }

  verify (data) {
    return this.sendRequest(this.prefix + '/verify', data);
  }

  confirmUser (data) {
    return this.sendRequest(this.prefix + '/confirmUser', data);
  }

  forgotPassword (data) {
    return this.sendRequest(this.prefix + '/forgotPassword', data);
  }

  resetPassword (data) {
    return this.sendRequest(this.prefix + '/resetPassword', data);
  }

  requestVerification (data, accessToken) {
    return this.sendRequest(this.prefix + '/requestVerification', data, null, accessToken);
  }

  async checkUser(responseData) {
    const
      access = getAccessToken(),
      checkData = {};

    let data = responseData ?? (await this.check(access));

    if (!access || !data || !data.admin) {
      checkData.noAdminAccess = true;
      localStorage.removeItem('admin');
      deleteCookie('admin');
    }

    if (data) {
      checkData.userId = data.user_id;
      checkData.userData = data.user;
      checkData.admin = data.admin;
      checkData.tns = data.permissions?.name === 'tns_admin' || data.permissions?.name === 'tns_superadmin';
      checkData.superadmin = data.permissions?.name === 'superadmin';
      checkData.permissions = data.permissions;
      checkData.application_view_settings = data.application_view_settings ?? {} ;
      if (checkData.application_view_settings && typeof checkData.application_view_settings.columnSettings !== 'object')
        checkData.application_view_settings.columnSettings = false
      if (checkData.other_services_view_settings && typeof checkData.other_services_view_settings.columnSettings !== 'object')
        checkData.other_services_view_settings.columnSettings = false
      checkData.access = !!access;
      checkData.authIsOff = data.authIsOff;
      checkData.tpAppsIsOff = data.tpAppsIsOff;
    }

    return checkData;
  }
}

export default AuthAPI;