import React, {Component} from "react";
import API from "../../../lib/API";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

class AddressPartSelect extends Component{
  constructor(props) {
    super(props);
    this.timeoutSuggestions = null;
    this.state = {
      suggestions: [],
    }
  }

  search(value) {
    return new Promise(resolve => {
      const {searchOptions} = this.props;
      clearTimeout(this.timeoutSuggestions);

      this.timeoutSuggestions = setTimeout(async () => {
        let response = await API.getAddress(value, searchOptions);
        resolve(response.map(item => {
           return {value: item.data, label: item.value};
        }));
      }, 500);
    });
  }

  render() {
    const { id, defaultValue, value, error, setAddressPart } = this.props;

    return <AsyncSelect
      id={id}
      components={{
        Input: ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />
      }}
      styles={{
        singleValue: (provided, state) => ({
          ...provided,
          display: state.selectProps.menuIsOpen ? 'none' : 'block',
        }),
        control: (provided) => {
          if (error)
            return {
              ...provided,
              borderColor: '#dc3545'
            }
          else
            return provided;
        }
      }}
      isSearchable
      loadingMessage={() => 'Загрузка ...'}
      noOptionsMessage={() => 'Не найдено'}
      placeholder="Начните вводить город и выберите из выпадающего списка"
      value={{label: value}}
      loadOptions={this.search.bind(this)}
      onChange={setAddressPart}
    />
  }
}

export default AddressPartSelect;