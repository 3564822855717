import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Notfound from './Notfound';

class NotFoundRoute extends Component {
	render() {
		return (
			<Layout>
				<Notfound />
			</Layout>
		);
	}
}

export default NotFoundRoute;
