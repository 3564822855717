import React, { Component } from 'react';

class PageSpinner extends Component {
	render() {
		return (
			<div className="wrap-spinner">
				<div className="spinner-grow" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}
}

export default PageSpinner;
