import React, {useEffect, useReducer, useState} from "react"
import API from "../../../lib/API";
import s from "./AdminConnectionCalcs.module.css";
import cx from "classnames";
import Button from "../../../components/Button";
import routes from "../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "../../../components/CurrencyInput";

function AdminConnectionCalcs (props) {
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return {records: props.data}
      case 'setRecordField':
        state.records[action.payload.index][action.payload.field] = action.payload.value
        return {...state}
      default:
        // A reducer must always return a valid state.
        // Alternatively you can throw an error if an invalid action is dispatched.
        return state
    }
  }

  const [ success, setSuccess ] = useState(false)

  useEffect(() => {
    const delayedRemove = () => setTimeout(() => setSuccess(false), 3000)
    if (success)
      delayedRemove()
  }, [success])

  async function updateCalcs() {
    setLoading(true)

    try {
      await API.adminAPI.updateCalcs({calcs: JSON.stringify(data.records)})
      setSuccess(true)
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  const [ data, dispatch ] = useReducer(reducer, {records: props.data})
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState(false)

  return <div className={s['main']}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className={cx('h2', s['title'])}>
            <span>Ставки за технологическое присоединение </span>
          </div>

          <div className="mb-4">
            <Button className="btn btn-primary" to={routes.AdminDashboardRoute.path}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              Назад
            </Button>

            <Button
              className="btn btn-success"
              onClick={updateCalcs}
            >
              Сохранить
            </Button>
          </div>

          {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
            {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
          </div>}
          {success &&
          <div className="alert alert-success" role="alert">
            <span>Изменения сохранены</span>
          </div>
          }
        </div>

        {data && <div className="col-12">
          <div className={s['wrap-table']}>
            <table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
              <tbody>
              <tr>
                <td className="text-center">Наименование мероприятия</td>
                <td className="text-center">Цена</td>
              </tr>
              {data.records.map((record, index) => {
                return <tr key={record.id}>
                  <td className="text-center">
                    <textarea
                      className={cx('form-control')}
                      rows="5"
                      value={record.name}
                      onChange={(e) => dispatch({type: 'setRecordField', payload: {index, field: 'name', value: e.currentTarget.value}})}
                    />
                  </td>
                  <td className="text-center">
                    <CurrencyInput
                      className={cx('form-control')}
                      maskOptions={{
                        thousandsSeparatorSymbol: '',
                        integerLimit: 10
                      }}
                      type="number"
                      value={record.price}
                      onChange={(e) => dispatch({type: 'setRecordField', payload: {index, field: 'price', value: e.currentTarget.value}})}
                    />
                  </td>
                </tr>
              })}
              </tbody>
            </table>
          </div>
        </div>}
      </div>
    </div>
  </div>
}

export default AdminConnectionCalcs