import React, {Component} from "react";

class DictionaryItem extends Component {
  render() {
    const {item, fields, openPopup} = this.props;

    return (
      <tr>
        {fields.map(value => <td  className="text-center" key={value}>{item[value]}</td>)}
        <td className="text-center">
          <button className="btn btn-primary" onClick={() => openPopup('EditItem', { item })}>Изменить</button>
          {(!item.system) && <button className="btn btn-danger" onClick={() => openPopup('DeleteItem', { item })}>Удалить</button>
          }
        </td>
      </tr>
    );
  }
}

export default DictionaryItem;