const locale = {
	errors: {
		'Unknown error': 'Неизвестная ошибка',
		'The user credentials were incorrect.': 'Неверные данные пользователя',
		'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.': 'Неверные данные пользователя',
		'The phone has already been taken.': 'Данный номер телефона уже используется',
		'The email has already been taken.': 'Данный Email уже используется',
		'The email must be a valid email address.': 'Адрес электронной почты должен быть действительным',
		'The inn has already been taken.': 'Данный ИНН уже используется',
		'The type field is required.': 'Поле "Тип" обязательное для заполнения',
		'The lastname field is required.': 'Поле "Фамилия" обязательное для заполнения',
		'The name field is required.': 'Поле "Имя" обязательное для заполнения',
		'The inn field is required.': 'Поле "ИНН" обязательное для заполнения',
		'The snils field is required.': 'Поле "СНИЛС" обязательное для заполнения',
		'The snils has already been taken.': 'Данный СНИЛС уже используется',
		'The email field is required.': 'Поле "Email" обязательное для заполнения',
		'The phone field is required.': 'Поле "Номер телефона" обязательное для заполнения',
		'The password field is required.': 'Поле "Пароль" обязательное для заполнения',
		'The password must be between 8 and 255 characters.': 'Поле "Пароль" должно содержать от 8 до 255 символов',
		'The documenttype field is required.': 'Поле "Вид документа" обязательное для заполнения',
		'The docseries field is required.': 'Поле "Серия" обязательное для заполнения',
		'The docnumber field is required.': 'Поле "Номер" обязательное для заполнения',
		'The docdepartment field is required.': 'Поле "Выдан" обязательное для заполнения',
		'The docissuedate field is required.': 'Поле "Дата выдачи" обязательное для заполнения',
		'The docfile field is required.': 'Поле "Сканированная копия документа" обязательное для заполнения',
		'The order must be an integer.': "Положение в списке, должно быть чилом",
		'The units field is required.': "Укажите единицу измерения",
		'The price field is required.': "Укажите цену за единицу измерения",
		'The price must be a number.': "Цена должна быть числом",
		//'The regaddressrfsubject field is required.': 'Поле "Субъект РФ" во вкладке адреса места регистрации обязательное для заполнения',
		//'The regaddresscity field is required.': 'Поле "Название города или населенного пункта" во вкладке адреса места регистрации обязательное для заполнения',
		//'The regaddressstreet field is required.': 'Поле "Улица" во вкладке адреса места регистрации обязательное для заполнения',
		//'The regaddresshouse field is required.': 'Поле "Номер дома" во вкладке адреса места регистрации обязательное для заполнения',

		'The regaddress must have subject.': 'Поле "Субъект РФ" во вкладке адреса места регистрации обязательное для заполнения',
		'The regaddress must have city.': 'Поле "Название города или населенного пункта" во вкладке адреса места регистрации обязательное для заполнения',
		'The regaddress must have street.': 'Поле "Улица" во вкладке адреса места регистрации обязательное для заполнения',
		'The regaddress must have house.': 'Поле "Номер дома" во вкладке адреса места регистрации обязательное для заполнения',

		//'The liveaddressrfsubject field is required.': 'Поле "Субъект РФ" во вкладке адреса места жительства обязательное для заполнения',
		//'The liveaddresscity field is required.': 'Поле "Название города или населенного пункта" во вкладке адреса места жительства обязательное для заполнения',
		//'The liveaddressstreet field is required.': 'Поле "Улица" во вкладке адреса места жительства обязательное для заполнения',
		//'The liveaddresshouse field is required.': 'Поле "Номер дома" во вкладке адреса места жительства обязательное для заполнения',

		'The liveaddress must have subject.': 'Поле "Субъект РФ" во вкладке адреса места жительства обязательное для заполнения',
		'The liveaddress must have city.': 'Поле "Название города или населенного пункта" во вкладке адреса места жительства обязательное для заполнения',
		'The liveaddress must have street.': 'Поле "Улица" во вкладке адреса места жительства обязательное для заполнения',
		'The liveaddress must have house.': 'Поле "Номер дома" во вкладке адреса места жительства обязательное для заполнения',

		'The uraddress must have subject.': 'Поле "Субъект РФ" во вкладке юридического адреса обязательное для заполнения',
		'The uraddress must have city.': 'Поле "Название города или населенного пункта" во вкладке юридического адреса обязательное для заполнения',
		'The uraddress must have street.': 'Поле "Улица" во вкладке юридического адреса обязательное для заполнения',
		'The uraddress must have house.': 'Поле "Номер дома" во вкладке юридического адреса обязательное для заполнения',

		'The postaddress must have subject.': 'Поле "Субъект РФ" во вкладке почтового адреса обязательное для заполнения',
		'The postaddress must have city.': 'Поле "Название города или населенного пункта" во вкладке почтового адреса обязательное для заполнения',
		'The postaddress must have street.': 'Поле "Улица" во вкладке почтового адреса обязательное для заполнения',
		'The postaddress must have house.': 'Поле "Номер дома" во вкладке почтового адреса обязательное для заполнения',

		'The connectionaddress must have subject.': 'Поле "Субъект РФ" во вкладке адреса объекта обязательное для заполнения',
		'The connectionaddress must have city.': 'Поле "Название города или населенного пункта" во вкладке адреса объекта обязательное для заполнения',
		'The connectionaddress must have street.': 'Поле "Улица" во вкладке адреса объекта обязательное для заполнения',
		'The connectionaddress must have house.': 'Поле "Номер дома" во вкладке адреса объекта обязательное для заполнения',

		'The warrant field is required.': 'Файл(ы) сканированной копии доверенности представителя обязателен',
		'The plan field is required.': 'Файл(ы) плана расположения энергопринимающих устройств обязателен',
		'The accident field is required.': 'Файл(ы) переченя и мощности энергопринимающих устройств обязателен',
		'The constitutivedocuments field is required.': 'Файл(ы) правоустанавливающих документов обязателен',
		'The otherdocuments field is required.': 'Файл(ы) прочих документов обязателен',
		'The login must be an integer.': 'Логин должен состоять из символов',
		'The login has already been taken.': 'Такой логин уже существует',
		'The inn must be an integer.': 'ИНН должен быть числовым',
		'The inn must be at least 1.': 'ИИН должнен быть более 1 символа',
		'The ogrn has already been taken.': 'Данный ОГРН уже используется',
		'The priority has already been taken.': 'Данный приоритет уже используется',
		'Max sms reached': 'Вы использовали все попытки, повторите через 5 минут',
		'Wrong code': 'Неверный код',
		'Can not change priority of a system status': 'Нельзя менять приоритет системного статуса',
		'The priority field is required.': 'Укажите приоритет',
		'UR users can not request signature': 'Так как вы являетесь Юридическим лицом подписание договора возможно только через усиленную квалифицированную электронную подпись',
		'The file may not be greater than 5120 kilobytes.': 'Файл должен быть меньше 5 МБ',
		'Paycheck number already exists': 'Счет с этим номером уже загружен в систему',
		'There are services connected to this group': 'Есть услуги принадлежащие этой группе',
		'This user has already voted': "Вы уже оставили отзыв",
		'There are applications connected to this service': "Нельзя удалить услугу, т.к. на нее имеется заявка",
		'Application creation error': 'Ошибка создания заявки. Пожалуйста попробуйте повторить отправку позже.',
		'Application creation is forbidden': 'Подача заявок не доступна'
    },
    dictionaries: {
        statuses: 'Статусы',
        departments: 'Филиалы',
        devices: 'Энергопринимающие устройтсва',
        requestreasons: 'Причины обращения'
    },
		permissionGroups: {
			superadmin: 'Суперадминистратор',
			technical_superadmin: 'Суперадминистратор ТП',
			technical_admin: 'Администратор ТП',
			other_services_superadmin: 'Суперадминистратор ПУ',
			other_services_admin: 'Администратор ПУ',
			tns_superadmin: 'Админ ТНС',
			tns_admin: 'Пользователь ТНС',
			support: 'Пользователь поддержки'
		},
		titles: {
			connectiontype: {
				new: 'новое присоединение',
				increase: 'увеличение максимальной мощности',
				temporarycontract: 'временное присоединение (до исполнения обязательств по заключенному договору ТП по постоянной схеме)',
				temporaryobject: 'временное присоединение передвижных объектов (до 150кВт, сроком до 12 месяцев).',
			},
			connectionagreementtype: {
				contractsupply: 'договор электроснабжения',
				contractenergy: 'договор обеспечивающий поставку электрической энергии'
			},
			character: {
				generators: 'для генераторов',
				lighting: 'осветительная',
				power: 'силовая',
				production: 'для производственной деятельности'
			},
			otherinfoaccount: {
				email: 'Электронная почта',
				paper: 'На бумажном носителе'
			},
			otherinfodocs: {
				email: 'Электронное взаимодействие',
				paper: 'На бумажном носителе'
			},
			otherinfostatus: {
				sms: 'SMS',
				email: 'E-mail'
			}
		}
};

export default locale;
