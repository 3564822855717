import React, {Component, useEffect, useState} from "react";
import Button from "../../../../../components/Button";
import cx from "classnames";
import s from "../../../../../components/Dictionary/Dictionary.module.css";

function EditItem (props) {
  const [ loading, setLoading ] = useState(false)
  const [ otherServicesTypeId, setOtherServicesTypeId ] = useState(props.item.other_services_type_id)
  const [ values, setValues ] = useState({})
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    const initialValues = {}

    for (let field of props.fields) {
      initialValues[field] = (props.item && props.item[field]) || props.item[field] === 0 ? props.item[field] : ""
    }
    console.log(props.serviceTypes.entries().next().value)
    initialValues.other_services_type_id = props.item?.other_services_type_id ?? props.serviceTypes.entries().next().value[0]
    setValues(initialValues)
  }, [])

  const serviceTypesOptions = [];
  props.serviceTypes.forEach((value, key) => {
    serviceTypesOptions.push(<option key={key} value={key}>{value}</option>)
  });


  function handleChangeInput(field, value) {
    values[field] = value;
    setValues({...values})
  }

  async function newService() {
    const {update, dictionaryAPI} = props;

    setLoading(true)
    try {
      console.log(values)
      await dictionaryAPI.create(values);
      update("Услуга добавлена");
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }

    setLoading(false)
  }

  async function editService() {
    const { item, update, dictionaryAPI } = props;

    setLoading(true)

    try {
      await dictionaryAPI.update({ id: item.id, ...values });

      update("Услуга изменена");
    } catch (e) {
      console.error(e)
      setErrors(e.response.errors)
    }
  }


  const popupEdit = props.item !== false;

  const { headers, fields, closePopup } = props;

  return (<div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">{popupEdit ? 'Изменить' : 'Новая'} запись</h5>
      <Button
        className="close"
        aria-label="Close"
        onClick={closePopup}
      >
        <span aria-hidden="true">&times;</span>
      </Button>
    </div>

    <div className="modal-body">
      {Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
        {Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div
          key={`${index}${indexMsg}`}>{errorMsg}</div>))}
      </div>}
      {/*id, created_at, updated_at*/}
      {Object.keys(values).length > 0 && fields.map(field => {
        if (field === 'other_services_type_id')
          return <div key={field} className="form-group">
            <label htmlFor={field}>{headers[field] ? headers[field] : field}</label>

            <select
              className="custom-select"
              value={values['other_services_type_id']}
              onChange={(e) => {
                handleChangeInput('other_services_type_id', e.currentTarget.value);
              }}
            >
              {serviceTypesOptions}
            </select>
          </div>
        else if (field === 'price')
          return <div key={field} className="form-group">
            <label htmlFor={field}>{headers[field] ? headers[field] : field}</label>

            <input
              type="number"
              className={cx("form-control", {[s['error-field']]: errors[field]})}
              id={field}
              value={values[field]}
              onChange={(e) => {
                handleChangeInput(field, e.currentTarget.value)
              }}
            />
          </div>
        else if (field !== 'id' && field !== 'created_at' && field !== 'updated_at')
          return <div key={field} className="form-group">
            <label htmlFor={field}>{headers[field] ? headers[field] : field}</label>

            <input
              type="text"
              className={cx("form-control", {[s['error-field']]: errors[field]})}
              id={field}
              value={values[field]}
              onChange={(e) => {
                handleChangeInput(field, e.currentTarget.value)
              }}
            />
          </div>
      })
     }
    </div>

    <div className="modal-footer">
      <Button
        type="button"
        className="btn btn-secondary"
        data-dismiss="modal"
        disabled={loading}
        onClick={closePopup}
      >Отмена</Button>

      {popupEdit ? <Button
        className="btn btn-success"
        onClick={editService}
        loading={loading}
        disabled={loading}
      >Изменить</Button> : <Button
        className="btn btn-success"
        onClick={newService}
        loading={loading}
        disabled={loading}
      >Добавить</Button>}
    </div>
  </div>)

}

export default EditItem;
