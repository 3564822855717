import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import SelectOther from "./SelectOther";
import cx from "classnames";
import Select from 'react-select';
import ReactHintFactory from "react-hint";
import {ApplicationContext} from "../lib/ApplicationContext";
const ReactHint = ReactHintFactory(React);

function TabConnectionType (props) {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors

  const { reasons, devices } = props;

  return <div className="col-12">

    <div className="form-group">
      <div className="h5">Вид присоединения</div>
    </div>

    <div className="form-group">
      <label>Выбрать причину обращения: <span className={s['red-star']}>*</span></label>
      <SelectOther
        id="connectiontype"
        options={reasons}
        value={appData.connectiontype}
        error={errors.connectiontype}
        //setValue={(value) => this.setValue('connectiontype', value)}
        setValue={value => dispatch({type: "setField", payload: {field: "connectiontype", value}})}
      />

      {(appData.connectiontype === 3) && <div className="form-group">
        <label>Реквизиты основного договора на технологическое присоединение:</label>
        <input
          type="text"
          className={cx("form-control")}
          id="connectionrequisites"
          value={appData.connectionrequisites}
          onChange={e => dispatch({type: "setField", payload: {field: "connectionrequisites", value: e.currentTarget.value}})}
        />
      </div>}

      {(appData.connectiontype === 3 || appData.connectiontype === 4) && <div className="form-group">
        <label>Срок электроснабжения по временной схеме(дней):
          <span data-rh='connectiontempduration' className="hint">?</span>
        </label>

        <input
          type="number"
          className={cx("form-control")}
          id="connectiontempduration"
          value={appData.connectiontempduration}
          onChange={e => dispatch({type: "setField", payload: {field: "connectiontempduration", value: e.currentTarget.value}})}
        />
      </div>}
    </div>

    <div className={cx("form-group", {[s['error-group']]: errors.connection_purpose})}>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="private"
          name="connection_purpose"
          className="custom-control-input"
          checked={appData.connection_purpose === 'private'}
          onChange={e => dispatch({type: "setField", payload: {field: "connection_purpose", value:'private'}})}
        />

        <label className="custom-control-label" htmlFor="private">Для бытовых нужд</label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="commercial"
          name="connection_purpose"
          className="custom-control-input"
          checked={appData.connection_purpose === 'commercial'}
          onChange={e => dispatch({type: "setField", payload: {field: "connection_purpose", value:'commercial'}})}
        />

        <label className="custom-control-label" htmlFor="commercial">Для коммерческого использования</label>
      </div>
    </div>

    <div className="form-group">
      <label>Выбрать энергопринимающее устройство: <span className={s['red-star']}>*</span></label>
      <SelectOther
        id="device"
        options={devices}
        value={appData.device}
        error={errors.device}
        setValue={value => dispatch({type: "setField", payload: {field: "device", value}})}
      />
    </div>

    <div className="form-group">
      <label htmlFor="connectionobject">Наименования объекта присоединения <span className={s['red-star']}>*</span></label>

      <input
        type="text"
        className={cx("form-control", {[s['error-field']]: errors.connectionobject})}
        id="connectionobject"
        value={appData.connectionobject}
        onChange={e => dispatch({type: "setField", payload: {field: "connectionobject", value: e.currentTarget.value}})}
      />
    </div>

    <div className="form-group">
      <label htmlFor="connectionagreementnumber">Номер основного договора электроснабжения</label>

      <input
        type="text"
        className={cx("form-control", {[s['error-field']]: errors.connectionagreementnumber})}
        id="connectionagreementnumber"
        value={appData.connectionagreementnumber}
        onChange={e => dispatch({type: "setField", payload: {field: "connectionagreementnumber", value: e.currentTarget.value}})}
      />
    </div>

    <div className="form-group">
      <label htmlFor="connectionsupplier">Гарантирующий поставщик (энергосбытовая организация), с которым планируется заключение договора <span className={s['red-star']}>*</span></label>

      <input
        type="text"
        className={cx("form-control", {[s['error-field']]: errors.connectionsupplier})}
        id="connectionsupplier"
        value={appData.connectionsupplier}
        onChange={e => dispatch({type: "setField", payload: {field: "connectionsupplier", value: e.currentTarget.value}})}
      />
    </div>

    <div className="form-group">
      <label>Вид договора <span className={s['red-star']}>*</span>
        <span data-rh='connectionagreementtype' className="hint">?</span></label>

      <Select
        options={[
          { value: "contractsupply", label: "Договор энергоснабжения" },
          { value: "contractenergy", label: "Договор купли-продажи (поставки) электрической энергии (мощности)" },
        ]}
        value={{
          value: appData.connectionagreementtype,
          label: appData.connectionagreementtype === "contractsupply" ? "Договор энергоснабжения" : "Договор купли-продажи (поставки) электрической энергии (мощности)"
        }}
        onChange={value => dispatch({type: "setField", payload: {field: "connectionagreementtype", value: value.value}})}
      />
    </div>

    <ReactHint
      autoPosition
      events
      onRenderContent={(target, content) => {
        switch (content) {
          case 'connectionagreementtype':
            return <div className="hint-text">
              <div>
                <b>-Договор энергоснабжения</b> - договор, по которому
                гарантирующий поставщик или энергосбытовая организация
                обязуется подавать энергию (или энергоносители) через
                присоединенную сеть другой стороне
                (абоненту-потребителю) и урегулирует взаимоотношения с
                третьими лицами самостоятельно.
              </div>
              <div>
                <b>- Договор купли-продажи (поставки) электрической
                  энергии</b> - по условиям указанного
                договора купли-продажи, потребитель должен самостоятельно
                урегулировать с сетевыми организациями
                вопросы оказания услуг по передаче электроэнергии
                (заключение дополнительного договора на поставку э/э).
              </div>
            </div>
          case 'connectiontempduration':
            return <div className="hint-text">
              <div>
                При наличии договора технологического присоединения по постоянной схеме электроснабжения указывается срок временного технологического присоединения, определяемый в соответствии с договором технологического присоединения по постоянной схеме электроснабжения.
              </div>
              <div>
                Если в соответствии с договором технологического присоединения мероприятия по технологическому присоединению реализуются поэтапно, указывается срок того из этапов, на котором будет обеспечена возможность электроснабжения энергопринимающих устройств с применением постоянной схемы электроснабжения на объем максимальной мощности, указанный в заявке, направляемой заявителем в целях временного технологического присоединения.
              </div>
              <div>
                Если энергопринимающие устройства являются передвижными и имеют максимальную мощность до 150 кВт включительно, указывается срок до 12 месяцев.
              </div>
            </div>
          default:
            break;
        }}
      }
    />
  </div>
}

export default TabConnectionType