import React from 'react'
import Dictionary, {DefaultDeleteItem} from "../../../../components/Dictionary"
import DictionaryItem from "./components/DictionaryItem"
import EditItem from "./components/EditItem"
import CreateTechnicalRequest from "./components/CreateTechnicalRequest"
import Search from "./components/Search";
import API from "../../../../lib/API"
import Layout from "../../../../components/Layout";

const DeleteItem = (props) => <DefaultDeleteItem
  vocabulary={{
    title: "Удаление пользователя",
    prompt: "Вы уверены что хотите удалить пользователя?",
    success: "Пользователь удален"
  }}
  {...props}
/>

function UsersDictionaryRoute(props) {
  return <Layout>
    <Dictionary
      dictionaryAPI={API.usersAPI}
      dictionaryItem={DictionaryItem}
      popups={{
        EditItem,
        DeleteItem,
        CreateTechnicalRequest
      }}
      vocabulary={{
        name: "Пользователи",
        headers: {
          id: "ID",
          type: "Тип",
          email: "E-mail",
          name: "ФИО или Наименование организации",
          egrnum: "Регистрационные данные",
          phone: "Телефон",
          created_at: "Дата регистрации"
        }
      }}
      search={Search}
    />
  </Layout>
}

export default UsersDictionaryRoute
