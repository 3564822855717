import React, {useState, useEffect, useContext} from 'react';
import { browserHistory } from './store';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import routes from './routes';
import PageSpinner from "./components/PageSpinner";
import API from "./lib/API";
import {AuthContext} from "./lib/AuthContext";
import UnauthorizedError from "./lib/UnauthorizedError";
import Layout from "./components/Layout";
import Login from "./routes/auth/login/Login";
import version from "./version"

console.log(version())
function App () {
	const [ loading, setLoading ] = useState(true)
	const [ authContext, setAuthContext ] = useState({
		authIsOff: false,
		authorized: false
	})
	//const [ authIsOff, setAuthIsOff ] = useState(false)

	function PublicRoute ({Component, path, ...routeProps}) {
		return <Route {...routeProps} render={(props) => <Component {...props}/>}/>
	}

	function ProtectedRoute ({ProtectedComponent, ...routeProps}) {
		if (authContext?.admin)
			return <Redirect to={routes.AdminDashboardRoute.path}/>

		if (authContext?.authorized && !authContext.authIsOff)
				return <Route {...routeProps} render={(props) => <ProtectedComponent {...props}/>}/>
		else {
			return <Layout>
				<Login />
			</Layout>
		}
	}

	function AdminRoute ({ProtectedComponent, routePermissions, ...routeProps}) {
		function checkPermission(routePermissions) {
			if (!authContext.permissions)
				return false
			if (routePermissions === 'admin')
				return authContext.admin

			if (routePermissions === 'superadmin')
				return authContext.admin

			if (Array.isArray(routePermissions)) {
				const permissions = []
				routePermissions.forEach(value => permissions.push(authContext.permissions[value]))

				return permissions.reduce((previousValue, currentValue) => !!previousValue || !!currentValue)
			}
			return false;
		}

		if (authContext?.authorized)
			if (checkPermission(routePermissions))
				return <Route {...routeProps} render={(props) => <ProtectedComponent {...props} isAdmin/>}/>
			else
				return <Layout>
					<div className="text-center h2 mt-5">Недоступно для текущего пользователя</div>
				</Layout>
		else
			return <Layout>
				<Login />
			</Layout>
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userCheck = await API.authAPI.checkUser()
				userCheck.authorized = true
				setAuthContext(userCheck)

				//setAuthIsOff(!!user.authIsOff)
			} catch (e) {
				console.error(e);
				if (e instanceof UnauthorizedError) {
					setAuthContext({
						authIsOff: false,
						authorized: false
					});
					try {
						const authIsOffSetting = await API.adminAPI.getSetting({name: 'authIsOff'})
						//setAuthIsOff(!!parseInt(authIsOffRecord.value))
						setAuthContext({
							authIsOff: !!parseInt(authIsOffSetting.value),
							authorized: false
						});
					} catch (e) {
						console.error(e);
					}
				}
			}

			setLoading(false)
		}

		fetchData()

	}, [])

	if (loading)
		return <PageSpinner />

	return <div className="App">
		<AuthContext.Provider value={{ authContext, setAuthContext }}>
			<Router history={browserHistory}>
				<div className="Routes">
					<Switch>
						{Object.entries(routes).map(
							([name, route]) => {
								if (route.public)
									return <PublicRoute
										exact
										key={name}
										name={name}
										path={route.path}
										Component={route.component}
									/>
								else if (!!route.permission) {
									return <AdminRoute
										exact
										key={name}
										path={route.path}
										ProtectedComponent={route.component}
										routePermissions={route.permission}
									/>
								} else
									return <ProtectedRoute
										exact
										key={name}
										path={route.path}
										ProtectedComponent={route.component}
									/>

						})}
					</Switch>
				</div>
			</Router>
		</AuthContext.Provider>
	</div>
}

export default App
