import React, {useContext} from "react";
import s from "../components/ApplicationRequest.module.css"
import ReactHintFactory from 'react-hint';
import cx from "classnames";
import {ApplicationContext} from "../lib/ApplicationContext";
const ReactHint = ReactHintFactory(React);

function TabOtherInfo () {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors
  //const { data, powerCondition, errors } = this.props;

  return <div className="col-12">
    <div className="form-group">
      <div className="h5">Прочие сведения</div>
    </div>

    <div className="form-group">
      <label>Способ обмена документами <span className={s['red-star']}>*</span> <span data-rh='aggrementtypeSelect' className="hint">?</span></label>

      <select
        className="custom-select"
        value={appData.aggrementtype}
        onChange={e => dispatch({type: "setField", payload: {field: 'aggrementtype', value: e.currentTarget.value}})}
      >
        <option value="0">В электронной форме</option>
        <option value="1">На бумажном носителе</option>
      </select>
    </div>

    <ReactHint
      autoPosition
      events
      onRenderContent={(target, content) => {
        if (content === 'aggrementtypeSelect')
            return <div className="hint-text">
              Взаимодействие до завершения процедуры технологического присоединения с физическими лицами максимальная
              мощность энергопринимающих устройств которых составляет до 15 кВт для бытовых и иных нужд, не связанных
              с осуществлением предпринимательской деятельности и юридическими лицами или индивидуальными предпринимателями
              по второй или третьей категории надежности энергопринимающих устройств, максимальная мощность которых
              составляет до 150 кВт на уровне напряжения 0,4 кВ осуществляется только в электронной форме
            </div>
        }
      }
    />

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="person"
          checked={appData.person}
          onChange={e => dispatch({type: "setField", payload: {field: 'person', value: !appData.person}})}
        />

        <label className="custom-control-label" htmlFor="person">В соответствии с Федеральным законом от 27 июля 2006 года № 152 «О персональных данных», я согласен на использование моих персональных данных, и даю согласие на обработку своих персональных данных по настоящей заявке с целью осуществления технологического присоединения энергопринимающих устройств объекта <span className={s['red-star']}>*</span></label>
      </div>
    </div>

  </div>

}

export default TabOtherInfo;