import React, {useContext, useRef} from "react";
import s from "../components/ApplicationRequest.module.css"
import cx from "classnames";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {ApplicationContext} from "../lib/ApplicationContext";

function TabPersonalId () {
  const { appData, dispatch } = useContext(ApplicationContext)
  const errors = appData.errors.errors
  const datepicker = useRef()

  return <div className="col-12">
      <div className="form-group">
        <div className="h5">Документ, удостоверяющий личность</div>
      </div>
      <div className="form-group">
        <label>Вид документа <span className={s['red-star']}>*</span></label>

        <select
          className="custom-select"
          value={appData.documenttype}
          onChange={e => dispatch({type: "setField", payload: {field: "documenttype", value: e.currentTarget.value}})}
        >
          <option value="pasport">Паспорт</option>
        </select>
      </div>

      {appData.documenttype === 'other' && <div className="form-group">
        <input
          type="text"
          className={cx("form-control", {[s['error-field']]: errors.documenttype})}
          id="documenttypeText"
          value={appData.documenttypeText}
          onChange={e => dispatch({type: "setField", payload: {field: "documenttypeText", value: e.currentTarget.value}})}
          placeholder="Название документа"
        />
      </div>}

    <div className="form-group form-row">
      <div className="col-4">
        <label htmlFor="docseries">Серия <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.docseries})}
          id="docseries"
          value={appData.docseries}
          onChange={e => dispatch({type: "setField", payload: {field: "docseries", value: e.currentTarget.value}})}
        />
      </div>
    </div>

    <div className="form-group form-row">
      <div className="col-4">
        <label htmlFor="docnumber">Номер <span className={s['red-star']}>*</span></label>

        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          guide={false}
          className={cx("form-control", {[s['error-field']]: errors.docnumber})}
          id="docnumber"
          value={appData.docnumber}
          onChange={e => dispatch({type: "setField", payload: {field: "docnumber", value: e.currentTarget.value}})}
        />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="docdepartment">Выдан <span className={s['red-star']}>*</span></label>

      <input
        type="text"
        className={cx("form-control", {[s['error-field']]: errors.docdepartment})}
        id="docdepartment"
        value={appData.docdepartment}
        onChange={e => dispatch({type: "setField", payload: {field: "docdepartment", value: e.currentTarget.value}})}
      />
    </div>

    <div className="form-group form-row">
      <div className="col-4">
        <label htmlFor="docissuedate">Дата выдачи <span className={s['red-star']}>*</span></label>

        <div className="input-group">
          <DatePicker
            className={cx("form-control", {[s['error-field']]: errors.docissuedate})}
            selected={appData.docissuedate}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={e => {dispatch({type: "setField", payload: {field: "docissuedate", value: e}})}}
            ref={datepicker}
          />

          <div
            className="input-group-append"
            style={{cursor: 'pointer'}}
            onClick={() => {datepicker.current.setOpen(true)}}
          >
            <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
          </div>
        </div>
      </div>
    </div>

  </div>
}

export default TabPersonalId;