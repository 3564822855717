import React, {useState, useEffect} from 'react';
import s from './Verify.module.css';
import cx from 'classnames';
import API from '../../../lib/API';
import Button from "../../../components/Button";
import routes from "../../index";

function Verify (props) {
	const [ loading, setLoading ] = useState(false)
	const [ isTokenInvalid, setIsTokenInvalid ] = useState(false)
	const [ success, setSuccess ] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			try {
				await API.authAPI.verify({token: props.token})
				setSuccess(true)
			} catch (e) {
				console.error(e)
				setIsTokenInvalid(true)
			}

			setLoading(false)
		}

		fetchData()
	}, [])

	return (
		<div className={s['main']}>
			<div className="form">
				<div className="form-title">Подтверждение Email</div>

				<div className="form-body">
					{loading && <div className={s['verify-text']}>
						<div>Идет подтверждение Вашего Email, ожидайте</div>

						<div className={cx('spinner-grow', s['spinner'])} role="status">
							<span className="sr-only">Загрузка...</span>
						</div>
					</div>}

					{!loading && success && <div>
						<div className="alert alert-success" role="alert">
							Email успешно подтвержден
						</div>
					</div>}

					{!loading && isTokenInvalid && <div className="text-center">
						<div className="alert alert-danger" role="alert">
							Данная ссылка не действительна
						</div>
						<Button className="btn btn-primary" to={routes.AuthLoginRoute.path}>
							Авторизоваться и получить новую ссылку
						</Button>
					</div>}
				</div>
			</div>
		</div>
	);
}

export default Verify;