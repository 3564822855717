import React, {useContext, useEffect, useRef, useState} from 'react';
import s from './OtherServicesApplications.module.css';
import cx from 'classnames';
import Button from '../../components/Button';
import API from '../../lib/API';
import moment from 'moment';
import routes from "../index";
import {PopupContext} from "../../lib/PopupContext";

function OtherServicesApplications (props) {
	const [ applications, setApplications ] = useState(null)
	const [ statuses, setStatuses] = useState(null)
	const [ services, setServices] = useState(null)
	const [ loading, setLoading ] = useState(true)
	const [ errors, setErrors] = useState(null)
	const [ nullifyInProgress, setNullifyInProgress ] = useState(false)

	const { setPopupSettings } = useContext(PopupContext)

	const newStatus = useRef();
	const nullifyStatus = useRef();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await API.otherServicesApplicationsAPI.get()
				setApplications(data.applications.data)

				const statuses = {};

				data.statuses.map(status => {
					statuses[status.id] = status;
					if (status.system === 'new')
						newStatus.current = status;
					if (status.system === 'nullified')
						nullifyStatus.current = status;

					return null;
				});

				setStatuses(statuses)

				const services = {};
				data.services.map(service => {
					services[service.id] = service
				})

				setServices(services)
			} catch (e) {
				console.log(e)
				setErrors(e?.response?.errors)
			}
			setLoading(false)
		}

		fetchData()

	}, [])

	function nullifyPrompt (index, application) {
		let text = 'Уверены что хотите аннулировать заявку?';
		if (application.application_number.trim())
			text = `Уверены что хотите аннулировать заявку №${application.application_number}?`;

		setPopupSettings({
			type: 'confirm',
			text,
			doAction: () => nullifyOrder(index, application.id)
		})
	}

	async function nullifyOrder(index, applicationId) {
		await setNullifyInProgress(true)

		try {
			await API.otherServicesApplicationsAPI.requestNullify({ application_id: applicationId })
			applications[index].requested_nullify = true
			setApplications([...applications])
			setPopupSettings({
				type: 'info',
				text: 'Ваш запрос на аннулирование заявки направлен в адрес АО «Донэнерго»',
			})
		} catch (e) {
			console.error(e)
			setPopupSettings({
				type: 'info',
				text: e.response.errors,
				error: true
			})
		}

		setNullifyInProgress(false)
	}

	return (
		<div className={s['main']}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<span className="mr-3">Мои заявки</span>

							{/* <Button
								className="btn btn-success"
								to={routes.OtherServicesApplicationRequestRoute.path}
							>Новая заявка</Button> */}

							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Загрузка...</span>
							</div>}
						</div>

						{errors && Object.keys(errors).length > 0 && <div className="mb-3">
							<p>Ошибка загрузки данных</p>
							<Button className="btn btn-success" onClick={() => {window.location.reload()}}>Перезагрузить</Button>
						</div>}
					</div>

					{!loading && <div className="col-12">
						{applications && applications.length > 0 ? <div className={s['wrap-table']}>
							<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
								<tbody>
								<tr>
									<td className="text-center">Услуга</td>
									<td className="text-center">Номер заявки</td>
									<td className="text-center">Дата подачи заявки</td>
									<td>Адрес объекта</td>
									<td className="text-center">Статус заявления</td>
									<td className="text-center">Аннулирование заявки</td>
								</tr>
								{applications.map((item, index) => <tr key={item.id} onClick={() => {
									props.history.push(routes.OtherServicesApplicationRoute.path.replace(":id", item.id))}
								}>
									<td className="text-center">{services[item.other_service_id].name}</td>
									<td className="text-center">{item.application_number}</td>
									<td className="text-center">{moment(item.created_at).format('DD.MM.YYYY')}</td>
									<td>{item && item.connectionaddress ? item.connectionaddress.stringified : null}</td>
									<td className="text-center">
										<div>{statuses[item.status_id].description}</div>
										{item.note && <div>({item.note})</div>}
									</td>
									<td className="text-center">

										{/*{item.status_id !== nullifyStatus.current.id && item.status_id <= newStatus.current.id && !item.requested_nullify && <div className={s['annul-wrap']}>*/}
										{item.status_id !== nullifyStatus.current.id && !item.requested_nullify && <div className={s['annul-wrap']}>

											<Button
												className="btn btn-danger"
												onClick={(e) => {
													e.stopPropagation()
													nullifyPrompt(index, item)
												}}
												loading={nullifyInProgress}
												disabled={nullifyInProgress}
											>Аннулировать</Button>
										</div>}

										{item.requested_nullify && !item.nullified? 'Заявка на аннулирование подана' : null}

									</td>
								</tr>)}
								</tbody>
							</table>
						</div> : <div className="alert alert-warning">
							<span>У вас еще нет заявок</span>
							{/* <Button
								className="btn btn-primary ml-3"
								to={routes.OtherServicesApplicationRequestRoute.path}
							>Новая заявка</Button> */}
						</div>}
					</div>}
				</div>
			</div>
		</div>
	)
}

export default OtherServicesApplications;