import React, {useState} from "react";
import Button from "../Button";
import cx from "classnames";
import s from "../../routes/TechnicalApplication/TechnicalApplication.module.css";
import API from "../../lib/API";

function ToggleInput (props) {
  const [ value, setValue ] = useState(props.defaultValue)
  const [ loading, setLoading ] = useState(false)

  async function setOffer (value) {
    await setLoading(true)
    try {
      await props.setterFunc({application_id: props.applicationId, value})
      setValue(value)
    } catch (e) {
      console.log(e)
      alert("Ошибка загрузки. Проверьте сетевое соединение и повторите попытку позже.") //TODO move to DOM
    }
    setLoading(false)
  }

  return <div className={s['docs__item']}>
    <div className={s['docs__label']}>{ props.text }</div>
    <div className={s['docs__field']}>
      <div>
        <Button
          className={cx('btn', {'btn-outline-primary': value === 'yes' || !value}, {'btn-primary': value === 'no'})}
          disabled={value === 'yes' || !value || loading}
          loading={loading}
          onClick={ () => setOffer('yes') }
        >Да</Button>

        <Button
          className={cx('btn', {'btn-outline-primary': value === 'no'}, {'btn-primary': value === 'yes' || !value})}
          disabled={value === 'no' || loading}
          loading={loading}
          onClick={ () => setOffer('no') }
        >Нет</Button>
      </div>
    </div>
  </div>

}

export default ToggleInput