import React from 'react'
import Layout from '../../../../components/Layout'
import Dictionary, {DefaultDeleteItem, DefaultEditItem} from '../../../../components/Dictionary'
import DictionaryItem from './components/DictionaryItem'
import API from "../../../../lib/API"
import routes from "../../../index";

const NewItem = (props) => <DefaultEditItem
	title="Добавить статус"
	success="Статус добавлен"
	{...props}
/>

const EditItem = (props) => <DefaultEditItem
	title="Изменить статус"
	success="Статус изменен"
	{...props}
/>

const DeleteItem = (props) => <DefaultDeleteItem
	vocabulary={{
		title: "Удаление статуса",
		prompt: "Вы уверены что хотите удалить статус?",
		success: "Статус удален"
	}}
	{...props}
/>

function StatusesDictionaryRoute (props) {
	return (
		<Layout>
			<Dictionary
				backRoute={routes.AdminDashboardTechnicalDictionariesRoute.path}
				dictionaryAPI={ API.statusesAPI }
				dictionaryItem={ DictionaryItem }
				popups={ { NewItem, EditItem, DeleteItem } }
				vocabulary={ { name: "Статусы заявок на технологическое присоединение", headers: {priority: "Приоритет", description: "Описание"} } }
			/>
		</Layout>
	);

}

export default StatusesDictionaryRoute;
