import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './modules';
import reduxReset from 'redux-reset';

export const browserHistory = createBrowserHistory();

const initialState = {
	router: routerMiddleware(browserHistory)
};

const enhancers = [];
const middleware = [
	thunk,
	routerMiddleware(browserHistory)
];

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	reduxReset(),
	...enhancers
);

window.browserHistory = browserHistory;

export default createStore(
	rootReducer,
	initialState,
	composedEnhancers
);
