import React, {useState} from 'react';
import Layout from '../../components/Layout';
import TechnicalApplication from './TechnicalApplication';
import TechnicalApplicationRequest
	from "../ApplicationRequests/TechnicalApplicationRequest/TechnicalApplicationRequest";

function TechnicalApplicationRoute (props) {
	const [ editApplication, setEditApplication ] = useState(null)

	return (
		<Layout>
			{editApplication && <TechnicalApplicationRequest
				typeRequest="editRequest"
				userData={editApplication}
				setEditApplication={setEditApplication}
			/>}
			{!editApplication && <TechnicalApplication
				isAdmin={props.isAdmin}
				history={props.history}
				id={props.match.params.id}
				urlParams={new URLSearchParams(props.location.search)}
				setEditApplication={setEditApplication}
			/>}
		</Layout>
	);
}

export default TechnicalApplicationRoute;
