import React, {useEffect, useState} from 'react';
import s from './AdminEvaluation.module.css';
import cx from 'classnames';
import moment from 'moment';
import API from '../../../lib/API';

function AdminEvaluation (props) {
	const [ loading, setLoading ] = useState(false)
	const [ errors, setErrors ] = useState({})
	const [ data, setData ] = useState(null)
	
	const questionData = {
		channel: {
				1: 'Центр обслуживания клиентов (потребителей)',
				2: 'Internet-приемная',
				3: 'Электронная почта',
		},
		center: {
			1: 'Да',
			2: 'Нет',
		},
		place: {
			1: 'Очень доволен',
			2: 'Доволен',
			3: 'Недоволен',
			4: 'Крайне недоволен',
		},
		service: {
			1: 'Очень доволен',
			2: 'Доволен',
			3: 'Недоволен',
			4: 'Крайне недоволен',
		},
		qualification: {
			1: 'Квалифицированна',
			2: 'Скорее квалифицированна',
			3: 'Неквалифицированна',
			4: 'Полностью неквалифицированна',
		},
		deadlineRequest: {
			1: 'Да',
			2: 'Нет',
		},
		deadlineContract: {
			1: 'Да',
			2: 'Нет',
		},
		serviceTechnical: {
			1: 'Доволен',
			2: 'Недоволен',
			3: 'Крайне недоволен',
		},
		problemPower: {
			1: 'Часто',
			2: 'Редко',
			3: 'Никогда',
		},
		reportProblem: {
			1: 'Да',
			2: 'Нет',
		},
		satisfiedQualification: {
			1: 'Да',
			2: 'Нет',
		},
		satisfiedDeadline: {
			1: 'Доволен',
			2: 'Частично доволен',
			3: 'Недоволен',
			4: 'Не обращался'
		},
		typeServices: {
			1: 'Установка/замена прибора учета',
			2: 'Техническое обслуживание электросетевого оборудования',
			3: 'Испытания и диагностика высоковольтного оборудования, электрозащитных средств',
			4: 'Энергоаудит предприятий, электроустановок',
		},
		satisfiedAdditional: {
			1: 'Да',
			2: 'Нет',
			3: 'Не обращался',
		}
	}
	
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			
			try {
				const response = await API.adminAPI.getVotes()
				setData(response.polls)
			} catch (e) {
				setErrors(e.response.errors)
			}
			
			setLoading(false)
		}
		
		fetchData()
	}, [])
	
	return (
		<div className={s['main']}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<span>Оценка потребителем качества услуг</span>
							
							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Загрузка...</span>
							</div>}
						</div>

						{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
							{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
						</div>}
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-3">
						<div className={s['wrap-table']}>
							{Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
								{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
							</div>}
		
							{!Object.keys(errors).length && <table className={s['table']}>
								<tbody>
									<tr>
										<td rowSpan="2">#</td>
										<td rowSpan="2">ID</td>
										<td rowSpan="2">Наименование</td>
										<td rowSpan="2">Дата</td>
										<td rowSpan="2">Время</td>
										<td colSpan="5">1. Клиентское обслуживание</td>
										<td colSpan="3">2. Технологическое присоединение</td>
										<td colSpan="4">3. Передача электроэнергии</td>
										<td colSpan="2">4. Оказание дополнительных услуг</td>
										<td colSpan="2">5. Ваши пожелания</td>
									</tr>
			
									<tr>
										<td>Какой из каналов взаимодействия с электросетевой компанией для Вас предпочтительнее?</td>
										<td>Известно ли Вам, что по вопросам, относящимся к технологическому присоединению и электроснабжению, Вы можете обращаться в Центр обслуживания клиентов(потребителей) в Вашем регионе?</td>
										<td>Довольны ли Вы месторасположением Центра обслуживания клиентов (потребителей)?</td>
										<td>Довольны ли вы обслуживанием в Центре обслуживания клиентов (потребителей)?</td>
										<td>Квалифицированной была помощь персонала Центра для решения Вашего вопроса?</td>
		
										<td>Соблюдались ли АО «Донэнерго» сроки рассмотрения Вашей заявки на технологическое присоединение?</td>
										<td>Были ли соблюдены сроки выполнения работ по договору со стороны АО «Донэнерго» (от 2 месяцев до 2-х лет)?</td>
										<td>Удовлетворены ли Вы деятельностью сотрудников АО «Донэнерго» при осуществлении процедуры технологического присоединения?</td>
		
										<td>Как часто у Вас возникают перепады напряжения в электросети (мигание света, низкая освещенность, перебои в работе электробытовых приборов и т. д.)?</td>
										<td>Сообщали ли Вы в случае ненадлежащего качества электроэнергии в АО «Донэнерго» ?</td>
										<td>Удовлетворены ли Вы оперативностью и квалификацией работников при рассмотрении Вашего обращения?</td>
										<td>Удовлетворены ли Вы сроками и качеством обслуживания приборов учета электрической энергии специалистами АО «Донэнерго» по Вашим заявкам?</td>
		
										<td>В каких услугах нашей организации Вы больше всего заинтересованы?</td>
										<td>Удовлетворены ли Вы качеством, предоставляемым АО «Донэнерго» дополнительных услуг?</td>
										
										<td>Что, на Ваш взгляд, необходимо изменить, чтобы повысить качество предоставляемых услуг?</td>
										<td>Ваши пожелания по работе взаимодействия с клиентами?</td>
									</tr>
		
									{data && data.map(item => <tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.id}</td>
										<td>{item.name}</td>
										<td>{moment.unix(item.created_at_timestamp).format('DD.MM.YYYY')}</td>
										<td>{moment.unix(item.created_at_timestamp).format('HH:MM')}</td>
		
										<td>{questionData.channel[item.channel] ? questionData.channel[item.channel] : item.channel}</td>
										<td>{questionData.center[item.center]}</td>
										<td>{questionData.place[item.place]}</td>
										<td>{questionData.service[item.service]}</td>
										<td>{questionData.qualification[item.qualification]}</td>
		
										<td>{questionData.deadlineRequest[item.deadlineRequest]}</td>
										<td>{questionData.deadlineContract[item.deadlineContract]}</td>
										<td>{questionData.serviceTechnical[item.serviceTechnical]}</td>
		
										<td>{questionData.problemPower[item.problemPower]}</td>
										<td>{questionData.reportProblem[item.reportProblem]}</td>
										<td>{questionData.satisfiedQualification[item.satisfiedQualification]}</td>
										<td>{questionData.satisfiedDeadline[item.satisfiedDeadline]}</td>
		
										<td>{questionData.typeServices[item.typeServices] ? questionData.typeServices[item.typeServices] : item.typeServices}</td>
										<td>{questionData.satisfiedAdditional[item.satisfiedAdditional]}</td>
		
										<td>{item.quality}</td>
										<td>{item.wishes}</td>
									</tr>)}
								</tbody>
							</table>}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdminEvaluation