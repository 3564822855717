import React, { useContext, useEffect, useRef, useState } from 'react';
import s from './Chat.module.css';
import cx from 'classnames';
import API from '../../lib/API';
import Button from '../Button';
import moment from 'moment';
import { AuthContext } from "../../lib/AuthContext";
import { PopupContext } from "../../lib/PopupContext";

function Chat(props) {
	const [loading, setLoading] = useState(true)
	const [nullifyInProgress, setNullifyInProgress] = useState(false)
	const [showNullifyControls, setShowNullifyControls] = useState(!!props.isAdmin && !props.application.nullified && !!props.application.requested_nullify)
	const [messages, setMessages] = useState(null)
	const [message, setMessage] = useState('')
	const [messageFile, setMessageFile] = useState(null)
	const [messageIsSending, setMessageIsSending] = useState(false)
	const [errors, setErrors] = useState(null)

	const { authContext } = useContext(AuthContext)
	const { setPopupSettings } = useContext(PopupContext)

	const serviceAPI = props.API ? props.API : API.applicationsAPI
	const { application } = props
	const chatWindow = useRef()

	async function getMessagesData() {
		try {
			await setLoading(true)
			let response = await API.feedbackAPI.get({ [props.serviceField]: application.id })
			setMessages(response)
			setLoading(false)
			chatWindow.current && (chatWindow.current.scrollTop = chatWindow.current.clientHeight);
		} catch (e) {
			console.error(e)
			setPopupSettings({
				type: 'info',
				text: e.response.errors,
				error: true
			})
		}
	}

	async function sendMessage() {
		if (!message && !messageFile) return;

		await setMessageIsSending(true)

		const data = {
			[props.serviceField]: application.id,
			message
		};

		if (messageFile)
			data.file = messageFile;

		try {
			await API.feedbackAPI.add(data)
			await getMessagesData()
			setMessage('')
			setMessageFile(null)
		} catch (e) {
			console.log(e)
			setErrors(e.response.errors)
		}

		setMessageIsSending(false)
	}

	async function deleteMessageConfirmation(id) {
		setPopupSettings({
			type: 'confirm',
			text: 'Удалить сообщение?',
			doAction: () => deleteMessage(id)
		})
	}

	async function deleteMessage(id) {
		setLoading(true)

		try {
			await API.feedbackAPI.delete({ id })
			await getMessagesData()
		} catch (e) {
			console.error(e)
			setPopupSettings({
				type: 'info',
				text: e.response.errors,
				error: true
			})
		}
		setPopupSettings({})
		setLoading(false)
	}

	async function nullify() {
		await setNullifyInProgress(true)

		try {
			await serviceAPI.nullify({ application_id: application.id })
			await getMessagesData()
			setShowNullifyControls(false)
			//messageItem.message = 'Успешно аннулирована'
			//messageItem.type = null;
		} catch (e) {
			console.error(e)
			setPopupSettings({
				type: 'info',
				text: e.response.errors,
				error: true
			})
		}

		setNullifyInProgress(false)
	}

	async function cancelNullify() {
		await setNullifyInProgress(true)

		try {
			await serviceAPI.cancelNullify({ application_id: application.id })
			await getMessagesData()
			setShowNullifyControls(false)
			//messageItem.message = 'Аннулирование отменено'
			//messageItem.type = null;
		} catch (e) {
			console.error(e)
			setPopupSettings({
				type: 'info',
				text: e.response.errors,
				error: true
			})
		}

		setNullifyInProgress(false)
	}

	useEffect(() => {

		getMessagesData()

	}, [])

	if (loading) {
		return (<div className={cx('spinner-grow', s['spinner'])} role="status">
			<span className="sr-only">Загрузка...</span>
		</div>)
	} else {
		// console.log(props.application)
		// console.log(authContext)
		const hideInput = authContext?.permissions?.name === "tns_superadmin"
			|| authContext?.permissions?.name === "tns_admin"
			|| authContext?.permissions?.name === "support"
			|| (
				authContext?.admin === false && (props.application.status_id === 1
					|| props.application.status_id === 6
					|| props.application.status_id === 7
					|| props.application.status_id === 8
					|| props.application.status_id === 9
				)
			)

		const canDeleteMessage = authContext.superadmin

		return (
			<div className={s['chat']}>
				{errors && Object.keys(errors).length > 0 && <div className="alert alert-danger" role="alert">
					{Object.keys(errors).map((errorType, index) => errors[errorType].map((errorMsg, indexMsg) => <div key={`${index}${indexMsg}`}>{errorMsg}</div>))}
				</div>}
				<div className={s['messages']} ref={chatWindow}>
					{messages && messages.length > 0 && <>
						{messages.map((messageItem, index) => <div
							key={index}
							className={cx(
								s['messages__item'],
								{ [s['messages__item--priority']]: messageItem.priority > 0 },
								{ [s['messages__item--my']]: messageItem && messageItem.user_id === authContext.userId }
							)}
						>
							<div className={s['messages__inside']}>
								{!!canDeleteMessage && <Button
									className="close"
									aria-label="Close"
									onClick={() => deleteMessageConfirmation(messageItem.id)}
								>
									<span aria-hidden="true">&times;</span>
								</Button>}
								{messageItem.user && messageItem.user.name && <div className={s['messages__name']}>{`${messageItem.user.name}`}</div>}
								<div>{messageItem.text}</div>
								<div className={s['messages__date']}>{moment(messageItem.created_at).format('DD.MM.YYYY HH:MM')}</div>
								{messageItem.file && <div><a href={messageItem.file.url}>{messageItem.file.name}</a></div>}
							</div>
						</div>)}
					</>}

					{!!showNullifyControls && !!props.isAdmin && !application.nullified && !!application.requested_nullify &&
						<div className={cx(s['messages__item'], s['messages__item--my'], s['messages__item--priority'])}>
							<div className={s['messages__inside']}>
								<div>Аннулировать заявку?</div>
							</div>
							<div className="mt-2 text-center">
								<Button
									className="btn btn-success"
									onClick={nullify}
									disabled={nullifyInProgress}
									loading={nullifyInProgress}
								>Подтвердить</Button>
								<Button
									className="btn btn-danger"
									onClick={cancelNullify}
									disabled={nullifyInProgress}
									loading={nullifyInProgress}
								>Отменить</Button>
							</div>
						</div>}
				</div>
				{!props.isAdmin && !application.nullified && !!application.requested_nullify &&
					<div className={cx(s['messages__item'], s['messages__item--my'], s['messages__item--priority'])}>
						<div className={s['messages__inside']}>
							<div>Заявка на аннулирование подана</div>
						</div>
					</div>}
				{!!application.nullified &&
					<div className={cx(s['messages__item'], s['messages__item--my'], s['messages__item--priority'])}>
						<div className={s['messages__inside']}>
							<div>Заявка аннулирована</div>
						</div>
					</div>}
				{!hideInput && <>
					<div className={s['input']}>
						<div className="mt-2">
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									id="messageFile"
									onChange={e => setMessageFile(e.currentTarget.files[0])}
								/>

								<label
									className="custom-file-label"
									htmlFor="messageFile"
								>{messageFile && `${messageFile.name}`}</label>
							</div>
						</div>


						<div className="row mt-3">
							<div className="col-10">
								<textarea
									className={cx('form-control', s['textarea'])}
									id="message"
									value={message}
									onChange={e => setMessage(e.target.value)}
									placeholder="Введите сообщение"
								></textarea>
							</div>
							<div className="col-sm-1 pl-0">
								<Button
									className="btn btn-primary"
									onClick={sendMessage}
									loading={messageIsSending}
									disabled={messageIsSending}
								>
									<img src="/images/send-button.png" />
								</Button>
							</div>
						</div>
					</div>
				</>}
			</div>
		);
	}
}

export default Chat;
