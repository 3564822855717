import React from 'react';
import Layout from '../../../components/Layout';
import Forgot from './Forgot';

function AuthForgotRoute (props)  {
	return (
		<Layout>
			<Forgot />
		</Layout>
	);
}

export default AuthForgotRoute;
