import React, {useState, useRef, useEffect} from "react"
import { Editor } from '@tinymce/tinymce-react';
import s from "../../../components/Dictionary/Dictionary.module.css";
import cx from "classnames";
import Button from "../../../components/Button";
import routes from "../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import API from "../../../lib/API";

function AdminMainPageEditor (props) {
  const [ loading, setLoading ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ html, setHTML] = useState("")
  const editorRef = useRef(null);

  const getHTML = () => {
    if (editorRef.current) {
      return editorRef.current.getContent()
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        const response = await API.adminAPI.getTextBlock({name: 'mainPage'})
        //console.log(response.text)
        setHTML(response.text)
      } catch (e) {
        console.error(e)
      }

      setLoading(false)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const timer = () => setTimeout(() => setSuccess(false), 3000)
    if (success)
      timer()
  }, [success])

  async function updateTextBlock() {
    setLoading(true)

    try {
      await API.adminAPI.updateTextBlock({name: 'mainPage', text: html})
      setSuccess(true)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  return <div className={s['main']}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className={cx('h2', s['title'])}>
            <span>Текст главной страницы</span>
          </div>
          {loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
            <span className="sr-only">Загрузка...</span>
          </div>}

          <div className="mb-4">
            <Button className="btn btn-primary" to={routes.AdminDashboardRoute.path}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              Назад
            </Button>
            <Button className="btn btn-success" disabled={loading}  onClick={updateTextBlock}>
              Сохранить
            </Button>
          </div>

          {success &&
          <div className="alert alert-success" role="alert">
            <span>Текст сохранен</span>
          </div>}
        </div>
      </div>
      <div className="row-cols-1">
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          value={html}
          onEditorChange={(value) => setHTML(value)}
          init={{
            language: 'ru',
            height: 450,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | link image | bullist numlist outdent indent | ' +
              'removeformat | code | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            automatic_uploads: true,
            file_picker_types: 'file image',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              //input.setAttribute('accept', 'image/*');

              /*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
              */

              input.onchange = async function () {
                var file = this.files[0];

                if (meta.filetype === 'file') {
                  const data = {
                    name: 'mainPage',
                    'file': file
                  }
                  const r = await API.adminAPI.uploadFile(data)
                  cb(r.location, { text: file.name});
                }
                if (meta.filetype === 'image') {
                  var reader = new FileReader();
                  reader.onload = async function () {
                    /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache =  editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    console.log('file_picker_callback')

                    cb(blobInfo.blobUri(), { title: file.name });

                  };
                  reader.readAsDataURL(file);
                }
              }

              input.click();
              console.log('file_picker')
            },
            images_upload_handler: (blobInfo, success, failure, progress) => {
              const data = {
                name: 'mainPage',
                'file': new File([blobInfo.blob()], blobInfo.filename())
              }

              return API.adminAPI.uploadFile(data)
            }
          }}
        />
      </div>
    </div>
  </div>
}

export default AdminMainPageEditor