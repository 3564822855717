import React, {useContext, useState} from "react";
import cx from "classnames";
import s from "../AdminOtherServicesApplications.module.css";
import {setStorageItem} from "../../../../lib/utils";
import routes from "../../../index";
import Button from "../../../../components/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {AuthContext} from "../../../../lib/AuthContext";
import API from '../../../../lib/API'
import {SettingsContext} from "../SettingsContext";

function TableRow (props) {
  const { item, selected, selectApplication } = props

  const { authContext } = useContext(AuthContext)
  const {
    history,
    columnSettings,
    statuses,
    departments,
    reasons
  } = useContext(SettingsContext)
  const [ applicationNumber, setApplicationNumber ] = useState(item.application_number)
  const [ applicationNumberUpdateInProgress, setApplicationNumberUpdateInProgress ] = useState(false)
  const [ statusId, setStatusId ] = useState(item.status_id)
  const [ nullified, setNullified ] = useState(item.nullified)
  const [ statusUpdateInProgress, setStatusUpdateInProgress ] = useState(false)
  const [ registeredAt, setRegisteredAt ] = useState(item.registered_at)
  const [ registeredAtUpdateInProgress, setRegisteredAtUpdateInProgress ] = useState(false)
  const [ comment, setComment ] = useState(item.comment)
  const [ commentUpdateInProgress, setCommentUpdateInProgress ] = useState(false)
  //const [ selected, setSelected ] = useState(false)

  const userIsTNSAdmin = authContext.permissions.name === 'tns_admin' || authContext.permissions.name === 'tns_superadmin'

  async function updateApplicationNumber () {
    setApplicationNumberUpdateInProgress(true)

    try {
      await API.otherServicesApplicationsAPI.setApplicationNumber({ application_id: item.id, application_number: applicationNumber })
      item.application_number = applicationNumber
    } catch (e) {
      console.log(e)
    }

    setApplicationNumberUpdateInProgress(false)
  }

  async function updateStatus () {
    setStatusUpdateInProgress(true)

    try {
      await API.otherServicesApplicationsAPI.setApplicationStatus({ application_id: item.id, status_id: statusId })
      item.status_id = statusId
      const nullifyStatus = Object.values(statuses).find(status => status.system === 'nullified')
      setNullified(parseInt(item.status_id) === nullifyStatus.id)
    } catch (e) {
      console.log(e)
    }

    setStatusUpdateInProgress(false)
  }

  async function updateRegisterDate () {
    setRegisteredAtUpdateInProgress(true)

    try {
      await API.otherServicesApplicationsAPI.setApplicationRegisterDate({ application_id: item.id, register_date: moment(registeredAt).format('YYYY-MM-DD') })
      item.registered_at = registeredAt
    } catch (e) {
      console.log(e)
    }

    setRegisteredAtUpdateInProgress(false)
  }

  async function updateComment () {
    setCommentUpdateInProgress(true)

    try {
      await API.otherServicesApplicationsAPI.setComment({ application_id: item.id, comment })
      item.comment = comment
    } catch (e) {
      console.log(e)
    }

    setCommentUpdateInProgress(false)
  }

  let datepicker

  return <div
    className={cx(s['table-row'], {[s['table-annul']]: nullified}, {[s['table-unviewed']]: !item.viewed || (!item.viewed && !selected)})}
    key={item.id}
    onClick={() => {
      //setStorageItem('adminOrdersPage', page)
      history.push(routes.AdminOtherServicesApplicationRoute.path.replace(":id", item.id))
    }}
  >
    <div className={s['table-cell']}>
      {item.unviewed_total ? <div className={s['unviewed']}>
        {item.unviewed_total}
      </div> : null}
    </div>

    <div className={s['table-cell']} onClick={(e) => {e.stopPropagation()}}>
      <div className="custom-control custom-checkbox custom-checkbox-simple">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`flags${item.id}`}
          checked={selected}
          onChange={() => {
            if (selected) {
              //setSelected(false)
              selectApplication(item.id, false)
            } else {
              //setSelected(true)
              selectApplication(item.id, true)
            }
          }}
        />

        <label className="custom-control-label" htmlFor={`flags${item.id}`} />
      </div>
    </div>

    {columnSettings.id && <div className={s['table-cell']}>
      <div>{item.id}</div>
    </div>}

    {columnSettings.number && <div
      className={cx(s['table-cell'], s['table-cell--number'])}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <input
        type="text"
        value={applicationNumber}
        className={cx('form-control', s['input'], {[s['unsized']]: !applicationNumber})}
        readOnly={userIsTNSAdmin}
        onChange={(e) => setApplicationNumber(e.target.value)}
        size={applicationNumber ? applicationNumber.length : null}
      />
      {/*{console.log(applicationNumber.toLowerCase(), item.application_number.toString().toLowerCase())}*/}
      {applicationNumber.toLowerCase() !== item.application_number.toString().toLowerCase() && <div className="mt-2">
        <Button
          className="btn btn-primary btn-sm"
          onClick={updateApplicationNumber}
          loading={applicationNumberUpdateInProgress}
          disabled={applicationNumberUpdateInProgress}
        >Сохранить</Button>
      </div>}
    </div>}

    <div className={s['table-cell']}>{moment(item.created_at).format('DD.MM.YYYY')}</div>

    {columnSettings.status_id && <div
      className={cx(s['table-cell'], s['table-cell--statuses'])}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {userIsTNSAdmin ? <div>
        {statuses[statusId].description}
      </div> : <select
        className="custom-select"
        value={statusId ? statusId : 0}
        onChange={e => setStatusId(e.target.value)}
      >
        {/*this.getStatusesOptions(statuses, item)*/}
        {Object.keys(statuses).map(key => <option key={key} value={key}>{statuses[key].description}</option>)}
      </select>}

      {Number(statusId) !== Number(item.status_id) && <Button
        className="btn btn-primary ml-3"
        onClick={updateStatus}
        loading={statusUpdateInProgress}
        disabled={statusUpdateInProgress}
      >Сохранить</Button>}

      {item.note && <div>({item.note})</div>}
    </div>}
    {columnSettings.dateReg && <div
      className={cx(s['table-cell'], s['table-cell--date'])}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={s['date']}>
        <div className="input-group">
          <DatePicker
            className="form-control"
            readOnly={userIsTNSAdmin}
            selected={registeredAt ? new Date(registeredAt) : ''}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            //popperPlacement={props.key < 16 ? 'bottom-end' : 'top-end'}
            onChange={(date) => setRegisteredAt(date)}
            ref={(c) => datepicker = c}
          />

          <div
            className="input-group-append"
            style={{cursor: 'pointer'}}
            onClick={() => {datepicker.setOpen(true)}}
          >
            <span className="input-group-text" id="basic-addon2"><FontAwesomeIcon icon={faCalendar} /></span>
          </div>
        </div>
      </div>

      {moment(registeredAt).format('DD.MM.YYYY') !== moment(item.registered_at).format('DD.MM.YYYY') && <Button
        className="btn btn-primary"
        onClick={updateRegisterDate}
        loading={registeredAtUpdateInProgress}
        disabled={registeredAtUpdateInProgress}
      >Сохранить</Button>}
    </div>}

    {columnSettings.name && <div className={s['table-cell']}>
      {(item.type === 'fiz' || item.type === 'ur') && <div>{item.name}</div>}
      {item.type === 'ip' && <div>ИП {item.name}</div>}
    </div>}

    {columnSettings.paycheck && <div className={s['table-cell']}>
      {item.paychecks && item.paychecks.map(paycheck => {
        if (paycheck.paid)
          return <div className={s['paycheck']}>№{paycheck.number} {parseFloat(paycheck.sum).toFixed(2)} р. {paycheck.authTimestamp ? '(' + moment(paycheck.authTimestamp).format('DD.MM.YYYY HH:m:s') + ')' : ''}</div>
      })}
    </div>}

    <div className={s['table-cell']}>
      <div>{departments[item.department_id] && departments[item.department_id].name}</div>
    </div>

    {columnSettings.address && <div className={s['table-cell']}>
      {item.connectionaddress ? <div className={s['address']}>
        {item.connectionaddress && item.connectionaddress.stringified}
      </div> : null}
    </div>}

    {columnSettings.docs && <div className={s['table-cell']} onClick={(e) => {e.stopPropagation()}}>
      <div className={s['docs']}>
        {item.warrant && item.warrant.length > 0 && item.warrant.map((item, index) => <div key={index}><a href={item.url}>
          Сканированная копия доверенности представителя {index + 1}
        </a></div>)}
        {item.plan && item.plan.length > 0 && item.plan.map((item, index) => <div key={index}><a href={item.url}>
          План расположения энергопринимающих устройств {index + 1}
        </a></div>)}
        {item.accident && item.accident.length > 0 && item.accident.map((item, index) => <div key={index}><a href={item.url}>
          Перечень и мощность энергопринимающих устройств {index + 1}
        </a></div>)}
        {item.constitutivedocuments && item.constitutivedocuments.length > 0 && item.constitutivedocuments.map((item, index) => <div key={index}><a href={item.url}>
          Правоустанавливающие документы {index + 1}
        </a></div>)}
        {item.otherdocuments && item.otherdocuments.length > 0 && item.otherdocuments.map((item, index) => <div key={index}><a href={item.url}>
          Прочие документы {index + 1}
        </a></div>)}
      </div>
    </div>}

    {columnSettings.comments && <div
      className={cx(s['table-cell'], s['table-cell-controls'])}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className="form-group mb-0">
          <textarea
            className={cx('form-control', s['textarea'])}
            value={comment}
            rows="3"
            readOnly={userIsTNSAdmin}
            onChange={(e) => {setComment(e.currentTarget.value)}}
          ></textarea>
      </div>

      {comment !== item.comment && <Button
        className="btn btn-primary btn-sm mt-2"
        onClick={(e) => {
          e.stopPropagation()
          updateComment()
        }}
        loading={commentUpdateInProgress}
        disabled={commentUpdateInProgress}
      >Сохранить</Button>}
    </div>}

    {columnSettings.aggrementtype && <div className={s['table-cell']}>
      {item.aggrementtype === 0 && <div>По email</div>}
      {item.aggrementtype === 1 && <div>На бумажном носителе</div>}
    </div>}

    {/*<div*/}
    {/*  className={s['table-cell']}*/}
    {/*  onClick={(e) => {*/}
    {/*    e.stopPropagation()*/}
    {/*  }}*/}
    {/*>*/}
    {/*  {item.application_file && <a*/}
    {/*    className="btn btn-primary"*/}
    {/*    href={item.application_file.url}*/}
    {/*  >Скачать</a>}*/}
    {/*</div>*/}

    {columnSettings.phone && <div className={s['table-cell']}>{item.phone}</div>}

  </div>
}

export default TableRow