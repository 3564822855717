import React, {useContext, useRef, useState} from "react";
import s from "../../routes/TechnicalApplication/TechnicalApplication.module.css";
import cx from "classnames";
import Button from "../Button";
import API from "../../lib/API";
import {AuthContext} from "../../lib/AuthContext";
import {PopupContext} from "../../lib/PopupContext";
import moment from "moment";

function UploadFileInput (props) {
  const [ loading, setLoading ] = useState(false)
  const [ value, setValue ] = useState('')
  const [ url, setURL ] = useState(props.url)
  const [ uploadedAt, setUploadedAt ] = useState(props.uploaded_at)

  const { applicationId, name, viewed } = props;

  const serviceAPI = props.API ? props.API : API.applicationsAPI

  const { authContext } = useContext(AuthContext)
  const { setPopupSettings } = useContext(PopupContext)

  const inputRef = useRef()

  async function sendFile () {
    await setLoading(true)
    let response;
    try {
      response = await serviceAPI.uploadAgreementFile({ application_id: applicationId, [name]: value });
      setURL(response.url)
      setUploadedAt(response.uploaded_at)
      setValue(null)
      inputRef.current.value = ""
    } catch (e) {
      console.error(e)
      setPopupSettings({
        type: 'info',
        text: "Ошибка загрузки. Проверьте сетевое соединение и повторите попытку позже."
      })
    }
    setLoading(false)
  }

  async function deleteFile () {
    setLoading(true)

    try {
      await serviceAPI.deleteAgreementFile({application_id: applicationId, name: props.name})
      setPopupSettings({})
      setURL(null)
      setValue('')
      setUploadedAt(null)
      inputRef.current.value = ""
    } catch (e) {
      console.error(e)
      setPopupSettings({
        type: 'info',
        text: "Ошибка загрузки. Проверьте сетевое соединение и повторите попытку позже."
      })
    }

    setLoading(false)
  }

  function deleteFileConfirm () {
    setPopupSettings({
      type: 'confirm',
      text: 'Удалить файл "' + props.text + '" ?',
      doAction: deleteFile
    })
  }

  return <div className={s['docs__item']}>
    <div className={s['docs__label']}>{ props.text }</div>
    <div className={s['docs__field']}>
      {url && <>
      <a
        className={cx('btn', 'btn-primary', 'mr-2', {[s['unviewed-btn']]: !viewed})}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        Скачать
      </a>
        {authContext.superadmin && <button
          className="btn btn-danger mr-2"
          onClick={deleteFileConfirm}
        >
          Удалить
        </button>}
      </>}

      <div className={cx('custom-file', s['docs__file'], {[s['docs__file--new']]: url})}>
        <input
          type="file"
          className="custom-file-input"
          id={name}
          ref={inputRef}
          onChange={(e) => {
            console.log(e)
            setValue(e.currentTarget.files[0])
          }}
        />

        <label
          className="custom-file-label"
          htmlFor={name}
        >{value && `${value.name}`}</label>
      </div>

      {value && <Button
        className="btn btn-primary ml-2"
        onClick={ sendFile }
        loading={loading}
        disabled={loading}
      >Отправить</Button>}
    </div>
    {uploadedAt && <sup>Загружен {moment(uploadedAt).format("DD.MM.YYYY HH:mm:ss")}</sup>}
  </div>
}

export default UploadFileInput