import React, {Component, useState} from "react";
import cx from "classnames";
import s from "../components/ApplicationRequest.module.css"
import Select from 'react-select';

function SelectOther(props)  {

  // function searchValue(value) {
  //   let found = false;
  //   const { options } = this.props;
  //
  //   let i = 0;
  //   while (!found && i < options.length) {
  //     if (options[i].value == value)
  //       found = options[i];
  //     i++;
  //   }
  //
  //   return found;
  // }
  const { id, options, value, error, setValue } = props
  //console.log(options)
  const selectValue = options.find((v) => {
    if (v.value == value)
      return v
  })

  const [ showOther, setShowOther ] = useState(props.value && !selectValue)
//console.log(props.value)
  return <>
    {!showOther && <Select
      styles={{
        control: (provided) => {
          if (error)
            return {
              ...provided,
              borderColor: '#dc3545'
            }
          else
            return provided;
        }
      }}
      options={options}
      value={selectValue}
      onChange={(newValue) => {setValue(newValue.value)}}
    />}
    <div className="custom-control custom-checkbox mt-3 mb-3">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id + 'toggle'}
        checked={showOther}
        onChange={() => {setShowOther(!showOther); setValue("")}}
      />

      <label className="custom-control-label" htmlFor={id + 'toggle'}>Иное</label>
    </div>

    {showOther && <input
      type="text"
      className={cx("form-control", {[s['error-field']]: error})}
      value={value}
      onChange={(e) => {setValue( e.currentTarget.value )}}
    />}
  </>
}

export default SelectOther;

