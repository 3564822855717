import React from 'react';
import s from './InfoModal.module.css';
import cx from 'classnames';
import Button from '../Button';

function QRModal(props) {
  const {visible, title, text, image, closePopup} = props;

  return (
    <div className={cx('modal', {[s['modal--active']]: visible})} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>

            <Button
              className="close"
              aria-label="Close"
              onClick={closePopup}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>

          <div className="modal-body">
            <div className="text-center">
              <img src={image} alt="QR code"/>
              <div>{text}</div>
            </div>
          </div>

          <div className="modal-footer">
            <Button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closePopup}
            >Ок</Button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default QRModal;
