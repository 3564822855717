import React, {useContext, useEffect, useState} from 'react';
import Layout from '../../../../components/Layout';
import PageSpinner from '../../../../components/PageSpinner';
import { Redirect } from 'react-router-dom';
import Dictionary, {DefaultDeleteItem} from "../../../../components/Dictionary";
import DictionaryItem from "./components/DictionaryItem";
import EditItem from "./components/EditItem";
import API from "../../../../lib/API";
import {AuthContext} from "../../../../lib/AuthContext";
import routes from "../../../index";

const DeleteItem = (props) => <DefaultDeleteItem
	vocabulary={{
		title: "Удаление услуги",
		prompt: "Вы уверены что хотите удалить услугу?",
		success: "Услуга удалена"
	}}
	{...props}
/>

function OtherServicesDictionaryRoute (props) {
	const [ serviceTypes, setServiceTypes ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			try {
				const result = (await API.otherServicesTypesDictionaryAPI.get()).data.data;
				const newServiceTypes = new Map()
				result.forEach(item => {
					newServiceTypes.set(item.id, item.name);
				})
				setServiceTypes(newServiceTypes)
			} catch (e) {
				console.error(e);
			}

			setLoading(false)
		}

		fetchData()
	}, [])

	if (loading || !serviceTypes) {
		return <PageSpinner/>;
	} else if (serviceTypes) {
		const ExtendedDictionaryItem = (props) => <DictionaryItem serviceTypes={serviceTypes} {...props}/>;
		const ExtendedEditItem = (props) => <EditItem serviceTypes={serviceTypes} {...props}/>;

		return (
			<Layout>
				<Dictionary
					backRoute={routes.AdminDashboardOtherServicesDictionariesRoute.path}
					dictionaryAPI={API.otherServicesDictionaryAPI}
					vocabulary={{
						name: "Типы прочих услуг",
						headers: {
							id: "ID",
							other_services_type_id: "Группа услуг",
							priority: "Положение в списке",
							name: "Наименования",
							units: "Ед. изм.",
							price: "Цена"
						}
					}}
					dictionaryItem={ExtendedDictionaryItem}
					popups={{
						EditItem: ExtendedEditItem,
						DeleteItem
					}}
				/>
			</Layout>
		);
	}
}

export default OtherServicesDictionaryRoute;
