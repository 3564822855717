import React, {Component, useContext, useEffect, useState} from 'react';
import s from './OtherServicesApplication.module.css';
import cx from 'classnames';
import { docTypes } from '../../lib/utils';
import API from '../../lib/API';
import moment from 'moment';
import Button from '../../components/Button';
import Chat from '../../components/Chat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import routes from "../index";
import FullAddressTable from "../../components/FullAddressTable/FullAddressTable";
import UserDocuments from "./components/UserDocuments";
import AdminDocuments from "./components/AdminDocuments";
import UserPaychecks from "./components/UserPaychecks";
import AdminPaychecks from "./components/AdminPaychecks";
import {AuthContext} from "../../lib/AuthContext";
import {PopupContext} from "../../lib/PopupContext";

function OtherServicesApplication (props) {
	const [application, setApplication] = useState(null)
	const [paychecks, setPaychecks] = useState(null)
	const [statuses, setStatuses] = useState(null)
	const [departments, setDepartments] = useState(null)

	const [appStatus, setAppStatus] = useState(null)

	const [statusPayment, setStatusPayment] = useState(props.urlParams.get('statusPayment'))
	const [loading, setLoading] = useState(true)
	const [page, setPage] = useState(statusPayment ? 3 : 1)
	const [errors, setErrors] = useState(null)

	const applicationId = props.id
	const { authContext } = useContext(AuthContext)
	const isAdmin = props.isAdmin

	const { setPopupSettings } = useContext(PopupContext)

	async function updateDepartment (department_id) {
		try {
			await API.otherServicesApplicationsAPI.setDepartment({application_id: application.id, department_id})
			setPopupSettings({
				type: 'info',
				text: 'Филиал изменен'
			})
		} catch (e) {
			console.error(e.response.errors)
			setPopupSettings({
				type: 'info',
				text: 'Ошибка изменения филиала',
				error: true
			})
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await API.otherServicesApplicationsAPI.getById({id: applicationId})
				setApplication(data.application)
				setStatuses(data.statuses)
				setPaychecks(data.paychecks)
				setDepartments(data.departments)

				const status = data.statuses.find(status => {
					return status.id === data.application.status_id
				})
				setAppStatus(status)
			} catch (e) {
				console.log(e)
				setErrors(e?.response?.errors)
			}
			setLoading(false)
		}

		fetchData()

	}, [])

	return (
		<div className={s['main']}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className={cx('h2', s['title'])}>
							<Button
								className="btn btn-primary"
								//onClick={() => props.history.goBack()}
								to={isAdmin ? routes.AdminOtherServicesApplicationsRoute.path : routes.OtherServicesApplicationsRoute.path}
							>
								<FontAwesomeIcon icon={faArrowAltCircleLeft}/>
								Назад
							</Button>

							{ isAdmin && !!authContext?.permissions.other_services_applications && appStatus?.system === 'new' && <Button
								className="btn btn-primary"
								onClick={() => props.setEditApplication(application)}
							>
								Редактировать
							</Button>}

							{application?.application_number && <span>Номер заявки {application.application_number}</span>}

							{loading && <div className={cx('spinner-grow', s['spinner'])} role="status">
								<span className="sr-only">Загрузка...</span>
							</div>}
						</div>
						<h2>{application?.other_service.name}</h2>
						{errors && Object.keys(errors).length > 0 && <div className="mb-3">
							<p>Ошибка загрузки данных</p>
							<Button className="btn btn-success" onClick={() => {
								window.location.reload()
							}}>Перезагрузить</Button>
						</div>}
					</div>

					{application && <div className="col-12">
						<ul className="nav nav-tabs">
							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 1})}
										aria-selected="true"
										onClick={() => setPage(1)}
									>Данные по заявке</span>
							</li>

							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 2})}
										aria-selected="true"
										onClick={() => setPage(2)}
									>
										<span>Исполнение заявки</span>

										{application && application.unviewed_files ? <div className={s['unviewed']}>
											{application.unviewed_files}
										</div> : null}
									</span>
							</li>

							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 3})}
										aria-selected="true"
										onClick={() => {
											setPage(3)
											//setPaymentError(null)
										}}
									>Оплата по договору</span>
							</li>

							<li className="nav-item">
									<span
										className={cx('nav-link', {'active': page === 4})}
										aria-selected="true"
										onClick={() => {
											setPage(4)
											//setPaymentError(null)
										}}
									>
										<span>Обратная связь</span>

										{application && (application.unviewed_messages || application.unviewed_request_nullify) ? <div className={s['unviewed']}>
											{parseInt(application.unviewed_messages) + parseInt(application.unviewed_request_nullify)}
										</div> : null}
									</span>
							</li>
						</ul>
					</div>}

					{application && <>
						{page === 1 && <div className="col-12">
							{authContext.admin && (authContext.permissions.name === 'superadmin' || authContext.permissions.name === 'technical_superadmin')
							&& departments && <>
								<div className="h5">Филиал</div>
								<div>
									<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
										<tbody>
										<tr>
											<td>Филиал Донэнерго</td>
											<td>
												<select
													className="custom-select"
													defaultValue={application.department_id}
													onChange={(e) => updateDepartment(e.target.value)}
												>
													{departments.map(item => <option value={item.id}>{item.name}</option>)}
												</select>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</>}

							<div className="h5">Данные заявителя</div>

							<div>
								<table className={cx('table', 'table-striped', s['table'], s['table-data'])}>
									<tbody>
									{application.type ? <tr>
										<td>Тип</td>
										<td>{application.type === 'fiz' ?
											'Физическое лицо' :
											application.type === 'ip' ? 'Индивидуальный предприниматель' :
												application.type === 'ur' ? 'Юридическое лицо' : 'Неопределено'}
										</td>
									</tr> : null}

									{application.name ? <tr>
										<td>Наименование заявителя</td>
										<td>{application.name}</td>
									</tr> : null}

									{application.inn ? <tr>
										<td>ИНН</td>
										<td>{application.inn}</td>
									</tr> : null}

									{application.snils ? <tr>
										<td>СНИЛС</td>
										<td>{application.snils}</td>
									</tr> : null}

									{application.egrip ? <tr>
										<td>ЕГРИП</td>
										<td>{application.egrip}</td>
									</tr> : null}

									<tr>
										<td>Адрес объекта подключения</td>
										<td>{application?.connectionaddress.stringified}</td>
									</tr>

									{application.email ? <tr>
										<td>Email</td>
										<td>{application.email}</td>
									</tr> : null}

									{application.phone ? <tr>
										<td>Номер телефона</td>
										<td>{application.phone}</td>
									</tr> : null}
									</tbody>
								</table>
							</div>

							{application.document && <div>
								<div className="h5">Документ, удостоверяющий личность</div>

								<div className={cx(s['doc'])}>
									<div>{`${docTypes[application.document.documenttype]} Серия ${application.document.docseries} Номер ${application.document.docnumber} Выдан ${application.document.docdepartment} ${moment(application.document.docissuedate).format('DD.MM.YYYY')}`}</div>
								</div>
							</div>}

							{application.regaddress && <div>
								<div className="h5">Адрес места регистрации</div>

								<FullAddressTable address={application.regaddress}/>
							</div>}

							{application.liveaddress && <div>
								<div className="h5">Адрес места жительства</div>

								<FullAddressTable address={application.liveaddress}/>

							</div>}

							{application.uraddress && <div>
								<div className="h5">Юридический адрес</div>

								<FullAddressTable address={application.uraddress}/>
							</div>}

							{application.postaddress && <div>
								<div className="h5">Почтовый адрес</div>

								<FullAddressTable address={application.postaddress}/>

							</div>}

							{application.additionalconditions && <div>
								<div className="h5">Дополнительные условия</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									<tr>
										<td>От моего имени будет выступать представитель по доверенности</td>
										<td>{application.additionalconditions.additionalconditionsrepresent ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Осуществляется присоединение устройств, находящихся в нежилых помещениях многоквартирного дома
										</td>
										<td>{application.additionalconditions.additionalconditionsuninhabited ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Проектом на многоквартирный дом для нежилого помещения предусмотрено индивидуальное
											вводно-распределительное устройство
										</td>
										<td>{application.additionalconditions.additionalconditionsindividual ? 'Да' : 'Нет'}</td>
									</tr>

									<tr>
										<td>Энергопринимающие устройства будут присоединены к устройствам противоаварийной и режимной
											автоматики
										</td>
										<td>{application.additionalconditions.additionalconditionsemergency ? 'Да' : 'Нет'}</td>
									</tr>
									</tbody>
								</table>
							</div>}

							<div>
								<div className="h5">Документы</div>

								<table className={cx('table', 'table-striped', s['table'])}>
									<tbody>
									{application.type === 'fiz' && <tr>
										<td>Сканированная копия паспорта физического лица:</td>
										<td>{application.passportfiz && application.passportfiz.length > 0 && application.passportfiz.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									{application.type === 'ur' && <tr>
										<td>Сканированная копии документов ЕГРЮЛ:</td>
										<td>{application.egrnum_docs && application.egrnum_docs.length > 0 && application.egrnum_docs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									{application.type === 'ip' && <tr>
										<td>Сканированная копии документов ЕГРИП:</td>
										<td>{application.egrnum_docs && application.egrnum_docs.length > 0 && application.egrnum_docs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>}

									<tr>
										<td>Сканированная копия доверенности представителя:</td>
										<td>{application.warrant && application.warrant.length > 0 && application.warrant.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Документы для индивидуального вводно-распределительное устройства:</td>
										<td>{application.conditionsindividualdocs && application.conditionsindividualdocs.length > 0 && application.conditionsindividualdocs.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>План расположения энергопринимающих устройств:</td>
										<td>{application.plan && application.plan.length > 0 && application.plan.map((item, index) => <div
											key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Перечень и мощность энергопринимающих устройств, которые могут быть присоединены к устройствам
											противоаварийной автоматики:
										</td>
										<td>{application.accident && application.accident.length > 0 && application.accident.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>

									<tr>
										<td>Прочие документы:</td>
										<td>{application.otherdocuments && application.otherdocuments.length > 0 && application.otherdocuments.map((item, index) =>
											<div key={index}><a href={item.url}>{item.name}</a></div>)}</td>
									</tr>
									</tbody>
								</table>
							</div>

							<div>
								<div className="h5">Прочие сведения</div>
							</div>
							<div>
								{application && application.aggrementtype === 0 &&
								<div className="alert alert-warning" role="alert">При подаче заявки был выбран способ получения договора
									по email</div>}
								{application && application.aggrementtype === 1 &&
								<div className="alert alert-warning" role="alert">При подаче заявки был выбран способ получения договора
									на бумажном носителе</div>}
							</div>

						</div>}

						{page === 2 && <div className="col-12">
							{application && application.application_file && <a
								className="btn btn-primary"
								href={application.application_file.url}
							>Скачать сгенерированную заявку</a>}

							{!isAdmin &&
							<UserDocuments
								applicationId={applicationId}
								agreement_files={application.agreement_files}
							/>
							}

							{isAdmin &&
							<AdminDocuments
								applicationId={applicationId}
								agreement_files={application.agreement_files}
							/>
							}

						</div>}

						{page === 3 && !isAdmin && <UserPaychecks
							paychecks={paychecks}
							statusPayment={statusPayment}
						/>}

						{page === 3 && isAdmin && <AdminPaychecks
							applicationId={applicationId}
							paychecks={paychecks}
							statusPayment={statusPayment}
							setPaychecks={setPaychecks}
						/>}

						{page === 4 && <div className="col-12">
							<Chat
								application={application}
								type="user"
								serviceField="other_services_application_id"
								API={API.otherServicesApplicationsAPI}
								isAdmin={isAdmin}
							/>
						</div>}
					</>}
				</div>
			</div>
		</div>
	)
}

export default OtherServicesApplication