import React, {Component} from "react";
import Select, { components } from 'react-select';

class ConnectionCitySelect extends Component{
  render() {
    const { id, options, value, error, setAddressPart } = this.props;

    return <Select
      id={id}
      components={{
        Input: ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />
      }}
      styles={{
        singleValue: (provided, state) => ({
          ...provided,
          display: state.selectProps.menuIsOpen ? 'none' : 'block',
        }),
        control: (provided) => {
          if (error)
            return {
              ...provided,
              borderColor: '#dc3545'
            }
          else
            return provided;
        }
      }}
      isSearchable
      loadingMessage={() => 'Загрузка ...'}
      noOptionsMessage={() => <p class="alert-danger">Населенный пункт вне зоны обслуживания АО "Донэнерго"</p>}
      placeholder="Начните вводить название населенного пункта и выберите из выпадающего списка"
      value={{label: value}}
      options={options}
      onChange={setAddressPart}
    />
  }
}

export default ConnectionCitySelect;