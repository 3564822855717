import React, {useContext} from 'react';
import s from './Technical.module.css';
import Button from '../../components/Button';
import routes from "../index";
import {AuthContext} from "../../lib/AuthContext";

function Technical (props) {
	const { isAdmin, mainPageTextBlock } = props
	const { authContext } = useContext(AuthContext)

	return (
		<div className={s['main']}>
			<div className={s['technical']}>
				<div className={s['banner']} style={{ backgroundImage: `url(./images/bg.png)` }}>
					<div className="container">
						<div className={s['banner-title']}>Технологическое присоединение</div>

						{isAdmin ? <div className={s['banner-btn']}>
							<Button to="/admin" className="btn-app btn-app-forward">Админ. консоль</Button>
						</div> : <div className={s['banner-btn']}>
							<Button to="/cabinet" className="btn-app btn-app-forward">Личный кабинет</Button>
						</div>}
						{authContext?.authIsOff && <div className={s['auth-danger']}>
							<span>Сервис временно не доступен. Приносим извинения за доставленные неудобства.</span>
						</div>}
					</div>
				</div>

				<div className={s['links']}>
					<div className="container">
						<div className={s['links-wrap']}>
							<div className={s['links-block']}>
								<Button className={s['link']} to={routes.TechnicalApplicationRequestRoute.path}>
									<span className={s['link-img']}><img src="/images/request.svg" alt=""/></span>
									<span className={s['link-text']}>Подать заявку на технологическое присоединение</span>
								</Button>
							</div>

							<div className={s['links-block']}>
								<Button className={s['link']} to={routes.OtherServicesApplicationRequestRoute.path}>
									<span className={s['link-img']}><img src="/images/request.svg" alt=""/></span>
									<span className={s['link-text']}>Подать заявку на прочие услуги</span>
								</Button>
							</div>

							<div className={s['links-block']}>
								<Button className={s['link']} to={routes.TechnicalCalcRoute.path}>
									<span className={s['link-img']}><img src="/images/cost.svg" alt=""/></span>
									<span className={s['link-text']}>Рассчитать стоимость технологического присоединения</span>
								</Button>
							</div>

							{/*<div className={s['links-block']}>*/}
							{/*	<Button className={s['link']} to={routes.route_technical_power.path}>*/}
							{/*		<span className={s['link-img']}><img src="/images/power.svg" alt=""/></span>*/}
							{/*		<span className={s['link-text']}>Рассчитать необходимую мощность</span>*/}
							{/*	</Button>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>

				<div className={s['info']}>
					<div className="container" dangerouslySetInnerHTML={{__html: mainPageTextBlock}} >
					</div>
				</div>
			</div>
		</div>
	);
}

export default Technical;