import React from "react";
import cx from "classnames";
import s from "../AdminUsers.module.css";
import TechnicalApplicationRequest from "../../../../ApplicationRequests/TechnicalApplicationRequest/TechnicalApplicationRequest";

function CreateTechnicalRequest (props) {
  const {item, closePopup} = props;

  return <div className={cx('modal', s['modal'])}>
    <TechnicalApplicationRequest
      typeRequest="createRequest"
      userData={ item }
      close={closePopup}
    />
  </div>
}

export default CreateTechnicalRequest;