import React from "react";
import cx from "classnames";
import s from "../AdminUsers.module.css";
import Registration from "../../../../auth/registration/Registration";

function EditItem (props)  {
  const {item, update, closePopup} = props;

  return <div className={cx('modal', s['modal'])}>
    <Registration
      typeReg={item ? 'adminEditUser' : 'adminAddUser'}
      user={item}
      onSuccess={update}
      close={closePopup}
    />
  </div>
}

export default EditItem;