export const SET_PROFILE_DATA = 'setProfileData/SET_PROFILE_DATA';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA:
            return {
                ...state,
                data: action.data
            }

        default:
            return state
    }
};

export const setProfileData = (props) => {
    return dispatch => {
        dispatch({
            type: SET_PROFILE_DATA,
            data: props
        })
    }
};
